import React from 'react';
import Nav from '../Nav'
import Footer from '../Footer'
import Refund from './refund'

const Privact = () => {
    return (
        <div>
            <Nav />
            <div className = ''>
                <Hero/>
                <PrivacyDoc />
                <Footer />
            </div>
        </div>
    )
}
export default Privact




const Hero = () => {
    return (
        <div class="pt-6 pb-5">
            <div class="container text-center">
                <div class="row justify-content-center mb-5">
                    <div class="col-lg-8">
                        <h1 class="mt-5 display-3 mb-2">Payment, Refund and Cancellation Policy</h1>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}





const PrivacyDoc = () => {
    return (
        <div class="pb-5">
        <div class="container ">
            <div class="row justify-content-center mb-5">
                <div class="col-lg-12">
                    <Refund />
                </div>
            </div>
        </div>
    </div>
    )
}