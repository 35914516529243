import content from '../../../content/landingPage.json'


const Complete = (props) => {
    return (
        <div class = 'container px-lg-6 px-5 mb-6' >
            <div className="row justify-content-center align-items-center" style = {{height: '70vh'}}>
                <div className="col-12 col-lg mt-6 ">
                    <h1 class="h1 mb-4">Thank you!</h1>
                    <div className="p mb-2 mt-3">{content.buySessions.complete.description1}</div>
                    <div className="p mt-3">{content.buySessions.complete.description2}</div>
                    <div className="btn btn-primary mt-5" onClick = {() => props.history.push('/my-dashboard/schedule')}>
                        Check your schedule
                    </div>
                </div>
                <div class ="col-12 col-lg-5 d-none d-lg-block">
                    <img alt="" class = 'img-fluid mt-lg-6 pt-lg-5' src = {content.buySessions.complete.img} />
                </div>
            </div>
        </div>
    )
}


export default Complete