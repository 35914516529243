import React, {useState, useEffect, useContext} from 'react'
import DashboardLayout from '../index'
import ScheduleGlance from './Schedule'
import axios from '../../../../utilis/axios'
import {Link} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Alerts from './Alerts'
import Swal from 'sweetalert2'
import instructor_content from '../../../../content/instructor_content.json'
import {AiFillCaretRight, AiFillCaretLeft} from 'react-icons/ai'
import { Store } from '../../../../store'
import moment from 'moment'

const Home = (props) => {


    useEffect(() => {
        if(props.location.query){
            if(props.location.query === 'new-user'){
                promptNewUser()
            }
        }
        // eslint-disable-next-line
    }, [])

    const promptNewUser = async () => {
        const response = await Swal.fire({
            title: instructor_content.new_user.title,
            text: instructor_content.new_user.description,
            icon: 'success',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Get Started'
        });
        if(response.isConfirmed){
            props.history.push('/instructor/profile')
        }
    }


    return (
        <DashboardLayout showTitle = {true} padding = 'high' title = "Dashboard" subTitle = "Overview" {...props}>
            <div className = " pb-3">
                <Onboarding />
                <div className = 'row'>
                    <div className = 'col'>
                        <ScheduleGlance />
                    </div>
                </div>
                <div className = 'row mt-5 mb-6'>
                    <div class = 'col-12 col-lg'>
                        <Alerts />
                    </div>
                    <div class = 'col-12 col-lg-5'>
                        <Announcements />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Home 





const Onboarding = () => {

    const {state} = useContext(Store)
    const content =instructor_content.new_user.onboarding

    return (
        <>
        {state.user.data.instructor_profile.onboarding.complete === undefined && <div class = "card bg-light shadow-sm border">
            <div class = 'card-body'>
                <h3>{content.title}</h3>
                <div class="checklist" tabindex="0">
                    <div class="form-check form-check1" tabindex="0">
                        <input class="form-check-input" type="checkbox" id="checklistOne" checked = {state.user.data.instructor_profile.onboarding.profile}/>
                        <label class="form-check-label">
                            {content.profile} 
                            {!state.user.data.instructor_profile.onboarding.profile && <Link class = "ml-2 small" to = "/instructor/profile">(Click here to start)</Link>}
                        </label>
                    </div>
                    <div class="form-check form-check1" tabindex="0">
                        <input class="form-check-input" type="checkbox" id="checklistTwo"checked = {state.user.data.instructor_profile.onboarding.course}/>
                        <label class="form-check-label">
                            {content.instrument}
                            {!state.user.data.instructor_profile.onboarding.course && <Link class = "ml-2 small" to = "/instructor/courses/add">(Click here to start)</Link>}
                        </label>
                    </div>
                    <div class="form-check form-check1" tabindex="0">
                        <input class="form-check-input" type="checkbox" id="checklistTwo"checked = {state.user.data.instructor_profile.onboarding.availability}/>
                        <label class="form-check-label">
                            {content.availability}
                            {!state.user.data.instructor_profile.onboarding.availability && <Link class = "ml-2 small" to = "/instructor/calendar/availability">(Click here to start)</Link>}
                        </label>
                    </div>
                    <div class="form-check form-check1" tabindex="0">
                        <input class="form-check-input" type="checkbox" id="checklistTwo"checked = {state.user.data.instructor_profile.onboarding.bank}/>
                        <label class="form-check-label">
                            {content.bank}
                            {!state.user.data.instructor_profile.onboarding.bank && <Link class = "ml-2 small" to = "/instructor/account/bank_details">(Click here to start)</Link>}
                        </label>
                    </div>
                    <div class="form-check form-check1" tabindex="0">
                        <input class="form-check-input" type="checkbox" id="checklistTwo"checked = {state.user.data.instructor_profile.onboarding.documents}/>
                        <label class="form-check-label">
                            {content.documents}
                            {!state.user.data.instructor_profile.onboarding.documents && <Link class = "ml-2 small" to = "/instructor/profile">(Click here to start)</Link>}
                        </label>
                    </div>
                </div>
            </div>
        </div>}
        {(state.user.data.instructor_profile.onboarding.complete && moment(state.user.data.instructor_profile.onboarding.complete).diff(moment(), 'days') > -1) ? <div class = "alert alert-light mb-4">
        {content.complete}
        </div> : <></>}
        </>
    )
}
















const Announcements = () => {


    const [announcements, setAnnouncements] = useState();
    const [index, setIndex] = useState(-1)

    useEffect(() => {
        fetchAnnouncements()
    }, [])

 

    const fetchAnnouncements = async () => {
        try{
            const res = await axios.get('/api/announcements/instructor');
            console.log(res.data)
            if(res.data.length > 0){
                setAnnouncements(res.data)
                setIndex(res.data.length-1)
            } 
        } catch(err){
            console.log(err)
        }
    }

    return (
        <div>
        {(announcements && index > -1) ? <div className="card shadow-sm border" >
            <div className="card-body">
                <img src={announcements[index].img} alt="..." class="card-img" style={{maxHeight: '230px', objectFit: 'cover'}} />
                <div className="row align-items-center mt-3">
                    <div className="col-12 ">
                    <div className="text-center">
                    </div>
                    </div>
                    <div className="col-12  px-4 py-3 ">
                    <h2>{announcements[index].title}</h2>
                    <p class="text-muted">
                        {announcements[index].description}
                    </p>
                    {announcements[index].cta && <Link to = {announcements[index].link} class="btn btn-primary lift">
                        {announcements[index].cta}
                    </Link>}
                    </div>
                </div> 
                <div class ="" style = {{position: 'absolute', bottom: '30px', right: '30px'}}>
                    <AiFillCaretLeft class = "pointer h4 mb-0 " onClick = {() => {
                        if(announcements[index-1]) setIndex(prev => prev-1)
                    }}/>
                    <AiFillCaretRight class = "pointer h4 mb-0 " onClick = {() => {
                        if(announcements[index+1]) setIndex(prev => prev+1)
                    }}/>
                </div>
            </div>
        </div>: <Skeleton height = {450} />}
        </div>
    )
}


