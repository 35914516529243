import React from 'react'

const Pagination = ({pageNum, totalPages, setPageNum}) => {
    return (
        <div className = 'row mt-3 mb-5 align-items-center'>
            <div className = 'col-auto'>
                <button 
                    className = {`btn btn-outline-secondary ${pageNum <= 1 ? 'disabled-input' : ''}`} 
                    onClick = {(e) => {
                        e.preventDefault()
                        if(pageNum === 1) return;
                        setPageNum(prev => prev-1); 
                        window.scrollTo({ top: 0, behavior: 'smooth' }) 
                    }}
                    disabled = {pageNum <= 1}
                >
                    Prev
                </button>
            </div>
            <div className = 'col text-center font-weight-bold'>
                {pageNum}/{totalPages}
            </div>
            <div className = 'col-auto'>
                <button 
                    className = {`btn btn-outline-secondary ${(pageNum === totalPages || totalPages === 0) ? 'disabled-input' : ''}`} 
                    onClick = {(e) => {
                        e.preventDefault()
                        if(pageNum === totalPages) return;
                        setPageNum(prev => prev+1); 
                        window.scrollTo({ top: 0, behavior: 'smooth' }) 
                    }}
                    disabled = {pageNum === totalPages || totalPages === 0}
                >
                    Next
                </button>
            </div>
        </div>
    )
}

export default Pagination
