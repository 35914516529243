import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import AsyncSelect from 'react-select/async';
import customStyles from '../../../../utilis/customStyles'
import {DateFormInput1} from '../../../../components/Forms'
import moment from 'moment'

const CreateDiscount = ({show, setShow, discounts, formatData}) => {

    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState(Array(10).fill(false));
    const [draft, setDraft] = useState({
        discountCode: '', 
        appliesTo: 'EVERYONE', 
        user: '', 
        redeemBy:'', 
        discountType: 'AMOUNT', 
        amountOff: '', 
        percentOff: '', 
        maximumAmountAllowed: '', 
        maxRedemptions: '', 
        comments: '',
        tnc: '',
    });
    

    useEffect(() => {
        setErrors(Array(10).fill(false))
    }, [draft])

    useEffect(() => {
        setDraft({
            discountCode: '', 
            appliesTo: 'EVERYONE', 
            user: '', 
            redeemBy:'', 
            discountType: 'AMOUNT', 
            amountOff: '', 
            minimumAmountRequired: '',
            percentOff: '', 
            maximumAmountAllowed: '', 
            maxRedemptions: '', 
            tnc: '',
            comments: ''
        })
    }, [show])


    const validate = () => {
        let new_errors = Array(10).fill(false)
        console.log(moment(draft.redeemBy, 'DD/MM/YYYY').toISOString())
        if(!draft.discountCode || draft.discountCode.length === 0) new_errors[0] = true;
        if(draft.appliesTo === 'USER' && (!draft.user || draft.user === '' || !draft.user.value)) new_errors[1] = true;
        if(draft.discountType === 'AMOUNT' && (!draft.amountOff || draft.amountOff === '')) new_errors[2] = true;
        if(draft.discountType === 'AMOUNT' && (!draft.minimumAmountRequired || draft.minimumAmountRequired === '')) new_errors[3] = true;
        if(draft.discountType === 'PERCENT' && (!draft.percentOff || draft.percentOff === '')) new_errors[4] = true;
        if(draft.discountType === 'PERCENT' && (!draft.maximumAmountAllowed || draft.maximumAmountAllowed === '')) new_errors[5] = true;
        if(!draft.redeemBy || draft.redeemBy === '') new_errors[6] = true;
        if(!draft.maxRedemptions || draft.maxRedemptions === '') new_errors[7] = true;
        if(!draft.tnc || draft.tnc === '') new_errors[8] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true;
    }


    const fetchUsers = async (inputValue, callback) => {
        try{
            const res = await axios.get(`/api/admin/enrollment/user/${inputValue}`);
            callback(res.data.map(item => ({
                value: item._id, 
                label: <NameWithAvatar email = {item.email} name = {item.firstName + ' ' + item.lastName} role = {item.role}/> 
            })))
        } catch(err){
            console.log(err)
        }
    }


    const createDiscount = async (e) => {
        e.preventDefault()
        if(!validate()) return;
        try{
            setLoading(true);
            const res = await axios.post('/api/admin/discount', {
                ...draft,
                user: draft.user.value,
                redeemBy: moment(draft.redeemBy, 'DD/MM/YYYY').toISOString(),
            })
            const newDiscounts = [...discounts];
            newDiscounts.push(res.data);
            formatData(newDiscounts)
            setLoading(false);
            setShow(false)
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }


    const generateCode = async () => {
        setDraft(prev => ({...prev, discountCode: 'ABC'}))
    }

 
    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2 mb-5'>Create a new discount code</div>
                </div>
                <form class = "mt-3 ">
                    <div class = 'form-group'>
                        <label>Discount Code</label>
                        <small class='btn-link pointer ml-3' onClick = {() => generateCode()}>(Generate discount code)</small>
                        <input
                            class = {`form-control ${errors[0] ? 'is-invalid' : ''}`}
                            value = {draft.discountCode}
                            onChange={e => setDraft(prev => ({...prev, discountCode: (e.target.value).toUpperCase()}))}
                        />
                        <div class = 'invalid-feedback'>Please enter a discount code</div>
                    </div>

                    <div class = 'form-group'>
                        <label>Recipient type</label>
                        <div className="btn-group mr-2 btn-block " >
                            <div 
                                onClick = {() => setDraft(prev => ({...prev, appliesTo: 'EVERYONE'}))} 
                                className={`btn border btn-${draft.appliesTo === 'EVERYONE' ? 'primary' : 'white'} btn-block`}
                            >Everyone</div>
                            <div 
                                onClick = {() => setDraft(prev => ({...prev, appliesTo: 'USER'}))} 
                                className={`btn border btn-${draft.appliesTo === 'USER' ? 'primary' : 'white'} btn-block mt-0`}
                            >One User</div>
                        </div>
                    </div>

                    {draft.appliesTo === 'USER' && <div className="form-group">
                        <label>Select the user</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={[{value: '', label: 'Please type to search'}]}
                            styles = {customStyles.bigSelect}
                            loadOptions={fetchUsers}
                            value = {draft.user}
                            onChange={e => {
                                if(e && e.value && e.value !== '') {
                                    setDraft(prev => ({...prev, user: e}))
                                } else setDraft(prev => ({...prev, user: null}))
                            }}
                            isClearable
                        />
                        {errors[1] && <div class = 'd-block invalid-feedback'>Please select a user</div>}
                    </div>}
                  

                    <div class = 'form-group'>
                        <label>Discount type</label>
                        <div className="btn-group mr-2 btn-block " >
                            <div 
                                onClick = {() => setDraft(prev => ({...prev, discountType: 'AMOUNT'}))} 
                                className={`btn border btn-${draft.discountType === 'AMOUNT' ? 'primary' : 'white'} btn-block`}
                            >Flat Amount</div>
                            <div 
                                onClick = {() => setDraft(prev => ({...prev, discountType: 'PERCENT'}))} 
                                className={`btn border btn-${draft.discountType === 'PERCENT' ? 'primary' : 'white'} btn-block mt-0`}
                            >Percentage</div>
                        </div>
                    </div>

                    
                    
                    {draft.discountType === 'AMOUNT' && <div className = ''>
                        <div class='form-group'>
                            <label>Amount Off</label>
                            <input
                                class = {`form-control ${errors[2] ? 'is-invalid' : ''}`}
                                value = {draft.amountOff}
                                type = 'number'
                                onChange={e => setDraft(prev => ({...prev, amountOff: e.target.value}))}
                            />
                            <div class = 'invalid-feedback'>Please enter amount off</div>
                        </div>
                        <div class='form-group'>
                            <label>Minimum amount required</label>
                            <input
                                class = {`form-control ${errors[3] ? 'is-invalid' : ''}`}
                                value = {draft.minimumAmountRequired}
                                type = 'number'
                                onChange={e => setDraft(prev => ({...prev, minimumAmountRequired: e.target.value}))}
                            />
                            <div class = 'invalid-feedback'>Please enter a minimum amount required</div>
                        </div>
                    </div>}
                    
                    
                    {draft.discountType === 'PERCENT' && <div className = ''>
                        <div class='form-group'>
                            <label>Percentage Off</label>
                            <input
                                class = {`form-control ${errors[4] ? 'is-invalid' : ''}`}
                                value = {draft.percentOff}
                                type = 'number'
                                onChange={e => setDraft(prev => ({...prev, percentOff: e.target.value}))}
                            />
                            <div class = 'invalid-feedback'>Please enter the percentage off</div>
                        </div>
                        <div class='form-group'>
                            <label>Maximum amount allowed</label>
                            <input
                                class = {`form-control ${errors[5] ? 'is-invalid' : ''}`}
                                value = {draft.maximumAmountAllowed}
                                type = 'number'
                                onChange={e => setDraft(prev => ({...prev, maximumAmountAllowed: e.target.value}))}
                            />
                            <div class = 'invalid-feedback'>Please enter maximum amount allowed</div>
                        </div>
                    </div>}  

                    <div class = 'form-group'>
                        <DateFormInput1 
                            placeholder = "Select a date"
                            value = {draft.redeemBy}
                            setValue = {(val) => {
                                setDraft(prev => ({...prev, redeemBy: val}));
                            }}
                            error = {errors[6]}
                            setError = {(val) => console.log('')}
                            errorMessage = {"Please enter a valid date"}
                            label = "Redeem by"
                            minimumDate = {new Date()}
                        />
                    </div>  

                    <div class='form-group'>
                        <label>Maximum redemptions</label>
                        <input
                            class = {`form-control ${errors[7] ? 'is-invalid' : ''}`}
                            value = {draft.maxRedemptions}
                            type = 'number'
                            onChange={e => setDraft(prev => ({...prev, maxRedemptions: e.target.value}))}
                        />
                        <div class = 'invalid-feedback '>Please enter maximum number of redemptions </div>
                    </div> 

                    <div class='form-group'>
                        <label>Terms and Conditions</label>
                        <textarea
                            class = {`form-control ${errors[8] ? 'is-invalid' : ''}`}
                            value = {draft.tnc}
                            onChange={e => setDraft(prev => ({...prev, tnc: e.target.value}))}
                        />
                        <div class = 'invalid-feedback '>Please enter terms and conditions</div>
                    </div>         
                    <div class='form-group'>
                        <label>Comments</label>
                        <input
                            class = {`form-control`}
                            value = {draft.comments}
                            onChange={e => setDraft(prev => ({...prev, comments: e.target.value}))}
                        />
                    </div>         
                    
                    <button class = "btn btn-primary btn-block mt-6" onClick = {(e) => createDiscount(e)} disabled = {loading}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Create Discount
                    </button>
                    
                </form>
                
            </div>
        </RightModal>
    )
}

export default CreateDiscount;








const NameWithAvatar = ({name, email, avatar, role}) => {
    return (
        <div class="row align-items-center no-gutters">
            <div class = "col">
                <div class="small mb-n1">{name}</div>
                <div class=" text-muted small">{email}, {role}</div>
            </div>
        </div>
    )
}