import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { AiOutlineClockCircle, AiFillCheckCircle } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import { MdCancel } from 'react-icons/md'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import axios from '../../../../utilis/axios'
import { saveSchedule } from '../../../../store/actions/instructor'
import { Store } from '../../../../store/'
import instructor_content from '../../../../content/instructor_content.json'
import Pagination from '../../../../components/Dashboard/Pagination'
import Swal from 'sweetalert2'


const Schedule = ({ selectedDay, scheduled, setScheduled, ...props }) => {

    const [filteredData, setFilteredData] = useState()
    const [totalPages, setTotalPages] = useState();
    const [pageNum, setPageNum] = useState(1);
    const [loading, setLoading] = useState('')

    useEffect(() => {
        if (scheduled) {
            const temp = scheduled.filter(item => {
                if ((item.status === 'cancelled' || item.status === 'rescheduled') && item.lateCancel && item.cancelled_by === 'student') {
                    if (!selectedDay) return true;
                    else {
                        if (selectedDay === moment(item.date_time).format('DD/MM/YYYY')) return true;
                        else return false;
                    }
                } else return false;
            })
                .sort((a, b) => moment(b.date_time) - moment(a.date_time))
                .sort((a, b) => {
                    const a1 = a.lateCharges === undefined ? 0 : 1
                    const b1 = b.lateCharges === undefined ? 0 : 1
                    return a1 - b1;

                })

            setTotalPages(temp.length % 10 === 0 ? parseInt(temp.length / 10) : parseInt(temp.length / 10) + 1)
            if (temp.length === 0) setPageNum(0)
            else setPageNum(1)
            setFilteredData(temp)
        }
    }, [scheduled, selectedDay])

    return (
        <div>
            <div class='alert alert-light'>
                {/* {instructor_content.schedule.late_cancel_help} */}
                Sessions cancelled by the member within 6 hours of the scheduled time are considered ‘late cancellations’.<br />
                Note: You have the option to charge for the session, or not.

            </div>
            {filteredData ? (filteredData.length > 0 ? (
                filteredData.length > 0 ? filteredData.slice(pageNum * 10 - 10, pageNum * 10).map(item => <ScheduleItem data={item} {...props} loading={loading} setLoading={setLoading} />) : filteredData.map(item => <ScheduleItem loading={loading} setLoading={setLoading} data={item} {...props} />)
            ) : <NoSchedule />) : <LoadingSchedule />}
            <Pagination pageNum={pageNum} setPageNum={setPageNum} totalPages={totalPages} />
        </div>
    )
}

export default Schedule




const ScheduleItem = ({ data, loading, setLoading }) => {

    const { state, dispatch } = useContext(Store);



    const saveResponse = async (val, e) => {
        e.preventDefault();
        try {
            let response;
            if (val) {
                response = await Swal.fire({
                    title: 'Are you sure you want to charge for this class',
                    text: 'This action cannot be undone. Charging the student without knowing the reason might result in dissatisfaction with the student. We suggest you talk to the student before charging for this class.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, charge for this class!'
                });
            }
            if (val === false || response.isConfirmed) {
                setLoading(val.toString())
                await axios.post(`/api/instructor/schedule/late_charges/${data._id}`, { isCharged: val })
                const newSchedule = [...state.instructor.schedule];
                const index = newSchedule.findIndex(item => item._id === data._id)
                newSchedule[index].lateCharges = val
                saveSchedule(newSchedule, dispatch)
                setLoading('')
            }
        } catch (err) {
            setLoading('')
            console.log(err)
        }
    }

    return (
        <div className='card mb-4'>
            <div className='card-header'>
                <div class='d-flex align-items-center'>
                    <AiOutlineClockCircle class='mr-3' />
                    <strong class='mr-2'>{moment(data.date_time).format('Do MMM YYYY')},</strong>
                    {moment(data.date_time).format('hh:mm A')} - {moment(data.date_time).add(45, 'm').format('hh:mm A')}
                </div>
                {data.lateCharges === undefined ? <div className="d-flex">
                    <button disabled={loading !== ''} className=" pointer no-button" onClick={(e) => saveResponse(true, e)}>
                        <OverlayTrigger overlay={<Tooltip>Charge</Tooltip>}>
                            {loading === 'true' ? <span class='spinner-border spinner-border-sm mr-3'></span> : <AiFillCheckCircle class='h1 mb-0 mr-2' />}
                        </OverlayTrigger>
                    </button>
                    <button disabled={loading !== ''} className=" pointer no-button" onClick={(e) => saveResponse(false, e)}>
                        <OverlayTrigger overlay={<Tooltip>Don't Charge</Tooltip>}>
                            {loading === 'false' ? <span class='spinner-border spinner-border-sm mr-3'></span> : <MdCancel class='h1 mb-0 ' />}
                        </OverlayTrigger>
                    </button>
                </div> : <div class='badge badge-primary'>{data.lateCharges ? 'Session Charged' : 'Session Not Charged'}</div>}
            </div>
            <div className='card-body py-3'>
                <div className='row align-items-center'>
                    <div className='col-auto'>
                        <div className="avatar avatar-md"  >
                            <img src={data.student.avatar} alt="..." class="avatar-img rounded-circle" />
                        </div>
                    </div>
                    <div className='col'>
                        <div class="h4 mb-1">{data.student.firstName + ' ' + data.student.lastName}</div>
                        <div class="text-muted">{data.course.title}</div>
                        <small class="small text-muted">Session ID: {data.bookingId}</small><br />
                        <div class = "badge badge-primary my-1">{ data.type === 'demo' ? 'Assessment':makeFirstLetterCap(data.type)} session</div>

                    </div>
                </div>
            </div>
        </div>
    )
}





const LoadingSchedule = () => {
    return (
        <div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
        </div>
    )
}


const NoSchedule = () => {
    return (
        <div className='card mb-4'>
            <div className='card-body d-flex align-items-center justify-content-center'>
                <div className='h3 text-uppercase text-muted mb-0 py-5'>
                    {instructor_content.schedule.no_late_cancel}
                </div>
            </div>
        </div>
    )
}

const makeFirstLetterCap = (text) => text ? text[0].toUpperCase() + text.slice(1) : ''