import React, {useState, useEffect, useContext} from 'react'
import axios from '../../../../utilis/axios';
import { Store } from '../../../../store';
import { saveEnrollment } from '../../../../store/actions/student';
import Review from '../Billing/Review';
import {Link} from 'react-router-dom'
import student_content from '../../../../content/student_content.json'

const TeacherList = () => {

    const {state, dispatch} = useContext(Store)
    const [courses, setCourses] = useState([]);
    const [tab, setTab] = useState('paid')

    useEffect(() => {
        if(state.student.enrollments && state.student.enrollments.length > 0){
            setCourses(state.student.enrollments)
        } else{
            fetchCourses();
        }
        fetchCourses()
        // eslint-disable-next-line
    }, [])


    const fetchCourses = async () => {
        try{
            const res = await axios.get(`/api/student/enrollments`)
            saveEnrollment(res.data, dispatch)
            setCourses(res.data)
        } catch(err){
            console.log(err)
        }
    }


    return (
        <div>
            <div className = "row mt-5 mb-2 align-items-center">
                <div class = 'col h2 mb-0'>
                    Your Membership
                </div>
                <div class = 'col-auto'>
                    <ul class="nav nav-tabs header-tabs  my-0 mb-3">
                        <li class="nav-item ">
                            <div class={`nav-link h4 pointer text-center ${tab === 'paid' && 'active'}`} onClick = {() => setTab('paid')}>
                                Paid Enrollment
                            </div>
                        </li>
                        <li class="nav-item ">
                            <div class={`nav-link h4 pointer text-center ${tab === 'demo' && 'active'}`} onClick = {() => setTab('demo')}>
                                Demo Enrollment
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            {tab === 'paid' && <div className = 'row'>
                {courses.filter(item => item.type === 'paid').filter(item => item.num_classes > 0).length > 0 ? courses.filter(item => item.type === 'paid').filter(item => item.num_classes > 0).map(item => <div className = 'col-12 col-lg-6'>
                    <TeacherCard course = {item}/>
                </div>) : <NoTeacherCard />}
            </div>}
            {tab === 'demo' && <div className = 'row'>
                { courses.filter(item => item.type === 'demo').length > 0 ? courses.filter(item => item.type === 'demo').map(item => <div className = 'col-12 col-lg-4'>
                    <TeacherCard course = {item}/>
                </div>) : <NoTeacherCard />}
            </div>}
        </div>
    )
}

export default TeacherList


const TeacherCard = ({course}) => {


    const [showReview, setShowReview] = useState(false);

    return (
      <>
        <div className="card ">
          <div className="card-body">
            <div className="row">
              <div className="col-auto">
                <div className="avatar avatar-xxl">
                  <img
                    src={course.instructor.avatar}
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                </div>
              </div>
              <div className="col">
                <h3 class="mb-2">{course.course && course.course.title}</h3>
                <h4 class="h4 text-muted mb-1">
                  {course.instructor.firstName +
                    " " +
                    course.instructor.lastName}
                </h4>
                <div class="badge badge-primary mb-2 mt-2">
                  {course.type === "demo" ? `Demo Session` : `Session overview`}
                </div>
                {course.type !== "demo" && (
                  <div class="mt-1 mb-2">
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div>
                        Sessions purchased: <strong>{course.num_classes}</strong>
                        </div>
                        <div>
                          Sessions completed:{" "}
                          <strong>{course.classes_completed}</strong>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div>
                          Sessions scheduled:{" "}
                          <strong>{course.classes_scheduled}</strong>
                        </div>
                        <div>
                          Sessions requested:{" "}
                          <strong>{course.classes_requested}</strong>
                        </div>
                      </div>
                    </div>
                    {course.classes_late_charged > 0 && (
                      <div class="mt-2">
                        Sessions late cancelled/rescheduled:{" "}
                        <strong>{course.classes_late_charged}</strong>
                      </div>
                    )}
                  </div>
                )}
                <div
                  class="btn btn-primary btn-sm small pointer mt-4"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowReview(true);
                  }}
                >
                  Write a review
                </div>
              </div>
            </div>
          </div>
        </div>
        <Review
          show={showReview}
          setShow={setShowReview}
          courseId={course && course.course && course.course._id}
        />
      </>
    );
}




const NoTeacherCard = () => {
    return (
        <div class = 'col'>
            <div className = 'card bg-light'>
                <div class = 'card-body py-6 '>
                    <p class = 'text-muted h3 mb-0 text-center px-5'>
                        {student_content.home.no_teacher} <Link className = 'btn-link pointer text-primary' to ='/instructors'>here</Link>.
                    </p>
                </div>
            </div>
        </div>
    )
}
