import {useState, useEffect} from 'react';
import axios from '../../../../utilis/axios';
import moment from 'moment'

const Earnings = () => {

  const [invoices, setInvoices] = useState();

  useEffect(() => {
    fetchInvoices();
  })

  const fetchInvoices = async () => {
    try{
      const res = await axios.get('/api/student/invoices');
      if(res.data){
        setInvoices(res.data)
      }
    } catch(err){
      console.log(err)
    }
  }

    return (
        <div>
         
           <div className="card shadow-sm border">
              <div className="card-header">
                <h4 class="card-header-title">
                  Invoices
                </h4>
              </div>
              <div className="table-responsive">
              {invoices ? <table class="table table-sm table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>Invoice ID</th>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody class="font-size-base">
                    {invoices.map(item => (
                      <EarningRow 
                        invoiceId = {item.receipt_id}
                        date = {moment(item.date).format('Do MMM YYYY')}
                        amount = {parseFloat(item.amount).toFixed(2)}
                        status = {item.status}
                      />
                    ))}
                  </tbody>
                </table> : <div class = 'd-flex align-items-center justify-content-center w-100' style = {{height: '40vh'}}><span class = 'spinner-border'></span></div>}
              </div>
            </div>
        </div>
    )
}

export default Earnings;


const EarningRow = ({invoiceLink, invoiceId, date, amount, status}) => {
  return (
    <tr>
      <td><div>{invoiceId}</div></td>
      <td>{date}</td>
      <td>Rs {amount}</td>
      <td><span class="badge badge-secondary">{status}</span></td>
    </tr>
  )
}