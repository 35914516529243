import React, { useState, useEffect, useContext } from "react";
import DashboardLayout from "../index";
import Profile from "./Profile";
import Security from "./Security";
import Invoices from "./Invoices";
import GST from "./GST";
import Referral from "./Referral";
import Notifications from "./Notifications";
import { logoutUser } from "../../../../services/auth";
import { logoutSuccess } from "../../../../store/actions/auth";
import { Store } from "../../../../store";

const Account = (props) => {
  const [tab, setTab] = useState("");

  useEffect(() => {
    const pathname = props.match.params.id;
    setTab(pathname);
  }, [props.match.params.id]);

  useEffect(() => {
    if (tab !== props.match.params.id && tab !== "")
      props.history.push(`/my-dashboard/${tab}`);
    // eslint-disable-next-line
  }, [tab]);

  return (
    <DashboardLayout>
      <div class="px-lg-6 mx-lg-6 px-md-3 mx-md-3">
        <div class="py-6 display-4 text-center">
          {tab === "refer-a-friend"
            ? "Refer friends, get rewarded"
            : "Your Account"}
        </div>
        <div className="row px-4 mx-0">
          <div className="col-12 col-lg-3 mb-4 mb-lg-0 d-none d-lg-block">
            <AccountNav tab={tab} setTab={setTab} />
          </div>
          <div className="col-12 col-lg-3 mb-4 mb-lg-0 d-block d-lg-none">
            <AccountNavSm tab={tab} setTab={setTab} />
          </div>
          <div className="col ml-lg-5">
            {tab === "notifications" && <Notifications />}
            {tab === "security" && <Security />}
            {tab === "profile" && <Profile />}
            {tab === "invoices" && <Invoices />}
            {tab === "gst" && <GST />}
            {tab === "refer-a-friend" && <Referral />}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Account;

const AccountNav = ({ setTab, tab }) => {
  const { dispatch } = useContext(Store);

  const handleLogout = async () => {
    try {
      logoutSuccess(dispatch);
      await logoutUser();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <ul class="list-group list-group-active shadow-sm bg-white rounded mt-0">
        <li
          onClick={() => setTab("profile")}
          class={`list-group-item list-group-item-action pointer ${
            tab === "profile" ? "active" : ""
          }`}
        >
          Profile
        </li>
        <li
          onClick={() => setTab("security")}
          class={`list-group-item list-group-item-action pointer ${
            tab === "security" ? "active" : ""
          }`}
        >
          Security
        </li>
        <li
          onClick={() => setTab("invoices")}
          class={`list-group-item list-group-item-action pointer ${
            tab === "invoices" ? "active" : ""
          }`}
        >
          Invoices
        </li>
        <li
          onClick={() => setTab("gst")}
          class={`list-group-item list-group-item-action pointer ${
            tab === "gst" ? "active" : ""
          }`}
        >
          Add GST Details
        </li>
        <li
          onClick={() => setTab("notifications")}
          class={`list-group-item list-group-item-action pointer ${
            tab === "notifications" ? "active" : ""
          }`}
        >
          Notifications
        </li>
        <li
          onClick={() => setTab("refer-a-friend")}
          class={`list-group-item list-group-item-action pointer ${
            tab === "refer-a-friend" ? "active" : ""
          }`}
        >
          Refer your friend
        </li>
      </ul>
      <ul class="list-group list-group-active shadow-sm bg-white rounded mt-4">
        <li
          class="list-group-item list-group-item-action pointer"
          onClick={() => handleLogout()}
        >
          Logout
        </li>
      </ul>
    </>
  );
};

const AccountNavSm = ({ setTab, tab }) => {
  return (
    <select
      class="form-control"
      value={tab}
      onChange={(e) => setTab(e.target.value)}
    >
      <option value="profile">Profile</option>
      <option value="notifications">Notifications</option>
      <option value="security">Security</option>
      <option value="invoices">Invoices</option>
      <option value="refer-a-friend">Refer your friend</option>
    </select>
  );
};
