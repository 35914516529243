import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import { BeatSpinner2 } from '../../../../components/Spinners/BeatSpinner'
import moment from 'moment'
import AddAccount from './AddAccount'
import { AiFillDelete } from 'react-icons/ai'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import Swal from 'sweetalert2'
import EditUserModal from './EditUserModal'

const AdminAccounts = (props) => {

    const [data, setData] = useState();
    const [accounts, setAccounts] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [show, setShow] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState()
    const [loading, setLoading] = useState(false);
    const [showEditUser, setShowEditUser] = useState(false)
    const [selectedUser, setSelectedUser] = useState('')

    useEffect(() => {
        fetchAdminAccounts()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(data) formatData(data)
        // eslint-disable-next-line
    }, [data])

    const fetchAdminAccounts = async () =>{
        try{

            const res = await axios.get('/api/admin/admin-accounts');
            setData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        setAccounts(data.map(item => ({
            ...item,
            createdAt: moment(item.createdAt).format('Do MMM YY, hh:mm A'),
            lastLoginDate: moment(item.lastLoginDate).format('Do MMM YY, hh:mm A'),
            delete: item.isSuperAdmin ? '' : {value: '', label: 
                <OverlayTrigger overlay = {<Tooltip>Delete Admin</Tooltip>}>
                    <div class = "h3 mb-0 pointer" onClick = {() => setSelectedAdmin(item._id)}>
                        <AiFillDelete />
                    </div>
                </OverlayTrigger>
        }
        })))  
    }

    useEffect(() => {
        if(selectedAdmin && selectedAdmin.length > 5) deleteAdmin(selectedAdmin)
         // eslint-disable-next-line 
    }, [selectedAdmin])


    const deleteAdmin = async (id) => {
        try{

            setSelectedAdmin()
            const newAccounts = [...accounts];
            const index = newAccounts.findIndex(item => item._id === id);
            newAccounts.splice(index, 1)
            setAccounts(newAccounts);
            
            const newData = [...data];
            const index1= newData.findIndex(item => item._id === id);
            newData.splice(index1, 1);
            setData(newData)

            await axios.delete(`/api/admin/admin-accounts/${id}`);

        } catch(err){
            console.log(err)
        }
    }


    const cleanAmazon = async () => {
        try{

            const response = await Swal.fire({
                title: 'Are you sure you want to clean your Amazon File Storage',
                text: 'This action cannot be undone. All files, which are not in use will be deleted from your Amazon Storage.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete them!'
            });
            if(response.isConfirmed){
                setLoading(true);
                const res = await axios.post('/api/admin/cleanAmazonS3')
                cogoToast.success(res.data)
                setLoading(false);
            }
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
            <div className = 'row align-items-center mt-5 border-bottom pb-3 mb-4'>
                <div className = 'col'>
                    <h3 class = 'h1'>Admin Accounts</h3>
                </div>
                <div class = 'col-auto'>
                    <div class = 'btn btn-outline-primary mr-2' onClick = {() => cleanAmazon()}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Clean Amazon S3
                    </div>
                    <div class = 'btn btn-primary' onClick = {() => setShow(true)}>Create New Admin</div>
                </div>
            </div>
            {accounts ? <Table 
                data = {accounts}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => {
                    setSelectedUser(accounts.filter(item => item._id === id)[0]); 
                    setShowEditUser(true)
                }}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
            /> : <BeatSpinner2 />}


            <AddAccount
                show = {show}
                setShow = {setShow}
                accounts = {data}
                setAccounts = {setData}
            />
             {selectedUser && <EditUserModal
                show = {showEditUser}
                setShow = {setShowEditUser}
                data = {selectedUser}
            />}


        </AdminDashboard>
    )
}

export default AdminAccounts




const headers = [
    {value: 'firstName', active: true, label: 'Name'},
    {value: 'email', active: true, label: 'Email'},
    {value: 'phone', active: true, label: 'Phone'},
    {value: 'createdAt', active: true, label: 'Creation Date'},
    {value: 'lastLoginDate', active: true, label: 'Last Login'},
    {value: 'delete', active: true, label: 'Delete'},
]


