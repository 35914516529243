import axios from "../utilis/axios";

export const loginUser = async (user) => {
    try{
        const resp = await axios.post('/api/auth/login', user);
        return resp.data;
    } catch(err){
        throw err; 
    }
};

export const logoutUser = async () => {
    const resp = await axios.get('/api/auth/logout');
    return resp;
};

export const getUser = async () => {
    try{
        const resp =  await axios.get('/api/current_user')
        return resp.data
    } catch(err){
        throw err;
    }
}

export const loginUserByOtp = async (user) => {
    try{
        const resp = await axios.post('/api/auth/otp/login', user);
        return resp.data;
    } catch(err){
        throw err; 
    }
};