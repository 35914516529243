import React from 'react'
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { saveSchedule } from '../../../../../store/actions/student';



const AttendanceModal = ({
  setLoading,
  data,
  state,
  dispatch,
  show,
  setShow,
  loading,
  setMainLoading,
  mainLoading
}) => {

  const [internetIssue, setInternetIssue] = React.useState(false);

  const sendConfirmation = async (e, response) => {
    e.preventDefault();
    try {
      setMainLoading(true);
      setLoading(response);
      const data1 = {
        response: response,
      };
      const res = await axios.post(
        `/api/student/schedule/complete_class/${data._id}`,
        data1
      );
      const newSchedule = [...state.student.schedule];
      const index = newSchedule.findIndex((item) => item._id === data._id);
      newSchedule[index].status = res.data.status;
      newSchedule[index].lessonDetails = res.data.lessonDetails;
      newSchedule[index].confirm_by_instructor = res.data.confirm_by_instructor;
      newSchedule[index].confirm_by_student = res.data.confirm_by_student;
      newSchedule[index].isFlagged = res.data.isFlagged;
      newSchedule[index].lateCharges = res.data.lateCharges;
      saveSchedule(newSchedule, dispatch);
      setMainLoading(false);
      setLoading("");
      setShow(false);
    } catch (err) {
      setMainLoading(false);
      setLoading("");
      console.log(err);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} centered  dialogClassName="dhun-internet-modal" >
      <div className="modal-content">
        <div className="modal-body ">
          <div>
            <h3 class='mb-3'>Was this session attended or missed by you?</h3>
          </div>
          <div className="close pointer" onClick={() => setShow(false)}></div>

          <div className="mt-2">
            <button
              disabled={mainLoading}
              class="btn btn-sm btn-outline-secondary  mr-2"
              onClick={(e) => sendConfirmation(e, "student_joined")}
            >
              {loading === "student_joined" && (
                <span class="spinner-border spinner-border-sm mr-2"></span>
              )}
              Completed
            </button>
            <button
              disabled={mainLoading}
              class="btn btn-sm btn-outline-secondary  mr-2"
              onClick={(e) => sendConfirmation(e, "student_no_show")}
            >
              {loading === "student_no_show" && (
                <span class="spinner-border spinner-border-sm mr-2"></span>
              )}
              Member did not join
            </button>
            <button
              disabled={mainLoading}
              class="btn btn-sm btn-outline-secondary  mr-2"
              onClick={(e) => sendConfirmation(e, "instructor_no_show")}
            >
              {loading === "instructor_no_show" && (
                <span class="spinner-border spinner-border-sm mr-2"></span>
              )}
              Mentor did not join
            </button>
            <button
              disabled={mainLoading}
              className={internetIssue ? "btn btn-sm btn-secondary  mr-2" : "btn btn-sm btn-outline-secondary  mr-2"}
              onClick={(e) => setInternetIssue(!internetIssue)}
            >
              Connectivity issue
            </button>



          </div>
          {internetIssue && <>
            <div className='mt-4'>
              <h3 className='h5 mb-3'>Was the internet issue at your end?</h3>
              <div className='mt-2'>
                <button
                  disabled={mainLoading}
                  className="btn btn-sm btn-outline-secondary mr-2"
                  onClick={(e) => sendConfirmation(e, "internet_issue_student")}
                >
                  {loading === "internet_issue_student" && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                  )}
                  Yes, it was at my end
                </button>{" "}
                <button
                  disabled={mainLoading}
                  className="btn btn-sm btn-outline-secondary"
                  onClick={(e) =>
                    sendConfirmation(e, "internet_issue_instructor")
                  }
                >
                  {loading === "internet_issue_instructor" && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                  )}
                  No, it was at instructor end
                </button>
              </div>
            </div>
          </>
          }

        </div>
      </div>
    </Modal>
  );
};

export default AttendanceModal
