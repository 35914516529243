import ReactPlayer from 'react-player'


const Video = () => {
    return (
      <div class="mt-lg-n4 mt-0 pt-0 mt-0 px-lg-5 px-2">
      <div class="container">
          <div class="row justify-content-center">
                <div class="col-12 col-md-10 col-lg-11 text-center" >
                    {/* <p class="" >Who we are</p> */}
                    {/* <h3 class="display-4 mb-5 px-lg-5 px-0"  > Gifting should be easy and fun, not cumbersome and boring; an act of care rather than an obligation</h3> */}
                    <div class = "d-none d-lg-block">
                    <ReactPlayer url='https://youtu.be/WAArhIpa2qU' className = "w-100" height = "60vh" controls/>
                    </div>
                    <div class = "d-block d-lg-none">
                    <ReactPlayer url='https://youtu.be/WAArhIpa2qU' className = "w-100" height = "40vh" controls/>
                    </div>
                </div>
                </div>
            </div>
      </div>
    )
  }

  export default Video;