const Doc = () => (
    <div>
    <div class ="mb-6">
    <h3 id = "facebook">Facebook Data Deletion Instructions</h3>
    <p>Last Updated: May 25, 2021</p>
    <p>Dhunguru Login is a facebook login app and we do not save your personal data in our server. According to Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL. </p>
    <p>If you want to delete your activities for Dhunguru Login App, you can remove your information by following these steps:</p>
    <ol >
        <li className ='my-3'>
            Go to your Facebook Account’s Setting &amp; Privacy. Click “Settings”
        </li>
        <li className ='my-3'>
            Look for “Apps and Websites” and you will see all of the apps and websites you linked with your Facebook.
        </li>
        <li className ='my-3'>
            Search and Click “Dhunguru Login” in the search bar.
        </li>
        <li className ='my-3'>
            Scroll and click “Remove”.
        </li>
        <li className ='my-3'>
            Congratulations, you have succesfully removed your app activities.
        </li>
    </ol>
    </div>
    <hr class = "mb-6"/>
    </div>
)

export default Doc
    