import {
  FETCH_SCHEDULE,
  FETCH_REQUESTS,
  FETCH_ENROLLMENT,
  FETCH_COURSES,
  FETCH_MESSAGES,
  FETCH_STUDENTS,
  ALL_STUDENTS,
  ALL_CLASSES
} from '../actions/instructor'


const teacherReducer = (state, action) => {
  switch (action.type) {
    case FETCH_STUDENTS:
      return {
        ...state,
        chat_students: action.payload.students,
      }

    case FETCH_MESSAGES:
      return {
        ...state,
        chat_messages: action.payload.messages,
      }

    case FETCH_SCHEDULE:
      return {
        ...state,
        schedule: action.payload.schedule
      }

    case FETCH_REQUESTS:
      return {
        ...state,
        requests: action.payload.requests
      }
    case ALL_STUDENTS:
      return {
        ...state,
        students: action.payload.students
      }

    case ALL_CLASSES:
      return {
        ...state,
        classes: action.payload.classes
      }

    case FETCH_ENROLLMENT:
      return {
        ...state,
        enrollments: action.payload.enrollments
      }

    case FETCH_COURSES:
      return {
        ...state,
        courses: action.payload.courses
      }

    default:
      return state;
  }
}

export default teacherReducer;

