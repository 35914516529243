import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import AsyncSelect from 'react-select/async';
import customStyles from '../../../../utilis/customStyles'
import axios from 'axios'


const RecordFine = ({show, setShow, data, setData}) => {

    const [state, setState] = useState({
        amount: '',
        scheduleId: '',
        instructorId: '',
        comment: ''
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(Array(4).fill(false));


    useEffect(() => {
        setErrors(Array(4).fill(false))
    }, [state])

    
    
    const fetchInstructors = async (inputValue, callback) => {
        try{
            const res = await axios.get(`/api/admin/enrollment/instructors/${inputValue}`);
            callback(res.data.map(item => ({value: item._id, label: <NameWithAvatar email = {item.email} name = {item.firstName + ' ' + item.lastName} avatar = {item.avatar}/> })))
        } catch(err){
            console.log(err)
        }
    }


    const validate = () => {
        const newErrors = Array(4).fill(false);
        if(!state.instructorId || state.instructorId === '' || !state.instructorId.value ) newErrors[0] = true;
        if(state.scheduleId === '') newErrors[1] = true
        if(state.amount === '') newErrors[2] = true
        if(state.comment === '') newErrors[3] = true
        setErrors(newErrors)
        if(newErrors.includes(true)) return true
        else return false;
    }


    const saveData = async (e) => {
        e.preventDefault();
        if(validate()) return;
        try{
            setLoading(true);
            const res = await axios.post('/api/admin/invoice/createFine', {...state, instructorId: state.instructorId.value})
            console.log(res.data);
            const newData = [...data];
            newData.push(res.data);
            setData(newData)

            setShow(false)
            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Create a Fine</div>
                </div>

                <form class = "mt-3 ">

                    <div class = "form-group mb-5">
                        <label>Select the mentor</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={[{value: '', label: 'Please type to search'}]}
                            styles = {customStyles.bigSelect}
                            loadOptions={fetchInstructors}
                            value = {state.instructorId}
                            onChange={e => {
                                if(e && e.value && e.value !== '') {
                                    setState(prev => ({...prev, instructorId: e}))
                                } else setState(prev => ({...prev, instructorId: null}))
                            }}
                            isClearable
                        />
                        {errors[0] && <div class = 'invalid-feedback d-block'>Please select a instructor</div>}
                    </div>



                    <div class = "form-group mb-5">
                        <label>Please enter the schedule Id</label>
                        <input
                            class = {`form-control ${errors[1] && 'is-invalid'}`}
                            value = {state.scheduleId}
                            onChange = {e => setState(prev => ({...prev, scheduleId: e.target.value}))}
                        />
                        <div class = 'invalid-feedback'>Please add a schedule id</div>
                    </div>


                    <div class = "form-group mb-5">
                        <label>Please enter the amount</label>
                        <div class = 'small text-muted mt-n2 mb-2'>Please enter a negative number if you want to create a fine. If added number is positive, it will reduce the overall fine imposed on teacher</div>
                        <input
                            class = {`form-control ${errors[2] && 'is-invalid'}`}
                            value = {state.amount}
                            onChange = {e => setState(prev => ({...prev, amount: e.target.value}))}
                            type = "number"
                        />
                        <div class = 'invalid-feedback'>Please add a amount</div>
                    </div>
                    
                    
                    <div class = "form-group mb-5">
                        <label>Please add a comment</label>
                        <input
                            class = {`form-control ${errors[3] && 'is-invalid'}`}
                            value = {state.comment}
                            onChange = {e => setState(prev => ({...prev, comment: e.target.value}))}
                        />
                        <div class = 'invalid-feedback'>Please add a comment</div>
                    </div>
                   
                 
                  
                    
                    <button class = "btn btn-primary btn-block mt-6" onClick = {(e) => saveData(e)} disabled = {loading}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Create Fine Invoice
                    </button>
                    
                </form>
                
            </div>
        </RightModal>
    )
}

export default RecordFine;






const NameWithAvatar = ({name, email, avatar}) => {
    return (
        <div class="row align-items-center no-gutters">
            <div class = "col-auto mr-3">
                <div className="avatar avatar-xs" >
                    {/* <img class="avatar-img rounded-circle" src={avatar} alt="..." /> */}
                </div>
            </div>
            <div class = "col">
                <div class="small mb-n1">{name}</div>
                <div class=" text-muted small">{email}</div>
            </div>
        </div>
    )
}