import React, {useState, useEffect} from 'react';
import {Stars} from '../Lesson'
import { percentageIncrease } from '../../../utilis/pricingConfig';
import { increasePriceByPercentage } from '../../../utilis/helpers';

const Package = ({setSeeDetails, seeDetails, setStep, enrollment, setEnrollment, course, purchaseNow, loading, applyDiscountCode, discountCode, setDiscountCode, appliedDiscount, setAppliedDiscount}) => {
    return (
        <div>
            <div class = 'container px-lg-6 mt-6 mb-6' >
                <div className = 'row'>
                    <div className = 'col-12 col-lg-8 px-5 px-lg-5'>
                        <LessonPackage 
                            setEnrollment = {setEnrollment}
                            enrollment = {enrollment}
                            course = {course}
                        />
                    </div>
                    <div className = 'd-none d-lg-block col-lg-4'>
                        <Summary 
                            loading = {loading} 
                            setStep ={setStep} 
                            purchaseNow = {purchaseNow} 
                            course = {course} 
                            enrollment = {enrollment}
                            applyDiscountCode = {applyDiscountCode}
                            discountCode = {discountCode}
                            setDiscountCode = {setDiscountCode}
                            appliedDiscount = {appliedDiscount}
                            setAppliedDiscount = {setAppliedDiscount}
                        />
                    </div>
                </div>
            </div>
            <div className = 'd-block d-lg-none'>
                <div class = 'd-block d-lg-none' style = {{height: '20vh'}}></div>
                <SmSummary 
                    loading = {loading} 
                    setStep ={setStep} 
                    purchaseNow = {purchaseNow} 
                    course = {course} 
                    enrollment = {enrollment}
                    applyDiscountCode = {applyDiscountCode}
                    discountCode = {discountCode}
                    setDiscountCode = {setDiscountCode}
                    appliedDiscount = {appliedDiscount}
                    setAppliedDiscount = {setAppliedDiscount}
                    setSeeDetails = {setSeeDetails} 
                    seeDetails = {seeDetails}
                />
            </div>
        </div>
    )
}


export default Package;


export const LessonPackage = ({enrollment, setEnrollment, course}) => {
    return (
        <div>  
            <div class = 'text-center'>
                {/* <div class = 'h1 mb-2'>Choose your preferred bundle  at ₹{course.price_per_class} per session</div> */}
                <div class = 'h1 mb-2'>Choose your preferred bundle  at ₹{increasePriceByPercentage(Number(course.price_per_class))} per session</div>
                <div class = 'p'>With bigger bundle comes bigger discount</div>
            </div>
            <div class = 'mt-5 px-4 px-lg-0'>
                <div class = 'h3 mb-2'>How many sessions would you like to purchase?</div>
                <div class = 'row '>
                    {course.discount.map(item => 
                        <div 
                            class  = {`col-12 col-md py-3 border ${enrollment.num_classes === item.num_classes ? 'btn-primary' : 'btn-white'}`} 
                            onClick = {() => {
                                setEnrollment(prev => ({...prev, num_classes: item.num_classes, discount: item.discount}))
                            }}
                        >
                            <div class = 'card-body text-left text-lg-center'>
                                <div className = 'row align-items-center'>
                                    <div className = 'col-6 col-lg-12'>
                                        <div class = 'bold h1 mb-1 d-flex d-lg-block '>{item.num_classes < 9 ? '0' + item.num_classes : item.num_classes} <span className = 'ml-2 d-inline-block d-lg-none'>Lessons</span></div>
                                    </div>
                                    <div className = 'col-6 col-lg-12  mt-n2'>
                                    <div class = 'small'>{item?.num_classes === '1' ? 'session' : 'sessions'}</div>
                                    </div>
                                    <div className = 'col-6 col-lg-12  mt-n2'>
                                    <div class = 'small'>{item.discount === '0' ? 'no' : `${item?.discount}%`} discount</div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </div>
                {/* <div class = 'small mt-3 '>
                The bigger the bundle, the higher the discount. 
                </div> */}
                <div class = 'mt-6'>
                Note: You can submit your GST input details in your dashboard, under ‘Profile’ Section, in case you want us to send a GST Invoice.
                </div>

            </div>
            
        </div>
    )
}



const Summary = ({enrollment, course, purchaseNow, loading, discountCode, setDiscountCode, appliedDiscount, setAppliedDiscount, applyDiscountCode}) => {

    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [discountValue, setDiscountValue] = useState(0);
    const [classDiscountValue, setClassDiscountValue] = useState(0);
    const [appliedDiscountValue, setAppliedDiscountValue] = useState(0);

    useEffect(() => {
        console.log(appliedDiscount)
        // const total_price = course.price_per_class*enrollment.num_classes;
        const total_price = (increasePriceByPercentage(Number(course.price_per_class)))*enrollment.num_classes;
        const classDiscount = total_price*(enrollment.discount/100)
        const coupenDiscount = appliedDiscount.discountCode ? (appliedDiscount.discountType === 'AMOUNT' ? appliedDiscount.amountOff: 
            total_price*((1-enrollment.discount/100)*(appliedDiscount.percentOff/100)) > appliedDiscount.maximumAmountAllowed ? appliedDiscount.maximumAmountAllowed :total_price*((1-enrollment.discount/100)*(appliedDiscount.percentOff/100)) 
        ) : 0
        const total_price_after_class_discount = total_price - classDiscount - coupenDiscount;
        setClassDiscountValue(classDiscount)
        setAppliedDiscountValue(appliedDiscount.discountCode ?  appliedDiscount.discountType === 'AMOUNT' ? 'Rs ' + appliedDiscount.amountOff : total_price*((1-enrollment.discount/100)*(appliedDiscount.percentOff/100)) > appliedDiscount.maximumAmountAllowed ? 'Rs '+ appliedDiscount.maximumAmountAllowed: appliedDiscount.percentOff + '%' : 0);
        setDiscountValue(parseFloat(coupenDiscount).toFixed(2));
        setSubTotal(parseFloat(total_price_after_class_discount).toFixed(2));
        setGst(parseFloat(total_price_after_class_discount*0.18).toFixed(2));
        setTotal(parseFloat(total_price_after_class_discount*1.18).toFixed(2));
    }, [enrollment, course, appliedDiscount]);

    const clearDiscount = () => {
        setDiscountCode('')
        setAppliedDiscount({})
    }

    return (
        <div className = 'card shadow-sm border'>
            <div className = 'card-body'>
                <div className = 'row mb-4 align-items-center'>
                    <div className = 'col-auto'>
                        <div class = 'avatar avatar-lg'>
                            <img alt="" src = {course.instructor.avatar} class = 'avatar-img rounded-circle' />
                        </div>
                    </div>
                    <div className = 'col'>
                        <div class = 'h2 mb-1'>{course.title}</div>
                        <div class = 'h3 mb-0'>{course.instructor.firstName + ' ' + course.instructor.lastName}</div>
                        {course.reviews.length > 0 && <div className = 'd-flex align-items-center'>
                            <Stars numStars = {(course.reviews.reduce((a, b) => a + b.rating, 0))/course.reviews.length}/> 
                            <span className = 'small pl-2 pt-1'>({course.reviews.length} reviews)</span>
                        </div>}
                    </div>
                </div>
                <div className = ' pt-4 mb-3'>
                    <div className = 'h7 mb-2'>Session details</div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8-unbold'>Session duration:</span>
                        <span className = 'h8'>45 mins</span>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8-unbold'>Number of sessions:</span>
                        <span className = 'h8'>{enrollment.num_classes}</span>
                    </div> 
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8-unbold'>Price per session:</span>
                         <span className = 'h8'>{'₹' + increasePriceByPercentage(Number(course.price_per_class))}</span>
                    </div>
                </div>
                <div className="input-group ">
                    <input 
                        placeholder = "Enter your coupon here"
                        style = {{height: '40px'}} 
                        className = {`form-control `} 
                        value = {discountCode} 
                        onChange = {e => {
                            setDiscountCode((e.target.value).toUpperCase())
                        }}
                        disabled = {appliedDiscount.discountCode ? true : false}
                    />
                    <div className="input-group-prepend" >
                        {!!appliedDiscount.discountCode ? <span className="input-group-text btn btn-info " onClick = {clearDiscount} style = {{height: '40px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}} >
                            Clear
                        </span> : <span className="input-group-text btn btn-secondary " onClick = {applyDiscountCode} style = {{height: '40px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}} >
                            Apply
                        </span>}
                    </div>
                </div>
                <div className = ' pt-4 mb-3'>
                    <div className = 'h7 mb-2'>Total</div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8-unbold'>Bundle price:</span>
                        {/* <span className = 'h8'>₹{course.price_per_class*enrollment.num_classes}</span> */}
                        <span className = 'h8'>₹{increasePriceByPercentage(Number(course.price_per_class))*enrollment.num_classes}</span>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8-unbold'>Class Discount  <span class = "text-muted">({enrollment.discount}%)</span>:</span>
                        <span className = 'h8'>- ₹{classDiscountValue}</span>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8-unbold'>Coupon Discount  <span class = "text-muted">({appliedDiscountValue}):</span>:</span>
                        <span className = 'h8'>- ₹{discountValue}</span>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1 mt-3 border-top pt-3'>
                        <span className = 'h8-unbold'>Subtotal:</span>
                        <span className = 'h8'>₹{subTotal}</span>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8-unbold'>GST <span class = "text-muted">(18%)</span>:</span>
                        <span className = 'h8'>+ ₹{gst} </span>
                    </div>
                    <div className = 'pt-4 d-flex mb-3 align-items-center justify-content-between  py-1'>
                        <span className = 'h8-unbold'>Order Total</span>
                        <span className = 'h8'>₹{total}</span>
                    </div>
                </div>

                
                
                <button className = 'btn btn-block btn-primary btn-pill' onClick = {(e) => purchaseNow(e)} disabled = {loading}>
                    {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                    Purchase Now
                </button>
                <div className = 'text-center mt-2 small'>
                Musical fulfilment awaits 
                </div>

            </div>
        </div>
    )
}



const SmSummary = ({enrollment, course, purchaseNow, loading, discountCode, setDiscountCode, setSeeDetails, seeDetails, appliedDiscount, setAppliedDiscount, applyDiscountCode}) => {

    const [subTotal, setSubTotal] = useState(0)
    const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [discountValue, setDiscountValue] = useState(0);
    const [appliedDiscountValue, setAppliedDiscountValue] = useState(0);
    const [classDiscountValue, setClassDiscountValue] = useState(0);

    useEffect(() => {
        console.log(appliedDiscount)
        // const total_price = course.price_per_class*enrollment.num_classes;
        const total_price = increasePriceByPercentage(Number(course.price_per_class))*enrollment.num_classes;
        const classDiscount = total_price*(enrollment.discount/100)
        const coupenDiscount = appliedDiscount.discountCode ? (appliedDiscount.discountType === 'AMOUNT' ? appliedDiscount.amountOff: 
        total_price*((1-enrollment.discount/100)*(appliedDiscount.percentOff/100)) > appliedDiscount.maximumAmountAllowed ? appliedDiscount.maximumAmountAllowed :total_price*((1-enrollment.discount/100)*(appliedDiscount.percentOff/100)) 
        ) : 0
        const total_price_after_class_discount = total_price - classDiscount - coupenDiscount;
        setAppliedDiscountValue(appliedDiscount.discountCode ?  appliedDiscount.discountType === 'AMOUNT' ? 'Rs ' + appliedDiscount.amountOff : total_price*((1-enrollment.discount/100)*(appliedDiscount.percentOff/100)) > appliedDiscount.maximumAmountAllowed ? 'Rs '+ appliedDiscount.maximumAmountAllowed: appliedDiscount.percentOff + '%' : 0);
        setClassDiscountValue(classDiscount)
        setDiscountValue(parseFloat(coupenDiscount).toFixed(2));
        setSubTotal(parseFloat(total_price_after_class_discount).toFixed(2));
        setGst(parseFloat(total_price_after_class_discount*0.18).toFixed(2));
        setTotal(parseFloat(total_price_after_class_discount*1.18).toFixed(2));
    }, [enrollment, course, appliedDiscount]);

    const clearDiscount = () => {
        setDiscountCode('')
        setAppliedDiscount({})
    }

    return (
        <div className = 'card mb-0' style = {{position: 'fixed', bottom: '0', left: '0', width: '100%'}}>
            <div className ='card-body'>
                <div className = 'see-details-pill bg-info' onClick = {() => setSeeDetails(prev => !prev)}>
                    See Details ^
                </div>
                {seeDetails && <div className = 'border-bottom py-2 px-3'>
                    <div className = 'h7'>Price Summary</div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8'>Selected Sessions</span>
                        {/* <span className = 'h8'>Rs {course.price_per_class*enrollment.num_classes}</span> */}
                        <span className = 'h8'>Rs {increasePriceByPercentage(Number(course.price_per_class))*enrollment.num_classes}</span>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8'>Discount</span>
                        <span className = 'h8'>{enrollment.discount} %</span>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between  py-1'>
                        <span className = 'h8'>Applied Discount</span>
                        <span className = 'h8'>{appliedDiscountValue}</span>
                    </div>
                </div>}
                <div className="input-group mb-3 mt-2">
                    <input 
                        placeholder = "Enter your coupon here"
                        style = {{height: '40px'}} 
                        className = {`form-control `} 
                        value = {discountCode} 
                        onChange = {e => {
                            setDiscountCode((e.target.value).toUpperCase())
                        }}
                        disabled = {appliedDiscount.discountCode ? true : false}
                    />
                    <div className="input-group-prepend" >
                        {!!appliedDiscount.discountCode ? <span className="input-group-text btn btn-info " onClick = {clearDiscount} style = {{height: '40px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}} >
                            Clear
                        </span> : <span className="input-group-text btn btn-secondary " onClick = {applyDiscountCode} style = {{height: '40px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px'}} >
                            Apply
                        </span>}
                    </div>
                </div>
                <div className = 'd-flex align-items-center justify-content-between px-3 py-2'>
                    <span className = 'h8'>Order Total</span>
                    <span className = 'h8'>Rs {total}</span>
                </div>
                <button disabled = {loading} className = 'btn btn-block btn-primary btn-pill' onClick = {(e) => purchaseNow(e)}>
                    {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                    Enroll Now
                </button>
                <div className = 'text-center mt-2 small'>
                Musical fulfilment awaits 
                </div>
            </div>
        </div>
    )
}