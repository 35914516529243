import React from 'react'
import AdminDashboard from '../index'

const Home = (props) => {
    return (
        <AdminDashboard showTitle = {false} padding = "high" {...props}> 
            <div className = 'row align-items-center mt-5  pb-3 mb-4' style = {{height: '80vh'}}>
                <div className = 'col text-center' style = {{fontSize: '3rem'}}>
                    Welcome to Admin Panel
                </div>
            </div>
        </AdminDashboard>
    )
}

export default Home
