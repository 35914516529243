import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import { validateEmail, cleanPhone, validatePhone } from '../../../../utilis/validation'
import {InputControl, DateFormInput1} from '../../../../components/Forms/'
import Select from 'react-select'

const InfoModal = ({show, setShow, user}) => {
    
    const [state, setState] = useState({email: '', phone: '', birthday: '', age_group: '', instrument: ''})
    const [address, setAddress] = useState({firstLine: '', city: '', state: '', zipCode: ''})
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([false, false])

    useEffect(() => {
        setState({email: user.email, phone: user.phone, birthday: user.birthday, age_group: user.onboarding.age_group, instrument: user.onboarding.instrument})
        setAddress({firstLine: user.fullAddress.firstLine, city: user.fullAddress.city, state: user.fullAddress.state, zipCode: user.fullAddress.zipCode})
    }, [user])

    useEffect(() => {
        setErrors([false, false])
    }, [state])


    const validate = () => {
        const new_errors = [false, false]
        if(!validateEmail(state.email)) new_errors[0] = true
        if(!validatePhone(state.phone)) new_errors[1] = true
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true
    }


    const editUser = async (e) => {
        e.preventDefault()
        if(!validate()) return;
        try{
            setLoading(true)
            await axios.post(`/api/admin/editUser/${user._id}`, {
                email: state.email, 
                phone: cleanPhone(state.phone),
                birthday: state.birthday,
                fullAddress: {
                    firstLine: address.firstLine,
                    state: address.state,
                    city: address.city,
                    zipCode: address.zipCode,
                },
                age_group: state.age_group,
                instrument: state.instrument,
            });
            setShow(false);
            setLoading(false)
            
        } catch(err){
            setLoading(false)
            console.log(err);
        }
    }

    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100 h-100 mt-1'>
                <div className = 'mb-5' id = 'details'>
                    <div className = 'h2'>{user.firstName + ' ' + user.lastName}</div>
                </div>

                <div className = 'form-group'>
                    <label>Email</label>
                    <input 
                        className = {`form-control ${errors[0] && 'is-invalid'}`}
                        value = {state.email}
                        onChange = {e => setState(prev => ({...prev, email: e.target.value}))}
                    />
                    <div class = 'invalid-feedback'>Please enter a valid email address</div>
                </div>
                
                <div className = 'form-group'>
                    <label>Phone</label>
                    <input 
                        className = {`form-control ${errors[1] && 'is-invalid'}`}
                        value = {state.phone}
                        onChange = {e => setState(prev => ({...prev, phone: e.target.value}))}
                    />
                    <div class = 'invalid-feedback'>Please enter a valid phone number</div>
                </div>
                
                <div className = 'form-group'>
                    <div>Age Group</div>
                    {age_groups.map(item => <span 
                        onClick ={() => setState(prev => ({...prev, age_group: item.value}))} 
                        className = {`btn border mr-3 mb-2 btn-${state.age_group === item.value ? 'primary':'white'}`}
                    >{item.label}</span>)}
                </div>

                 <div class = "form-group">
                    <label>Your Instrument</label>
                    <Select
                        value={instruments.filter(item => item.value === state.instrument)[0]}
                        onChange={(e) => {setState(prev => ({...prev, instrument: e.value}))}}
                        options={instruments}
                        isMulti = {true}
                    />
                </div>
                <div className = 'form-group'>
                    <DateFormInput1 
                        placeholder = "Birthday"
                        error = {false}
                        setError = {(val) => {}}
                        errorMessage = {"Please enter a valid birthday"}
                        label = "Birthday"
                        setValue = {(val) => setState(prev => ({...prev, birthday: val}))}
                        value = {state.birthday}
                    />
                </div>
                <div className="">
                        <InputControl 
                        placeholder = "Your full address"
                        error = {false}
                        setError = {(val) => {}}
                        errorMessage = {""}
                        label = "Your full address"
                        setValue = {(val) => setAddress(prev => ({...prev, firstLine: val}))}
                        value = {address.firstLine}
                    />
                </div>
                <div className="">
                    <InputControl 
                        placeholder = "City"
                        error = {false}
                        setError = {(val) => {}}
                        errorMessage = {""}
                        label = "City"
                        setValue = {(val) => setAddress(prev => ({...prev, city: val}))}
                        value = {address.city}
                    />
                </div>
                <div className="">
                    <InputControl 
                        placeholder = "State"
                        error = {false}
                        setError = {(val) => {}}
                        errorMessage = {""}
                        label = "State"
                        setValue = {(val) => setAddress(prev => ({...prev, state: val}))}
                        value = {address.state}
                    />
                </div>
                <div className="">
                    <InputControl 
                        placeholder = "Zip Code"
                        error = {false}
                        setError = {(val) => {}}
                        errorMessage = {""}
                        label = "Zip Code"
                        setValue = {(val) => setAddress(prev => ({...prev, zipCode: val}))}
                        value = {address.zipCode}
                    />
                </div>



                <div class = "mt-6">
                    <button disabled = {loading} class = "btn btn-block btn-outline-danger mt-3" onClick = {(e) => editUser(e)}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Edit User
                    </button>
                </div>
                
            </div>
        </RightModal>
    )
}

export default InfoModal





const age_groups = [
    {value: '5-15', label: "5-15"},
    {value: '16-25', label: "16-25"},
    {value: '26-35', label: "26-35"},
    {value: '35-50', label: "35-50"},
    {value: '50+', label: "50 above"},
]


const instruments = [
    { value: 'guitar', label: "Guitar"},
    { value: 'piano', label: "Piano"},
    { value: 'drums', label: "Drums"},
    {value: 'keyboard', label: "Keyboard" },
    {value: 'violin', label: "Violin" },
    {value: 'ukulele', label: "Ukulele" },
    {value: 'cajon', label: "Cajon" },
    {value: 'harmonium', label: "Harmonium" },
    {value: 'flute', label: "Flute" },
    {value: 'tabla', label: "Tabla" },
    {value: 'veena', label: "Veena" },
    {value: 'trumpet', label: "Trumpet" },
    {value: 'mridangam', label: "Mridangam" },
    {value: 'sitar', label: "Sitar" },
    {value: 'singing', label: "Singing" },
    {value: 'songWriting', label: "Song Writing" },
    {value: 'musicComposition', label: "Music Production" },
    {value: 'musicTheory', label: "Music Theory" },
    {value: 'rap', label: "Rap" },
    {value: 'musicTherapy', label: "Music Therapy" },
    {value: 'dJing', label: "DJing" },
    {value: 'dhol', label: "Dhol" },
    {value: 'harmonica', label: "Harmonica" },
    {value: 'mandolin', label: "Mandolin" },
    {value: 'doubleBass', label: "Double Bass" },
    {value: 'cello', label: "Cello" },

]
