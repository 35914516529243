import {useContext} from 'react'
import LgFooter from '../../LandingPage/Footer'
import Nav from '../../LandingPage/Nav'
import cogoToast from 'cogo-toast'
import { Store } from '../../../store'
import { fetchUser } from '../../../store/actions/auth'
import {useChannel, useEvent} from '@harelpls/use-pusher'
import {withRouter} from 'react-router-dom'
import { saveMessages, saveInstructors } from '../../../store/actions/student';


const StudentDashboard = (props) => {

    const {state, dispatch} = useContext(Store)
    const channel = useChannel(state.user.data._id);

    useEvent(channel, "notifications", async (data) =>{
        if(state.user.data.notifications.filter(item => item._id === data._id).length === 0){
            if(data.type === 'NEW_MESSAGE_S' && !props.location.pathname.includes('/my-dashboard/messages')){
              editMessage(data.data.sender_id, data.data.message, new Date(), data.data.messageId)
            }
            if(data.type === "NEW_MESSAGE_S" && props.location.pathname === '/my-dashboard/messages'){
            } else notification(data.title, data.description, data.url)
            const newUser = {...state.user.data}
            newUser.notifications.push(data);
            fetchUser(newUser, dispatch)
        }
    });
    

    const notification = (title, description, url) => {
        const {hide} = cogoToast.info(
          description, { position: 'top-right', title: title, hideAfter: 6, 
            onClick: () => {
              if(url){
                if(url.slice(0, 4) === 'http') window.open(url, '_blank');
                else {
                  props.history.push(url)
                  window.location.reload();
                }
                hide();
              } else hide();
            } 
          }
        );
    }


    const editMessage =  (userId, message, time, message_id) => {
      try{
  
          const allMessages = Object.values(state.student.chat_messages).flat()
          if(!state.student.chat_messages || allMessages.length === 0) return;
          if(message.length === 0 || allMessages.filter(item => item._id === message_id).length>0){
            // console.log("Duplicate")
              return;
          }
          const newMessages = {...state.student.chat_messages}; 
          let newInstructors = [...state.student.chat_instructors]
          if(!newMessages[userId]) {
              newMessages[userId] = []
          };
          newMessages[userId] = [{
              from: 'instructor',
              message: message,
              time: time,
              isRead: false,
              _id: message_id
          }, ...newMessages[userId]]
          
          const index = newInstructors.findIndex(item => item._id === userId);
          if(index < 0){
              return;
          }
          newInstructors[index].lastMsgTime = new Date();
          newInstructors[index].lastMsg = message.length > 20 ? message.slice(0, 20) + '...' : message;
          newInstructors[index].unreadMsg = newMessages[userId].reduce((a, b) => {
              if(!b.isRead && b.from === 'instructor') return a + 1
              else return a
          }, 0)
      
          newInstructors = newInstructors.sort((a, b) => {
              if(b.lastMsgTime){
                  return new Date(b.lastMsgTime) - new Date(a.lastMsgTime)
              } else return -1
          })
      
          saveInstructors(newInstructors, dispatch)
          saveMessages(newMessages, dispatch);
      }
      catch(err){
          console.log(err)
      }
      
  }


    return (
        <div className = "">
            <Nav {...props}/> 
            {props.children}
            <div className = 'mt-6'></div>
            <LgFooter/>
        </div>
    )
}

export default withRouter(StudentDashboard);