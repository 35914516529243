import React, {useState, useEffect} from 'react'
import {DateFormInput1} from '../../../components/Forms'
import Select from 'react-select'
import axios from '../../../utilis/axios'
import content from '../../../content/landingPage.json'
import moment from 'moment'

const Schedule = ({course, schedule, setSchedule, scheduleFreeSession, loading}) => {

    const [availability, setAvailability] = useState({});
    const [errors, setErrors] = useState([false, false])

  
    const fetchAvailability = async (date1) => {
        try{
                setSchedule(prev => ({...prev, time: {value: null, label: 'Loading...'}}))
                const res = await axios.post(`/api/student/schedule/instructor_availability/${course.instructor._id}`, {date: date1})
                const newAvailability = {...availability};
                newAvailability[date1] = getAvailability(res.data);
                setAvailability(newAvailability)
                setSchedule(prev => ({...prev, time: {value: null, label: 'Please select a time slot'}}))
        } catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        setErrors([false, false]);
    }, [schedule])


    const finalStep = () => {
        const err = [false, false];
        if(schedule.date === '') err[0] = true;
        if(!schedule.time.value || schedule.time.value === '') err[1] = true;
        if(err.includes(true)) {
            setErrors(err);
            return;
        } else{
            scheduleFreeSession();
        }
    }

    return (
        <div className = 'h-100 d-flex align-items-center justify-content-center' style = {{backgroundImage: `url('${content.bookFreeSession.img}')`, backgroundColor: 'rgba(0, 0, 0, 0.5)', backgroundSize: 'cover', minHeight: 'calc(100vh - 60px)'}}>
            <div class = 'bg-layer'>
            </div>
            <div className = 'card shadow-sm border' style = {{width: '500px'}}>
                <div className = 'card-body py-6'>
                    <div class = 'mb-4 text-center px-lg-5'>
                        <div className = 'h2 mb-1'>{content.bookFreeSession.schedule.title}</div>
                        <div className = 'h8-unbold mb-1'>{content.bookFreeSession.schedule.description}</div>
                    </div>
                    <form class = 'px-lg-4'>  
                        <DateFormInput1 
                            placeholder = "Select a date"
                            error = {errors[0]}
                            setError = {(val) => setErrors(prev => {prev[0]=val; return prev})}
                            errorMessage = {"Please enter a valid date"}
                            label = "Date"
                            setValue = {(val) => {
                                setSchedule(prev => ({...prev, date: val}))
                                fetchAvailability(val);
                            }}
                            value = {schedule.date}
                            minimumDate = {moment().add(1,'d')}
                        />
                        <div class = "form-group my-4">
                            <label>Time Slot</label>
                            <Select 
                                value = {schedule.date ? schedule.time : {value: '', label: 'Please select a date'}}
                                options = {availability[schedule.date] ? availability[schedule.date] : [{value: '', label: ''}]}
                                onChange = {e => setSchedule(prev => ({...prev, time: e}))}
                                isDisabled = {(schedule.date && availability[schedule.date]) ? false : true}
                            />
                            {errors[1] && <div class = 'invalid-feedback d-block'>Please choose a slot</div>}
                        </div>
                        <div class = "btn btn-primary btn-block mt-6" onClick = {() => finalStep()}>
                            {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                            Schedule your free session
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Schedule




export const getAvailability = (array) => {
    let temp = []
    let start1 = 0
    for(let i=0; i<array.length; i++){
        if(array[i]){
            temp.push({value:start1, label: timeFormat(start1) + ' - ' + timeFormat(start1, true)})
            start1++
        } else{
            start1++
        }
    }
    return temp
}



const timeFormat = (time, isSecond) => {
    if(time < 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " AM"
    } else if(time === 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " PM"
    } else if(time > 12){
        return time1(time-12) + ":" + (isSecond ? "45":"00") + " PM"
    }
}

const time1 = (time) => time >= 10 ? time : "0"+time