import React, {useEffect, useState} from 'react'
import DashboardLayout from '../index'
import Notifications from './Notifications'
import Security from './Security'
import Bank from './Bank'

const Account = (props) => {

    const [tab, setTab] = useState('')
    
    useEffect(() => {
        const pathname = props.match.params.id;
        setTab(pathname)
    }, [props.match.params.id])

    useEffect(() => {
        if(tab !== props.match.params.id && tab !== "") props.history.push(`/instructor/account/${tab}`)
        // eslint-disable-next-line
    }, [tab])

    return (
        <DashboardLayout showTitle = {false} {...props}>
            <div className = 'mt-4'>
                <div className = 'row justify-content-center'>

                    <div className = 'col-12 col-lg-10 col-xl-8'>
                        <div className = 'header mt-md-5'>
                            <div className = 'header-body'>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 class="header-pretitle">Overview</h6>
                                        <h1 class="header-title">Account</h1>
                                    </div>
                                </div>
                                <AccountNav tab = {tab} setTab = {setTab}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className = "row justify-content-center">
                <div className = 'col-11 col-lg-10 col-xl-8'>
                    {tab === "bank_details" && <Bank />}
                    {tab === "notifications" && <Notifications />}
                    {tab === "security" && <Security />}
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Account



const AccountNav = ({tab, setTab}) => {
    return(
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'bank_details' && 'active'}`} onClick = {() => setTab('bank_details')}>Bank Details</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'security' && 'active'}`} onClick = {() => setTab('security')}>Security</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'notifications' && 'active'}`} onClick = {() => setTab('notifications')}>Notifications</div>
                    </li>
                </ul>
        </div>
    </div>
    )
}