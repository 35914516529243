const teacherReducer = (state, action) => {
    switch (action.type) {
      case 'NEW_MESSAGE':
        return {
          ...state,
          newMessage: action.payload
      }
      default:
        return state;
    }
  }
  
  export default teacherReducer;
  
  