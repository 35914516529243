import React, {useContext} from 'react'
import { Store } from '../../../../store'
import CreateAccount from './CreateAccount'
import {Redirect} from 'react-router-dom'

const SuperAdmin = (props) => {

    const {state} = useContext(Store)

    return (
        <div>
            {state.user.data.isSuperAdmin ? <CreateAccount /> : <Redirect to = "/admin" />}
        </div>
    )
}

export default SuperAdmin


