import React, {useState, useEffect, useContext} from 'react'
import DashboardLayout from '../index'
import TableDragSelect from 'react-table-drag-select'
import { Store } from '../../../../store'
import { saveInstructorProfile } from '../../../../store/actions/auth'
import axios from '../../../../utilis/axios'
import instructor_content from '../../../../content/instructor_content.json'


const Availability = (props) => {

    const {state, dispatch} = useContext(Store)
    const [cells, setCells] = useState(Array(25).fill().map(_ =>  Array(8).fill(false)));
    const [profile, setProfile] = useState(state.user.data.instructor_profile)

    useEffect(() => {

        if(profile.availability && profile.availability.m){
            const arr = [
                Array(25).fill(false),
                [false, ...profile.availability.m],
                [false, ...profile.availability.tu],
                [false, ...profile.availability.w],
                [false, ...profile.availability.th],
                [false, ...profile.availability.f],
                [false, ...profile.availability.sa],
                [false, ...profile.availability.su],
            ]
            const tranposedCells = arr[0].map((_, colIndex) => arr.map(row => row[colIndex]));
            setCells(tranposedCells)
        }
        //eslint-disable-next-line
    }, [])

    const changeCells = (val) => {
        setCells(val);
        const newProfile = {...profile}
        const tranposedCells = val[0].map((_, colIndex) => val.map(row => row[colIndex]));
        const newAvailArray = {
            "m": tranposedCells[1].slice(1),
            "tu": tranposedCells[2].slice(1),
            "w": tranposedCells[3].slice(1),
            "th": tranposedCells[4].slice(1),
            "f": tranposedCells[5].slice(1),
            "sa": tranposedCells[6].slice(1),
            "su": tranposedCells[7].slice(1),
        }
        newProfile.availability = newAvailArray
        setProfile(newProfile);
        saveProfile(newProfile)
    }

    const saveProfile = async (newProfile) => {
        try{

            const res = await axios.put(`/api/instructor/profile/availability`, {availability: newProfile.availability})
            newProfile.onboarding.course = true;
            saveInstructorProfile(state.user.data, res.data, dispatch)

        } catch(err){
            console.log(err)
            throw err;
        }
    }


    return (
        <DashboardLayout showTitle = {false} padding = "high" {...props}>
            <div className = 'row align-items-center mt-5 border-bottom pb-3 mb-4'>
                <div className = 'col'>
                    <h3 class = 'h1'>{instructor_content.availability.title}</h3>
                    <h3 className="p">{instructor_content.availability.sub_heading}</h3>
                </div>
            </div>
            
            <div className="p mb-2 mt-3  pb-3">{instructor_content.availability.description}</div>
            <div className="p mb-2 mt-3 bold">{instructor_content.availability.how_to}</div>
            <div className = 'card '>
                <div className = 'table-body mb-0'>
                    <TableDragSelect class = "table table-sm table-nowrap" value={cells} onChange={(value) => changeCells(value)}>
                        <tr class = 'thead'>
                            <td disabled />
                            <td disabled>
                                <div class = 'd-block d-lg-none'>M</div>
                                <div class = 'd-none d-lg-block'>Monday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>Tu</div>
                                <div class = 'd-none d-lg-block'>Tuesday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>W</div>
                                <div class = 'd-none d-lg-block'>Wednesday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>Th</div>
                                <div class = 'd-none d-lg-block'>Thursday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>F</div>
                                <div class = 'd-none d-lg-block'>Friday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>Sa</div>
                                <div class = 'd-none d-lg-block'>Saturday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>SU</div>
                                <div class = 'd-none d-lg-block'>Sunday</div>
                                
                            </td>
                        </tr>
                        {cells.slice(1).map((item, index) => <tr class = "py-1">
                            <td class ="tr-pad" disabled>{formaTime(index)}</td>
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                        </tr>)}
                    </TableDragSelect>
                </div>
            </div>

        </DashboardLayout>
    )
}

export default Availability




const formaTime = (index) => {
    if(index === 12) return index+' PM'
    return index > 12 ? (index-12 < 10 ? '0' + (index-12) : index-12) + ' PM' : (index < 10 ? '0' + index : index)  + ' AM'
}