import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {RiEmotionUnhappyLine, RiEmotionSadLine, RiEmotionNormalLine, RiEmotionLine, RiEmotionLaughLine} from 'react-icons/ri'
import axios from '../../../../utilis/axios';
import student_content from '../../../../content/student_content.json'

const Review = ({show, setShow, courseId}) => {

    const [review, setReview] = useState({review: '', rating: ''})
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([false, false])


    const validate = () => {
        const new_errors = [false, false]
        if(review.review.length < 10) new_errors[0] = true
        if(review.rating === '') new_errors[1] = true
        setErrors(new_errors)
        if(new_errors.includes(true)) return true;
        else return false;
    }

    const submitReview = async (e) => {
        e.preventDefault();
        if(validate()) return;
        try{
            setLoading(true)
            await axios.post(`/api/student/lesson/review/${courseId}`, review)
            setLoading(false)
            setShow(false)
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered>
            <div className = 'card mb-0'>
                <div className = 'card-header'>
                    <h3 className = 'card-header-title'>{student_content.review.title}</h3>
                </div>
                <div className = 'card-body'>
                    <h4 className = ''>Star Rating</h4>
                    <div className="btn-group mr-2 btn-block " >
                        <div onClick = {() => setReview(prev => ({...prev, rating: 1}))} className={`btn border btn-${review.rating === 1 ? 'danger' : 'white'} btn-block`}><RiEmotionUnhappyLine size = {25}/></div>
                        <div onClick = {() => setReview(prev => ({...prev, rating: 2}))} className={`btn border btn-${review.rating === 2 ? 'danger' : 'white'} btn-block mt-0`}><RiEmotionSadLine size = {25}/></div>
                        <div onClick = {() => setReview(prev => ({...prev, rating: 3}))} className={`btn border btn-${review.rating === 3 ? 'warning' : 'white'} btn-block mt-0`}><RiEmotionNormalLine size = {25}/></div>
                        <div onClick = {() => setReview(prev => ({...prev, rating: 4}))} className={`btn border btn-${review.rating === 4 ? 'success' : 'white'} btn-block mt-0`}><RiEmotionLine size = {25}/></div>
                        <div onClick = {() => setReview(prev => ({...prev, rating: 5}))} className={`btn border btn-${review.rating === 5 ? 'success' : 'white'} btn-block mt-0`}><RiEmotionLaughLine size = {25}/></div>
                    </div>
                    {errors[1] && <div class = "invalid-feedback d-block">You forgot to give a rating</div>}
                    <h4 className = 'mt-5'>Add review</h4>
                    <textarea 
                        style = {{resize: 'none', minHeight: '20vh'}}  
                        placeholder = {student_content.review.placeholder}
                        className = {`form-control w-100 ${errors[0] && 'is-invalid'}`} 
                        value = {review.review} 
                        onChange = {e => {const value = e.target.value; setReview(prev => ({...prev, review: value}))}}
                    />
                    <div class = "invalid-feedback">Please enter a review of length at least 10 characters</div>
                    
                    <div class = 'row no-gutters'>
                        <div class = 'col'></div>
                        <div class = 'col-auto mr-2'>
                            <div class = 'btn  btn-outline-primary mt-4' onClick = {() => setShow(false)}>
                                Cancel
                            </div>
                        </div>
                        <div class = 'col-auto'>
                            <button class = 'btn  btn-primary mt-4' onClick = {(e) => submitReview(e)} disabled = {loading}>
                                {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                                Submit review
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Review
