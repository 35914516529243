import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import Select from 'react-select'
import {DateFormInput1} from '../../../../components/Forms'
import axios from '../../../../utilis/axios'
import customStyles from '../../../../utilis/customStyles'
import AsyncSelect from 'react-select/async';
import moment from 'moment'

const RequestSchedule = ({show, setShow, enrollments, setEnrollments}) => {

    const [loading, setLoading] = useState(false)
    const [enrollment, setEnrollment] = useState({student: '', instructor: '', course: '',  type: '', num_classes: '', price_per_class: '', total_paid: '', cordinator: '', pending_amount: ''});
    const [firstClass, setFirstClass] = useState({date: '', time: ''});
    const [coursesOptions, setCoursesOptions] = useState([])
    const [errors, setErrors] = useState(Array(6).fill(false));


    useEffect(() => {
        setErrors(Array(6).fill(false))
    }, [enrollment, firstClass])

    useEffect(() => {
        if(enrollment.instructor && enrollment.instructor.value && enrollment.instructor.value.length > 0){
            fetchCourses();
            setEnrollment(prev => ({...prev, course: ''}))
        }
        // eslint-disable-next-line
    }, [enrollment.instructor])


    const fetchInstructors = async (inputValue, callback) => {
        try{
            const res = await axios.get(`/api/admin/enrollment/instructors/${inputValue}`);
            callback(res.data.map(item => ({value: item._id, label: <NameWithAvatar email = {item.email} name = {item.firstName + ' ' + item.lastName} avatar = {item.avatar}/> })))
        } catch(err){
            console.log(err)
        }
    }

    const fetchStudents = async (inputValue, callback) => {
        try{
            const res = await axios.get(`/api/admin/enrollment/students/${inputValue}`);
            callback(res.data.map(item => ({value: item._id, label: <NameWithAvatar email = {item.email} name = {item.firstName + ' ' + item.lastName} avatar = {item.avatar}/> })))
        } catch(err){
            console.log(err)
        }
    }

    const fetchCourses = async () => {
        try{
            const res = await axios.get(`/api/admin/enrollment/courses/${enrollment.instructor.value}`);
            setCoursesOptions(res.data.map(item => ({value: item._id, label: item.instrument })))
        } catch(err){
            console.log(err)
        }
    }


    const validate = () => {
        const newErrors = Array(6).fill(false);
        if(!enrollment.instructor || enrollment.instructor === '' || !enrollment.instructor.value ) newErrors[0] = true;
        if(!enrollment.student || enrollment.student === '' || !enrollment.student.value ) newErrors[1] = true;
        if(!enrollment.course || enrollment.course === '' || !enrollment.course.value ) newErrors[2] = true;
        if(!enrollment.type || enrollment.type === '' || !enrollment.type.value ) newErrors[3] = true;
        if(enrollment.type && enrollment.type.value && enrollment.type.value === 'paid'){
            if(enrollment.num_classes === '' ) newErrors[4] = true;
            if(enrollment.price_per_class === '' ) newErrors[5] = true;
        }
        if(enrollment.type && enrollment.type.value && enrollment.type.value === 'demo'){
            if(firstClass.date === '' ) newErrors[4] = true;
            if(!firstClass.time || firstClass.time ==='' || !firstClass.time.value) newErrors[5] = true;
        }
        setErrors(newErrors)
        if(newErrors.includes(true)) return true
        else return false;
    }


    const createEnrollment = async (e) => {
        e.preventDefault();
        if(validate()) return;
        try{
            setLoading(true);
            const req_body = {
                instructor: enrollment.instructor.value,
                student: enrollment.student.value,
                course: enrollment.course.value,
                type: enrollment.type.value,
                cordinator: enrollment.cordinator
            }
            if(enrollment.type.value === 'paid'){
                req_body.num_classes = enrollment.num_classes
                req_body.classes_left = enrollment.num_classes
                req_body.price_per_class = enrollment.price_per_class
                req_body.pending_amount = enrollment.pending_amount;
                req_body.total_price = parseInt(enrollment.price_per_class) * parseInt(enrollment.num_classes)
                const res = await axios.post('/api/admin/enrollment/paid', req_body);

                const newEnrollments = [...enrollments];
                const index = newEnrollments.findIndex(item => item._id === res.data._id);
                if(index > -1){
                    newEnrollments[index] = res.data;
                } else{
                    newEnrollments.push(res.data);
                }
                setEnrollments(newEnrollments)
                setShow(false);

            } else{
                const date_time = moment(firstClass.date + "-" + firstClass.time.label.slice(0, 8), "DD/MM/YYYY-hh:mm A")
                const res = await axios.post('/api/admin/enrollment/demo', {enrollment: req_body, schedule: date_time})
                
                const newEnrollments = [...enrollments];
                const index = newEnrollments.findIndex(item => item._id === res.data._id);
                if(index > -1){
                    newEnrollments[index] = res.data;
                } else{
                    newEnrollments.push(res.data);
                }
                setEnrollments(newEnrollments)
                setShow(false)

            }
            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }




 
    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Create a Enrollment</div>
                </div>

                <form class = "mt-3 ">

                    <div class = "form-group mb-5">
                        <label>Select the mentor</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={[{value: '', label: 'Please type to search'}]}
                            styles = {customStyles.bigSelect}
                            loadOptions={fetchInstructors}
                            value = {enrollment.instructor}
                            onChange={e => {
                                if(e && e.value && e.value !== '') {
                                    setEnrollment(prev => ({...prev, instructor: e}))
                                } else setEnrollment(prev => ({...prev, instructor: null}))
                            }}
                            isClearable
                        />
                        {errors[0] && <div class = 'invalid-feedback d-block'>Please select a instructor</div>}
                    </div>
                    
                    <div class = "form-group mb-5">
                        <label>Select the student</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={[{value: '', label: 'Please type to search'}]}
                            styles = {customStyles.bigSelect}
                            loadOptions={fetchStudents}
                            value = {enrollment.student}
                            onChange={e => {
                                if(e && e.value && e.value !== '') {
                                    setEnrollment(prev => ({...prev, student: e}))
                                } else setEnrollment(prev => ({...prev, student: null}))
                            }}
                            isClearable
                        />
                        
                        {errors[1] && <div class = 'invalid-feedback d-block'>Please select a student</div>}
                    </div>
                   
                   
                    <div class = "form-group mb-5">
                        <label>Select the membership</label>
                        <Select
                            options={coursesOptions}
                            styles = {customStyles.bigSelect}
                            value = {enrollment.course}
                            onChange={e => {
                                if(e && e.value && e.value !== '') {
                                    setEnrollment(prev => ({...prev, course: e}))
                                } else setEnrollment(prev => ({...prev, course: null}))
                            }}
                        />
                        
                        {errors[2] && <div class = 'invalid-feedback d-block'>Please select a membership</div>}
                    </div>
                    
                    <div class = "form-group mb-5">
                        <label>Enrollment Type</label>
                       <Select 
                            options = {typeOptions} 
                            styles = {customStyles.bigSelect}
                            value = {enrollment.type}
                            onChange = {e => setEnrollment(prev => ({...prev, type: e}))} 
                        />
                        {errors[3] && <div class = 'invalid-feedback d-block'>Please select a type</div>}
                    </div>

                    {enrollment.type && enrollment.type.value === 'paid' && 
                        <PaidEnrollmetnConfig 
                            enrollment = {enrollment} 
                            setEnrollment = {setEnrollment}
                            errors = {errors}
                        />}
                    {enrollment.type && enrollment.type.value === 'demo' && 
                        <DemoEnrollmentConfig 
                            enrollment = {enrollment} 
                            setEnrollment = {setEnrollment}
                            firstClass = {firstClass}
                            setFirstClass = {setFirstClass}
                            errors = {errors}
                        />}
                  
                    <div class = 'form-group'>
                        <label>Cordinator</label>
                        <input 
                            class = {`form-control`}  
                            value = {enrollment.cordinator} 
                            onChange = {e => setEnrollment(prev => ({...prev, cordinator: e.target.value}))}
                        />
                    </div>
                    
                    <button class = "btn btn-primary btn-block mt-6" onClick = {(e) => createEnrollment(e)} disabled = {loading}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Create/Add Enrollment
                    </button>
                    
                </form>
                
            </div>
        </RightModal>
    )
}

export default RequestSchedule;





const PaidEnrollmetnConfig = ({enrollment, setEnrollment, errors, setErrors}) => {
    return (
        <div class = ''>
            <div class = 'form-group'>
                <label>Total Sessions</label>
                <input 
                    class = {`form-control ${errors[4] && 'is-invalid'}`} 
                    type = "number" 
                    value = {enrollment.num_classes} 
                    onChange = {e => setEnrollment(prev => ({...prev, num_classes: e.target.value}))}
                />
                <div class = 'invalid-feedback'>This field is mandatory</div>
            </div>
            <div class = 'form-group'>
                <label>Price Per Class</label>
                <input class = {`form-control ${errors[5] && 'is-invalid'}`} type = "number" value = {enrollment.price_per_class} onChange = {e => setEnrollment(prev => ({...prev, price_per_class: e.target.value}))}/>
                <div class = 'invalid-feedback'>This field is mandatory</div>
            </div>
            
            <div class = 'form-group'>
                <label>Pending Amount</label>
                <input class = {`form-control`} type = "number" value = {enrollment.pending_amount} onChange = {e => setEnrollment(prev => ({...prev, pending_amount: e.target.value}))}/>
            </div>
        </div>
    )
}




const DemoEnrollmentConfig = ({enrollment, setEnrollment, firstClass, setFirstClass, errors, setErrors}) => {


    return (
        <div>
            <DateFormInput1 
                placeholder = "Select a date"
                error = {errors[4]}
                setError = {(val) => console.log('')}
                errorMessage = {"Please enter a valid date"}
                label = "Date"
                setValue = {(val) => {
                    setFirstClass(prev => ({...prev, date: val}));
                }}
                value = {firstClass.date}
                minimumDate = {new Date()}
            />
            <div class = "form-group my-4">
                <label>Time Slot</label>
                <Select 
                    value = {firstClass.time}
                    options = {timeOptions}
                    onChange = {e => setFirstClass(prev => ({...prev, time: e}))}
                />
                {errors[5] && <div class = 'invalid-feedback d-block'>This field is mandatory</div>}
            </div>
        </div>
    )
}




const typeOptions = [
    {value: 'paid', label: 'Paid'},
    {value: 'demo', label: 'Demo'},
]


const time1 = (time) => time >= 10 ? time : "0"+time


const timeFormat = (time, isSecond) => {
    if(time < 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " AM"
    } else if(time === 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " PM"
    } else if(time > 12){
        return time1(time-12) + ":" + (isSecond ? "45":"00") + " PM"
    }
}

const timeOptions = Array(24).fill().map((item, index) => {
    return {value: index, label: timeFormat(index, false) + ' - ' + timeFormat(index, true)}
})




const NameWithAvatar = ({name, email, avatar}) => {
    return (
        <div class="row align-items-center no-gutters">
            <div class = "col-auto mr-3">
                <div className="avatar avatar-xs" >
                    {/* <img class="avatar-img rounded-circle" src={avatar} alt="..." /> */}
                </div>
            </div>
            <div class = "col">
                <div class="small mb-n1">{name}</div>
                <div class=" text-muted small">{email}</div>
            </div>
        </div>
    )
}