import React, {useContext} from 'react'
import { Store } from '../../../../store'
import AllLogs from './AllLogs'
import {Redirect} from 'react-router-dom'

const Logs = (props) => {

    const {state} = useContext(Store)

    return (
        <div>
            {state.user.data.isSuperAdmin ? <AllLogs /> : <Redirect to = "/admin" />}
        </div>
    )
}

export default Logs


