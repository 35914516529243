import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { SelectStyle, selectTheme } from "../../../../../components/Select";
import MyDropzoneImage from "../../../../../components/Dropzone/MyDropzoneImage";
import MyDropzone from "../../../../../components/Dropzone/MyDropzone";
import instructor_content from "../../../../../content/instructor_content.json";

const Basics = ({
  setTab,
  setCourse,
  course,
  headerImg,
  setHeaderImg,
  instruments,
  videos,
  setVideos,
  photos,
  setPhotos,
}) => {
  const [errors, setErrors] = useState(Array(6).fill(false));

  useEffect(() => {
    setErrors(Array(6).fill(false));
  }, [
    course.instrument,
    course.title,
    course.details,
    course.headerImg,
    course.photos,
    course.videos,
    photos,
    videos,
  ]);

  const validate = () => {
    const new_errors = Array(6).fill(false);
    if (course.instrument === "") new_errors[0] = true;
    if (course.title === "") new_errors[2] = true;
    if ((course.photos ? course.photos.length : 0) + photos.length > 5)
      new_errors[4] = true;
    if ((course.videos ? course.videos.length : 0) + videos.length > 1)
      new_errors[5] = true;
    setErrors(new_errors);
    if (new_errors.includes(true)) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return false;
    } else return true;
  };

  const moveNext = async () => {
    if (!validate()) return;
    setTab("pricing");
  };

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
          <h6 class="mb-4 text-uppercase text-muted">Step 1 of 2</h6>
          <h1 class="mb-3">{instructor_content.course.add.step1.title}</h1>
          <p class="mb-5 text-muted">
            {instructor_content.course.add.step1.description}
          </p>
        </div>
      </div>

      <div class="mb-6 mt-5">
        <Instrument
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
          instruments={instruments}
        />
        <CourseLevel
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseDetails
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseImage
          headerImg={headerImg}
          setHeaderImg={setHeaderImg}
          course={course}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseMapping course={course} setCourse={setCourse} />
        <Images
          photos={photos}
          setPhotos={setPhotos}
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <Videos
          videos={videos}
          setVideos={setVideos}
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
      </div>

      <div className="row align-items-center">
        <div className="col-auto">
          <Link
            to="/instructor/courses"
            class="btn btn-lg btn-white"
            type="reset"
          >
            Cancel
          </Link>
        </div>
        <div className="col text-center">
          <h6 class="text-uppercase text-muted mb-0">Step 1 of 2</h6>
        </div>
        <div className="col-auto">
          <div className="btn btn-lg btn-primary" onClick={() => moveNext()}>
            Continue
          </div>
        </div>
      </div>
    </div>
  );
};

export default Basics;

const Instrument = ({ course, setCourse, errors, errText, instruments }) => {
  return (
    <div class="">
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.instrument.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.instrument.description}
        </div>
        <div className="row">
          <div class="col-12 col-lg-5">
            <Select
              value={
                course.instrument
                  ? options.filter(
                      (item) => item.value === course.instrument
                    )[0]
                  : { value: null, label: "Please select" }
              }
              onChange={(e) => {
                setCourse((prev) => ({ ...prev, instrument: e.value }));
              }}
              options={
                instruments
                  ? options.filter((item) => !instruments.includes(item.value))
                  : [{ value: "", label: "Loading.." }]
              }
              styles={SelectStyle}
              theme={selectTheme}
            />
            {errors[0] && (
              <div class="invalid-feedback d-block">
                {instructor_content.course.basics.instrument.error}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CourseLevel = ({ course, setCourse, errors, setErrors }) => {
  return (
    <div class="">
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.subject_title.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.subject_title.description}
        </div>
        <input
          class={errors[2] ? "form-control is-invalid" : "form-control"}
          placeholder=""
          value={course.title}
          onChange={(e) => {
            setCourse((prev) => ({ ...prev, title: e.target.value }));
          }}
        />
        <div class="invalid-feedback">
          {instructor_content.course.basics.subject_title.error}
        </div>
      </div>
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.comfort_level.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.comfort_level.description}
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Beginner</div>
          <div className="col-5 col-lg-3">
            <select
              class="form-control form-control-sm"
              value={course.level.beginner}
              onChange={(e) => {
                console.log(e.target.value);
                setCourse((prev) => ({
                  ...prev,
                  level: { ...prev.level, beginner: e.target.value },
                }));
              }}
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Intermediate</div>
          <div className="col-5 col-lg-3">
            <select
              class="form-control form-control-sm"
              value={course.level.intermediate}
              onChange={(e) =>
                setCourse((prev) => ({
                  ...prev,
                  level: { ...prev.level, intermediate: e.target.value },
                }))
              }
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Advance</div>
          <div className="col-5 col-lg-3">
            <select
              class="form-control form-control-sm"
              value={course.level.advance}
              onChange={(e) =>
                setCourse((prev) => ({
                  ...prev,
                  level: { ...prev.level, advance: e.target.value },
                }))
              }
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

const CourseDetails = ({ course, setCourse, errors, setErrors }) => {
  return (
    <div class="">
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.details.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.details.description}
        </div>
        <textarea
          class={errors[1] ? "form-control is-invalid" : "form-control"}
          style={{ minHeight: "150px" }}
          value={course.details}
          onChange={(e) => {
            setCourse((prev) => ({ ...prev, details: e.target.value }));
          }}
          // placeholder={instructor_content.course.basics.details.placeholder}
        ></textarea>
        <div class="invalid-feedback ">
          {instructor_content.course.basics.details.error}
        </div>
      </div>
    </div>
  );
};

const CourseImage = ({
  headerImg,
  setHeaderImg,
  course,
  errors,
  setErrors,
}) => {
  return (
    <div class="">
      <div className="row mb-5 pb-2">
        <div className="col-5">
          <div className="form-group">
            <label class="bold mb-1">
              {instructor_content.course.basics.image.title}
            </label>
            <div class="text-muted small mb-3">
              {instructor_content.course.basics.image.description}
            </div>
          </div>
        </div>
        <div className="col-3">
          <div style={{ width: "200px" }}>
            <MyDropzoneImage
              file={headerImg}
              onFileChange={(file) => setHeaderImg(file)}
              height="200px"
              link={course.headerImg}
            />
          </div>
        </div>
        {errors[3] && (
          <div class="invalid-feedback d-block">
            {instructor_content.course.basics.image.error}
          </div>
        )}
      </div>
    </div>
  );
};

const CourseMapping = ({ course, setCourse }) => {
  return (
    <div>
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.speciality.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.speciality.description}
        </div>
        <input
          class="form-control"
          value={course.speciality}
          onChange={(e) =>
            setCourse((prev) => ({ ...prev, speciality: e.target.value }))
          }
          placeholder={instructor_content.course.basics.speciality.placeholder}
        />
      </div>
    </div>
  );
};

const Images = ({
  photos,
  setPhotos,
  course,
  setCourse,
  errors,
  setErrors,
}) => {
  const deleteImg = (index) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
  };

  const deleteSavedImage = (index) => {
    const newPhotos = [...course.photos];
    newPhotos.splice(index, 1);
    setCourse((prev) => ({ ...prev, photos: newPhotos }));
  };

  return (
    <div className="form-group mb-5 mt-5 pb-2">
      <label class="bold mb-1">
        {instructor_content.course.basics.photos.title}
      </label>
      <div class="text-muted small mb-3">
        {instructor_content.course.basics.photos.description}
      </div>
      <MyDropzone
        files={photos}
        onFileChange={(data) => setPhotos(data)}
        height="100px"
        isMulti={true}
        type="image/jpeg, image/png"
        limit={5}
      />
      <div className="row no-gutters mt-4">
        {course.photos &&
          course.photos.length > 0 &&
          course.photos.map((item, index) => (
            <div className="col-auto pr-3">
              <div class="d-flex align-items-start">
                <img
                  alt=""
                  class="rounded"
                  src={item.url}
                  style={{ height: "50px", width: "50px", objectFit: "cover" }}
                />
                <div
                  class="close1 mt-n3"
                  onClick={() => deleteSavedImage(index)}
                >
                  &times;
                </div>
              </div>
            </div>
          ))}
        {photos.map((item, index) => (
          <div className="col-auto pr-2">
            <div class="d-flex align-items-start">
              <img
                alt=""
                class="rounded"
                src={URL.createObjectURL(item)}
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
              />
              <div class="close1 mt-n3" onClick={() => deleteImg(index)}>
                &times;
              </div>
            </div>
          </div>
        ))}
      </div>
      {errors[4] && (
        <div class="invalid-feedback d-block mt-4">
          You cant add more than 5 photos
        </div>
      )}
    </div>
  );
};

const Videos = ({
  videos,
  setVideos,
  course,
  setCourse,
  errors,
  setErrors,
}) => {
  const deleteVideos = (index) => {
    const newVideos = [...videos];
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };

  const deleteSavedVideos = (index) => {
    const newVideos = [...course.videos];
    newVideos.splice(index, 1);
    setCourse((prev) => ({ ...prev, videos: newVideos }));
  };

  return (
    <div className="form-group mb-5 pb-2">
      <label class="bold mb-1">
        {instructor_content.course.basics.videos.title}
      </label>
      <div class="text-muted small mb-3">
        {instructor_content.course.basics.videos.description}
      </div>
      <MyDropzone
        files={videos}
        onFileChange={(data) => setVideos(data)}
        height="100px"
        isMulti={true}
        type=".mp4"
      />
      <div className="row no-gutters mt-4">
        {course.videos &&
          course.videos.length > 0 &&
          course.videos.map((item, index) => (
            <div className="col-auto pr-2">
              <div class="d-flex align-items-start">
                <video
                  class="rounded"
                  style={{ height: "50px", width: "50px", objectFit: "cover" }}
                >
                  <source src={item.url} />
                </video>
                <div
                  class="close1 mt-n3"
                  onClick={() => deleteSavedVideos(index)}
                >
                  &times;
                </div>
              </div>
            </div>
          ))}
        {videos.map((item, index) => (
          <div className="col-auto pr-2">
            <div class="d-flex align-items-start">
              <video
                class="rounded"
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
              >
                <source src={URL.createObjectURL(item)} />
              </video>
              <div class="close1 mt-n3" onClick={() => deleteVideos(index)}>
                &times;
              </div>
            </div>
          </div>
        ))}
      </div>
      {errors[5] && (
        <div class="invalid-feedback d-block mt-4">
          You cant add more than 1 video
        </div>
      )}
    </div>
  );
};

const options = [
  { value: "guitar", label: "Guitar" },
  { value: "piano", label: "Piano" },
  { value: "drums", label: "Drums" },
  { value: "keyboard", label: "Keyboard" },
  { value: "violin", label: "Violin" },
  { value: "ukulele", label: "Ukulele" },
  { value: "cajon", label: "Cajon" },
  { value: "harmonium", label: "Harmonium" },
  { value: "flute", label: "Flute" },
  { value: "tabla", label: "Tabla" },
  { value: "veena", label: "Veena" },
  { value: "trumpet", label: "Trumpet" },
  { value: "mridangam", label: "Mridangam" },
  { value: "sitar", label: "Sitar" },
  { value: "singing", label: "Singing" },
  { value: "songWriting", label: "Song Writing" },
  { value: "musicComposition", label: "Music Production" },
  { value: "musicTheory", label: "Music Theory" },
  { value: "rap", label: "Rap" },
  { value: "musicTherapy", label: "Music Therapy" },
  { value: "dJing", label: "DJing" },
  { value: "dhol", label: "Dhol" },
  { value: "harmonica", label: "Harmonica" },
  { value: "mandolin", label: "Mandolin" },
  { value: "doubleBass", label: "Double Bass" },
  { value: "cello", label: "Cello" },
];
