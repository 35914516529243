import React from 'react'
import content from '../../../content/landingPage.json'



  
export const HowItWorks = () => {
    return (
      <section>
      <div class="container mt-6 pt-5 mb-6 pb-4 rounded py-6" id = "value_props" style = {{backgroundColor: 'rgba(254, 201, 60, 0)'}}>
        <div class="row justify-content-center text-center mb-3">
          <div class="col-xl-8 col-lg-9">
              <p class="mb-2" >{content.home.learningProcess.title}</p>
              <h3 class="display-4 mb-2 px-lg-5 px-0"> {content.home.learningProcess.description}</h3>
          </div>
        </div>
      
        <div className="row mx-0 text-center mt-4">
            
           {content.home.learningProcess.steps.map((item, index) =>  <div className="col-12 col-md-4 mb-4 mb-md-0  cs-step pt-2 px-3 tns-item tns-slide-active" id="tns1-item0">
              <div className="cs-step-body">
                <img src =  {item.icon} alt ="" class = 'img-fluid mb-3 ' style = {{height: '170px'}}/>
                <h3 className="h2 mb-2">{item.title}</h3>
                <p className="mb-0 text-dark">{item.description}</p>
              </div>
            </div>)}
          </div>
          </div>
    </section>
    )
}



export const ValueProps1 = () => {


  return (
      <section>
    <div class="container mt-6 pt-5 mb-6 pb-6 " id = "value_props">
      <div class="row justify-content-center text-center mb-3">
        <div class="col-xl-8 col-lg-9">
            <p class="mb-2" >{content.home.valueProps.title}</p>
            <h3 class="display-4 mb-2 px-lg-5 px-0"  > {content.home.valueProps.description}</h3>
        </div>
      </div>
      <div class="row px-4 px-lg-0 mt-5 text-center">
        {content.home.valueProps.props.map(item => (<div class="col-md-6 col-lg-3 d-flex px-lg-4">
            <div className="cs-step-body">
              <img src =  {item.img} alt ="" class = 'img-fluid mb-3 rounded my-5 ' style = {{height: '120px', objectFit: 'cover'}}/>
              <h3 className="h2 mb-2">{item.title}</h3>
              <p className="mb-0 text-dark ">{item.description}</p>
            </div>
        </div>))}
      </div>
    </div>
  </section>
  )
}