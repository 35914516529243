import React, {useState} from 'react'
import ReactPlayer from 'react-player'
import { Modal } from 'react-bootstrap'


const Player = ({show, setShow, data}) => {

    const [url, setUrl] = useState(data.length > 0 ? data[0]._id : '')



    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered dialogClassName = 'model-120w'> 
                <div className = 'modal-content' >
                    <div className = 'modal-body '>
                        <div class = 'close pointer' onClick = {() => setShow(false)}>&times;</div>
                        <ReactPlayer 
                            config={{ file: { attributes: { disablepictureinpicture: 'true', controlsList: 'nodownload' }}}} 
                            onContextMenu={e => e.preventDefault()} 
                            url={data.filter(item => item._id === url).length > 0 ? data.filter(item => item._id === url)[0].link : ''} 
                            controls = {true} 
                            width = {'100%'} 
                            height = {'100%'} 
                            style = {{width: '100%', height: '100%'}}
                        />
                        
                       {data.length > 1 && <div class = 'text-right'>
                            <div class = 'btn-group btn-group-sm '>
                                {data.map((item, index) => (
                                    <div 
                                        class = {`btn btm-sm border btn-${url === data[index]._id ? '' : 'outline-'}primary`} 
                                        onClick = {() => setUrl(data[index]._id)}
                                    >
                                        {ordinal_suffix_of(index+1)} video
                                    </div>))
                                }
                            </div>
                        </div>}
                    </div>
                </div>
            </Modal>
    )
}

export default Player


function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}