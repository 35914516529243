import React, { useEffect } from "react";
import axios from "../../../utilis/axios";
import BeatSpinner from '../../../components/Spinners/BeatSpinner'


const ConfirmAccount = (props) => {

  const id = props.match.params.id;

  useEffect(() => {
    confirmAccount()
    //eslint-disable-next-line
  }, [])


  const confirmAccount = async () => {
    try {
      const res = await axios.post(`/api/auth/confirm-account/${id}`);
      if (res.status === 200) props.history.push('/login')
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <BeatSpinner />
  );
};

export default ConfirmAccount;
