import React, {useState, useEffect, useContext} from 'react'
import {  AiOutlineUser } from 'react-icons/ai'
import Nav from '../Nav'
import InputRange from 'react-input-range';
import {HiThumbUp} from 'react-icons/hi'
import {Link, useHistory} from 'react-router-dom'
import AnimateHeight from 'react-animate-height';
import { StickyContainer, Sticky } from 'react-sticky';
import InfoModal from './Modal'
import 'react-input-range/lib/css/index.css'
import { Store } from '../../../store';
import axios from '../../../utilis/axios'
import { saveCourses, saveInstrument } from '../../../store/actions/landing';
import Footer from '../Footer'
import ReactPlayer from 'react-player'
import { Modal } from 'react-bootstrap'
import {Stars} from '../../../pages/LandingPage/Lesson'
import { increasePriceByPercentage } from '../../../utilis/helpers';

const Instructors = (props) => {

    const {state, dispatch} = useContext(Store)
    const [instrument, setInstrument] = useState(state.landing.instrument);
    const [viewFilters, setViewFilters] = useState(false);
    const [instructors, setInstructors] = useState([])
    const [show, setShow] = useState(false)
    const [filters, setFilters] = useState(initFilters)

    useEffect(() => {
        if(props.location.query && props.location.query.length > 0) saveInstrument(props.location.query, dispatch)
        // eslint-disable-next-line
    }, [props.location.query])

    useEffect(() => {
        if(state.landing.instrument === ''){
            setShow(true)
        } else setShow(false)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(state.landing.instrument !== '') {
            setInstrument(state.landing.instrument)
        }
        // eslint-disable-next-line
    }, [state.landing.instrument])


    useEffect(() => {
        if(state.landing.courses && state.landing.courses.length > 0){
            formatData(state.landing.courses)
        } else fetchCourses();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(state.landing.courses && state.landing.courses.length > 0){
            formatData(state.landing.courses)
        } 
        // eslint-disable-next-line
    }, [state.landing.courses])

    const fetchCourses = async () => {
        try{
            const res = await axios.get('/api/student/instructors');
            saveCourses(res.data, dispatch)
        } catch(err){
            console.log(err);
        }
    }

    const formatData = (data) => {
        const allInstructors = []
        for(let i=0; i < data.length; i++){
            const index = allInstructors.findIndex(item => item._id === data[i].instructor._id)
            if(index > -1){
                if(!allInstructors[index].minPrice || allInstructors[index].minPrice < data[i].price_per_class){
                    allInstructors[index].minPrice = data[i].price_per_class
                }
                const maxDiscount = Math.max(...data[i].discount.map(item => item.discount));
                const min_price_per_class = data[i].price_per_class * (1-(maxDiscount/100))


                allInstructors[index].instruments.push({
                    lesson_id: data[i]._id,
                    instrument: data[i].instrument,
                    level: data[i].level,
                    price_per_class: data[i].price_per_class,
                    video: (data[i].videos && data[i].videos.length > 0) ? data[i].videos[0].url : "",
                    // min_price_per_class: min_price_per_class
                    min_price_per_class: increasePriceByPercentage(min_price_per_class)
                })

            } else{
                const maxDiscount = Math.max(...data[i].discount.map(item => item.discount));
                const min_price_per_class = data[i].price_per_class * (1-(maxDiscount/100))
                allInstructors.push({
                    _id: data[i].instructor._id, 
                    profile: data[i].instructor_profile, 
                    instructor: data[i].instructor,
                    minPrice: data[i].price_per_class,
                    isStarTeacher: data[i].instructor_profile.isStarTeacher,
                    isBulkDiscount: data[i].instructor_profile.isBulkDiscount,
                    rating: data[i].reviews.length > 0 ? (data[i].reviews.reduce((a, b) => a + parseFloat(b.rating), 0))/data[i].reviews.length : 0,  
                    numReviews: data[i].reviews.length,
                    isBackgroundChecked: data[i].instructor_profile.isBackgroundChecked,
                    isRecordingAllowed: data[i].instructor_profile.isRecordingAllowed,
                    instruments: [{
                        lesson_id: data[i]._id,
                        instrument: data[i].instrument.toLowerCase(),
                        level: data[i].level,
                        price_per_class: data[i].price_per_class,
                        // min_price_per_class: min_price_per_class,
                        min_price_per_class: increasePriceByPercentage(min_price_per_class),
                        video: (data[i].videos && data[i].videos.length > 0) ? data[i].videos[0].url : ""
                    }],
                    data: data[i],
                })
            }
        }
        setInstructors(allInstructors.sort((a, b) => b.rating - a.rating))
    }


    return (
        <div>
            <Nav />
            <InfoModal show = {show} setShow = {setShow} instrument = {instrument} setInstrument = {setInstrument} {...props}/>
            {instrument !== '' ? <div className = 'container px-4 px-xl-5 px-lg-5'>
                <StickyContainer>
                    <div className = 'd-none d-lg-block'>
                        <Title setShow = {setShow} instrument = {instrument}  setInstrument = {setInstrument}/>
                    </div>
                    <div className = 'd-block d-lg-none'>
                        <div className = 'mb-4  rounded '> 
                            <Sticky>
                                {({ style }) => (
                                    <div className = 'mx-n4' style={{...style, width: '100vw', backgroundColor: 'white', zIndex: '10000'}} >
                                        <TitleSm setViewFilters = {setViewFilters} instrument = {instrument} setShow = {setShow} setInstrument = {setInstrument}/> 
                                    </div>
                                )}
                            </Sticky>
                        </div>
                    </div>
                    <StickyContainer>
                    <div className=  'row '>
                        <div className = 'col-lg-4 col-xl-3 d-none d-lg-block'>
                        <Sticky>
                            {({style, isSticky}) => 
                                <div className = '' style = {{...style, paddingTop: isSticky ? '15px' : '0px'}}>
                                    <div className = 'card rounded shadow-sm'>
                                        <Filters filters = {filters} setFilters = {setFilters}/>
                                    </div>
                                </div>
                            }
                        </Sticky>
                        </div>
                        <div className = 'col-12 col-lg-8 col-xl-9 '>
                        <AnimateHeight height = {viewFilters ? 'auto': 0} duration = {300}>
                            <div className = 'card shadow-sm mx-n4 mb-5'>
                                <Filters setViewFilters = {setViewFilters} filters = {filters} setFilters = {setFilters}/>
                            </div> 
                        </AnimateHeight>
                        
                        <InstructorList instructors = {instructors} instrument = {instrument} filters = {filters} />
                        </div>
                    </div>
                    </StickyContainer>
                </StickyContainer>
            </div> : ''}
            {instrument && <Footer/>}
        </div>  
    )
}

export default Instructors


const Title = ({instrument, setShow, setInstrument}) => {
    return (
        <div className = 'mt-4 pt-4 mb-4'>
            <div className = 'row align-items-center'>
                <div className = 'col'>
                    <div className = 'h1 mb-0'>Learn {instrument}</div>
                    <div className=  'btn btn-sm btn-primary mt-2' onClick = {() => {setShow(true)}}>Change instrument</div>
                </div>
            </div>
        </div>
    )
}

const TitleSm = ({setViewFilters, instrument, setShow}) => {
    return (
        <>
        <div className = 'd-flex border align-items-center justify-content-between py-3 bg-white px-4'>
            <div className = 'h3 mb-0'>Learn {instrument}</div>
            <div className=  'btn btn-sm btn-primary ' onClick = {() => {setShow(true)}}>Change instrument</div>
        </div>
        <div className = 'row border text-center py-2 bg-white no-gutters'>
            <div 
                className = 'col border-right font-weight-bold d-flex align-items-center justify-content-center pointer' 
                onClick = {() => {window.scrollTo({ top: 100, behavior: 'smooth' }); setViewFilters(prev => !prev)}}
            >
                Filters
            </div>
        </div>
        </>
    )
}





const Filters = ({filters, setFilters, setViewFilters}) => {

    const editFilters = (key, value) => {
        const newFilters = {...filters}
        newFilters[key] = value
        setFilters(newFilters)
    }

    const editFilters2 = (value) => {
        const newFilters = {...filters}
        newFilters.level = [value]
        setFilters(newFilters)
    }



    return (
        
            <div className = 'card-body'>
                <div className = 'row border-bottom '>
                    <div className = 'col h3 mb-0 pb-3'>Filters</div>
                    <div className = 'col-auto small mb-0 pb-3 btn-link pointer' onClick = {() => setFilters(initFilters)}>Clear filters</div>
                </div>
                
                <div className = 'my-4 pb-2'>
                    <div className = 'h8 mb-1'>Level</div>
                    <div className = 'd-flex align-items-center justify-content-between'>
                        <div className = 'small'>Advanced</div>
                        <input className = '' type = 'radio' checked = {filters.level.includes('advance')} onClick = {() => editFilters2('advance')}/>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between'>
                        <div className = 'small'>Intermediate</div>
                        <input className = '' type = 'radio' checked = {filters.level.includes('intermediate')} onClick = {() => editFilters2('intermediate')}/>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between'>
                        <div className = 'small'>Beginner</div>
                        <input className = '' type = 'radio' checked = {filters.level.includes('beginner')} onClick = {() => editFilters2('beginner')}/>
                    </div>
                </div>

                <div className = 'my-4 pb-2'>
                    <div className = 'h8 mb-1'>Speciality</div>
                    <div className = 'd-flex align-items-center justify-content-between'>
                        <div className = 'small'><AiOutlineUser className = 'mr-2'/>DhunGuru Verified</div>
                        <div className = ''>
                            <input className = '' type = 'checkbox' checked = {filters.backgroundCheck} onClick = {() => editFilters('backgroundCheck', !filters.backgroundCheck)}/>
                        </div>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between'>
                        <div className = 'small'><AiOutlineUser className = 'mr-2'/>Top Guru</div>
                        <input className = '' type = 'checkbox' checked = {filters.starTeacher} onClick = {() => editFilters('starTeacher', !filters.starTeacher)}/>
                    </div>
                    <div className = 'd-flex align-items-center justify-content-between'>
                        <div className = 'small'><AiOutlineUser className = 'mr-2'/>Bulk discount available</div>
                        <input className = '' type = 'checkbox' checked = {filters.isBulkDiscount} onClick = {() => editFilters('isBulkDiscount', !filters.isBulkDiscount)}/>
                    </div>
                </div>
        

                <div className = 'mt-4 pb-2'>
                <div className = 'h8 mb-1 mb-3'>Price Range</div>
                    <InputRange
                        maxValue={5000}
                        minValue={100}
                        step={10}
                        value={filters.price}
                        onChange={value => setFilters(prev => ({...prev, price: value}))}
                        formatLabel={value => ''}
                    />
                     <div className = 'd-flex align-items-center justify-content-between'>
                         <span className = 'small font-weight-bold'>Rs {filters.price.min}</span>
                         <span className = 'small font-weight-bold'>Rs {filters.price.max}</span>
                     </div>
                </div>
                
                <div className = 'mt-4 pb-2'>
                <div className = 'h8 mb-1'>Preferred gender</div>
                    <select className = 'form-control py-1 h-35' value = {filters.gender} onChange = {e => setFilters(prev => ({...prev, gender: e.target.value}))}>
                        <option value = "all">All</option>
                        <option value = "male">Male</option>
                        <option value = "female">Female</option>
                    </select>
                </div>

                <div className = 'mt-4 '>
                <div className = 'h8 mb-1'>Your age</div>
                    <select className = 'form-control py-1 h-35' value = {filters.age} onChange = {e => setFilters(prev => ({...prev, age: e.target.value}))}>
                        <option value = "all">Above 15 </option>
                        <option value = "student">Below 15</option>
                    </select>
                </div>

                <div className = 'mt-4 btn btn-block btn-primary d-lg-none ' onClick = {() => setViewFilters(false)}>
                    Apply Filters
                </div>

            </div>
    )
}







const InstructorList = ({instructors, instrument, filters}) => {
    
    const [filteredData, setFilteredData] = useState(instructors);
    const [totalPages, setTotalPages] = useState(0);
    const [searchInput, setSearchInput] = useState('')


    useEffect(() => {
        if(instrument !== ''){
            instrument = instrument.replace(/\s/g,'')
            let temp = instructors
                .filter( item => item.instruments.filter(item => item.instrument.toLowerCase() === instrument.toLowerCase()).length > 0 )
                .map( item => ({
                    ...item, 
                    lesson_id: item.instruments.filter(item => item.instrument.toLowerCase() === instrument.toLowerCase())[0].lesson_id, 
                    min_price_per_class: item.instruments.filter(item => item.instrument.toLowerCase() === instrument.toLowerCase())[0].min_price_per_class, 
                    level: item.instruments.filter(item => item.instrument.toLowerCase() === instrument.toLowerCase())[0].level
                }))
            
            temp = (temp
                .filter(item => {
                    if(searchInput.length > 0){
                        return (item.instructor.firstName + item.instructor.lastName).toLowerCase().includes(searchInput.split(' ').join('').toLowerCase())
                    } else return true
                })
                .filter(item => {
                    if(filters.age === 'student'){
                        return item.profile.teachesChildren
                    } else return true
                })
                .filter(item => {
                    if(filters.gender === 'all') return true;
                    return (filters.gender.toLowerCase() === item.profile.gender.toLowerCase())
                })
                .filter(item => {
                    if(filters.backgroundCheck) return item.profile.isBackgroundChecked
                    else return true
                })
                .filter(item => {
                    if(filters.starTeacher) return item.profile.isStarTeacher
                    else return true
                })
                .filter(item => {
                    if(filters.isBulkDiscount) return item.profile.isBulkDiscount
                    else return true
                })
                .filter(item => {
                    if(item.minPrice <= filters.price.max && item.minPrice >= filters.price.min) return true;
                    else return false
                })
                .filter(item => {
                    const level = {
                        advance: item.level.advance === 'Not Comfortable' ? false : true,
                        intermediate: item.level.intermediate === 'Not Comfortable' ? false : true,
                        beginner: item.level.beginner === 'Not Comfortable' ? false : true,
                    }
                    if(filters.level.includes('advance')){
                        if(level.advance) return true;
                        return false;
                    }
                    if(filters.level.includes('intermediate')){
                        if(level.intermediate) return true;
                        return false;
                    }
                    if(filters.level.includes('beginner')){
                        if(level.beginner) return true;
                        return false;
                    }
                    else return true
                })
            )
            setFilteredData(temp)
            setTotalPages(temp.length%25 === 0 ? parseInt(temp.length/25) : parseInt(temp.length/25)+1)
        }
    }, [instrument, instructors, filters, searchInput])




    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        setPageNum(1)
    }, [instrument])
    
    return (
        <div className = ''>
            <Search 
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
            />
            {filteredData.length > 25 ? filteredData.slice(pageNum*25-25, pageNum*25).map(item => <InstructorCard data = {item} width = {1000} instrument = {instrument}/>) : filteredData.map(item => <InstructorCard data = {item} width = {1000}/>)}
            <div className = 'card bg-success-soft border py-3  mx-4 mx-lg-0 '>
                <div className = 'card-body text-center px-lg-6'>
                        <h4 class = 'mb-2 h2'>Too many options for your perfect Guru?</h4>
                        <div class = 'p mb-3'>Our student counsellors are readily available at all times to help you in finding the perfect Guru as per your requirements</div>
                        <Link to = '/contact' class = 'btn btn-outline-primary'>Get in Touch</Link>
                </div>
            </div>
            <div className = 'row mt-3 mb-5 align-items-center'>
                <div className = 'col-auto'>
                    <button 
                        className = {`btn btn-outline-secondary ${pageNum === 1 ? 'disabled-input' : ''}`} 
                        onClick = {(e) => {
                            e.preventDefault()
                            if(pageNum === 1) return;
                            setPageNum(prev => prev-1); 
                            window.scrollTo({ top: 0, behavior: 'smooth' }) 
                        }}
                        disabled = {pageNum === 1}
                    >
                        Prev
                    </button>
                </div>
                <div className = 'col text-center font-weight-bold'>
                    {pageNum}/{totalPages}
                </div>
                <div className = 'col-auto'>
                    <button 
                        className = {`btn btn-outline-secondary ${(pageNum === totalPages || totalPages === 0) ? 'disabled-input' : ''}`} 
                        onClick = {(e) => {
                            e.preventDefault()
                            if(pageNum === totalPages) return;
                            setPageNum(prev => prev+1); 
                            window.scrollTo({ top: 0, behavior: 'smooth' }) 
                        }}
                        disabled = {pageNum === totalPages || totalPages === 0}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    )
}



const Search = ({searchInput, setSearchInput}) => {
    return (
        <div className = 'card bg-white'>
            <div className = 'card-body py-2'>
                <form>
                    <div className="input-group input-group-flush">
                        <div className="input-group-prepend">
                            <span className="input-group-text">
                                <i className="fas fa-search"></i>
                            </span>
                        </div>
                        <input 
                            className="form-control search" 
                            type="search" 
                            placeholder="Search your guru" 
                            value = {searchInput} 
                            onChange = {(e) => setSearchInput(e.target.value)}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
    }
    


const InstructorCard = ({data, width}) => {
    
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const url = data.instruments.filter(item => item.lesson_id === data.lesson_id).length > 0 ? data.instruments.filter(item => item.lesson_id === data.lesson_id)[0].video : undefined;

    
    return (
        <>
           <div class='card rounded border shadow mb-4 mx-4 mx-lg-0 text-center text-lg-left' >
                <div className="card-body">
                    <div className = 'row justify-content-between align-items-center'>
                        <div className = 'col-12 col-md-auto mb-4 mb-lg-0 text-center'>
                            <div class = 'avatar avatar-xxl' style = {{height: '13rem', width: '13rem'}}>
                                <img alt="" className = "avatar-img rounded-circle" src = {data.instructor.avatar}/>
                            </div>
                        </div>
                        <div className = "col-12 col-md d-flex flex-column">
                            <div className = 'row align-items-start mb-2'>
                                <div className = 'col'>
                                    <span class="h3 card-title mb-0 pointer h2" onClick = {() => history.push(`/lesson/${data.lesson_id}`)}>{data.instructor.firstName + ' ' + data.instructor.lastName}</span>
                                </div>
                                <div className = 'col-auto d-none d-lg-block'>
                                    {data.data.reviews && data.data.reviews.length > 0 && <Rating data = {data}/>}
                                </div>
                                <div class = "col-auto">
                                    {url && <span class = "btn btn-outline-primary btn-sm ml-2" onClick = {() => setIsOpen(true)}>Play video</span>}
                                </div>
                            </div>
                            <div className="row ml-0 mb-2">
                                {data.isBackgroundChecked && <div className="col-auto rounded border px-3 bg-info-soft  small py-0 mr-2">DhunGuru Verified</div>}
                                {data.isStarTeacher && <div className="col-auto rounded border px-3 bg-info-soft  small py-0 mr-2">Top Guru</div>}
                                {data.isBulkDiscount && <div className="col-auto rounded border px-3 bg-info-soft  small py-0 mr-2">Bulk Discount Available</div>}
                            </div>
                            <Bio bio = {data.profile.bio ? data.profile.bio : ''} />
                            <div className = 'd-flex align-items-center justify-content-between mt-4 flex-column flex-lg-row'>
                                <div className = ''>
                                    {/* <div className = 'text-dark' >Price starts from <strong>Rs {Math.ceil(data.min_price_per_class + (0.18 * data.min_price_per_class))}/session</strong></div> */}
                                    <div className = 'text-dark' >Price starts from <strong>Rs {(Math.ceil(data.min_price_per_class + (0.18 * data.min_price_per_class)))}/session</strong></div>
                                </div>
                                <div>
                                    
                                    <Link className = 'btn btn-primary' to = {`/lesson/${data.lesson_id}`}>
                                        Check out lesson plans
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show = {isOpen} onHide = {() => setIsOpen(false)} centered  contentClassName ='bg-transparent'>
                <ReactPlayer 
                    config={{ file: { attributes: { disablepictureinpicture: 'true', controlsList: 'nodownload' }}}} 
                    onContextMenu={e => e.preventDefault()} 
                    url={url} 
                    controls = {true} 
                    width = {'100%'} 
                    height = {'100%'} 
                    style = {{width: '100%', height: '100%'}}
                />
            </Modal>
            </>
    )
}

const Bio = ({bio}) => {

    const [more, setMore] = useState(false)

    return (
        <p class = "mb-0">
            {more ? bio : (bio.length > 200 ? bio.slice(0, 200) + '...' : bio)}
            {bio.length > 200 && <span class = 'btn-link pointer' onClick = {() => setMore(prev => !prev)}>{more ? 'Read less' : 'Read more'}</span>}
        </p>
    )
}


const Rating = ({data}) => {
    return <div className = 'd-inline-flex rounded text-success justify-content-center align-items-center '>
        <Stars numStars = {parseFloat(data.rating).toFixed(1)}/>
        {/* <div className = 'small pr-1 '>{parseFloat(data.rating).toFixed(1)}</div>  */}
        {/* <HiThumbUp className = ' mn-0' style = {{fontSize: '12px'}}/> */}
    </div>
}




const initFilters = {
    backgroundCheck: false,
    starTeacher: false,
    isBulkDiscount: false,
    price: {min: 100, max: 5000},
    gender: 'all',
    age: 'all',
    level: []
}



