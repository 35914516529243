import React from 'react';
import Nav from '../Nav'
import Footer from '../Footer'
import legalDoc from './legalDoc'
import AnchorLink from 'react-anchor-link-smooth-scroll';
 

const Legal = () => {
    return (
        <div>
            <Nav />
            <div className = ''>
                <Hero/>
                <LegalList />
                <Footer />
            </div>
        </div>
    )
}
export default Legal




const Hero = () => {
    return (
        <div class="pt-6 pb-5">
            <div class="container text-center">
                <div class="row justify-content-center mb-5">
                    <div class="col-lg-8">
                        <h1 class="mt-5 display-2 mb-2"  >Legal</h1>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}



const LegalList = () => {
    return (
        <section class = "mt-5 mb-5">
            <div class = "container">
                <div class = "row">
                    <div class = "col-12 col-lg-3 mb-6 mb-lg-0">
                        <h5 class = "mt-0 h1 ">Categories</h5>
                        <div class = "mb-3">
                            <AnchorLink href = "#privacy" className = "font-weight-light  pointer mb-0 d-block h3">Privacy Policy</AnchorLink><br/>
                            <AnchorLink href = "#facebook" className = "font-weight-light  pointer mb-0 d-block h3">Facebook Data Deletion Policy</AnchorLink><br/>
                            <AnchorLink href = "#refund" className = "font-weight-light  pointer mb-0 d-block h3">Refund Policy</AnchorLink><br/>
                        </div>
                    </div>
                    <div class = "col-12 col-lg-9 pl-lg-40">
                        <Terms />
                    </div>
                </div>
            </div>
        </section>
    )
}


const Terms = () => {
    return (
        <div class="">
            {legalDoc}
        </div>
    )
}


