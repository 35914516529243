import React, {useState, useEffect} from 'react'
import Nav from '../Nav'
import Footer from '../Footer'
import content from '../../../content/landingPage.json'
import submitResponse from '../../../utilis/submitResponse'
import {validateEmail, validatePhone} from '../../../utilis/validation'
import cogoToast from 'cogo-toast'

const Contact = () => {
    return (
        <div>
            <Nav />
            <div className = ''>
                <Body />
                <Footer />
            </div>
        </div>
    )
}
export default Contact



const Body = () => {

    const [state, setState] = useState({name: '', email: '', phone: '', subject: '', message: '', contactType: ''})
    const [errors, setErrors] = useState(Array(6).fill(false));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors(Array(6).fill(false));
    }, [state])


    const validate = () => {

        const new_errors = Array(6).fill(false);
        if(state.name === '') new_errors[0] = true;
        if(!validateEmail(state.email)) new_errors[1] = true;
        if(!validatePhone(state.phone)) new_errors[2] = true;
        if(state.subject === '') new_errors[3] = true;
        if(state.message === '') new_errors[4] = true;
        if(state.contactType === '') new_errors[5] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) return true;
        else return false;
    }

    const submitData = async (e) => {
        e.preventDefault();
        if(validate()) return;
        try{

            setLoading(true);
            await submitResponse({...state, type: 'CONTACT'})
            setState({name: '', email: '', phone: '', subject: '', message: '', contactType: ''})
            cogoToast.success("We will reach out to you shortly")
            setLoading(false);
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

    return (
        <div class="container py-6 px-4 my-5">
            <div class="row justify-content-between">
                <div class="col-lg-5 col-12">
                    <h2 class = "display-3">Contact Us</h2>
                    
                    <p>Dhunguru Music India Pvt Ltd <br/>
                    {content.contact.address1} <br />
                    {content.contact.address2}<br />
                    {content.contact.address3}   <br/>
                    <a href = "mailto:support@dhunguru.com">support@dhunguru.com</a>
                    </p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.2549895659145!2d77.10645161506474!3d28.411561800798278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d21f3f7985915%3A0x7842fc32eac01c23!2sMAGNUM%20TOWERS!5e0!3m2!1sen!2sin!4v1628450937056!5m2!1sen!2sin" title = "aa" style={{border:"0", width: '100%', height: '50%'}} allowfullscreen="" loading="lazy"></iframe>
                </div>
                <div class="col-lg-6 col-12 mt-lg-0 mt-5">
                    <form class="">
                        <div class = "form-group">
                            <label>Name</label>
                            <input className = {`form-control ${errors[0] && 'is-invalid'}`} value = {state.name} onChange = {e => setState(prev => ({...prev, name: e.target.value}))}/>
                            <div class = 'invalid-feedback'>Please enter a valid name</div>
                        </div>
                        <div class = "form-group">
                            <label>Email Address</label>
                            <input className = {`form-control ${errors[1] && 'is-invalid'}`} value = {state.email} onChange = {e => setState(prev => ({...prev, email: e.target.value}))}/>
                            <div class = 'invalid-feedback'>Please enter a valid email</div>
                        </div>
                        <div class = "form-group">
                            <label>Phone Number</label>
                            <input className = {`form-control ${errors[2] && 'is-invalid'}`} value = {state.phone} onChange = {e => setState(prev => ({...prev, phone: e.target.value}))}/>
                            <div class = 'invalid-feedback'>Please enter a valid phone</div>
                        </div>
                        <div class = "form-group">
                            <label>Reason</label>
                            <div>
                                {contactReasons.map(item => (
                                    <div 
                                        class = {`btn mr-2 btn-sm mb-2 btn-${state.contactType === item ? 'dark' : 'outline-dark'}`}
                                        onClick = {() => setState(prev => ({...prev, contactType: item}))}
                                    >{item}</div>
                                ))}
                            {errors[5] && <div class = 'invalid-feedback d-block'>Please select a reason</div>}
                            </div>
                        </div>
                        <div class = "form-group">
                            <label>Subject</label>
                            <input className = {`form-control ${errors[3] && 'is-invalid'}`} value = {state.subject} onChange = {e => setState(prev => ({...prev, subject: e.target.value}))}/>
                            <div class = 'invalid-feedback'>Please enter a valid name</div>
                        </div>
                        <div class = "form-group">
                            <label>Message</label>
                            <textarea style = {{minHeight: '10vh'}} className = {`form-control ${errors[4] && 'is-invalid'}`} value = {state.message} onChange = {e => setState(prev => ({...prev, message: e.target.value}))}>
                            </textarea>
                            <div class = 'invalid-feedback'>Please enter a valid name</div>
                        </div>
                        <button disabled = {loading} onClick = {e => submitData(e)} class="btn btn-primary w-25">
                            {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                            Send
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}


const contactReasons = [
    "Issue",
    "Parnterships",
    "Media",
    "General Query",
    "Help in finding a guru",
]