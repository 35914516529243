import React, {useEffect, useState} from 'react'
import Basic from './Basic'
import Pricing from './Pricing'
import Resources from './Resources'
import axios from '../../../../../utilis/axios';
import Swal from 'sweetalert2'
import cogoToast from 'cogo-toast'
import imageCompression from 'browser-image-compression';


const Instrument = ({data}) => {

    const courseId = data._id;
    const [course, setCourse] = useState(data)
    const [tab, setTab] = useState('basics');
    const [loading, setLoading] = useState(false);
    const [headerImg, setHeaderImg] = useState([])
    const [photos, setPhotos] = useState([]);
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        setCourse(data)
    }, [data])


    const saveCourse = async () => {
        try{
            setLoading(true)
            console.log("he")
            await axios.put(`/api/admin/course/`, course);
            await saveFileData();
            cogoToast.success("Changes have been saved.")
            setLoading(false)
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }


    const saveFileData = async () => {
        let hideToast = () => {};
        try{

            let newHeaderImg = '', photoslink = '', videoslink = '';
            if(headerImg.length > 0){
                const formData = new FormData()
                formData.append('course', headerImg[0])  
                const res2 = await axios.post(`/api/admin/course/uploadHeader/${courseId}`, formData)
                newHeaderImg = res2.data
            }
            if(photos.length > 0){
                const formData = new FormData()
                for(let i=0; i<photos.length; i++){
                    const file = await imageCompression(photos[i], compressionConfig);
                    formData.append(`photos${i}`,file )  
                }
                const { hide } = cogoToast.loading("Uploading photos...", { hideAfter: 0 });
                hideToast = hide;
                const res2 = await axios.post(`/api/admin/course/upload/photos/${courseId}`, formData)
                photoslink = res2.data
                hide();

            }
            if(videos.length > 0) {
                const formData = new FormData()
                for(let i=0; i<videos.length; i++){
                    formData.append(`video${i}`, videos[i])  
                }
                var { hide } = cogoToast.loading(`Uploading videos...`, { hideAfter: 0 });
                // eslint-disable-next-line
                hideToast = hide;
                const res2 = await axios.post(`/api/admin/course/upload/videos/${courseId}`, formData)
                videoslink = res2.data
                hide();
            }
            return {newHeaderImg, photoslink, videoslink};

        } catch(err){
            console.log(err)
        }
    }



    const deleteInstrument = async() => {
        try{

            const response = await Swal.fire({
                title: 'Are you sure you want to delete the course',
                text: 'This action cannot be undone. ',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });
            if(response.isConfirmed){
                await axios.delete(`/api/admin/course/${courseId}`)
            }

        } catch(err){ 
            console.log(err)
        }
    }



    return (
        <div>

            <div className = 'header mt-md-5'>
                <div className = 'header-body'>
                    <div className="row align-items-center">
                        <div className="col">
                            <h6 class="header-pretitle">Overview</h6>
                            {course && <h1 class="header-title">{course.instrument}</h1>}
                        </div>
                        <div class = 'col-auto'>
                            <div class ='btn btn-danger' onClick = {deleteInstrument}>Delete Instrument</div>
                        </div>
                    </div>
                    <AccountNav tab = {tab} setTab = {setTab}/>
                </div>
            </div>
            {course && <div className = "row justify-content-center px-4 px-lg-0">
                <div className = 'col-12'>
                    {tab === 'basics' && <Basic 
                        loading = {loading}
                        course = {course} 
                        setCourse = {setCourse} 
                        headerImg = {headerImg} 
                        setHeaderImg = {setHeaderImg} 
                        saveCourse = {saveCourse}
                        videos = {videos}
                        photos = {photos}
                        setVideos = {setVideos}
                        setPhotos = {setPhotos}
                    />}
                    {tab === 'pricing' && <Pricing 
                        loading = {loading}
                        course = {course} 
                        setCourse = {setCourse} 
                        saveCourse = {saveCourse}
                    />}
                    {tab === 'resources' && <Resources 
                        loading = {loading}
                        course = {course} 
                        setCourse = {setCourse} 
                        courseId = {courseId}
                    />}
                </div>
            </div>}
            
        </div>
    )
}

export default Instrument




const AccountNav = ({tab, setTab}) => {
    return(
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'basics' && 'active'}`} onClick = {() => setTab('basics')}>Basics</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'pricing' && 'active'}`} onClick = {() => setTab('pricing')}>Pricing</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'resources' && 'active'}`} onClick = {() => setTab('resources')}>Resources</div>
                    </li>
                </ul>
        </div>
    </div>
    )
}



const compressionConfig = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
}




