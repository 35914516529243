import React, { useState, useContext, useEffect } from 'react'
import DashboardLayout from '../index'
import ReactPlayer from 'react-player'
import { Card, Modal } from 'react-bootstrap'
import { Store } from '../../../../store'
import axios from '../../../../utilis/axios';
import { getDistinct } from '../../../../utilis/helpers'
import moment from 'moment'
import { saveClasses, saveEnrollment } from '../../../../store/actions/student'
import Skeleton from 'react-loading-skeleton'
import cogoToast from 'cogo-toast'
import student_content from '../../../../content/student_content.json'

const Classes = () => {

    const { state, dispatch } = useContext(Store)
    const [tab, setTab] = useState();
    const [instructors, setInstructors] = useState();
    const [recordings, setRecordings] = useState([]);




    useEffect(() => {
        if (state.student.classes && state.student.classes.length > 0) {
            formatData(state.student.classes)
        } else fetchRecordings();

        if (state.student.enrollments && state.student.enrollments.length > 0) {
            formatEnrollments(state.student.enrollments)
        } else fetchEnrollments(state.student.enrollments)
        // eslint-disable-next-line
    }, [])

    const fetchRecordings = async () => {
        try {
            const res = await axios.get('/api/student/classes');
            // console.log(res.data)
            formatData(res.data)
            saveClasses(res.data, dispatch)

        } catch (err) {
            console.log(err)
        }
    }


    const fetchEnrollments = async () => {
        try {
            const res = await axios.get('/api/student/enrollments');
            formatEnrollments(res.data)
            saveEnrollment(res.data, dispatch)

        } catch (err) {
            console.log(err)
        }
    }


    const formatData = (data) => {
        const newRecordings = {};
        for (let i = 0; i < data.length; i++) {
            if (newRecordings[data[i].course._id]) {
                newRecordings[data[i].course._id].push({
                    title: data[i].title,
                    date: moment(data[i].date_time).format('DD MMM YY'),
                    time: moment(data[i].date_time).format('hh:mm A'),
                    description: data[i].lessonDetails,
                    links: data[i].zoom.recording_links !== undefined ? data[i].zoom.recording_links : [],
                })
            } else {
                newRecordings[data[i].course._id] = [{
                    title: data[i].title,
                    date: moment(data[i].date_time).format('DD MMM YY'),
                    time: moment(data[i].date_time).format('hh:mm A'),
                    links: data[i].zoom.recording_links !== undefined ? data[i].zoom.recording_links : [],
                    description: data[i].lessonDetails
                }]
            }
        }
        setRecordings(newRecordings)
    }

    console.log(instructors, 'instructors');
    const formatEnrollments = (data) => {
        const newInstructors = getDistinct(data.map(item => ({ ...item.instructor, course: item.course.title, _id: item.course._id })), '_id');
        let nonTherapyInstructors = newInstructors.filter(item => item.course !== "Music therapy")
        setInstructors(nonTherapyInstructors);
        setTab(newInstructors[0]._id)
    }



    return (
        <DashboardLayout>
            <div className="px-lg-6 mx-lg-6 px-3 mx-3 mt-4">
                <ActivityCard />
                <div className='row mt-6'>
                    {(instructors && instructors.length > 0) && <div className='col-12 col-lg-3'>
                        <InstructorNav tab={tab} setTab={setTab} data={instructors} />
                    </div>}
                    <div className='col'>
                        <PrevClasses recordings={instructors ? (recordings[tab] ? recordings[tab] : []) : null} tab={tab} />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Classes


const ActivityCard = () => {
    return (
        <div className='card '>
            <div className="card-body py-5">
                <div className="row align-items-center">
                    <div className="col-12 col-md-4 order-md-2">
                        <div className="text-center">
                            <img src="https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/RecordedSessions.svg" alt="..." class="img-fluid mt-n5 mt-lg-0 mb-4 mr-md-n5" />
                        </div>
                    </div>
                    <div className="col-12 col-md-8  px-4 py-3 order-md-1">
                        <h1 class='display-3'>{student_content.recordings.title}</h1>
                        <p class="text-muted lead">{student_content.recordings.description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}



const InstructorNav = ({ data, setTab, tab }) => {
    return (
        <div>
            <ul class="list-group list-group-active shadow-sm bg-white rounded mt-0">
                {data ? (data.length > 0 ? data.map(item => <li class={`list-group-item list-group-item-action pointer ${tab === item._id ? 'active' : ''}`} onClick={() => setTab(item._id)}>
                    <div>
                        <div class='row no-gutters'>
                            <div class='col-auto'>
                                <div class='avatar avatar-sm'>
                                    <img alt="" class='avatar-img rounded-circle' src={item.avatar} />
                                </div>
                            </div>
                            <div class='col pl-3'>
                                {item.firstName + ' ' + item.lastName}
                                <div class={`small text-${tab === item._id ? 'white' : 'secondary'}`}>{item.course}</div>
                            </div>
                        </div>
                    </div>
                </li>) : '') : <LoadingInstructorNav />}
            </ul>
        </div>

    )
}


const PrevClasses = ({ recordings, tab }) => {
    const [isActive, setIsActive] = useState("Allowed");

    // const handleRecordingAllowance = async () => {
    //     await axios.get(`/api/student/checkAccessByCourse/${tab}`).then((res) => {
    //         if (res.status === 200) {
    //             setIsActive(res.data)
    //         }
    //     }).catch((e) => {
    //         setIsActive(false)
    //     })
    // }

    // useEffect(() => {
    //     if (tab) {
    //         handleRecordingAllowance();
    //     }
    // }, [tab])
    return (
        <div>
            {isActive !== "Allowed" ?
                <Card className="mt-5 shadow bg-white rounded">
                    <Card.Body>
                        <div>
                            <h3>You're not allowed to access this recording</h3>
                            <span>To access this recording, you have to schedule new Sessions</span>
                        </div>
                    </Card.Body>
                </Card> : <>
                    <div class='h3 mt-5 mt-md-0'>{student_content.recordings.checkout}</div>
                    {recordings ? (recordings.length > 0 ? (<div className="list-group bg-white">
                        {recordings.map(item => (<ClassItem data={item} />))}
                    </div>) : <NoRecording />) : <LoadingRecordings />}
                </>}
        </div>
    )
}




const ClassItem = ({ data }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState(data.links.length > 0 ? data.links[0]._id : '')
    const playVideo = () => {
        if (data.links.length > 0) setIsOpen(true)
        else cogoToast.info(student_content.recordings.toast)
    }

    return (
        <div className="list-group-item py-4">

            <div className="row align-items-center">
                <div className="col-12 col-md ml-n2 mb-4 mb-md-0 d-flex align-items-center">
                    <div class='ml-3'>
                        <h4 class="text-body text-focus mb-1 name">{data.title}</h4>
                        <p class="small text-muted mb-0">
                            <span class="fe fe-clock"></span> <time datetime="2018-05-24">{data.description}</time>
                        </p>
                    </div>
                </div>
                <div className='col-4 mx-lg-3 px-lg-5 col-md-auto border-right text-center'>
                    <div class='h6 mb-1 text-uppercase text-muted'>Date</div>
                    <div class="small text-dark">{data.date}</div>
                </div>
                <div className='col-4 mx-lg-3 px-lg-3 col-md-auto text-center'>
                    <div class='h6 mb-1 text-uppercase text-muted'>Time</div>
                    <div class="small text-dark">{data.time}</div>
                </div>
                <div className='col-12 col-md-auto pl-md-5 mt-4 mt-md-0 d-flex'>
                    <div className='btn btn-sm btn-outline-primary d-flex align-items-center btn-block justify-content-center' onClick={() => playVideo()}>{data.links.length} recordings</div>
                </div>
            </div>
            <Modal show={isOpen} onHide={() => setIsOpen(false)} centered dialogClassName='model-120w'>
                <div className='modal-content' >
                    <div className='modal-body '>
                        <div class='close pointer' onClick={() => setIsOpen(false)}>&times;</div>
                        <ReactPlayer
                            config={{ file: { attributes: { disablepictureinpicture: 'true', controlsList: 'nodownload' } } }}
                            onContextMenu={e => e.preventDefault()}
                            url={data.links.filter(item => item._id === url).length > 0 ? data.links.filter(item => item._id === url)[0].link : ''}
                            controls={true}
                            width={'100%'}
                            height={'100%'}
                            style={{ width: '100%', height: '100%' }}
                        />

                        {data.links.length > 1 && <div class='text-right'>
                            <div class='btn-group btn-group-sm '>
                                {data.links.map((item, index) => (
                                    <div
                                        class={`btn btm-sm border btn-${url === data.links[index]._id ? '' : 'outline-'}primary`}
                                        onClick={() => setUrl(data.links[index]._id)}
                                    >
                                        {ordinal_suffix_of(index + 1)} video
                                    </div>))
                                }
                            </div>
                        </div>}
                    </div>
                </div>
            </Modal>
        </div>
    )
}


const LoadingInstructorNav = () => {
    return (
        <ul class="list-group list-group-active shadow-sm bg-white rounded mt-0">
            <li class='list-group-item'>
                <Skeleton height={50} />
            </li>
            <li class='list-group-item'>
                <Skeleton height={50} />
            </li>
            <li class='list-group-item'>
                <Skeleton height={50} />
            </li>
        </ul>
    )
}



const NoRecording = () => {
    return (
        <div className="card mb-0">
            <div class='card-body d-flex align-items-center justify-content-center text-uppercase text-muted h3 py-6 mb-0'>
                {student_content.recordings.no_recording}
            </div>
        </div>
    )
}

const LoadingRecordings = () => {
    return (
        <div className="">
            <Skeleton height={70} />
            <div class='mb-3'></div>
            <Skeleton height={70} />
            <div class='mb-3'></div>
            <Skeleton height={70} />
            <div class='mb-3'></div>
        </div>
    )
}


function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}