import React, {useState} from 'react'
import DetailedProfile from './AdditionalInfo'
import Basics from './Basics'


const Profile = ({profile, setProfile}) => {

    const [tab, setTab] = useState('basics')

    return (
       <>
       <div className="header bg-white px-4 pt-2 rounded shadow-sm">
            <div className="header-body pt-0">
                <AccountNav tab = {tab} setTab = {setTab}/>
            </div>
        </div>
            <div className = "row justify-content-center">
                <div className = 'col-12'>
                    {tab === 'basics' && <Basics profile = {profile} setProfile = {setProfile}/>}
                    {tab === 'detailed_profile' && <DetailedProfile profile = {profile} setProfile = {setProfile}/>}
                </div>
            </div>
        </>
    )
}

export default Profile




const AccountNav = ({tab, setTab}) => {
    return(
        <div className="row align-items-end ">
            <div class = 'col'>
                <ul class="nav nav-tabs header-tabs">
                <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'basics' && 'active'}`} onClick = {() => setTab('basics')}>Basics</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'detailed_profile' && 'active'}`} onClick = {() => setTab('detailed_profile')}>Additional Info</div>
                    </li>
                </ul>

            </div>
        </div>
    )
}
