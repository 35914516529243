import React, {useState, useContext, useEffect} from 'react'
import DashboardLayout from '../../index'
import Basics from './Basics'
import Pricing from './Pricing'
import axios from '../../../../../utilis/axios'
import { Store } from '../../../../../store'
import { saveCourses } from '../../../../../store/actions/instructor'
import { saveInstructorProfile } from '../../../../../store/actions/auth'
import cogoToast from 'cogo-toast'
import instructor_content from '../../../../../content/instructor_content.json'
import imageCompression from 'browser-image-compression';


const AddCourse = (props) => {

    const {state, dispatch} = useContext(Store)
    const [tab, setTab] = useState('basics');
    const [course, setCourse] = useState(initCourse);
    const [allInstruments, setAllInstruments] = useState()
    const [headerImg, setHeaderImg] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0)

    useEffect(() => {
        fetchInstruments();
    }, [])


    const fetchInstruments = async () => {
        try{
            
            const res = await axios.get(`/api/instructor/instruments`);
            setAllInstruments(res.data.map(item => item.instrument))

        } catch(err){
            console.log(err)
        }
    }

    const saveCourse = async () => {
        let hideToast = () => {};
        let courseId = ''
        try{

            setLoading(true);
            const res = await axios.post(`/api/instructor/course`, {course});
            courseId = res.data._id;
            const formData = new FormData()
            formData.append('course', headerImg[0])  
            const headerImgLink = await axios.post(`/api/instructor/course/uploadHeader/${courseId}`, formData)
            let photoslink = '', videoslink = '';
            if(photos.length > 0){
                const formData = new FormData()
                for(let i=0; i<photos.length; i++){
                    const file = await imageCompression(photos[i], compressionConfig);
                    formData.append(`photos${i}`, file)  
                }
                var { hide } = cogoToast.loading("Uploading photos...", { hideAfter: 0 });
                hideToast = hide;
                const res2 = await axios.post(`/api/instructor/course/upload/photos/${courseId}`, formData)
                photoslink = res2.data
                hide();
            }
            if(videos.length > 0) {
                // console.log('saving videos')
                const formData = new FormData()
                for(let i=0; i<videos.length; i++){
                    formData.append(`video${i}`, videos[i])  
                }
                // eslint-disable-next-line 
                var { hide } = cogoToast.loading(`Uploading videos...`, { hideAfter: 0 });
                // eslint-disable-next-line
                hideToast = hide;
                const res2 = await axios.post(`/api/instructor/course/upload/videos/${courseId}`, formData, {
                    onUploadProgress: progressEvent => {
                        let progress = parseFloat((progressEvent.loaded / progressEvent.total) * 100).toFixed(5);
                        setUploadProgress(progress)
                    }
                })
                videoslink = res2.data;
                hide();
            }
            
            
            cogoToast.success(instructor_content.course.add_toast.success)


            if(state.instructor.courses.length > 0){
                const allCourses = state.instructor.courses;
                allCourses.push({
                    ...res.data,
                    headerImg: headerImgLink.data,
                    videos: videoslink,
                    photos: photoslink
                }) 
                saveCourses(allCourses, dispatch)
            }

            const newProfile = {...state.user.data.instructor_profile}
            newProfile.onboarding.course = true;
            saveInstructorProfile(state.user.data, newProfile, dispatch)


            props.history.push('/instructor/courses')
            setLoading(false);

        } catch(err){
            hide()
            if(courseId){
                await deleteCourse(courseId)
            } else{
                setLoading(false)
                console.log(err)
                throw err
            }
        }
    }


    const deleteCourse = async (courseId) => {
        try{

            setLoading(true);
            // console.log('deleting course')
            await axios.delete(`/api/instructor/course/${courseId}`)
            setLoading(false)

        } catch(err){
            cogoToast.error('Some error occured, please refresh again')
            console.log(err)
        }
    }

    return (
        <DashboardLayout 
            showTitle = {false} 
            padding = "high"
            {...props}
        >
            {loading && <div class = 'progress-bar-1' style = {{width: uploadProgress + 'vw'}}></div>}
            <div class = 'row justify-content-center px-4 px-lg-0'>
                <div class = "col-12 col-lg-10 col-xl-8">
                    <form class = "tab-content py-5">
                        <div class = {`tab-pane fade ${tab === 'basics' ? 'active show':''}`}>
                            <Basics 
                                setTab = {setTab} 
                                course = {course} 
                                setCourse = {setCourse} 
                                instruments = {allInstruments} 
                                headerImg = {headerImg} 
                                setHeaderImg = {setHeaderImg}
                                videos = {videos}
                                photos = {photos}
                                setVideos = {setVideos}
                                setPhotos = {setPhotos}
                            />
                        </div>
                        <div class = {`tab-pane fade ${tab === 'pricing' ? 'active show':''}`}>
                            <Pricing setTab = {setTab} course = {course} setCourse = {setCourse} loading = {loading} saveCourse = {saveCourse}/>
                        </div>
                    </form>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default AddCourse





const initCourse  = {
    title: "",
    instrument: "",
    level: {
        beginner: "Comfortable",
        intermediate: "Comfortable",
        advance: "Comfortable"
    },
    details: "",
    speciality: "",
    techniques: "",
    price_per_class: '',
    discount: [
        {num_classes: '1', discount: ''},
        {num_classes: '8',discount: ''},
        {num_classes: '16',discount: ''},
        {num_classes: '24',discount: ''},
    ],
}


const compressionConfig = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
}