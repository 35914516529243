export const FETCH_USER = 'FETCH_USER'

export const fetchUser = async (user, dispatch) =>{
     return dispatch({
        type: FETCH_USER,
        payload: {
            data: user,
            isLoggedIn: true, 
        }
    })
}


export const loginFailure = async (dispatch) =>{
    return dispatch({
       type: FETCH_USER,
       payload: {
           data: {},
           isLoggedIn: false, 
       }
   })
}


export const loginSuccess = async (user, dispatch) =>{
    return dispatch({
       type: FETCH_USER,
       payload: {
           data: user,
           isLoggedIn: true, 
       }
   })
}


export const saveInstructorProfile = async (user, instructor, dispatch) =>{
    return dispatch({
       type: FETCH_USER,
       payload: {
           data: {...user, instructor_profile: instructor},
           isLoggedIn: true, 
       }
   })
}


export const logoutSuccess = (dispatch) =>{
    dispatch({
        type: FETCH_USER,
        payload: {
            data: {},
            isLoggedIn: false, 
        }
    })
}
