import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import { BeatSpinner2 } from '../../../../components/Spinners/BeatSpinner'
import moment from 'moment'
import AddZoom from './AddZoom'
import { AiFillDelete } from 'react-icons/ai'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const ZoomAccounts = (props) => {

    const [data, setData] = useState();
    const [zoomAccounts, setZoomAccounts] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [show, setShow] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState()


    useEffect(() => {
        fetchZoomAccounts()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(data) formatData(data)
        // eslint-disable-next-line
    }, [data])

    const fetchZoomAccounts = async () =>{
        try{

            const res = await axios.get('/api/admin/zoomAccounts');
            setData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        setZoomAccounts(data.map(item => ({
            ...item,
            counter: item.counter.toString(),
            createdAt: moment(item.createdAt).format('Do MMM YY, hh:mm A'),
            delete: {value: '', label: 
                <OverlayTrigger overlay = {<Tooltip>Delete</Tooltip>}>
                    <div class = "h3 mb-0 pointer" onClick = {() => setSelectedAccount(item._id)}>
                        <AiFillDelete />
                    </div>
                </OverlayTrigger>
            }
        })))  
    }

    useEffect(() => {
        if(selectedAccount && selectedAccount.length > 5) deleteAnnouncements(selectedAccount)
         // eslint-disable-next-line 
    }, [selectedAccount])


    const deleteAnnouncements = async (id) => {
        try{

            setSelectedAccount()
            const newAccounts = [...zoomAccounts];
            const index = newAccounts.findIndex(item => item._id === id);
            newAccounts.splice(index, 1)
            setZoomAccounts(newAccounts);
            
            const newData = [...data];
            const index1 = newData.findIndex(item => item._id === id);
            newData.splice(index1, 1);
            setData(newData)

            await axios.delete(`/api/admin/zoomAccounts/${id}`);

        } catch(err){
            console.log(err)
        }
    }

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
            <div className = 'row align-items-center mt-5 border-bottom pb-3 mb-4'>
                <div className = 'col'>
                    <h3 class = 'h1'>Zoom Accounts</h3>
                </div>
                <div class = 'col-auto'>
                    <div class = 'btn btn-primary' onClick = {() => setShow(true)}>Add Zoom Account</div>
                </div>
            </div>
            {zoomAccounts ? <Table 
                data = {zoomAccounts}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => console.log('')}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
            /> : <BeatSpinner2 />}


            <AddZoom
                show = {show}
                setShow = {setShow}
                zoomAccounts = {data}
                setZoomAccounts = {setData}
            />
        </AdminDashboard>
    )
}

export default ZoomAccounts




const headers = [
    {value: 'email', active: true, label: 'Email'},
    {value: 'type', active: true, label: 'Type'},
    {value: 'counter', active: true, label: 'Api Counter'},
    {value: 'createdAt', active: true, label: 'Created At'},
    {value: 'delete', active: true, label: 'Delete'},
]




