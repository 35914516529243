import React, { useState } from 'react'
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { saveSchedule } from '../../../../store/actions/student';



const PenalityModal = ({ show, setShow, sendConfirmation }) => {



  return (
    <Modal show={show ? true : false} onHide={() => setShow(undefined)} centered>
      <div className='modal-content' >
        <div className='modal-body '>
          <div onClick={() => setShow(undefined)} class="close pointer">&times;</div>
          <div class="h2">
            Do you want to charge for this session?
          </div>
          <div class="">
            We advise you to talk to your student and see what was the reason of cancelling the class late. In case of a genuine reason we request you to not charge for this session.
          </div>
          <div class='btn-group btn-group-md mt-3 w-100 '>
            <button class='btn btn-danger border' onClick={(e) => { setShow(undefined); sendConfirmation(e, show, true) }}>
              Charge
            </button>
            <button class='btn btn-success border' onClick={(e) => { setShow(undefined); sendConfirmation(e, show, false) }}>
              Don't Charge
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}




const AttendanceInstModal = ({
  setLoading,
  data,
  state,
  dispatch,
  show,
  setShow,
  loading,
  setMainLoading,
  mainLoading
}) => {

  const [internetIssue, setInternetIssue] = React.useState(false);
  const [showPenalityModal, setShowPenalityModal] = React.useState(undefined);
  const [draft, setDraft] = useState('')

  const studentMissedClass = (e, type) => {
    e.preventDefault();
    if (data.type === 'paid') setShowPenalityModal(type)
    else {
      sendConfirmation(e, 'student_no_show', false)
    }
  }

  const sendConfirmation = async (e, response, isCharged) => {
    e.preventDefault();
    try {
      setMainLoading(true);
      setLoading(response);
      const data1 = {
        draft: draft,
        response: response,
        isCharged: isCharged
      };
      const res = await axios.post(
        `/api/instructor/schedule/complete_class/${data._id}`,
        data1
      );
      const newSchedule = [...state.instructor.schedule];
      const index = newSchedule.findIndex((item) => item._id === data._id);
      newSchedule[index].status = res.data.status;
      newSchedule[index].lessonDetails = res.data.lessonDetails;
      newSchedule[index].confirm_by_instructor = res.data.confirm_by_instructor;
      newSchedule[index].confirm_by_student = res.data.confirm_by_student;
      newSchedule[index].isFlagged = res.data.isFlagged;
      newSchedule[index].lateCharges = res.data.lateCharges;
      saveSchedule(newSchedule, dispatch);
      setMainLoading(false);
      setLoading("");
      setShow(false);

    } catch (err) {
      setMainLoading(false);
      setLoading("");
      console.log(err);
    }
  };

  return (
    <>
      <PenalityModal
        show={showPenalityModal}
        setShow={setShowPenalityModal}
        sendConfirmation={sendConfirmation}
      />
      <Modal show={show} onHide={() => setShow(false)} centered dialogClassName="dhun-internet-modal">
        <div className="modal-content">
          <div className="modal-body ">
            <div>
              <h3 class='mb-3'>Was this session attended or missed by student?</h3>
            </div>
            <div className="close pointer" onClick={() => setShow(false)}></div>

            <div className="mt-2">
              <button
                disabled={mainLoading}
                class="btn btn-sm btn-outline-secondary mr-2"
                onClick={(e) => sendConfirmation(e, "student_joined", true)}
              >
                {loading === "student_joined" && (
                  <span class="spinner-border spinner-border-sm mr-2"></span>
                )}
                Completed
              </button>
              <button
                disabled={mainLoading}
                class="btn btn-sm btn-outline-secondary  mr-2"
                onClick={(e) => studentMissedClass(e, "student_no_show")}
              >
                {loading === "student_no_show" && (
                  <span class="spinner-border spinner-border-sm mr-2"></span>
                )}
                Member did not join
              </button>
              <button
                disabled={mainLoading}
                class="btn btn-sm btn-outline-secondary  mr-2"
                onClick={(e) => sendConfirmation(e, "instructor_no_show", false)}
              >
                {loading === "instructor_no_show" && (
                  <span class="spinner-border spinner-border-sm mr-2"></span>
                )}
                Mentor did not join
              </button>
              <button
                disabled={mainLoading}
                className={internetIssue ? "btn btn-sm btn-secondary  mr-2" : "btn btn-sm btn-outline-secondary  mr-2"}
                onClick={(e) => setInternetIssue(!internetIssue)}
              >
                Connectivity Issue
              </button>



            </div>
            {internetIssue && <>
              <div className='mt-4'>
                <h3 className='h5 mb-0'>Was the internet issue at your end?</h3>
                <div className='mt-2'>
                  <button
                    disabled={mainLoading}
                    className="btn btn-sm btn-outline-secondary  mr-2"
                    onClick={(e) => sendConfirmation(e, "internet_issue_instructor", false)}
                  >
                    {loading === "internet_issue_student" && (
                      <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    Yes, it was at my end
                  </button>{" "}
                  <button
                    disabled={mainLoading}
                    className="btn btn-sm btn-outline-secondary"
                    onClick={(e) =>
                      studentMissedClass(e, "internet_issue_student")
                    }
                  >
                    {loading === "internet_issue_instructor" && (
                      <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    No, it was at student's end
                  </button>
                </div>
              </div>
            </>
            }

          </div>
        </div>
      </Modal>
    </>
  );
};

export default AttendanceInstModal;
