import {useRouteMatch, Switch } from "react-router-dom";

import Home from '../../pages/Dashboards/Admin/Home'
import Instructors from '../../pages/Dashboards/Admin/Instructors'
import Instructor from '../../pages/Dashboards/Admin/Instructor'
import Students from '../../pages/Dashboards/Admin/Students'
import Billing from '../../pages/Dashboards/Admin/Invoices'
import Sessions from '../../pages/Dashboards/Admin/Sessions'
import Requests from '../../pages/Dashboards/Admin/Requests'
import Enrollments from '../../pages/Dashboards/Admin/Enrollments'
import ProtectedRoute from '../ProtectedRoute';
import Messages from "../../pages/Dashboards/Admin/Messages";
import SuperAdmin from "../../pages/Dashboards/Admin/SuperAdmin";
import Annoucements from "../../pages/Dashboards/Admin/Announcement";
import Users from "../../pages/Dashboards/Admin/Users";
import ZoomAccounts from "../../pages/Dashboards/Admin/Zoom";
import Security from "../../pages/Dashboards/Admin/Security";
import Logs from "../../pages/Dashboards/Admin/Logs";
import Discounts from "../../pages/Dashboards/Admin/Discounts";


const AdminPagesRoutes = () => {

    let {path} = useRouteMatch();


    return (
        <>
            <Switch>
                <ProtectedRoute exact path={`${path}`} component={Home} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/instructors`} component={Instructors} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/instructor/:id`} component={Instructor} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/students`} component={Students} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/invoices`} component={Billing} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/sessions`} component={Sessions} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/requests`} component={Requests} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/enrollments`} component={Enrollments} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/discounts`} component={Discounts} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/messages`} component={Messages} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/super-admin`} component={SuperAdmin} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/annoucements`} component={Annoucements} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/zoom_accounts`} component={ZoomAccounts} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/users`} component={Users} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/security`} component={Security} role = 'admin'/>
                <ProtectedRoute exact path={`${path}/logs`} component={Logs} role = 'admin'/>
            </Switch>
        
        </>
    );
}

export default AdminPagesRoutes;



