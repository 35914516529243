import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import { BeatSpinner2 } from '../../../../components/Spinners/BeatSpinner'
import moment from 'moment'
import LogDetails from './LogDetails'

const AllLogs = (props) => {

    const [logs, setLogs] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [show, setShow] = useState(false);
    const [selectedLog, setSelectedLog] = useState()

    useEffect(() => {
        fetchLogs()
        // eslint-disable-next-line
    }, [])


    const fetchLogs = async () =>{
        try{

            const res = await axios.get('/api/admin/logs');
            formatData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        setLogs(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => ({
            firstName: item.user.firstName + ' ' + item.user.lastName,
            date: {value: moment(item.createdAt).unix(), label: moment(item.createdAt).format('Do MMM YY, hh:mm A')},
            changeType: {value: item.changeType, label: <div class='badge badge-primary'>{item.changeType}</div>},
            ...item,
        })))  
    }


   

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
            <div className = 'row align-items-center mt-5 border-bottom pb-3 mb-4'>
                <div className = 'col'>
                    <h3 class = 'h1'>All Logs</h3>
                </div>
            </div>
            {logs ? <Table 
                data = {logs}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => {
                    console.log(logs.filter(item => item._id === id)[0])
                    setSelectedLog(logs.filter(item => item._id === id)[0]); 
                    setShow(true)
                }}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {['changeType']}
                isDownloadable = {true}
            /> : <BeatSpinner2 />}

            {selectedLog && <LogDetails 
                show = {show}
                setShow = {setShow}
                data = {selectedLog}
            />}


        </AdminDashboard>
    )
}

export default AllLogs




const headers = [
    {value: 'firstName', active: true, label: 'Name'},
    {value: 'date', active: true, label: 'Date'},
    {value: 'changeType', active: true, label: 'Type'},
    {value: 'comments', active: true, label: 'Comments'},
]


