import React, {useState, useEffect} from 'react'
import axios from '../../../../utilis/axios';
import AdminDashboard from '../index'
import Approvals from './Approvals'
import Profile from './Profile'
import Instruments from './Instruments'
import Documents from './Documents'
import BeatSpinner from '../../../../components/Spinners/BeatSpinner'
import BankAccount from './Bank';
import Availability from './Availability';


const Instructor = (props) => {

    const {id} = props.match.params;
    const [courses, setCourses] = useState();
    const [instructor, setInstructor] = useState();
    const [tab, setTab] = useState('approval');

    useEffect(() => {
        fetchInstructor();
        // eslint-disable-next-line
    }, [])

    const fetchInstructor = async () => {
        try{

            const res = await axios.get(`/api/admin/instructor/info/${id}`);
            console.log(res.data)
            setCourses(res.data.courses)
            setInstructor({
                ...res.data.instructor,
                ...res.data.instructor.user,
                instructor_id: res.data.instructor._id
            })

        } catch(err){
            console.log(err)
        }
    }


    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
        {instructor ? <><div className="header px-lg-6 mx-lg-4">
            <div className="header-body">
                <div className = 'row align-items-end mt-5 '>
                    <div className = 'col'>
                        <h6 class="header-pretitle">Instructor</h6>
                        <h1 class="header-title">{instructor.firstName + ' ' + instructor.lastName}</h1>
                    </div>
                    <AccountNav tab = {tab} setTab = {setTab}/>
                </div>
            </div>
        </div>
        <div className = "row justify-content-center">
            <div className = 'col-12 col-lg-10'>
                {tab === "approval" && <Approvals instructor = {instructor} setInstructor = {setInstructor} courses = {courses} setCourses = {setCourses}/>}
                {tab === "profile" && <Profile profile = {instructor} setProfile = {setInstructor}/>}
                {tab === "documents" && <Documents data = {instructor}/>}
                {tab === "bank" && <BankAccount data = {instructor}/>}
                {(tab === "instruments" && courses.length > 0) && <Instruments courses = {courses}/>}
                {(tab === "availability") && <Availability data = {instructor}/>}
            </div>
        </div></> : <BeatSpinner />}

            
        </AdminDashboard>
    )
}

export default Instructor




const AccountNav = ({tab, setTab}) => {
    return(
        <div className="col-auto">
            <ul class="nav nav-tabs  header-tabs">
                <li class="nav-item">
                    <div class={`nav-link pointer ${tab === 'approval' && 'active'}`} onClick = {() => setTab('approval')}>Approvals</div>
                </li>
                <li class="nav-item">
                    <div class={`nav-link pointer ${tab === 'profile' && 'active'}`} onClick = {() => setTab('profile')}>Profile</div>
                </li>
                <li class="nav-item">
                    <div class={`nav-link pointer ${tab === 'instruments' && 'active'}`} onClick = {() => setTab('instruments')}>Instruments</div>
                </li>
                <li class="nav-item">
                    <div class={`nav-link pointer ${tab === 'documents' && 'active'}`} onClick = {() => setTab('documents')}>Documents</div>
                </li>
                <li class="nav-item">
                    <div class={`nav-link pointer ${tab === 'availability' && 'active'}`} onClick = {() => setTab('availability')}>Availability</div>
                </li>
                <li class="nav-item">
                    <div class={`nav-link pointer ${tab === 'bank' && 'active'}`} onClick = {() => setTab('bank')}>Bank Account</div>
                </li>
            </ul>
        </div>
    )
}