import {useRouteMatch, Switch } from "react-router-dom";

import StudentsHome from '../../pages/Dashboards/Student/Home'
import StudentsSchedule from '../../pages/Dashboards/Student/Schedule'
import StudentsClasses from '../../pages/Dashboards/Student/Classes'
import StudentsMessages from '../../pages/Dashboards/Student/Messages'
import StudentsBilling from '../../pages/Dashboards/Student/Billing'
import StudentsAccount from '../../pages/Dashboards/Student/Account'
import StudentsReferal from '../../pages/Dashboards/Student/Account/Referral'
import StudentResources from '../../pages/Dashboards/Student/Resources'
import ProtectedRoute from '../ProtectedRoute';

const AdminPagesRoutes = () => {

    let {path} = useRouteMatch();

    return (
        <>
        <Switch>
            <ProtectedRoute exact path={`${path}`} component={StudentsHome}  role = 'student'/>
            <ProtectedRoute exact path={`${path}/classes`} component={StudentsClasses}  role = 'student'/>
            <ProtectedRoute exact path={`${path}/messages`} component={StudentsMessages}  role = 'student'/>
            <ProtectedRoute exact path={`${path}/schedule`} component={StudentsSchedule}  role = 'student'/>
            <ProtectedRoute exact path={`${path}/billing`} component={StudentsBilling}  role = 'student'/>
            <ProtectedRoute exact path={`${path}/resources`} component={StudentResources}  role = 'student'/>
            <ProtectedRoute exact path={`${path}/:id`} component={StudentsAccount}  role = 'student'/>
        </Switch> 
        </>
    );
}

export default AdminPagesRoutes;



