export default class FileHelper {
  static isImage(link) {
    const ext = link.split(".").pop();
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
        return true;
      default:
        return false;
    }
  }
}
