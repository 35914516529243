import React, {useState, useEffect, useContext, useRef, Fragment} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {FaChalkboardTeacher} from 'react-icons/fa'
import {FiPercent} from 'react-icons/fi'
import {ImUsers} from 'react-icons/im'
import {IoIosPeople} from 'react-icons/io'
import {SiGoogleclassroom} from 'react-icons/si'
import {RiAdminLine, RiLockPasswordFill} from 'react-icons/ri'
import {GiTakeMyMoney} from 'react-icons/gi'
import {BiMessage} from 'react-icons/bi'
import {GrAnnounce} from 'react-icons/gr'
import {SiZoom} from 'react-icons/si'
import { logoutUser } from '../../services/auth';
import { logoutSuccess } from '../../store/actions/auth';
import {Store} from '../../store'

const NavBar = (props) => {
  
  const {state, dispatch} = useContext(Store);
  const [collapseNav, setCollapseNav] = useState(false);
  const [showUserDropDown, setShowUserDropDown] = useState(false)
  const [activeLink, setActiveLink] = useState('')
  const userDropDown = useRef(null);

  useEffect(() => {
    setCollapseNav(false)
  }, [activeLink])

  useEffect(()=> {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  useEffect(() => {
    setActiveLink(props.location.pathname)
  },[props.location.pathname]) 
  

  const handleClickOutside = (event) => {
    if(userDropDown.current && !userDropDown.current.contains(event.target)) {
      setShowUserDropDown(false)
    }
  };


  const handleLogout = async () => {
    try {
      logoutSuccess(dispatch);
      await logoutUser();
    } catch (e) {
      console.error(e);
    }
  }
  
  
  return (
    <Fragment >
          
        <nav className="navbar navbar-vertical fixed-left pb-0 navbar-expand-md navbar-light">
          <div className="container-fluid">
            <button className="navbar-toggler" onClick = {()=>setCollapseNav(!collapseNav)}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link to = "/" className="navbar-brand">
              <img src='https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/logo.png' className="navbar-brand-img mx-auto" alt="..." />
            </Link>
            <div className="navbar-user d-md-none">
              <div className="dropdown">
                <div className="dropdown-toggle" >
                  <div className="avatar avatar-sm" onClick = {() => setShowUserDropDown(true)}>
                    <img className="avatar-img rounded-circle" src='https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/generic-avatar.png'  alt="..."/>
                  </div>
                </div>
                <div ref = {userDropDown} className={`dropdown-menu dropdown-menu-right ${showUserDropDown ? 'show' : ''}`} >
                  <div className = 'small pl-4 py-2 navbar-heading'>Hi, Abhishek</div>
                    <div onClick = {handleLogout} className="dropdown-item pointer">Log out</div>
                  </div>
                </div>
              </div>
              <div className={`collapse navbar-collapse ${collapseNav ? 'show' : ''}`}>
                <ul className = 'navbar-nav'>
                  {state.user.data.access.instructors && <li className="nav-item">
                    <Link to="/admin/instructors" className={`nav-link ${activeLink === '/admin/instructors' && 'active'}`}>
                      <FaChalkboardTeacher className = 'mr-3'/> Instructors
                    </Link>
                  </li>}
                  {state.user.data.access.students && <li className="nav-item">
                    <Link to="/admin/students" className={`nav-link ${activeLink === '/admin/students' && 'active'}`}>
                      <IoIosPeople className = 'mr-3'/> Students
                    </Link>
                  </li>}
                  {state.user.data.access.enrollments && <li className="nav-item">
                    <Link to="/admin/enrollments" className={`nav-link ${activeLink === '/admin/enrollments' && 'active'}`}>
                      <ImUsers className = 'mr-3'/> Enrollments
                    </Link>
                  </li>}
                  {state.user.data.access.sessions && <li className="nav-item">
                    <Link to="/admin/sessions" className={`nav-link ${activeLink === '/admin/sessions' && 'active'}`}>
                      <SiGoogleclassroom className = 'mr-3'/> Sessions
                    </Link>
                  </li>}
                  {state.user.data.access.requests && <li className="nav-item">
                    <Link to="/admin/requests" className={`nav-link ${activeLink === '/admin/requests' && 'active'}`}>
                      <SiGoogleclassroom className = 'mr-3'/> Requests
                    </Link>
                  </li>}
                  {state.user.data.access.invoices && <li className="nav-item">
                    <Link to="/admin/invoices" className={`nav-link ${activeLink === '/admin/invoices' && 'active'}`}>
                      <GiTakeMyMoney className = 'mr-3'/> Invoices
                    </Link>
                  </li>}
                  {state.user.data.access.messages && <li className="nav-item">
                    <Link to="/admin/messages" className={`nav-link ${activeLink === '/admin/messages' && 'active'}`}>
                      <BiMessage className = 'mr-3'/> Messages
                    </Link>
                  </li>}
                  {state.user.data.access.announcements && <li className="nav-item">
                    <Link to="/admin/annoucements" className={`nav-link ${activeLink === '/admin/annoucements' && 'active'}`}>
                      <GrAnnounce className = 'mr-3'/> Annoucements
                    </Link>
                  </li>}
                  {state.user.data.access.zoom && <li className="nav-item">
                    <Link to="/admin/zoom_accounts" className={`nav-link ${activeLink === '/admin/zoom_accounts' && 'active'}`}>
                      <SiZoom className = 'mr-3'/> Zoom Accounts
                    </Link>
                  </li>}
                  {state.user.data.access.discounts && <li className="nav-item">
                    <Link to="/admin/discounts" className={`nav-link ${activeLink === '/admin/discounts' && 'active'}`}>
                      <FiPercent className = 'mr-3'/> Discounts
                    </Link>
                  </li>}
                  {state.user.data.access.allUsers && <li className="nav-item">
                    <Link to="/admin/users" className={`nav-link ${activeLink === '/admin/users' && 'active'}`}>
                      <IoIosPeople className = 'mr-3'/> All Users
                    </Link>
                  </li>}
                  {state.user.data.isSuperAdmin && <li className="nav-item">
                    <Link to="/admin/super-admin" className={`nav-link ${activeLink === '/admin/super-admin' && 'active'}`}>
                      <RiAdminLine className = 'mr-3'/> Create Admins
                    </Link>
                  </li>}
                  {state.user.data.isSuperAdmin && <li className="nav-item">
                    <Link to="/admin/logs" className={`nav-link ${activeLink === '/admin/logs' && 'active'}`}>
                      <RiAdminLine className = 'mr-3'/> Logs
                    </Link>
                  </li>}
                </ul>

                <hr className = 'navbar-divider my-3' />
                <ul className = 'navbar-nav'>
                  <li className="nav-item">
                    <Link to="/admin/security" className={`nav-link ${activeLink === '/admin/security' && 'active'}`}>
                      <RiLockPasswordFill className = 'mr-3'/> Security
                    </Link>
                  </li>
                </ul>

                <div className="mt-auto"></div>
                <div className="navbar-user d-none d-md-flex py-4 navbar-light" >
                    
                    <div className="dropup">
                      <div className="dropdown-toggle" >
                        <div className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" src={state.user.data.avatar} alt="..." />
                        </div>
                      </div>
                      <div className="dropdown-menu" style = {{zIndex: '1000'}}>
                        <div className = 'small pl-4 py-2 navbar-heading'>Hi</div>
                          <div onClick = {handleLogout} className="dropdown-item pointer">Log out</div>
                      </div>
                    </div>
                </div>
            </div> 
          </div>
        </nav>
    </Fragment>
    )
}
        

export default withRouter(NavBar);       
    



