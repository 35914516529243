import {useState} from 'react'
import Instrument from './Instrument'


const Basics = ({courses}) => {

    const [selected, setSelected] = useState(0)
    

    return (
        <div>
             <div className="header bg-white px-4 pt-2 rounded shadow-sm">
                <div className="header-body pt-0">
                    <AccountNav selected = {selected} setSelected = {setSelected} courses = {courses}/>
                </div>
            </div>

            <Instrument data = {courses[selected]}/>
            
        </div>
    )
}

export default Basics



const AccountNav = ({selected, setSelected, courses}) => {
    return(
        <div className="row align-items-end ">
            <div class = 'col-auto'>
                <strong>Listed Instruments: </strong>
            </div>
            <div class = 'col'>
                <ul class="nav nav-tabs header-tabs">
                    {courses.map((item, index) => <li class="nav-item">
                        <div class={`nav-link pointer pt-0 ${selected === index && 'active'}`} onClick = {() => setSelected(index)}>{item.instrument[0].toUpperCase() + item.instrument.slice(1)}</div>
                    </li>)}
                </ul>

            </div>
        </div>
    )
}






