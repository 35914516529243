import React from 'react'
import axios from '../../../../utilis/axios'

const Approvals = ({instructor, courses, setCourses, setInstructor}) => {

    
    const approveInstructor = async () => {
        try{
            
            const newValue = !instructor.approve
            setInstructor(prev => ({...prev, approve: newValue}))
            if(newValue){
                await axios.get(`/api/admin/instructor/approve/${instructor.instructor_id}`)
            } else{
                await axios.get(`/api/admin/instructor/disapprove/${instructor.instructor_id}`)
            }

        } catch(err){
            console.log(err)
        }
    }
    
    

    const isAvailbleForClasses = async () => {
        try{
            
            const newValue = !instructor.isAvailbleForClasses
            setInstructor(prev => ({...prev, isAvailbleForClasses: newValue}))
            if(newValue){
                await axios.get(`/api/admin/instructor/isAvailbleForClasses/${instructor.instructor_id}`)
            } else{
                await axios.get(`/api/admin/instructor/isNotAvailbleForClasses/${instructor.instructor_id}`)
            }

        } catch(err){
            console.log(err)
        }
    }


    const tagTeacher = async (tag) => {
        try{
            
            const newValue = !instructor[tag]
            setInstructor(prev => ({...prev, [tag]: newValue}))
            await axios.get(`/api/admin/instructor/tag/${instructor.instructor_id}/${tag}/${newValue}`)

        } catch(err){
            console.log(err)
        }
    }
    
    
    const approveInstrument = async (id) => {

        try{
            const newCourses = [...courses];
            const index = newCourses.findIndex(item => item._id === id)
            const newValue = !courses[index].approve
            newCourses[index].approve = newValue
            setCourses(newCourses)
            if(newValue){
                console.log("Approving")
                await axios.get(`/api/admin/instructor/lesson/approve/${id}`)
            } else{
                await axios.get(`/api/admin/instructor/lesson/disapprove/${id}`)
            }

        } catch(err){
            console.log(err)
        }

    }
    


    return (
        <div>
             <div className="row">
                <div className = 'col-12 col-lg-10'>
                    <div className = 'row align-items-center py-4'>
                        <div className="col-auto">
                            <div className="custom-control custom-switch" onClick = {() => approveInstructor()}>
                                <input type="checkbox" className="custom-control-input" readOnly checked={instructor.approve}/> 
                                <label className="custom-control-label" ></label>
                            </div>
                        </div>
                        <div className="col ml-n2">
                            <h4 className="mb-1">Approve Instructor Profile</h4>
                            <small>Please check the profile and all the documents submitted thoroughly.</small>
                        </div>

                        <div className="col-auto">
                            <div className="custom-control custom-switch" onClick = {() => isAvailbleForClasses()}>
                                <input type="checkbox" className="custom-control-input" readOnly checked={instructor.isAvailbleForClasses}/> 
                                <label className="custom-control-label" ></label>
                            </div>
                        </div>
                        <div className="col ml-n2">
                            <h4 className="mb-1">Is Available For Classes</h4>
                            <small>Please mark it check if the instructor is not approved but is available for classes.</small>
                        </div>
                        
                    </div>
                </div>
            </div>

            <h2 class = 'h2 mb-3 mt-5'>Instructor Tagging</h2>
            <div className="row">
                <div className = 'col-12 col-lg-6'>
                    <div className = 'card shadow-sm border'>
                        <div className = 'card-body'>
                            <div className = 'row align-items-center'>
                                <div className="col-auto">
                                    <div className="custom-control custom-switch" onClick = {() => tagTeacher('isBackgroundChecked')}>
                                        <input type="checkbox" className="custom-control-input" readOnly checked={instructor.isBackgroundChecked}/> 
                                        <label className="custom-control-label" ></label>
                                    </div>
                                </div>
                                <div className="col ml-n2">
                                    <h4 className="mb-1">Background Check</h4>
                                    <small>Please check the profile and all the documents submitted thoroughly.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = 'col-12 col-lg-6'>
                    <div className = 'card shadow-sm border'>
                        <div className = 'card-body'>
                            <div className = 'row align-items-center'>
                                <div className="col-auto">
                                    <div className="custom-control custom-switch" onClick = {() => tagTeacher('isStarTeacher')}>
                                        <input type="checkbox" className="custom-control-input" readOnly checked={instructor.isStarTeacher}/> 
                                        <label className="custom-control-label" ></label>
                                    </div>
                                </div>
                                <div className="col ml-n2">
                                    <h4 className="mb-1">Star Teacher</h4>
                                    <small>Please check the profile and all the documents submitted thoroughly.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = 'col-12 col-lg-6'>
                    <div className = 'card shadow-sm border'>
                        <div className = 'card-body'>
                            <div className = 'row align-items-center'>
                                <div className="col-auto">
                                    <div className="custom-control custom-switch" onClick = {() => tagTeacher('isBulkDiscount')}>
                                        <input type="checkbox" className="custom-control-input" readOnly checked={instructor.isBulkDiscount}/> 
                                        <label className="custom-control-label" ></label>
                                    </div>
                                </div>
                                <div className="col ml-n2">
                                    <h4 className="mb-1">Bulk discount available?</h4>
                                    <small>Please check the profile and all the documents submitted thoroughly.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class = ' mt-5'>
                <h2 class = 'h2 mb-3'>Instrument Approvals</h2>
                <div class = 'card shadow-sm border'>
                    <div class = 'list-group list-group-lg'>
                        {courses.map((item, index) => <div class = 'list-group-item'>
                            <div class = 'row align-items-center'>
                                <div class = 'col'>
                                    <h3 class = 'mb-2'>{item.title}</h3>
                                    <h3 class = 'badge badge-primary mb-0'>{item.instrument[0].toUpperCase() + item.instrument.slice(1)}</h3>
                                </div>
                                <div className="col-auto">
                                    <div className="custom-control custom-switch" onClick = {() => approveInstrument(item._id)}>
                                        <input type="checkbox" className="custom-control-input" readOnly checked={item.approve}/> 
                                        <label className="custom-control-label" ></label>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Approvals
