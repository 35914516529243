import { useState, useContext } from 'react';
import axios from '../../../../utilis/axios'
import { Store } from '../../../../store';
import cogoToast from 'cogo-toast'
import { InputControl } from '../../../../components/Forms'

const Security = () => {

  const { state } = useContext(Store)
  const [data, setData] = useState(initData);
  const [errors, setErrors] = useState(Array(3).fill(false))
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const forgotPassword = async () => {
    try {
      setLoading(true);
      await axios.post('/api/auth/forgot-password', { email: state.user.data.email });
      cogoToast.success('The Password reset link with detailed instructions has been sent to your specified email address', { position: 'top-right' })
      setLoading(false);

    } catch (err) {
      console.log(err)
      setLoading(false);
    }
  }

  const updatePassword = async (values) => {

    try {
      setLoading1(true);
      await axios.post('/api/auth/update-password', data);
      setData(initData)
      cogoToast.success('Your password has been successfully updated', { position: 'top-right' })
      setLoading1(false);
    } catch (err) {
      console.log(err)
      setLoading1(false);
    }
  }

  return (
    <>
      <div className="row justify-content-between align-items-center mb-5">
        <div className="col-12 col-md-9 col-xl-7">
          <h2 class="mb-2">Change password</h2>
          <p class="text-muted mb-xl-0">
            We will send you a confirmation email upon changing your password.
          </p>
        </div>
        <div className="col-12 col-xl-auto">
          <button class="btn btn-white border" onClick={() => forgotPassword()}>
            <span className={loading ? `spinner-border spinner-border-sm mr-2` : ''}></span>
            Forgot password?
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 order-md-2">
          <div className="card bg-light border ml-md-4">
            <div className="card-body">
              <p class="mb-2">Password requirements</p>
              <p class="small text-muted mb-2">
                To create a new password, you have to meet all of the following requirements:
              </p>
              <ul class="small text-muted pl-4 mb-0">
                <li>Minimum 8 character</li>
                <li>At least one special character</li>
                <li>At least one number</li>
                <li>Can’t be the same as a previous password</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <form>
            <InputControl
              type='password'
              label='Current Password'
              value={data.currentPassword}
              setValue={(val) => setData(prev => ({ ...prev, currentPassword: val }))}
              error={errors[0]}
              setError={(val) => setErrors(prev => { prev[0] = val; return prev })}
              errorText="This is incorrect password"
            />
            <InputControl
              type='password'
              label='New Password'
              value={data.password}
              setValue={(val) => setData(prev => ({ ...prev, password: val }))}
              error={errors[1]}
              setError={(val) => setErrors(prev => { prev[1] = val; return prev })}
              errorText="This is incorrect password"
            />
            <InputControl
              type='password'
              label='Confirm New Password'
              value={data.password1}
              setValue={(val) => setData(prev => ({ ...prev, password1: val }))}
              error={errors[2]}
              setError={(val) => setErrors(prev => { prev[2] = val; return prev })}
              errorText="This is incorrect password"
            />
            <div className="btn btn-block btn-primary lift" onClick={() => updatePassword()}>
              <span className={loading1 ? `spinner-border spinner-border-sm mr-2` : ''}></span>
              Update password
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Security;




const initData = { currentPassword: '', password: '', password1: '' }