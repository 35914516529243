import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import moment from 'moment'
import {BeatSpinner2} from '../../../../components/Spinners/BeatSpinner'

const Sessions = (props) => {

    const [searchInput, setSearchInput] = useState('');
    const [fetchedData, setFetchedData] = useState();
    const [sessions, setSessions] = useState([]);


    useEffect(() => {
        fetchSessions();
        // eslint-disable-next-line
    }, [])
    
    
    useEffect(() => {
        if(fetchedData) formatData(fetchedData);
        // eslint-disable-next-line
    }, [fetchedData])


    const fetchSessions = async () => {
        try{

            const res = await axios.get('/api/admin/requests');
            setFetchedData(res.data)
            

        } catch(err){
            console.log(err)
        }
    }

    const removeRequest = async (id) => {
        try{
            await axios.delete('/api/admin/cancelRequest/'+id).then(res=>{
                if(res.status===200){

                    fetchSessions();
                }
                
            }).catch(e=>{
                console.error(e);
            });
        }
        catch(e){
            console.log(e)
        }
    }

    const formatData = (data) => {
        const formatData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => ({
            ...item,
            studentName: {value: item.student ? item.student.firstName + ' ' + item.student.lastName : "", label: <NameWithAvatar name = {item.student ? item.student.firstName + ' ' + item.student.lastName : ""} avatar = {item.student && item.student.avatar}/>},
            instructorName: {value: item.instructor.firstName + ' ' + item.instructor.lastName, label: <NameWithAvatar name = {item.instructor.firstName + ' ' + item.instructor.lastName} avatar = {item.instructor.avatar}/>},
            courseName: item.course.instrument,
            date: {value: moment(item.date_time).format('Do MMM YY, hh:mm A'), label: moment(item.date_time).format('Do MMM YY, hh:mm A')},
            // date: {value: item.date_time, label: moment(item.date_time).format('Do MMM YY, hh:mm A')},
            enrollmentId: item.enrollment && item.enrollment.enrollmentId,
            type: {value: item.type, label: <div class = "badge badge-primary">{item.type}</div>},
            // createdAt: {value: item.createdAt, label: moment(item.createdAt).format('Do MMM YY, hh:mm A')},
            createdAt: {value: moment(item.createdAt).format('Do MMM YY, hh:mm A'), label: moment(item.createdAt).format('Do MMM YY, hh:mm A')},
            deleteValue: { value: " ", label: <div class = "btn btn-primary btn-sm" onClick = {() => removeRequest(item._id)}>Remove</div>}
        }))
        setSessions(formatData)
    }

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
        <div class = 'header mt-4'>
            <div class = 'header-body'>
                <div className = 'row align-items-end'>
                    <div className = 'col'>
                        <h3 class = 'h1 mb-0'>Requests</h3>
                    </div>
                    <div class = 'col-auto'>
                    </div>
                </div>
            </div>
        </div>
            {fetchedData ? <Table 
                data = {sessions}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => {}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
                dateFilter = "date"
            /> : <BeatSpinner2 /> }
           
        </AdminDashboard>
    )
}

export default Sessions





const headers = [
    {value: 'bookingId', active: true, label: 'Booking Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'courseName', active: true, label: 'Instrument'},
    {value: 'type', active: true, label: 'Type'},
    {value: 'date', active: true, label: 'Session Date'},
    {value: 'createdAt', active: true, label: 'Created At'},
    {value: 'deleteValue', active: true, label: 'Delete'}
]


const NameWithAvatar = ({name, avatar}) => {
    return (
        <>
        <span class="item-name text-reset">{name}</span>
        </>
    )
}