
import { FETCH_USER } from "../actions/auth";

const authReducer = (state, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        data: action.payload.data,
        isLoggedIn: action.payload.isLoggedIn,
      };
    case 'FETCH_INSTRUCTOR':
      return {
        ...state,
        instructor: action.payload,
      };;
    default:
      return state;
  }
}

export default authReducer;

