import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { AiOutlineClockCircle } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import student_content from '../../../../content/student_content.json'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Pagination from '../../../../components/Dashboard/Pagination'


const Cancelled = ({ selectedDay, scheduled, setScheduled, ...props }) => {

    const [filteredData, setFilteredData] = useState()
    const [totalPages, setTotalPages] = useState();
    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        if (scheduled) {
            const temp = scheduled.filter(item => {
                if (item.type === 'demo' && item.status === 'rescheduled') return false;
                if (item.status === 'cancelled' || (item.status === 'rescheduled' && item.lateCancel)) {
                    if (!selectedDay) return true;
                    else {
                        const day = (moment({ ...selectedDay, month: selectedDay.month - 1 }).format('DD/MM/YYYY'))
                        if (day === moment(item.date_time).format('DD/MM/YYYY')) return true;
                        else return false;
                    }
                } else return false;
            })
                .sort((a, b) => moment(b.date_time) - moment(a.date_time))

            setTotalPages(temp.length % 10 === 0 ? parseInt(temp.length / 10) : parseInt(temp.length / 10) + 1)
            if (temp.length === 0) setPageNum(0)
            else setPageNum(1)
            setFilteredData(temp)
        }
    }, [scheduled, selectedDay])

    return (
        <div>
            <div class='alert alert-light'>{student_content.schedule_view.cancelled_help}</div>
            {filteredData ? (filteredData.length > 0 ? (
                filteredData.length > 10 ? filteredData.slice(pageNum * 10 - 10, pageNum * 10).map(item => <ScheduleItem data={item} {...props} />) : filteredData.map(item => <ScheduleItem data={item} {...props} />)
            ) : <NoSchedule />) : <LoadingSchedule />}
            <Pagination pageNum={pageNum} setPageNum={setPageNum} totalPages={totalPages} />
        </div>
    )
}

export default Cancelled




const ScheduleItem = ({ data }) => {

    const isCancelled = data.status === 'cancelled' ? true : false

    console.log(data, "studetn")


    return (
        <div className='card mb-4'>
            <div className='card-header'>
                <div class='d-flex align-items-center justify-content-between'>
                    <div>
                        <AiOutlineClockCircle class='mr-3' />
                        <strong class='mr-2'>{moment(data.date_time).format('Do MMM YYYY')},</strong>
                        {moment(data.date_time).format('hh:mm A')} - {moment(data.date_time).add(45, 'm').format('hh:mm A')}
                    </div>
                    {(data.cancelled_by === 'student' && data.type !== 'demo') && <div>
                        {data.lateCancel && <div class='badge badge-danger mr-2'>{isCancelled ? 'Cancelled late ' : 'Late rescheduled'}</div>}

                        {(data.lateCharges === undefined && data.lateCancel) && <OverlayTrigger overlay={<Tooltip>Whether this session will be charged or not will be decided by the mentor.</Tooltip>}><div class='badge badge-primary'>Awaiting mentor's response</div></OverlayTrigger>}

                        {data.lateCharges === false && <div class='badge badge-success'>Session not charged</div>}
                        {data.lateCharges === true && <div class='badge badge-danger'>Session charged</div>}
                    </div>}
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='row align-items-center'>
                    <div className='col-auto'>
                        <div className="avatar avatar-md"  >
                            <img src={data.instructor.avatar} alt="..." class="avatar-img rounded-circle" />
                        </div>
                    </div>
                    <div className='col'>
                        <div class="h4 mb-1">{data.instructor.firstName + ' ' + data.instructor.lastName}</div>
                        <div class="text-muted">{data.course.title}</div>
                        <small class="small text-muted">Session ID: {data.bookingId}</small><br />
                        <div class="badge badge-primary my-1">{data.type === 'demo' ? 'Assessment' : makeFirstLetterCap(data.type)} session</div>
                        <div class="badge badge-primary my-1 ml-2">{isCancelled ? "Cancellation made by" : "Rescheduled by"}: {data.cancelled_by === "instructor" ? "Mentor" : "Member"}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}





const LoadingSchedule = () => {
    return (
        <div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
        </div>
    )
}


const NoSchedule = () => {
    return (
        <div className='card mb-4'>
            <div className='card-body d-flex align-items-center justify-content-center'>
                <div className='h3 text-uppercase text-muted mb-0 py-5'>
                    {student_content.schedule_view.no_cancelled_classes}
                </div>
            </div>
        </div>
    )
}
const makeFirstLetterCap = (text) => text ? text[0].toUpperCase() + text.slice(1) : ''