import React, {useState, useEffect, useContext} from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import {Calendar} from '@hassanmojab/react-modern-calendar-datepicker';
import { Store } from '../../../../store';
import { saveSchedule } from '../../../../store/actions/student';
import axios from '../../../../utilis/axios'
import moment from 'moment'
import {Link} from 'react-router-dom'


const ScheduleGlance = () => {

    const {state, dispatch} = useContext(Store);
    const [scheduled, setScheduled] = useState();
    const [selectedDay, setSelectedDay] = useState({day: moment().date(), month: moment().month() + 1, year: moment().year()});
    const [numClasses, setNumClasses] = useState()


    useEffect(() => {
        if(!state.student.schedule || state.student.schedule.length === 0 ) fetchSchedule()
        else {
            setScheduled(state.student.schedule.filter(item => item.status === 'scheduled'))
            setNumClasses(state.student.schedule.filter(item => {
                if(item.status !== 'scheduled') return false;
                const a = moment(item.date_time)
                const b = moment()
                const diff = a.diff(b, 'days')
                if(diff < 7 && diff >= 0) return true;
                else return false;
            }).length)

        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(state.student.schedule) {
            setScheduled(state.student.schedule.filter(item => item.status === 'scheduled'))
        }
    }, [state.student.schedule])
    

    const fetchSchedule = async () => {
        try{
            
            const res = await axios.get('/api/student/schedule');
            // console.log(res.data)
            setScheduled(res.data.filter(item => item.status === 'scheduled'))
            saveSchedule(res.data, dispatch)
            setNumClasses(res.data.filter(item => {
                if(item.status !== 'scheduled') return false;
                const a = moment(item.date_time)
                const b = moment()
                const diff = a.diff(b, 'days')
                if(diff < 7 && diff >= 0) return true;
                else return false;
            }).length)

        } catch(err){
            console.log(err)
        }   
    }


    return (
        <div className = ''>
            <h3 className = "h2 mb-3">View Calendar</h3>
            <div className = 'card shadow-sm border' >
                <div className = 'card-body'>
                    <div className = 'row'>
                        <div className = 'col-auto'>
                            <Calendar
                                value={selectedDay}
                                onChange={setSelectedDay}
                                shouldHighlightWeekends
                                minimumDate={{day: moment().date(), month: moment().month() + 1, year: moment().year()}}
                            />
                        </div>
                        <div className = 'col '>
                            <div className = "justify-content-between align-items-center mt-2 d-none d-lg-flex">
                                <h4 className = 'h5 mt-3 mt-lg-0 mb-0 text-muted'>{numClasses > 0 ?  `You have ${numClasses} sessions this week`:'You have no scheduled sessions this week'}</h4>
                                <Link to = '/my-dashboard/schedule' class = "btn-link small pointer ">View schedule</Link>
                            </div>
                            <div className = "mt-4 ">
                                <div class = {`d-flex align-items-center justify-content-between pointer`}>
                                    <div className = "h4 mb-0">{moment({date: selectedDay.day, month: selectedDay.month-1, year: selectedDay.year}).format('ddd, Do MMM')}</div>
                                    
                                </div>
                                
                                <div style = {{minHeight: '26vh', maxHeight: '27vh', overflow: 'scroll'}} className = 'mt-3 mb-3 fadeout-container'>
                                    {scheduled && scheduled
                                        .filter(item => {
                                            if(moment(item.date_time).diff(moment(), 'hours') < 0) return false;
                                            const a = moment(item.date_time).format('DD-MM-YYYY')
                                            const b = moment({date: selectedDay.day, month: selectedDay.month-1, year: selectedDay.year}).format('DD-MM-YYYY')
                                            return a===b
                                        })
                                        .sort((a, b) => new Date(a.date_time) - new Date(b.date_time))
                                        .length > 0 ? 
                                            scheduled
                                                .filter(item => {
                                                    const a = moment(item.date_time).format('DD-MM-YYYY')
                                                    const b = moment({date: selectedDay.day, month: selectedDay.month-1, year: selectedDay.year}).format('DD-MM-YYYY')
                                                    return a===b
                                                })
                                                .sort((a, b) => new Date(a.date_time) - new Date(b.date_time))
                                                .map(item => <TimeCard data = {item}/>): 
                                                <NoSchedule />
                                    }
                                    <div className="fadeout"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ScheduleGlance




const TimeCard = ({data}) => {

    const joinNow = async () => {
        try{
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = data.zoom.join_url;
            a.target = "_blank"
            a.click();
            document.body.removeChild(a);
            await axios.get(`/api/student/schedule/clicked_join/${data._id}`)
        } catch(err){
            console.log(err)
        }
    }


    return (
        <div className = "row bg-primary-soft rounded shadow-md py-3 mx-1 align-items-center my-2">
            <div className = 'col'>
                <div class = "bold">{moment(data.date_time).format('hh:mm A')}</div>
                <div class = "small">{data.course.title}, {data.instructor.firstName + ' ' + data.instructor.lastName}</div>
            </div>
            {moment(data.date_time).diff(moment(), 'minutes') < parseInt(process.env.REACT_APP_JOIN_NOW)  &&  <div className = "col-auto">
                <div className = "btn btn-primary btn-sm" onClick = {() => joinNow()}>Join</div>
            </div>}
        </div>
    )
}



const NoSchedule = () => {
    return (
        <div className = "row bg-primary-soft rounded shadow-md py-3 mx-1 align-items-center my-2">
            <div className = 'col p-3 '>
                <div class = "h4 mb-0 d-flex align-items-center justify-content-center">No sessions on this date</div>
            </div>
        </div>
    )
}


