import React, {useState, useContext, useEffect} from 'react'
import moment from 'moment'
import {DateFormInput1} from '../../../../../components/Forms'
import Select from 'react-select'
import {Modal} from 'react-bootstrap'
import axios from '../../../../../utilis/axios'
import { Store } from '../../../../../store'
import { saveRequests, saveSchedule } from '../../../../../store/actions/student'
import cogoToast from 'cogo-toast'
import instructor_content from '../../../../../content/instructor_content.json'

const Reschedule = ({show, setShow, data}) => {

    const {state, dispatch} = useContext(Store)
    const [schedule, setSchedule] = useState({date: '', time: ''})
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([false, false])
    const [availability, setAvailability] = useState({});

    useEffect(() => {
        setErrors([false, false])
    }, [schedule])


    const fetchAvailability = async (date1) => {
        try{
            if(availability[date1] === undefined){
                setSchedule(prev => ({...prev, time: {value: null, label: 'Loading...'}}))
                const res = await axios.post(`/api/instructor/schedule/instructor_availability/`, {date: date1})
                const newAvailability = {...availability};
                newAvailability[date1] = getAvailability(res.data);
                setAvailability(newAvailability)
                setSchedule(prev => ({...prev, time: {value: null, label: 'Please select a time slot'}}))
            }

        } catch(err){
            console.log(err)
        }
    }
   
    const finalStep = () => {
        const err = [false, false];
        if(schedule.date === '') err[0] = true;
        if(!schedule.time || !schedule.time.value || schedule.time.value === '') err[1] = true;
        if(err.includes(true)) {
            setErrors(err);
            return;
        } else{
            scheduleSession();
        }
    }

    const scheduleSession = async () => {
        try{
            setLoading(true)
            const scheduleISODate = moment(schedule.date + "-" + schedule.time.label.slice(0, 8), "DD/MM/YYYY-hh:mm A")
            const res = await axios.post(`/api/instructor/schedule/demo/reschedule/${data._id}`, {new_date: scheduleISODate})
            if(res.data === 'NO_ZOOM_ACCOUNT_AVAILABLE'){
                cogoToast.error(instructor_content.schedule.no_zoom_account)
                setLoading(false);
                return;
            }
            if(res.data === 'SLOT_NOT_AVAILABLE'){
                cogoToast.error(instructor_content.schedule.no_slot_available)
                setLoading(false)
                return;
            }
            const newSchedule = [...state.student.schedule]
            const newRequests = [...state.student.requests]
            
            const index = newRequests.findIndex(item => item._id === data._id)
            newRequests.splice(index, 1)
            
            newSchedule.push(res.data)

            saveRequests(newRequests, dispatch)
            saveSchedule(newSchedule, dispatch)
            setShow(false);
            setLoading(false)
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered>
            <div className = 'modal-content' >
                <div className = 'modal-body py-5'>
                    <div class = 'mb-4 text-center px-lg-5'>
                        <div className = 'h2 mb-1'>{instructor_content.schedule.reschedule_demo.title}</div>
                        <div className = 'h8-unbold mb-1'>{instructor_content.schedule.reschedule_demo.description}</div>
                    </div>
                    <form class = 'px-lg-4'>  
                        <DateFormInput1 
                            placeholder = "Select a date"
                            error = {errors[0]}
                            setError = {(val) => setErrors(prev => {prev[0]=val; return prev})}
                            errorMessage = {"Please enter a valid date"}
                            label = "Date"
                            setValue = {(val) => {
                                setSchedule(prev => ({...prev, date: val}));
                                fetchAvailability(val)
                            }}
                            value = {schedule.date}
                            minimumDate = {moment().add(1,'d')}
                        />
                        <div class = "form-group my-4">
                            <label>Time Slot</label>
                            <Select 
                                value = {schedule.date ? schedule.time : {value: '', label: 'Please select a date'}}
                                options = {availability[schedule.date] ? availability[schedule.date] : [{value: '', label: '..'}]}
                                onChange = {e => setSchedule(prev => ({...prev, time: e}))}
                                isDisabled = {(schedule.date && availability[schedule.date]) ? false : true}
                            />
                            {errors[1] && <div class = 'invalid-feedback d-block'>Please choose a slot</div>}
                        </div>
                        <div class = "btn btn-primary btn-block mt-5" onClick = {() => finalStep()}>
                            {loading && <span class = 'spinner-border spinner-border-sm mr-1'></span>}
                            Schedule the demo
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default Reschedule






export const getAvailability = (array) => {
    let temp = []
    let start1 = 0
    for(let i=0; i<array.length; i++){
        if(array[i]){
            temp.push({value:start1, label: timeFormat(start1) + ' - ' + timeFormat(start1, true)})
            start1++
        } else{
            start1++
        }
    }
    return temp
}



const timeFormat = (time, isSecond) => {
    if(time < 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " AM"
    } else if(time === 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " PM"
    } else if(time > 12){
        return time1(time-12) + ":" + (isSecond ? "45":"00") + " PM"
    }
}

const time1 = (time) => time >= 10 ? time : "0"+time