import React from 'react';
import {ValueProps1, HowItWorks} from './ValueProps'
import { Link } from 'react-router-dom';
import Nav from '../Nav'
import Footer from '../Footer'
import Instructors from './Instructors'
import Video from './Video'
import FreeSession from './FreeSession'
import content from '../../../content/landingPage.json'
import AnchorLink from 'react-anchor-link-smooth-scroll';


const Home = (props) => {
    return (
        <div>
            <Nav />
            <div className = '' style = {{minHeight: '200vh'}}>
                <Hero2/>
                <ValueProps1/>
                <Video />
                <Instructors />
                <HowItWorks />
                <FreeSession {...props}/>
                <Footer />
            </div>
        </div>
    )
}

export default Home


// const Hero = () => {
//     return (
//         <section class="py-0 " style = {{overflow: 'none'}}>
//             <div className= 'container d-flex py-6  align-items-center flex-column justify-content-between'>
//                 <div className="row align-items-center justify-content-start px-lg-3 h-100 w-100" style ={{minHeight:'60vh'}}>
//                     <div className="col-sm-12 col-md-6 col-xl order-md-1 order-2 mr-xl-auto ">
//                         <h1 class="display-2 mb-3 text-dark d-none d-lg-block">{content.home.hero.title} </h1>
//                         <h1 class="display-4 mb-3 text-dark d-block d-lg-none">{content.home.hero.title} </h1>
//                         <h1 class="h1 py-3 mb-1 mb-md-3 text-dark">{content.home.hero.description}</h1>
//                         <div className = 'd-flex align-items-center'>
//                             <Link to = "/instructors" class="btn  btn-primary btn-lg btn-hover-shadow mb-3 px-md-4 px-4 mr-sm-2 mr-1 ">{content.home.hero.cta1}</Link>
//                             <a href = "/#free-session" class="btn  btn-outline-primary btn-lg btn-hover-shadow mb-3 px-md-4 px-4 mr-sm-2 mr-1 ">{content.home.hero.cta2}</a>
//                         </div>
//                     </div>
//                     <div className="col-sm-10 col-md-6 col-xl-auto order-md-2 order-1 mx-md-0 mx-auto mb-md-0 mb-5 d-none d-lg-block">
//                         <img src={content.home.hero.img} alt="Illustration"  className = 'img-fluid rounded-circle' style = {{width: '70vh', height: '70vh', objectFit: 'cover'}}/>
//                     </div>
//                     <div className="col-sm-10 col-md-6 col-xl-auto order-md-2 order-1 mx-md-0 mx-auto mb-md-0 mb-5  d-lg-none d-flex align-itemss-center justify-content-center mt-n5">
//                         <img src={content.home.hero.img} alt="Illustration"  className = 'img-fluid rounded-circle' style = {{width: '45vh', height: '45vh', objectFit: 'cover'}}/>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     )
// }


const Hero2 = () => {


    return (
        <section class="py-6 d-flex align-items-center" style={{height: '92vh', backgroundImage: `url(${content.home.hero.img})`, backgroundSize: 'cover', backdropFilter: 'blur(5px)', backgroundPosition: 'bottom', boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0,.3)'}}>
          <div className="container my-6  text-white px-lg-6">
            <div className="row align-items-center justify-content-start px-lg-3 h-100 w-100" style ={{minHeight:'60vh'}}>
                    <div className="col-sm-12 col-md-6 col-xl order-md-1 order-2 mr-xl-auto ">
                        <h1 class="display-2 mb-3  d-none d-lg-block">{content.home.hero.title} </h1>
                        <h1 class="display-4 mb-3  d-block d-lg-none">{content.home.hero.title} </h1>
                        <h1 class="h1 py-3 mb-1 mb-md-3 ">{content.home.hero.description}</h1>
                        <div className = 'd-flex align-items-center'>
                            <Link to = "/instructors" class="btn  btn-primary btn-lg btn-hover-shadow mb-3 px-md-4 px-4 mr-sm-2 mr-1 ">{content.home.hero.cta1}</Link>
                            <AnchorLink href = "#free-session" class="btn  btn-white btn-lg btn-hover-shadow mb-3 px-md-4 px-4 mr-sm-2 mr-1 ">{content.home.hero.cta2}</AnchorLink>
                        </div>
                    </div>
                </div>
          </div>
      </section>
    )
}





  
  

