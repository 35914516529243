import React, {useState, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import axios from '../../../../../utilis/axios';
import { Store } from '../../../../../store'
import { saveRequests } from '../../../../../store/actions/student'
import student_content from '../../../../../content/student_content.json'


const Cancel = ({show, setShow, data}) => {

    const {state, dispatch} = useContext(Store)
    const [loading, setLoading] = useState(false);

    const cancelRequest = async (id) => {
        try{
            setLoading(true)
            await axios.post(`/api/student/schedule/request/cancel/${data._id}`)
            const newRequests = [...state.student.requests];
            const index = newRequests.findIndex(item => item._id === data._id)
            newRequests.splice(index, 1)
            saveRequests(newRequests, dispatch)
            setLoading(false)
            setShow(false)
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered>
            <div className = 'modal-content' >
                <div className = 'modal-body '>
                <div class = 'close pointer' onClick = {() => setShow(false)}>&times;</div>
                    <div class = 'mb-4 text-center px-lg-5 pt-4'>
                        <div className = 'h2 mb-1'>{student_content.schdule_modals.cancel_request.title}</div>
                        <div className = 'h8-unbold mb-1'>
                            {student_content.schdule_modals.cancel_request.description}
                        </div>
                    </div>
                    <form class = 'px-lg-4'>  
                        <div class = "btn btn-primary btn-block mb-4" onClick = {() => cancelRequest()}>
                            {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                            Cancel Session Request
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default Cancel


