import {useEffect, useContext} from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import {Store} from '../../store'

import Home from '../../pages/LandingPage/Home'
import FAQs from '../../pages/LandingPage/FAQs'
import About from '../../pages/LandingPage/About'
import Contact from '../../pages/LandingPage/Contact'
import HowItWorks from '../../pages/LandingPage/HowItWorks'
import Lesson from '../../pages/LandingPage/Lesson'
import Instructors from '../../pages/LandingPage/Explore/'
import TeachWithUs from '../../pages/LandingPage/TeachWithUs'
import BookClass from '../../pages/LandingPage/BookClass';
import FreeSession from '../../pages/LandingPage/FreeSession';
import RefundPolicy from '../../pages/LandingPage/Privacy/RefundPolicy';
 
import {getUser} from '../../services/auth'
import {fetchUser, loginFailure} from '../../store/actions/auth'
import Privacy from '../../pages/LandingPage/Privacy/Legal.js';
import PrivacyMl from '../../pages/LandingPage/Privacy/LegalML.js'
import PrivacyMt from '../../pages/LandingPage/Privacy/LegalMT.js'

const LandingPageRoutes = () => {

    const {state, dispatch } = useContext(Store);
 

    useEffect(() => {
        fetching()
        // eslint-disable-next-line
    }, [])

    const fetching = async () => {
        if(state.user.isLoggedIn === null){
            try{
                const user = await getUser();
                fetchUser(user, dispatch)
            } catch(err){
                loginFailure(dispatch)
            }
        } 
    }


    return (
        <>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/about" component={About} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/faqs" component={FAQs} />
                <Route exact path="/instructors" component={Instructors} />
                <Route exact path="/lesson/:id" component={Lesson} />
                <Route exact path="/how-it-works" component={HowItWorks} />
                <Route exact path="/legal" component={Privacy} />
                <Route exact path="/legal/ml" component={PrivacyMl} />
                <Route exact path="/legal/mt" component={PrivacyMt} />
                <Route exact path="/refund-policy" component={RefundPolicy} />
                <Route exact path="/become-a-guru" component={TeachWithUs} />
                <Route exact path="/book-package/:id" component={BookClass} />
                <Route exact path="/book-free-session/:id" component={FreeSession} />
                <Redirect from = "/" to = "" />
            </Switch>
        </>
    );
}

export default LandingPageRoutes;


