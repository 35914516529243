import React from 'react'

const Documents = ({data}) => {

    const openFile = (name) => {
        if(data.documents[name].url){
            window.open(data.documents[name].url, '_blank');
        }
    }

    return (
        <>
        <div class = 'row'>
            <div class = 'col-4'>
                <div class = 'card shadow-sm border'>
                    <div class = 'card-body pointer text-center h3 mb-0 py-6' onClick = {() => openFile('aadhaar')}>
                        Identity Card (Front Side) <small>({(data.documents && data.documents.aadhaar && data.documents.aadhaar.url) ? 'Uploaded' : 'Not Uploaded'})</small>
                    </div>
                </div>
            </div>
            <div class = 'col-4'>
                <div class = 'card shadow-sm border'>
                    <div class = 'card-body pointer text-center h3 mb-0 py-6' onClick = {() => openFile('cancelledCheque')}>
                    Identity Card (Back Side) <small>({(data.documents && data.documents.cancelledCheque && data.documents.cancelledCheque.url) ? 'Uploaded' : 'Not Uploaded'})</small>
                    </div>
                </div>
            </div>
            <div class = 'col-4'>
                <div class = 'card shadow-sm border'>
                    <div class = 'card-body pointer text-center h3 mb-0 py-6' onClick = {() => openFile('education')}>
                        Education Degree <small>({(data.documents && data.documents.education && data.documents.education.url) ? 'Uploaded' : 'Not Uploaded'})</small>
                    </div>
                </div>
            </div>
            <div class = 'col-4'>
                <div class = 'card shadow-sm border'>
                    <div class = 'card-body pointer text-center h3 mb-0 py-6' onClick = {() => openFile('panCard')}>
                        Pan Card <small>({(data.documents && data.documents.panCard && data.documents.panCard.url) ? 'Uploaded' : 'Not Uploaded'})</small>
                    </div>
                </div>
            </div>
            
        </div>

        </>
    )
}

export default Documents

