import React, { useState, useContext, useEffect } from 'react'
import { Store } from '../../../../../store'
import axios from '../../../../../utilis/axios';
import cogoToast from 'cogo-toast'
import { saveCourses } from '../../../../../store/actions/instructor'
import instructor_content from '../../../../../content/instructor_content.json'
import MyDropzone from '../../../../../components/Dropzone/MyDropzone'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AiFillDelete } from 'react-icons/ai'

const Resources = ({ course, setCourse, courseId }) => {

    const { state, dispatch } = useContext(Store);
    const [show, setShow] = useState(false);
    const [newResource, setNewResource] = useState({ title: '', description: '', file: [] })
    const [errors, setErrors] = useState(Array(3).fill(false))
    const [loading, setLoading] = useState('');

    useEffect(() => {
        setErrors(Array(3).fill(false))
    }, [newResource])

    const validate = () => {
        const new_errors = Array(3).fill(false)
        if (newResource.title === '') new_errors[0] = true;
        if (newResource.description === '') new_errors[1] = true;
        if (newResource.file.length !== 1) new_errors[2] = true;
        setErrors(new_errors)
        if (new_errors.includes(true)) return false;
        else return true;
    }

    const uploadResources = async e => {
        e.preventDefault()
        if (!validate()) return;
        try {

            setLoading(true);
            const formData = new FormData()
            formData.append('title', newResource.title)
            formData.append('description', newResource.description)
            formData.append('file', newResource.file[0])

            const res = await axios.post(`/api/instructor/course/upload/resource/${courseId}`, formData);

            const new_course = { ...course };
            new_course.resources.push({ ...newResource, url: res.data.url, _id: res.data._id })
            setCourse(new_course);

            cogoToast.success(instructor_content.course.resources.success)

            if (state.instructor.courses.length > 0) {
                const allCourses = state.instructor.courses;
                const index = allCourses.findIndex(item => item._id === courseId);
                if (index > -1) {
                    allCourses[index] = {
                        ...allCourses[index],
                        resources: new_course.resources,
                    }
                }
                saveCourses(allCourses, dispatch)
            }
            setShow(false)
            setLoading(false)

        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }



    const deleteResource = async (resource_id) => {
        try {

            const new_course = { ...course };
            const index = new_course.resources.findIndex(item => item._id === resource_id);

            if (state.instructor.courses.length > 0) {
                const allCourses = state.instructor.courses;
                const index1 = allCourses.findIndex(item => item._id === courseId);
                if (index1 > -1) allCourses[index1].resources.splice(index, 1);
                saveCourses(allCourses, dispatch)
            } else {
                new_course.resources.splice(index, 1);
                setCourse(new_course);
            }

            await axios.delete(`/api/instructor/course/resource/delete/${courseId}/${resource_id}`)
            cogoToast.success(instructor_content.course.resources.successDelete)

        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div class="">
            <div class='alert alert-light py-4 shadow-sm'>
                <div class="row align-items-center">
                    <div class='col'>
                        <h2 class="mb-2">{instructor_content.course.resources.title}</h2>
                        <p class='small mb-0'>{instructor_content.course.resources.description} </p>
                    </div>
                    <div class='col-auto'>
                        <div class="btn btn-sm btn-primary" onClick={() => setShow(true)}>{instructor_content.course.resources.cta}</div>
                    </div>
                </div>
            </div>
            {course.resources.length > 0 && <ul class="list-group list-group-active shadow-sm bg-white rounded mt-0">
                {course.resources.map(item => <ResourceItem data={item} deleteResource={deleteResource} />)}
            </ul>}
            {course.resources.length === 0 && <NoResource />}
            <NewResource
                show={show}
                setShow={setShow}
                newResource={newResource}
                setNewResource={setNewResource}
                loading={loading}
                uploadResources={uploadResources}
                errors={errors}
            />
        </div>
    )
}

export default Resources





const ResourceItem = ({ data, deleteResource }) => {

    return (
        <div className="list-group-item py-4">
            <div className="row align-items-center">
                <div className="col-12 col-md ml-n2 mb-4 mb-md-0 d-flex align-items-center">
                    <div class='ml-3'>
                        <h4 class="text-body text-focus mb-2 name">{data.title}</h4>
                        <p class=" text-muted mb-2">{data.description}</p>
                        <a href={data.url} target="_blank" rel="noreferrer" class="small">click here</a>
                    </div>
                </div>

                <div className='col-auto col-md-auto text-center'>
                    <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>}>
                        <div class="h3 mb-0 pointer" onClick={() => deleteResource(data._id)}>
                            <AiFillDelete />
                        </div>
                    </OverlayTrigger>
                </div>

            </div>
        </div>
    )
}



const NewResource = ({ show, setShow, newResource, setNewResource, loading, uploadResources, errors }) => {

    const content = instructor_content.course.resources.upload

    useEffect(() => {
        if (show) setNewResource({ title: '', description: '', file: [] })
        // eslint-disable-next-line
    }, [show])

    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <div className='modal-content' >
                <div className='modal-body py-5'>
                    <div class='close pointer' onClick={() => setShow(false)}>&times;</div>
                    <h1>{content.title}</h1>
                    <form>
                        <div class='form-group'>
                            <label>{content.resource_title.label}</label>
                            <input
                                class={errors[0] ? 'form-control is-invalid' : 'form-control'}
                                // placeholder={content.resource_title.placeholder}
                                value={newResource.title}
                                onChange={e => setNewResource(prev => ({ ...prev, title: e.target.value }))}
                            />
                            <div class="invalid-feedback">{content.resource_title.error}</div>
                        </div>
                        <div class='form-group'>
                            <label>{content.resource_description.label}</label>
                            <input
                                class={errors[1] ? 'form-control is-invalid' : 'form-control'}
                                // placeholder={content.resource_description.placeholder}
                                value={newResource.description}
                                onChange={e => setNewResource(prev => ({ ...prev, description: e.target.value }))}
                            />
                            <div class="invalid-feedback">{content.resource_description.error}</div>
                        </div>
                        <div class='form-group'>
                            <label>{content.resource_file.label}</label>
                            <MyDropzone
                                files={newResource.file}
                                onFileChange={(data) => setNewResource(prev => ({ ...prev, file: data }))}
                                height='100px'
                                isMulti={false}
                                type='.pdf'
                            />
                            {errors[2] && <div class="invalid-feedback d-block">{content.resource_file.error}</div>}
                        </div>
                        <button disabled={loading} class="btn btn-primary" onClick={e => uploadResources(e)}>
                            {loading && <span class='spinner-border spinner-border-sm mr-2'></span>}
                            {content.cta}
                        </button>
                    </form>
                </div>
            </div>
        </Modal>
    )
}


const NoResource = () => {
    return (
        <div className='card mb-4'>
            <div className='card-body d-flex align-items-center justify-content-center'>
                <div className='h3 text-uppercase text-muted mb-0 py-5'>
                    {instructor_content.course.resources.no_resource}
                </div>
            </div>
        </div>
    )
}