import React, {useEffect, useState} from 'react'
import instructor_content from '../../../../../content/instructor_content.json'


const Pricing = ({course, setCourse, saveCourse, loading}) => {

    const [errors, setErrors] = useState(Array(2).fill(false))

    const validate = () => {
        const new_errors = Array(2).fill(false)
        if(course.price_per_class === '') new_errors[0] = true;
        for(let i=0; i<4; i++){
            if(!course.discount[i].num_classes) new_errors[1] = true;
            if(!course.discount[i].discount) new_errors[1] = true;
            if(course.discount[i].num_classes < 1) new_errors[1] = true;
            if(course.discount[i].discount < 0) new_errors[1] = true;
            if(course.discount[i].discount > 90) new_errors[1] = true;
        }
        const temp = course.discount.map(item => item.num_classes);
        if(temp.filter(onlyUnique).length !== temp.length) new_errors[1] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true;
    }

    const save = async e => {
        e.preventDefault()
        try{
            if(!validate()) return;
            saveCourse()
        } catch(err){
            console.log(err)
        }
    }


    return (
        <div>

            <div class = 'mb-6 '>
                <PricingPerClass course = {course} setCourse = {setCourse} errors = {errors} setErrors = {setErrors} errText = "Please enter your pricing per class"/>
                <Batches course = {course} setCourse = {setCourse} errors = {errors} setErrors = {setErrors} errText = "Please fill all the inputs"/>
            </div>


            <div className="row align-items-center mb-5">
                <div className="col-4">
                    <button class="btn btn-block btn-lg btn-primary mb-6" disabled = {loading} onClick = {(e) => save(e)}>
                        {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </button>
                </div>
                <div className="col-auto">
                </div>
            </div>
        </div>
    )
}

export default Pricing





const PricingPerClass = ({course, setCourse, errors, setErrors, errText}) => {

    return (
        <div class = "mb-5 pb-2">
        <div className = 'form-group'>
            <label class = "bold mb-1">{instructor_content.course.pricing.price.title}</label>
            {/* <div class = 'text-muted small mb-3'>{instructor_content.course.pricing.price.description}</div> */}
            <div class = 'text-muted small mb-3'>
                These charges would be visible to the student upfront, which includes Dhunguru’s {course && course.instructor_profile && course.instructor_profile.commission ? course.instructor_profile.commission : 20}% commission
            </div>
            <InputItem icon = "₹" input = {course.price_per_class} setValue = {value => setCourse(prev => ({...prev, price_per_class: value}))}/>
            {errors[0] && <div class = "invalid-feedback d-block ">{errText}</div>}

            <div class = "text-dark mt-3">Teachers earnings post Dhunguru's commission, <strong>Rs {course && course.instructor_profile && course.instructor_profile.commission ? course.price_per_class*((100 - Number(course.instructor_profile.commission))/100) : course.price_per_class*0.8}</strong></div>
        </div>
        </div>
    )
}


const Batches = ({course, setCourse, errors, setErrors, errText}) => {

    const editDiscount = (value, index, key) => {
        const new_course = {...course}
        course.discount[index][key] = value;
        setCourse(new_course)
        const newErr = [...errors];
        newErr[0] = false;
        setErrors(newErr)
    }

    return (
        <div class = "mb-5 pb-2">
        <div className = 'form-group'>
            <label class = "bold mb-1">{instructor_content.course.pricing.batches.title}</label>
            <div class = 'text-muted small mb-3'>{instructor_content.course.pricing.batches.description}</div>
            <div className = 'row align-items-center mb-2'>
                <div className = 'col-5 col-lg-3 bold small ml-5'>
                    Number of Sessions
                </div>
                <div className = 'col-5 col-lg-3 bold small'>
                    Discount
                </div>
            </div>
            <div className = 'row align-items-center mb-3'>
                <div className = 'col-auto bold'>1.</div>
                <div className = 'col-5 col-lg-3'>
                <input class = 'form-control disabled-input' disabled = {true} type = "number" value = {course.discount[0].num_classes} />
                </div>
                <div className = 'col-5 col-lg-3'>
                    <InputItemPost icon = "%" input = {course.discount[0].discount} setValue = {value => editDiscount(value, 0, 'discount')}/>
                </div>
            </div>
            <div className = 'row align-items-center mb-3'>
                <div className = 'col-auto bold'>2.</div>
                <div className = 'col-5 col-lg-3'>
                    <input class = 'form-control' type = "number" value = {course.discount[1].num_classes} onChange = {e => editDiscount(e.target.value, 1, 'num_classes')}/>
                </div>
                <div className = 'col-5 col-lg-3'>
                    <InputItemPost icon = "%" input = {course.discount[1].discount} setValue = {value => editDiscount(value, 1, 'discount')}/>
                </div>
            </div>
            <div className = 'row align-items-center mb-3'>
                <div className = 'col-auto bold'>3.</div>
                <div className = 'col-5 col-lg-3'>
                    <input class = 'form-control' type = "number" value = {course.discount[2].num_classes} onChange = {e => editDiscount(e.target.value, 2, 'num_classes')}/>
                </div>
                <div className = 'col-5 col-lg-3'>
                    <InputItemPost icon = "%" input = {course.discount[2].discount} setValue = {value => editDiscount(value, 2, 'discount')}/>
                </div>
            </div>
            <div className = 'row align-items-center mb-3'>
                <div className = 'col-auto bold'>4.</div>
                <div className = 'col-5 col-lg-3'>
                    <input class = 'form-control' type = "number" value = {course.discount[3].num_classes} onChange = {e => editDiscount(e.target.value, 3, 'num_classes')}/>
                </div>
                <div className = 'col-5 col-lg-3'>
                    <InputItemPost icon = "%" input = {course.discount[3].discount} setValue = {value => editDiscount(value, 3, 'discount')}/>
                </div>
            </div>
            {errors[1] && <div class = "invalid-feedback d-block ">{errText}</div>}
        </div>
        </div>
    )
}



const InputItem = ({icon, input, setValue}) => {
    return (
        <div className="input-group input-group-merge">
            <input type="number" class="form-control form-control-prepended" placeholder=""  value = {input} onChange = {e => setValue(e.target.value)}/>
            <div className="input-group-prepend">
                <div className="input-group-text text-muted" style = {{height: '41px'}}>
                    {icon}
                </div>
            </div>
        </div>
    )
}


const InputItemPost = ({icon, input, setValue}) => {
    return (
        <div className="input-group input-group-merge">
            <input type="number" class="form-control form-control-appended" placeholder="" value = {input} onChange = {e => setValue(e.target.value)}/>
            <div className="input-group-append">
                <div className="input-group-text text-muted" style = {{height: '41px'}}>
                {icon}
                </div>
            </div>
        </div>
    )
}


function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}