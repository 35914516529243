import axios from 'axios'

const submitResponse =  async (state) => {
        try{
            const scriptURL = "https://script.google.com/macros/s/AKfycbwbw7WCakYZGpymhQBDc8JcbYWoWTOJPvfDH1cpyJPKMuT7dC0H/exec";
            const formData = new FormData()
            formData.append('type', state.type)
            formData.append('name', state.name)
            formData.append('email', state.email)
            formData.append('phone', state.phone)
            formData.append('message', state.message)
            formData.append('instrument', state.instrument)
            formData.append('message', state.message)
            formData.append('subject', state.subject)
            formData.append('city', state.city)
            formData.append('contactType', state.contactType)
            await axios.post(scriptURL, formData)
            return;
        } catch(err){
            throw err;
        }
}



export default submitResponse