import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {AiOutlineClockCircle, AiFillSetting} from 'react-icons/ai'
import CancelRequest from './Modals/CancelRequest'
import Skeleton from 'react-loading-skeleton'
import student_content from '../../../../content/student_content.json'
import Pagination from '../../../../components/Dashboard/Pagination'

const Requests = ({selectedDay, requests, setRequests, ...props}) => {

    const [filteredData, setFilteredData] = useState()
    const [totalPages, setTotalPages] = useState();
    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        if(requests){
            let temp = requests.filter(item => {
                if(!selectedDay) return true;
                else {
                    const day = (moment({...selectedDay, month: selectedDay.month - 1}).format('DD/MM/YYYY'))
                    if(day === moment(item.date_time).format('DD/MM/YYYY')) return true;
                    else return false;
                }
            }).sort((a, b) => moment(a.date_time) - moment(b.date_time))
            const deleted = temp.filter(data => data.deletedBy)
            const notDeleted = temp.filter(data => !data.deletedBy)

            temp = [...notDeleted, ...deleted]
            setTotalPages(temp.length%10 === 0 ? parseInt(temp.length/10) : parseInt(temp.length/10)+1)
            if(temp.length === 0) setPageNum(0)
            else setPageNum(1)
            setFilteredData(temp)
        }
    }, [requests, selectedDay])

    return (
        <div>
            <div class = 'alert alert-light'>{student_content.schedule_view.request_help}</div>
            {filteredData ? (filteredData.length > 0 ? (
                filteredData.length > 10 ? filteredData.slice(pageNum*10-10, pageNum*10).map(item => <RequestItem data = {item} {...props}/>) : filteredData.map(item => <RequestItem data = {item} {...props}/>)
            ): <NoRequests />) : <LoadingRequests />}
            <Pagination pageNum = {pageNum} setPageNum = {setPageNum} totalPages = {totalPages}/>
        </div>
    )
}

export default Requests




const RequestItem = ({data}) => {

    const [showCancel, setShowCancel] = useState(false);
    

    return (
        <div className = 'card mb-4'>
            <div className = 'card-header'>
                <div class = 'd-flex align-items-center'>
                    <AiOutlineClockCircle class = 'mr-3'/> 
                    <strong class = 'mr-2'>{moment(data.date_time).format('Do MMM YYYY')},</strong>
                    {moment(data.date_time).format('hh:mm A')} - {moment(data.date_time).add(45, 'm').format('hh:mm A')}
                </div>
                {!data.deletedBy && 
                    <div className = "dropdown">
                        <div class = 'navbar-user-link show-notif'>
                        <AiFillSetting class = ' mb-0 h3 '/>
                        <ul class="dropdown-menu show-notif-hover" style = {{right: '0%', left: 'auto'}}>
                            <li><div className="dropdown-item pointer" onClick = {() => setShowCancel(true)}>Cancel Request</div></li>
                        </ul>
                        </div>
                    </div>
                }
            </div>
            <div className = 'card-body py-3'>
                <div className = 'row align-items-center'>
                    <div className = 'col-auto'>
                        <div className="avatar avatar-md"  >
                            <img src={data.instructor.avatar} alt="..." class="avatar-img rounded-circle" />
                        </div>
                    </div>
                    <div className = 'col'>
                        <div class = "h4 mb-1">{data.instructor.firstName + ' ' + data.instructor.lastName}</div>
                        <div class = "text-muted">{data.course.title}</div>
                        <small class = "small text-muted">Session ID: {data.bookingId}</small><br/>
                        {data.deletedBy && 
                            <>
                                <small class = "small text-muted"> Cancellation made by: {data.deletedBy === 'instructor' ? 'Mentor': 'Member'}</small><br/>
                            </>
                        }
                        {data.reason && 
                            <>
                                <small class = "small text-muted"> Reason: {data.reason}</small><br/>
                            </>
                        }
                        <div class = "badge badge-primary my-1">{ data.type === 'demo' ? 'Assessment':makeFirstLetterCap(data.type)} session</div>
                        {data.deletedBy  ? 
                        <div class = "badge badge-danger my-1 ml-2"> Request Cancelled</div>
                        : 
                        <div class = "badge badge-primary my-1 ml-2">{data.requestType === 'schedule'? 'Session' :makeFirstLetterCap(data.requestType)} requested</div>
                        } 
                    </div>
                </div>
            </div>
            <CancelRequest show = {showCancel} setShow = {setShowCancel} data = {data}/>
        </div>
    )
}



const LoadingRequests = () => {
    return (
        <div>
            <Skeleton height = {100}/>
            <div class = 'mb-4'></div>
            <Skeleton height = {100}/>
            <div class = 'mb-4'></div>
            <Skeleton height = {100}/>
        </div>
    )
}


const NoRequests = () => {
    return (
        <div className = 'card mb-4'>
        <div className = 'card-body d-flex align-items-center justify-content-center'>
            <div className = 'h3 text-uppercase text-muted mb-0 py-5'>
               {student_content.schedule_view.no_request_sent}
            </div>
        </div>
    </div>
    )
}


const makeFirstLetterCap = (text) => text ? text[0].toUpperCase() + text.slice(1) : ''
