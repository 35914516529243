import AppRouter from "./routes";
import { StoreProvider } from './store';



const App = () => {

  return (
      <StoreProvider>
          <AppRouter />
      </StoreProvider>
  );
}

export default App;



