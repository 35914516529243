const Doc = () => (
    <>
      <h3 id="tnc_teacher">DHUNGURU TERMS OF USE FOR TEACHERS (“TEACHER TERMS”)</h3>
      <p>Last Updated: May 28, 2024</p>
      <p><strong>IMPORTANT NOTICE: PLEASE READ THROUGH THESE TERMS CAREFULLY.</strong><br />
        Welcome to Dhunguru Music India Private Limited <strong>("Dhunguru")</strong>. The following document, known as the <strong>(“Teacher Terms”)</strong> outlines the terms and conditions governing your access to and utilization of our online website or application <strong>(“Platform”)</strong>. This platform serves as a conduit connecting students and teachers for music and instrument lessons. By engaging with the Platform, you enter into a legally binding agreement with Dhunguru, and it is imperative that you thoroughly review and comprehend these Terms of Use. 
      </p>
      <p>By accessing or utilizing the Platform, you hereby acknowledge and agree to adhere to the Teacher Terms, along with the Privacy Policy and guidelines hyperlinked within these Teacher Terms, and any prospective modifications thereof (collectively referred to as the <strong>"Agreement"</strong>). <br />
        A <strong>(“User”)</strong> shall mean any natural or legal person, who is competent to enter into a contract as per the Indian Contract Act, 1872, who uses the Platform, with or without registration, to avail the services offered, for himself or herself and/or for any other person.<br />
        If you find yourself unable to comprehend or align with these Teacher Terms of Use, we kindly request that you refrain from using the Platform. It is important to note that we bear no obligation to actively enforce the Agreement on your behalf against another user. Nevertheless, we encourage you to communicate with us should you believe that another user has contravened the terms of the Agreement. While we appreciate your cooperation in reporting potential violations, it is imperative to understand that we retain the right to conduct investigations and take appropriate action, at our sole discretion.
      </p>
      <ol>
        <li className='my-3'>
          <strong>"Teacher"</strong> as used herein refers to an individual who seeks and obtains Dhunguru’s approval to offer his or her services as an educator through the Platform. Dhunguru’s approval generally is based on the applicant’s affirmation that he or she has at least - 
          <ul>
            <li>1 year teaching experience, or</li>
            <li>is an extraordinary exception.</li>
          </ul>
          <p>In pursuit of Dhunguru's approval as a prospective Teacher, it is imperative that you furnish pertinent information pertaining to your qualifications as a Teacher. By submitting your application to assume the role of a Teacher, you hereby grant Dhunguru explicit consent to conduct a thorough background check at its discretion. Dhunguru retains the unequivocal right to decline Teacher status for any reason within its sole discretion. In the event that the outcome of the background check yields adverse consequences for a Teacher or Teacher Applicant, leading to denial of access to the Platform, Dhunguru commits to promptly disclose the findings to the concerned Teacher or Teacher Applicant. This notification will be accompanied by a reasonable timeframe, affording the individual an opportunity to respond to or rectify any inaccuracies within the provided information. It is imperative to acknowledge and consent to these conditions as an integral part of the application process, acknowledging Dhunguru's prerogative to make determinations based on the results of the background check.</p>
          <p>All Teachers utilizing the Platform are explicitly bound by the obligation not to employ any facet of the Platform for purposes divergent from its designated intent, specifically as a platform for conducting Sessions. Engaging in any use of the Platform contrary to its intended purpose constitutes a breach of this Agreement. Any such violation of this Agreement may result in disciplinary measures, including but not limited to the denial of access to the Platform. It is imperative for all Teachers to adhere to the stipulated terms and conditions, recognizing that any deviation from the intended purpose of the Platform is strictly prohibited and subject to appropriate consequences as determined by Dhunguru.</p>
          <p>If you object to anything in this Agreement (including anything in our Privacy Policy or other applicable terms), please immediately terminate your use of the Platform.</p>
          <p>YOU EXPRESSLY AGREE THAT YOUR USE OF THIS PLATFORM IS AT YOUR SOLE RISK. WE RESERVE THE RIGHT TO DISCONTINUE ANY ASPECT OF THE PLATFORM AT ANY TIME. THIS AGREEMENT IS TERMINABLE AT ANY TIME BY EITHER PARTY (DHUNGURU OR YOU) FOR ANY REASON. ANY LICENSE YOU GRANT DHUNGURU THROUGH ANY AGREEMENT WITH DHUNGURU WILL SURVIVE TERMINATION OF THE AGREEMENT WITH DHUNGURU.</p>
        </li>
        <li className='my-3'>
          <strong>Teacher Accounts.</strong> To access certain features offered through the Platform, it is a prerequisite to register and create an account, necessitating the provision of specific personal information. The confidentiality of your account password is your sole responsibility, and you are accountable for all activities associated with your account. Prompt notification of any unauthorized use of your account is mandatory. We retain the discretion to terminate your account at any time, without limitation and for any reason. Your account is designated for individual use exclusively, and you must furnish complete and accurate information about yourself during the account creation process. Impersonation, adoption of identities other than your own, creation of accounts for entities other than yourself, provision of unauthorized email addresses, and the establishment of multiple accounts are strictly prohibited. The use of pseudonyms is not permissible. For a comprehensive understanding of how your information is handled, please refer to the applicable Privacy Policy. The term "Teacher Profile" denotes the individual webpage within the Platform where a Teacher can upload their distinct Teacher Content.
        </li>
        <li className='my-3'>
          <strong>Initial Interview/Onboarding.</strong>
          <ul>
            <li>Before initiating your inaugural session on the Platform, it is imperative that you undergo one or more assessments or interviews through video-conference with Dhunguru (the "Interview"). These assessments are designed to ascertain and validate your technical competence and proficiency. It is a prerequisite to ensure that the quality and standard of services provided through the Platform meet the specified criteria. By participating in the Interview, you acknowledge and consent to the evaluation of your skills and capabilities as relevant to the intended scope of your engagement on the Platform. This process aims to uphold the integrity and reliability of the services offered, promoting a high standard of professional competence within the Dhunguru community.</li>
            <li>In its sole discretion, Dhunguru may choose to record the Initial Interview, and in such instances, Dhunguru will seek your explicit consent for the recording and subsequent utilization thereof in connection with the promotion of the Platform. Your consent to the recording encompasses the use of your name, likeness, and any performance exhibited during the Initial Interview for promotional purposes related to your Profile and/or the Platform. It is expressly clarified that Dhunguru holds no obligation to record, edit, or utilize any recording of the Teacher, or any portion thereof. Should the Teacher object to the recording, edited recording, or its use, it is incumbent upon the Teacher to promptly communicate such objection to Dhunguru. In the event of such objection, Dhunguru will discontinue the use of the recording within a reasonable period.</li>
            <li>You must have a set curriculum in place for your respective subject(s) that needs to be shared with the concerned team at the time of your onboarding.</li>
            <li>Upon onboarding with the platform, a Teacher shall be bound to be a part of the Platform for a minimum period of 6 months, and a two months’ notice is mandatory to be given before any Teacher decides to quit the platform. If the Teacher decides to quit the platform before 6 months, no pending payout accrued for such Teacher shall be released.</li>
          </ul>
        </li>
        <li className='my-3'>
          <strong>Communications from Dhunguru and other Users.</strong> Upon the creation of an account, you expressly consent to receive specific communications related to the Platform. These communications may include messages from students or prospective students seeking information about your teaching style or availability. It is acknowledged and agreed that Dhunguru retains the right to access and review any message transmitted through the Platform's internal messaging service, exercising this right at its discretion and for any purpose or without assigning a reason. Dhunguru may utilize the information contained in such messages for any purpose or no reason. Additionally, you may receive marketing communications from Dhunguru, and you retain the option to opt-out of such communications. This acknowledgment is an integral part of your agreement with Dhunguru and underscores the importance of understanding the terms governing communication and information usage within the Platform.
        </li>
        <li className='my-3'>
          <strong>Proprietary Rights, Materials and Teacher Content.</strong><br />
          Dhunguru, along with its licensors, maintains exclusive ownership and retains all proprietary rights pertaining to the Platform. The Platform encompasses content, visual interfaces, interactive features, information, graphics, design, compilation, computer code, products, software, services, and other constituents of the Platform (referred to as the "Dhunguru Materials"). These Dhunguru Materials are safeguarded by copyright, trade dress, patent, trademark laws, international conventions, and other pertinent intellectual property and proprietary rights, as well as applicable laws. Unless specific information is within the public domain or express written permission has been granted, users are prohibited from engaging in activities such as copying, modifying, publishing, transmitting, distributing, licensing, performing, displaying, or selling any Dhunguru Materials. It is important to note that Dhunguru Materials exclude Teacher Content (as defined below) or other content submitted by students. Dhunguru expressly retains all rights not explicitly granted in this Agreement. Under this Agreement, users are not entitled to acquire any right, title, or interest in the Platform or Dhunguru Materials beyond the limited rights expressly stipulated herein.<br />
          "Teacher Content" herein denotes any content or material of any nature uploaded or otherwise submitted by a Teacher to or through the Platform or provided by a Teacher during or in connection with a Session conducted through the Platform. In assuming the role of the Teacher, you bear responsibility for the accuracy of the submitted Teacher Content. Through the act of submitting Teacher Content, you hereby grant Dhunguru a perpetual, non-exclusive, worldwide license to utilize all your submitted Teacher Content in any manner deemed necessary for the Purpose of the Platform. It is imperative to note that this license extends globally and persists even after the termination or expiration of this Agreement. However, in situations where you share Teacher Content privately in the context of a specific student interaction (e.g., sharing curriculum with a particular student or students via the Platform's messaging function or within the course of a specific lesson), Dhunguru commits not to intentionally distribute such materials to individuals other than our employees and the intended recipients unless expressly instructed or granted consent to act otherwise by you. Dhunguru reserves the right, at our sole discretion and without prior notice to you, to moderate, remove, screen, edit, or reinstate Teacher Content from time to time. For instance, we may exercise this right if we reasonably believe that Teacher Content violates the terms outlined in this Agreement. It is important to acknowledge that Dhunguru is under no obligation to retain or furnish you with copies of Teacher Content, and we do not provide any assurances of confidentiality regarding Teacher Content.<br />
          The subsequent enumeration delineates specific types of Teacher Content that are deemed illegal or prohibited on the Platform. Dhunguru expressly reserves the right to conduct investigations and, at its sole discretion, initiate appropriate legal action against any individual found in violation of this provision. Remedial actions may include, without limitation, the removal of the offending communication from the Platform and the termination of membership for such violators. Prohibited Teacher Content includes, but is not limited to:<br />
          <ol>
            <li>Content that is overtly offensive to the online community, such as material that advocates racism, bigotry, hatred, or physical harm directed at any group or individual;</li>
            <li>Material that engages in or promotes the harassment of another person;</li>
            <li>Involvement in the transmission of "junk mail," "chain letters," or unsolicited mass mailing, commonly referred to as "spamming";</li>
            <li>Information that is knowingly false, misleading, or advocates illegal activities. This encompasses conduct that is abusive, threatening, obscene, profane, offensive, sexually oriented, racially offensive, defamatory, or libelous;</li>
            <li>Promotion of an illegal or unauthorized copy of another person's copyrighted work, including the provision of pirated computer programs or links to such programs, information facilitating the circumvention of manufacturer-installed copy-protection devices, or provision of pirated images, audio, or video, or links to pirated files;</li>
            <li>Content featuring restricted or password-only access pages, hidden pages or images not linked to or from another accessible page;</li>
            <li>Material that exploits individuals under the age of 18 in a sexual or violent manner or solicits personal information from individuals under the age of 18;</li>
            <li>Instructional information pertaining to illegal activities, such as the creation or purchase of illegal weapons, invasion of privacy, or the provision or creation of computer viruses;</li>
            <li>Solicitation of passwords or personal identifying information from other users for commercial or unlawful purposes;</li>
            <li>Engagement in commercial activities and/or sales without the prior written consent of Dhunguru including but not limited to contests, sweepstakes, barter, advertising, and pyramid schemes.</li>
          </ol>
        </li>
        <li className='my-3'>
          <strong>Recorded Sessions.</strong> All sessions conducted by Dhunguru are automatically recorded ("Recorded Session") based on the explicit consent obtained from both Teachers and Students during the sign-up process. By utilizing the Platform, the teacher expressly grants Dhunguru the irrevocable right to record each session, with recordings being stored and catalogued for subsequent access by the Students of the Platform. Both Students and Teachers acknowledge and agree to the following:<br />
          <ul>
            <li>Dhunguru is the sole owner of all rights, including copyright, associated with any Recorded Sessions. Recorded Sessions are intended to be used exclusively for the Purpose of the Platform, specifically for the learning of music.</li>
            <li>Recorded Sessions will be utilized in accordance with the purposes outlined herein.</li>
            <li>Audio and/or video from Recorded Sessions may not be posted on any third-party media-hosting website (e.g., SoundCloud, YouTube, or Vimeo) without the express permission of Dhunguru.</li>
          </ul>
          <p>PLEASE DO NOT USE THE PLATFORM IF YOU DO NOT CONSENT FOR THE SESSIONS TO BE RECORDED.</p>
        </li>
        <li className='my-3'>
          <strong>Adequate Equipment and Bandwidth.</strong> To access and utilize the Platform, it is a prerequisite that you possess a computer equipped with a webcam. It is important to note that we cannot ensure the consistent functionality of the Platform across all hardware configurations or network connections. A level of connectivity equal to or exceeding the standards of the computer and webcam employed during your Initial Interview with Dhunguru is mandatory. A minimum connectivity threshold of 500 kilobits per second (kbps) for both upload and download speeds is essential for proper utilization of the Platform. In the event that, during your Initial Interview, you fail to demonstrate that your system and bandwidth meet the required standards to facilitate Sessions through the Platform, Dhunguru reserves the exclusive right, at its sole discretion, to deny your authorization to provide Sessions through the Platform. Suggested minimum computer hardware requirements are below:<br />
          <strong>Supported OS</strong><br />
          Windows 10, 8.1, 8, 7, XP;<br />
          Mac: OS X 10.6 or higher;<br />
          Linux: Debian 7.0; Fedora Core 18, 19; openSUSE 12.2, 12.3; Red Hat 6.1, 6.3; Scientific Linux (SL) 6.1, 6.3; Ubuntu 12.04, 12.10, 13.04, 13.10<br />
          <strong>Recommended Configuration System</strong><br />
          Core 2 Duo 2GHz; 2 GB RAM; 40 MB free disk space For HD: Graphics capabilities that support HD resolutions on one or more displays<br />
          <strong>Devices & Accessories</strong><br />
          For details, see https://support.zoom.us/hc/en-us/articles/201362023-System-requirements-for-Windows-macOS-and-Linux<br />
          <strong>Webcams</strong><br />
          Supported: Standard integrated webcams<br />
          Preferred: External USB webcams that support 720p30 and higher<br />
          <strong>Microphones and Speakers</strong><br />
          Supported: Integrated system microphones and speakers, webcam microphones<br />
          Preferred: Headsets and speakerphones with integrated echo cancellation<br />
        </li>
        <li className='my-3'>
          <strong>Relationship between teachers and the Platform.</strong> The affiliation between the Platform and the Teacher is established when the individual registers, accepts this Agreement, and receives approval from Dhunguru to act as a Teacher. Teachers acknowledged in this Agreement and throughout the Platform are independent contractors providing services to students and are explicitly not considered employees of Dhunguru. Dhunguru maintains a non-supervisory role over Teachers and refrains from providing directives on the manner in which Sessions are to be taught through the Platform. Teachers do not serve as agents of Dhunguru, and Dhunguru bears no liability for the actions, errors, omissions, representations, warranties, breaches, or negligence of Teachers. Furthermore, Dhunguru is not responsible for any personal injuries, death, property damage, or other associated damages or expenses arising from the activities of Teachers. It is recommended that Teachers acquire suitable general liability and professional liability insurance before engaging with the Platform. Teachers are solely accountable for complying with applicable income tax, sales tax, and other relevant laws governing the sales of Sessions. Dhunguru emphasizes that Teachers are not employees or agents of any kind, and the provision of the Platform is contingent upon the Platform Fee. Teachers are empowered to establish their own prices, availability, and session schedules on the Platform. Dhunguru retains the right to conduct audits of Sessions for the purpose of ensuring Platform safety and stability. Dhunguru reserves the discretionary right to delete an account, limit, or deny access to the Platform at any time and for any reason or without providing a specific reason. The punctuality of the Teacher is deemed essential for the proper use of the Platform in line with its intended Purpose of conducting Sessions.
        </li>
        <li className='my-3'>
          <strong>Session Scheduling.</strong> A student has the option to select from the list of available session times on a Teacher’s schedule, and is permitted to schedule a session up to 6 hours prior to the designated time, contingent upon Teacher availability and the Teacher may either accept or deny the student’s session request at any time up to 3 hours prior to the scheduled session time. Please note that you shall not be able to act on a session request after that. <br />
          In the event that the Teacher and student mutually agree to convene with less notice (i.e., if the Teacher extends an invitation to meet for a session at a time less than 6 hours later), such practice is generally permitted by the Platform<br />
          <strong>It is mandated for the teachers to take sessions only through the Zoom Meeting provided through Dhunguru Platform.</strong> The teachers are strictly prohibited to take sessions on any other platform (E.g. Google Meet, Microsoft Teams Meeting, etc.). In situations where a teacher conducts a session on an alternative platform, compensation for that session will not be provided, and Dhunguru bears no responsibility for the conduct of the students during such sessions.
        </li>
        <li className='my-3'>
          <strong>Session duration and mandates.</strong> It is mandatory that every paid session being conducted on the Platform – 
          <ul>
            <li>to be of minimum 45 minutes and nothing less than that. Even if a student comes to a session without proper practice, the Teacher cannot end the session in less than 45 minutes.</li>
            <li>no breaks to be taken (except for an urgent reason) that leads to loss of session time, and any such lost time needs to be compensated for at the end of the session;</li>
            <li>to be conducted by the teacher stationed at one place indoors and not while on the go or moving around outdoors;</li>
            <li>to be conducted with the Teacher’s video kept on at all times for the entire length of the session.</li>
          </ul>
          <p>In the event either or all of the above is not adhered with during a session, there shall be a penalty equivalent to the fees for such Session.</p>
        </li>
        <li className='my-3'>
          <strong>Cancellations/Schedule Modification.</strong> 
          <ul>
            <li>All sessions are expected to occur as per the agreed-upon schedule. Parties involved are obligated to adhere to the agreed-upon Session time. Sessions may be cancelled, provided that Appropriate Notice is given. Appropriate Notice, in this context, is defined as a minimum of 2 hours prior to the scheduled Session time. In the event of rescheduling, the party initiating the change must utilize the Platform's internal messaging service to promptly notify the other party. This notification should be made as soon as reasonably possible and, crucially, no less than 2 hours before the originally scheduled Session time. If a cancellation or rescheduling occurs within 6 hours of the scheduled Session time, it will be deemed a No-show.</li>
            <li>Cancellation of sessions would only stand good if it has been cancelled via the dashboard, and a cancellation via communication on call/message between the student and Teacher shall not be considered as a valid cancellation, if challenged by the student.</li>
            <li>Teachers who repeatedly cancel Sessions without Appropriate Notice may face consequences, including but not limited to limited access or denial of access to the Dhunguru platform. Such actions will be taken in alignment with the terms and conditions of the Platform.</li>
            <li>Furthermore, if a Teacher modifies the schedule of the session after joining the session and ends it before time, it shall be deemed a No-show.</li>
            <li>The Teachers are also strictly forbidden from modifying the session timings, by asking the students via call/message to join any time before or after the actual session schedule time.</li>
          </ul>
        </li>
        <li className='my-3'>
          <strong>No Show Policy.</strong>
          <ul>
            <li>The Teacher acknowledges that their use of the Dhunguru platform is at their own discretion, subject to the terms and conditions outlined in this agreement. While Teacher use is discretionary, punctuality is deemed essential. This section outlines the terms and consequences related to punctuality and attendance at Sessions. Both parties agree that punctuality is of the essence concerning the Teacher's use of the Platform. Timely attendance at scheduled Sessions is crucial for the effective functioning of the Dhunguru platform. Being more than twenty minutes late or not attending a Session (“No-show”) is considered a material breach of this Agreement and there shall be a penalty equivalent to the value for that particular session and the session shall be credited back to the Student’s enrolment, even if it has been mutually agreed by the student. The Teacher cannot demand pay-out in instances of No-show.</li>
            <li>Also, in the event a Teacher observes that a student has consecutively made two no-shows, the Teacher is bound to report that Student to the team.</li>
            <li>In the event there is a No-show by the student, the only way for the Teacher to charge for the session is if he/she has waited in the session for a minimum of 20 minutes, and the student failed to join.</li>
            <li>Dhunguru, at its discretion, reserves the right to limit or permanently refuse access to the Platform for the Teacher if there is a repeated pattern of tardiness or non-attendance of Sessions without Appropriate Notice and will be handled on a case-by-case basis. Dhunguru will assess the circumstances surrounding repeated lateness or non-attendance before making a determination.</li>
            <li>Teachers encountering challenges in providing a Session due to a student's inadequate hardware or inappropriate conduct are required to promptly contact Dhunguru, providing a detailed explanation of the circumstances. Resolution of such situations and discussions related to Refunds will be handled on a case-by-case basis. If inadequate student hardware is believed to be the cause of the problem, Teachers must include a screenshot of their computer's bandwidth rating from <a href='https://speedtest.net'>https://speedtest.net</a> along with their explanation of the circumstances.</li>
          </ul>
        </li>
        <li className='my-3'>
          <strong>Teacher Conduct.</strong> All teachers on the Platform must ensure that the sessions are conducted in a professional manner and in compliance with the following - 
          <ul>
            <li>no teacher must consume any eatables during a Session;</li>
            <li>the background must be the standard Dhunguru logo (provided by Dhunguru) for all vocal sessions, and any other plain color background for an instrument session;</li>
            <li>all Teachers must be dressed appropriately and their attire needs to be of a semi-formal/formal nature (nightwear is strictly barred);</li>
            <li>refrain from behaving in a manner that makes the student feel uncomfortable or disrespected. In the event of an argument/disagreement with a student, the Teacher needs to report the incident to the team and not engage in that argument;</li>
            <li>ensure that their display name when they join the zoom session, must not contain anything other than their name (must not have their email IDs or phone number);</li>
            <li>a Teacher needs to inform all of their students, atleast one week prior to going on a break and in no event will the duration of the break exceed 14 days at a time;</li>
            <li>a change in fees for a subject(s) should be preferably done after consultation/discussion with the Platform;</li>
          </ul>
        </li>
        <li className='my-3'>
          <strong>Payment.</strong> Payment by the student is finalized at the time of purchasing one or multiple Sessions and is remitted to Dhunguru Music India Private Limited. This payment action establishes Platform credit, commensurate with the number of Sessions acquired, which the student may utilize with the designated Teacher or other Teachers on the Platform.<br />
          Upon successful completion of a Session, the Teacher is obligated to acknowledge the Session from their dashboard on the Platform to receive payment. Each Session must be acknowledged within 14 days of completion; otherwise, the corresponding pay-out for that Session will be remitted to Dhunguru. Teachers receive email notifications for Sessions pending acknowledgment for more than one week post-Session completion. Nonetheless, the responsibility for Session acknowledgment lies solely with the Teacher. Sessions marked by 11:59 PM IST on Friday of a given week qualify for payment on the following Monday. It is imperative that Sessions are marked by the 11:59 PM IST deadline to qualify for the upcoming payment. The payment cycle is as follows:<br />
          <ul>
            <li>Payment for sessions acknowledged from 1st to 15th of a month are disbursed on 20th of the same month;</li>
            <li>Payment for sessions acknowledged from 16th to 30th/31st of a month are disbursed on 5th of the subsequent month.</li>
          </ul>
          <p>Teachers have the option to add their direct deposit information to their Dhunguru account for payment via direct deposit or Bank Transfer through digital payments. The Session price set by the Teacher may be subject to discounts, with Teachers retaining the ability to opt-out of such discounts at any time, including during profile setup. Sessions booked at a discounted price before the Teacher opts out will be considered purchased at the lower rate.</p>
          <p>The Teachers are forbidden from marking the sessions incorrectly in order to avoid a penalty. For instance, in the event of a no-show by a Teacher, if the Teacher marks the session as “Student No-show” or “Internet Issues Student” in order to evade the penalty owing to that No-show, the Teacher shall be penalized for a sum of Rs. 500 per such instance of a wrong marking.</p>
        </li>
        <li className='my-3'>
          <strong>Platform Fee.</strong> Prior to disbursing the Teacher Payment Amount, Dhunguru will deduct and retain 20% of the amount paid by the student for the Session as the "Platform Fee." Both the Teacher and Dhunguru acknowledge and agree that the Platform Fee constitutes payment by the Student for the use of the Dhunguru platform. As per the GST Policy, 1% of the amount paid by the student for the Session shall be deducted as Tax Deducted at Source (TDS) by Dhunguru. This deduction is a legal requirement, and both parties hereby acknowledge and agree to comply with applicable tax regulations. Dhunguru will disburse the Teacher Payment Amount through Bank Transfer/Direct deposit through digital payments. The selected disbursement method will be communicated and agreed upon between Dhunguru and the Teacher. The amount disbursed to the Teacher, after the deduction of the Platform Fee and TDS, is hereby defined as the "Teacher Payment Amount." Dhunguru reserves the right to modify the Platform Fee, provided that reasonable notice is given to the Teacher. Such modifications will be communicated in writing and will be effective as of the specified date.
        </li>
        <li className='my-3'>
          <strong>Performance Review.</strong> The performance of the Teacher on the Dhunguru platform may be subject to review by students who have participated in Sessions facilitated by the Teacher through the Platform. Students on the Dhunguru platform reserve the right to provide feedback and reviews on the performance of the Teacher based on their experience during Sessions. Participation in the review process is voluntary for students, and their feedback is intended to contribute to the overall assessment of the Teacher's performance. Both the Teacher and students are expected to maintain professionalism and civility in providing and receiving feedback, respectively. Any inappropriate or malicious conduct in the review process may be subject to appropriate action. The reviews received from students may be visible on the Teacher's profile on the Dhunguru platform. Such reviews can contribute to the overall assessment of the Teacher's performance by potential students. Reviews are encouraged to be fair, constructive, and focused on the educational experience. Dhunguru reserves the right to moderate and address any reviews that violate platform policies.
        </li>
        <li className='my-3'>
          <strong>Platform Rights.</strong> Dhunguru reserves the right to delete your teacher account or deny you access to the Platform at any time for any reason including but not limited to:<br />
          <ul>
            <li>Non-maintenance of an up-to-date calendar within your Teacher Profile;</li>
            <li>No response within 48 hours to requests for Sessions made by students through the Platform; or</li>
            <li>In the event of an extended absence or unavailability due to being out of town or any other reason, it is required that you refrain from conspicuously noting this information on your Teacher Profile.</li>
          </ul>
        </li>
        <li className='my-3'>
          <strong>Exchange of Personal Information.</strong> In adherence to our Terms of Use (Music Sessions), Teachers are unequivocally prohibited from sharing any contact details with students. Sharing contact details with students is grounds for immediate blocking of access to the Platform for the offending Teacher. The Teacher's Profile will be permanently deleted from the Platform. In the event of a Teacher's breach of these Terms, including but not limited to this section addressing contact detail sharing, the Teacher shall be liable to pay the Platform damages amounting to Rupees Ten Thousand (Rs. 10,000/-) per student enrolled with him/her. The financial liability outlined herein is not exhaustive, and Dhunguru reserves the right to pursue additional legal remedies available under applicable laws. This policy is rigorously enforced to safeguard the confidentiality and privacy of users/students on the Dhunguru platform.
        </li>
        <li className='my-3'>
          <strong>Engagement/ Interactions with other users.</strong><br />
          <ol>
            <li>Users of the Platform, including Teachers, Students, prospective Teachers, and prospective Students, are individually and solely responsible for their interactions. Dhunguru explicitly disclaims any representations or warranties regarding the conduct of Platform users and shall not be held liable for any user's behaviour. Users agree to exercise reasonable precautions in all interactions, especially in offline or in-person meetings. Under no circumstances should financial information, such as credit card or bank account details, be shared with any other Platform user.</li>
            <li>Release. You hereby release Dhunguru from any and all claims, demands, damages (actual, consequential, nominal, punitive, or otherwise), equitable relief, and any other legal, equitable, and administrative remedy, of every kind and nature, whether known or unknown, suspected or unsuspected, disclosed or undisclosed, past, present, or future, arising out of or in any way connected with your interaction with other users of the Platform.</li>
            <li>DHUNGURU HAS NO DUTY TO MONITOR COMMUNICATIONS AND/OR INTERACTIONS TAKING PLACE ON OR THROUGH THE PLATFORM.</li>
            <li>SEXUALLY EXPLICIT MATERIAL IS NOT ALLOWED TO BE USED/TRANSMITTED THROUGH THE PLATFORM.</li>
          </ol>
        </li>
        <li className='my-3'>
          <strong>Teaching Children.</strong><br />
          <p>Dhunguru acknowledges the participation of children in Sessions through the Platform. In the event that your utilization of the Platform results in the exposure of any user under the age of 18 to sexually explicit material, and Dhunguru becomes aware of such conduct, we are obligated by law to report such instances to the appropriate authorities. Furthermore, any other conduct deemed as a violation of this Agreement may also be reported to the appropriate authorities at the discretion of Dhunguru on a case-by-case basis. This commitment to reporting is in alignment with our commitment to maintaining a safe and secure environment for all users, especially minors, utilizing the Platform.</p>
        </li>
        <li className='my-3'>
          <strong>Platform Use.</strong> The use of this Platform must be for lawful purposes only and in accordance with the Purpose of the Platform. You are prohibited from assisting others in engaging in any activities that are not permissible under the terms of the Agreement. You must refrain from (a) submitting or transmitting any content or material through the Platform or (b) engaging in any conduct that Dhunguru, at its sole discretion, deems: 
          <ol>
            <li>violates or infringes the rights of others, including but not limited to patent, trademark, trade secret, copyright, privacy, publicity, or other proprietary rights;</li>
            <li>is unlawful or objectionable at our sole discretion, including but not limited to materials or conduct(s) that is threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, obscene, invasive of another’s privacy, pornographic, sexually explicit, or tortiously interferes with another;</li>
            <li>forges email headers or disguises the origin of any communication;</li>
            <li>sends unsolicited bulk messages within the internal messaging service of the Platform;</li>
            <li>use of automated “bots” to access or compile information posted with respect to the Platform;</li>
            <li>victimizes, harasses, degrades, or intimidates any individual or group of individuals;</li>
            <li>impersonates any person, business, or entity;</li>
            <li>expresses or implies that any statements made by you are endorsed by Dhunguru without our specific prior written consent;</li>
            <li>“frames” or “mirrors” any part of the Platform, without Dhunguru’s prior written authorization. Additionally, You must not use meta tags or code or other devices containing any reference to Dhunguru or the Platform in order to direct any person to other web site for any purpose;</li>
            <li>modifies, adapts, sublicenses, translates, sells, reverse engineers, deciphers, decompiles or otherwise disassembles any portion of the Platform or any software used on or for the Platform, or cause others to do so;</li>
            <li>encourages any conduct that constitutes a criminal act or that gives rise to a civil liability;</li>
            <li>promotes any business other than the Platform or your services as offered through the Platform in accordance with the Purpose of the site; or</li>
            <li>violates the Agreement or otherwise interferes with the rights of others.</li>
          </ol>
          <p>You additionally agree not to post or transmit any image of another person without obtaining that person's explicit consent. Furthermore, you may not utilize the Platform in any manner that has the potential to damage, disable, overburden, or impair Dhunguru's servers, or disrupt the use and enjoyment of the Platform by any other party. Unauthorized attempts to access services or information for which you have not been granted access, including password mining or any other illicit processes, are strictly prohibited.</p>
          <p>We may take any legal and technical remedies to prevent the violation of this Agreement and to otherwise enforce the Agreement at our sole discretion.</p>
        </li>
        <li className='my-3'>
          <strong>Amendments to the Terms.</strong> Dhunguru retains the right to modify, amend, add, or remove sections of this Agreement at our sole discretion. Any such changes will be published on the Platform website and communicated to you through the email address you have provided to Dhunguru. It is crucial to maintain an up-to-date email address with Dhunguru for this purpose (Please note that your submission of personal information, including your email address, is governed by our Privacy Policy). We advise you to periodically review the Agreement for any updates. Your ongoing use of the Platform following the posting of changes signifies your binding acceptance of the modified terms.
        </li>
        <li className='my-3'>
          <strong>Disclaimer of Warranties and Limitation of Liability/Other Disclaimers.</strong>
          <p>The platform and all information, content, materials, products, and services provided through this platform are offered by us on an "as is" and "as available" basis. Dhunguru makes no representations or warranties of any kind, express or implied, regarding the operation of the platform or the information, content, materials, products, or services made available through it.<br />
          To the maximum extent permissible under applicable law, Dhunguru disclaims all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose. Dhunguru does not warrant that the platform and any information, content, materials, products, or services provided through it, are free of viruses or other harmful components. Dhunguru also does not warrant that the platform and the services offered will not utilize data causing you to exceed any applicable restrictions on data use imposed by your internet provider, mobile or otherwise.<br />
          Except in jurisdictions where such provisions are restricted, under no legal theory, including but not limited to negligence, will Dhunguru or its affiliates, contractors, employees, agents, or third-party partners or suppliers be liable to you or any third person for damages of any kind arising from the use of the platform. This includes, without limitation, direct, indirect, incidental, consequential, special, and punitive damages related to any act or omission, such as lost profits, data, or use of the platform, even if Dhunguru has been advised of the possibility of such damages. In cases where applicable law does not allow the above release of liability, Dhunguru's liability will be limited to the extent permitted by law.<br/>
          In no event shall Dhunguru or its affiliates, contractors, employees, agents, or third-party partners, licensors, or suppliers' total liability to you for any cause whatsoever (whether in contract, tort, warranty, or otherwise), and regardless of the form of the action, exceed the amount paid by you to Dhunguru for the use of the platform in the 12 months prior to the action giving rise to the liability.</p>
        </li>
        <li className='my-3'>
          <strong>Operation and Content.</strong> Dhunguru disclaims responsibility for any incorrect or inaccurate content posted on or in connection with the Platform, whether caused by users, members, or any equipment or programming associated with or utilized in the Platform. Dhunguru assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communication line failure, theft, or destruction, or unauthorized access to, or alteration of, user communications. Additionally, Dhunguru is not accountable for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email, or players, due to technical problems or traffic congestion on the Internet or at any website, or a combination thereof. This includes any injury or damage to users or any other person's computer related to or resulting from participating or downloading materials in connection with the web and/or in connection with the Platform.
        </li>
        <li className='my-3'>
          <strong>Other website links.</strong> The Platform may contain links to other websites. Interactions that occur between you and the third-party sites are strictly between you and such sites, and are not the responsibility of Dhunguru. We are not responsible for examining or evaluating, and do not warrant the offerings of, any of these businesses or individuals or the content of their sites. Dhunguru does not assume any liability or responsibility for the actions or omissions, product, availability, or content of these outside resources. We encourage you to read those third-party sites’ applicable terms of use and privacy policies.
        </li>
        <li className='my-3'>
          <strong>Choice of Law.</strong>
          <p>The Agreement shall be governed, construed, and enforced in accordance with the laws of India, including but not limited to:
          <ol>
            <li>The Indian Contact,1872;</li>
            <li>The (Indian) Information Technology Act, 2000;</li>
            <li>The (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011;</li>
            <li>The (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 and</li>
            <li>The (Indian) Digital Data Protection Act, 2023.</li>
          </ol><br />
          The parties mutually agree that all actions and proceedings arising out of or related to this Agreement shall be exclusively litigated in the courts located in Gurugram. Each party hereby submits to the personal jurisdiction of such courts for the purposes of such actions or proceedings. Furthermore, each party agrees to irrevocably waive, to the fullest extent permitted by applicable law, any right to a jury trial in any legal proceeding arising out of or relating to this Agreement.</p>
        </li>
        <li className='my-3'>
          <strong>Assignment.</strong> The Agreement, and any rights and licenses granted hereunder, must not be transferred or assigned by you to any other party, but may be assigned by Dhunguru without restriction. Any assignment made or attempted to be made in violation of this Agreement shall be void.
        </li>
        <li className='my-3'>
          <strong>Warranty.</strong> You expressly warrant that you are over the age of eighteen (18) and have the capacity to enter into this Agreement, that any and all information (including without limitation Teacher Content) provided by you through the Platform is accurate and does not infringe the intellectual property rights of any other party and that your conduct and use of the Platform will conform with the terms of this Agreement.
        </li>
        <li className='my-3'>
          <strong>Indemnification.</strong> You agree to indemnify and hold Dhunguru and/or any of Dhunguru’s subsidiaries, members, affiliates, officers, agents, licensors, partners, and employees harmless from any loss, liability, claim, damages, obligations, or demand, including reasonable attorney’s fees, made by any third party due to or arising out of your use or interaction with the Platform including without limitation: (i) your violation or breach of this Agreement, (ii) your Teacher/Student Content, (iii) any intellectual property infringement or other infringement of the rights of third parties, or (iv) any breach of your representations and warranties outlined above. Dhunguru reserves the right, with no obligation, to assume, at your expense, the exclusive defense and control of any matter for which you are required to indemnify us. You agree to cooperate fully with our defense of these claims and not to settle any matter without the prior written consent of Dhunguru. We will make reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.
        </li>
        <li className='my-3'>
          <strong>Notice.</strong> All notices given to you by the Company or by you to the Company shall be in writing and in the English language. Such notice shall be sent by e-mail or mailed by a prepaid internationally-recognized courier service to the intended recipient at the address set out below, or any changed address that is notified by either Party:<br />
          <strong>Notice to the Company:</strong><br />
          Dhunguru Music India Pvt Ltd<br />
          WeWork<br />
          DLF Two Horizon Centre,<br />
          5th Floor, DLF Phase 5,<br />
          Gurugram, Haryana 122002<br />
          M: +918045683666<br />
          <strong>Notice to User:</strong><br />
          At the email address provided by you at the time of registration on the Website.
        </li>
        <li className='my-3'>
          <strong>Severability.</strong> If any provision of this Agreement is deemed unlawful, void, or unenforceable by a judge or tribunal body, then that provision shall be considered severable from this Agreement. The invalidity or unenforceability of any provision shall not affect the validity and enforceability of the remaining provisions.
        </li>
        <li className='my-3'>
          <strong>Claims.</strong> You and Dhunguru agree that any cause of action arising out of or related to the platform must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
        </li>
        <li className='my-3'>
          <strong>Grievance Redressal.</strong> If you have any questions or grievances regarding the Platform, or the contents thereof, you may reach out to ‘Aditi Raj’ the Company’s Data Protection Officer at <a href='support@dhunguru.com'>support@dhunguru.com</a>.
        </li>
        <li className='my-3'>
          <strong>Repeat Infringer.</strong> Please note that Dhunguru will promptly terminate without notice any Teacher’s access to the Platform if that user or teacher is determined by Dhunguru to be a “repeat infringer.” A “repeat infringer” is a Platform user who has been notified by Dhunguru of infringing activity violations more than twice and/or who has had their Teacher Content or any other user-submitted content removed from the Platform more than twice. In addition, Dhunguru accommodates and implements a policy of terminating, in appropriate circumstances and at Dhunguru’s sole discretion, access to our Platform for users who infringe any intellectual property rights of others, whether or not there is repeat infringement. If you believe that a user of our Platform is a repeat infringer, please follow the instructions provided in the "Notice Procedure for Claims of Copyright Infringement" section of our Terms of Service, and provide sufficient information to us in your notice so that we can verify the alleged repeat infringement.
        </li>
      </ol>
    </>
  );
  
  export default Doc;
  