import React, { Fragment, useEffect, useState } from 'react'
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';
import { RiSkipForwardLine, RiSkipBackLine } from 'react-icons/ri'
import { FaSlidersH } from 'react-icons/fa'
import { BsCloudDownload } from 'react-icons/bs'
import { CSVLink } from "react-csv";
import { InputMaskItemSm } from '../Forms'
import moment from 'moment'

const Table = ({
    data,
    isSelectable,
    headers,
    isSearchable,
    handleRowClick,
    isHeader,
    isFooter,
    isSortable,
    searchInput,
    setSearchInput,
    loading,
    refreshDemo,
    startPayment,
    filters,
    isDownloadable,
    dateFilter
}) => {

    const [tabelHeaders, setTableHeaders] = useState(headers.map(item => ({ ...item, sort: null, selected: false })));
    const [tableData, setTableData] = useState(data);
    const [tableDataView, setTableDataView] = useState(data);
    const [allSelected, setAllSelected] = useState(false);
    const [numRowsInPages, setNumRowsInPages] = useState(10);
    const [tablePage, setTablePage] = useState(1);
    const [appliedFilters, setAppliedFilters] = useState(Array(filters.length).fill('*'));
    const [numAppliedFilters, setNumAppliedFilters] = useState(0);
    const [showFilters, setShowFilters] = useState(false);
    const [showDateFilters, setShowDateFilters] = useState(false)
    const [dateFilters, setDateFilters] = useState({ start: '', end: '' })

    useEffect(() => {
        setTableHeaders(headers.map(item => ({ ...item, sort: null, selected: false })))
    }, [headers])

    useEffect(() => {
        setTableData(data);
        setTableDataView(data)
        // eslint-disable-next-line
    }, [data])



    useEffect(() => {
        if (tableData === data) {
            setTableData(data.map(item => ({ ...item, selected: false })))
            setTableDataView(data.map(item => ({ ...item, selected: false })))
        }
        // eslint-disable-next-line
    }, [])

    const sortTable = (value) => {
        if (!isSortable) return;
        let arr = [...tabelHeaders];
        const index = arr.findIndex(item => item.value === value);
        if (arr[index].sort === -1 || arr[index].sort === 0) {
            arr = arr.map(item => ({ ...item, sort: 0 }))
            arr[index].sort = 1
            setTableDataView(prev => prev.sort(compareValues(arr[index].value, 'asc')))
        } else {
            arr = arr.map(item => ({ ...item, sort: 0 }))
            arr[index].sort = -1
            setTableDataView(prev => prev.sort(compareValues(arr[index].value, 'desc')))
        }
        setTableHeaders(arr);
    }

    const selectRow = (index) => {
        let arr = [...tableData];
        arr[index].selected = !arr[index].selected;
        setTableData(arr);
        setTableDataView(arr);
        if (arr.filter(item => item.selected === false).length === 0) {
            setAllSelected(true);
        } else {
            setAllSelected(false)
        }
    }

    const selectAll = () => {
        if (allSelected) {
            setTableData(prev => prev.map(item => ({ ...item, selected: false })))
            setTableDataView(prev => prev.map(item => ({ ...item, selected: false })))
            setAllSelected(false)
        } else {
            setTableData(prev => prev.map(item => ({ ...item, selected: true })))
            setTableDataView(prev => prev.map(item => ({ ...item, selected: true })))
            setAllSelected(true)
        }
    }



    const search = (input) => {
        let searchedData = tableData.filter(row => {
            if (input[0] === '/') {
                const values = Object.values(row);
                return values.filter(item2 => {
                    return JSON.stringify(item2 || '').toLowerCase().includes(input.slice(1).toLowerCase())
                }).length !== 0
            } else {
                return tabelHeaders.filter(item3 => {
                    if (item3.active === true) {
                        const temp = row[item3.value] || ''
                        if (temp && temp.label) {
                            return JSON.stringify(temp.value || '').toLowerCase().includes(input.toLowerCase())
                        } else {
                            return JSON.stringify(temp || '').toLowerCase().includes(input.toLowerCase())
                        }
                    }
                    else return false
                }).length !== 0
            }
        })
        setTablePage(1)
        filters.map((filter, index) => {
            const value = appliedFilters[index];
            if (value !== '*') {
                searchedData = searchedData.filter(item => {
                    // eslint-disable-next-line
                    if (item[filter.value]) return item[filter].value == (value)
                    // eslint-disable-next-line
                    else return (item[filter]) == (value)
                });

            }
            return null;
        })
        if (dateFilter) {
            if (dateFilters.start && moment(dateFilters.start, 'DD/MM/YYYY').isValid()) {
                const date1 = moment(dateFilters.start + ', 00:01 AM', 'DD/MM/YYYY, hh:mm A');
                searchedData = searchedData.filter(item => {
                    let date2 = "";
                    if (item[dateFilter] && item[dateFilter].value) {
                        date2 = moment(item[dateFilter].label, 'Do MMM YY, hh:mm A');
                    }
                    else date2 = moment(item[dateFilter], 'Do MMM YY, hh:mm A');
                    return date2.diff(date1, 'hours') > 0
                });
            }
            if (dateFilters.end && moment(dateFilters.end, 'DD/MM/YYYY').isValid()) {
                const date1 = moment(dateFilters.end + ', 23:59 PM', 'DD/MM/YYYY, hh:mm A');
                searchedData = searchedData.filter(item => {
                    let date2 = ''
                    if (item[dateFilter] && item[dateFilter].value) {
                        date2 = moment(item[dateFilter].label, 'Do MMM YY, hh:mm A');
                    }
                    // eslint-disable-next-line
                    else date2 = moment(item[dateFilter], 'Do MMM YY, hh:mm A');
                    return date2.diff(date1, 'hours') < 0
                });
            }
        }

        setTableDataView(searchedData);
    }

    useEffect(() => {
        if (tableData.length > 0 && searchInput.length > 0) {

            search(searchInput)
        }
        // eslint-disable-next-line
    }, [searchInput])


    const changeFilters = (e, index) => {
        const arr = [...appliedFilters];
        arr[index] = e.target.value;
        setAppliedFilters(arr)
    }

    const clearFilters = (e) => {
        e.preventDefault();
        setAppliedFilters(Array(filters.length).fill('*'))
        setTableDataView(tableData)
        setShowFilters(false)
        setNumAppliedFilters(0)
    }


    const applyFilters = (e) => {
        e.preventDefault();
        let num = 0;
        let arr = [...tableData];
        filters.map((filter, index) => {
            const value = appliedFilters[index];
            if (value !== '*') {
                num++;
                arr = arr.filter(item => {
                    // eslint-disable-next-line
                    if (item[filter] && item[filter].value) {
                        // eslint-disable-next-line
                        return (item[filter].value) == (value)
                    }
                    // eslint-disable-next-line
                    else return (item[filter]) == (value)
                });

            }
            return null;
        })
        if (dateFilter) {
            if (dateFilters.start && moment(dateFilters.start, 'DD/MM/YYYY').isValid()) {
                const date1 = moment(dateFilters.start + ', 00:01 AM', 'DD/MM/YYYY, hh:mm A');
                arr = arr.filter(item => {
                    let date2 = "";
                    if (item[dateFilter] && item[dateFilter].value) {
                        date2 = moment(item[dateFilter].label, 'Do MMM YY, hh:mm A');
                    }
                    else date2 = moment(item[dateFilter], 'Do MMM YY, hh:mm A');

                    return date2.diff(date1, 'minutes') > 0
                });
            }
            if (dateFilters.end && moment(dateFilters.end, 'DD/MM/YYYY').isValid()) {
                const date1 = moment(dateFilters.end + ', 23:59 PM', 'DD/MM/YYYY, hh:mm A');
                arr = arr.filter(item => {
                    let date2 = ''
                    if (item[dateFilter] && item[dateFilter].value) {
                        date2 = moment(item[dateFilter].label, 'Do MMM YY, hh:mm A');
                    }
                    // eslint-disable-next-line
                    else date2 = moment(item[dateFilter], 'Do MMM YY, hh:mm A');
                    return date2.diff(date1, 'minutes') < 0
                });
            }
        }
        //If search input exists
        const searchedData = arr.filter(item => {
            const values = Object.values(item);
            return values.filter(item2 => {
                if (item2 && item2.value) {
                    return JSON.stringify(item2.value || '').toLowerCase().includes(searchInput.toLowerCase())
                } else {
                    return JSON.stringify(item2 || '').toLowerCase().includes(searchInput.toLowerCase())
                }
            }).length !== 0
        })
        setShowFilters(false)
        setShowDateFilters(false)
        setNumAppliedFilters(num)
        setTableDataView(searchedData);
    }


    const clearDateFilters = (e) => {
        e.preventDefault();
        setDateFilters({ start: '', end: '' })
        setTableDataView(tableData)
        setShowDateFilters(false)
        setNumAppliedFilters(0)
    }

    console.log("Data : ",data);

    return (
        <Fragment>
            <div className="card shadow-sm border" style={{ marginBottom: '0px' }}>
                {isHeader && <div className="card-header">
                    <div className="row align-items-center no-gutters">
                        <div className="col">
                            {isSearchable && <Search search={search} searchInput={searchInput} setSearchInput={setSearchInput} />}
                        </div>
                        <div className='col-auto mr-2'>
                            <form>
                                <select defaultValue='10' className="custom-select custom-select-sm form-control-flush px-2" style={{ width: '100px' }} onChange={(e) => { setNumRowsInPages(e.target.value); setTablePage(1) }}>
                                    <option value="5">5 per page</option>
                                    <option value="10">10 per page</option>
                                    <option value="20">20 per page</option>
                                </select>
                            </form>
                        </div>


                        {isDownloadable && <div className="col-auto mr-2">
                            <CSVLink class='btn btn-primary border mb-0' filename="data.csv"
                                data=
                                //  {createCsvData}
                                {data.map(item => {
                                    try {
                                        const obj = { ...item };

                                        const keys = headers.map(item => item.value)
                                        const objRow = {}
                                        for (let i = 0; i < keys.length; i++) {
                                            if (obj[keys[i]] && obj[keys[i]].label) {
                                                if (moment(obj[keys[i]].value).isValid() && moment(obj[keys[i]].value, 'Do MMM YY, hh:mm A').isValid() && (obj[keys[i]].label && typeof obj[keys[i]].label === 'string' && !obj[keys[i]].label.startsWith('Rs'))) {
                                                    objRow[keys[i]] = obj[keys[i]].label
                                                }

                                                else if (typeof (obj[keys[i]].value) === "number") {
                                                    objRow[keys[i]] = moment.unix(obj[keys[i]].value).format("Do MMM YY, hh:mm A");
                                                }
                                                else {
                                                    objRow[keys[i]] = obj[keys[i]].value
                                                }
                                            }
                                            else if (typeof (obj[keys[i]]) === Object) {
                                                objRow[keys[i]] = obj[keys[i]].value

                                            }
                                            else {
                                                objRow[keys[i]] = obj[keys[i]]
                                            }
                                        }
                                        return objRow
                                    } catch (err) {
                                        console.log(err)
                                        // alert("error in csv")
                                    }
                                })}
                                headers={headers.map(item => ({ label: item.label, key: item.value }))} >
                                <BsCloudDownload />
                            </CSVLink>
                        </div>}
                        {dateFilter && data.length > 0 && (data[0][dateFilter].label ? moment(data[0][dateFilter].label, 'Do MMM YY, hh:mm A').isValid() : moment(data[0][dateFilter], 'Do MMM YY, hh:mm A').isValid()) &&
                            <DateFilters
                                clearDateFilters={clearDateFilters}
                                showDateFilters={showDateFilters}
                                setShowDateFilters={setShowDateFilters}
                                applyDateFilters={applyFilters}
                                dateFilters={dateFilters}
                                setDateFilters={setDateFilters}
                            />}

                        {filters.length > 0 && <div className='col-auto ml-2 mr-2'>
                            <Filters applyFilters={applyFilters} clearFilters={clearFilters} numFilters={numAppliedFilters} showFilters={showFilters} setShowFilters={setShowFilters}>
                                {filters.map((filter, index) => {
                                    const tableHeader = tabelHeaders.filter(item => item.value === filter)[0]
                                    if (!tableHeader) return <></>
                                    return (
                                        <div className="list-group-item" key={index}>
                                            <div className="row">
                                                <div className="col">
                                                    <small>{tableHeader.label}</small>
                                                </div>
                                                <div className="col-auto">
                                                    <select className="custom-select custom-select-sm" value={appliedFilters[index]} onChange={e => changeFilters(e, index)}>
                                                        <option value="*">Any</option>
                                                        {data.map(item => {
                                                            if (item[filter] && item[filter].label) return item[filter].value
                                                            else return item[filter]
                                                        }).filter(onlyUnique).map((item, index) => {
                                                            return <option key={index} value={item}>{item}</option>
                                                        })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Filters>
                        </div>}

                    </div>
                </div>}




                <div className="table-responsive" >
                    <table className={`table table-sm table-nowrap card-table table-hover`} >
                        <thead>
                            <tr>
                                {isSelectable ? (<CheckBoxHeader onSelection={selectAll} selected={allSelected} />) : (<Fragment></Fragment>)}
                                {tabelHeaders.map((item, index) => {
                                    if (item.active === true) {
                                        return (<th onClick={() => sortTable(item.value)} style={{ cursor: 'pointer' }} key={item.value}>
                                            {item.label}
                                            {isSortable && <i className="fas fa-sort"></i>}
                                        </th>)
                                    } else return (<Fragment key={item.value}></Fragment>)
                                })}
                            </tr>

                        </thead>
                        <tbody className="list">
                            {tableDataView.map((row, index) => {
                                if (index < (tablePage * numRowsInPages) && index >= ((tablePage - 1) * numRowsInPages)) {
                                    return (<tr key={index} onClick={() => handleRowClick(row._id)}>
                                        {isSelectable ? <CheckBox isChecked={row.selected} index={index} onSelection={selectRow} /> : (<Fragment></Fragment>)}
                                        {tabelHeaders.map((header, index2) => {
                                            if (header.value === 'actions') return <td key={index2} onClick={e => e.stopPropagation()}>{row[header.value]}</td>
                                            else if (header.value === 'actions11') return <td key={index2} onClick={e => e.stopPropagation()}>{row[header.value].label}</td>
                                            else if (header.value === 'refreshDemo') return <td key={index2} onClick={e => e.stopPropagation()}>
                                                <div class='btn btn-sm btn-primary' onClick={() => refreshDemo(row[header.value].value)}>
                                                    {loading === row[header.value].value ? <span class='spinner-border spinner-border-sm mx-4'></span> : "Refresh Demo"}
                                                </div>
                                            </td>
                                            else if (header.value === 'pay1') return <td key={index2} onClick={e => e.stopPropagation()}>
                                                {row.status !== 'paid' ? <div class='btn btn-sm btn-primary' onClick={() => startPayment(row[header.value].value)}>
                                                    {loading === row[header.value].value ? <span class='spinner-border spinner-border-sm mx-4'></span> : "Record Payment"}
                                                </div> : 'Payment Completed'}
                                            </td>
                                            else if (header.value === 'hideUser') return <td key={index2} onClick={e => e.stopPropagation()}>
                                                {!row[header.value] ? '' : typeof row[header.value] === 'object' ? row[header.value].label : row[header.value]}
                                            </td>
                                            else if (row[header.value] && row[header.value].length > 30) return <Description desc={row[header.value]} />
                                            else return header.active ? (<td key={index2}>{!row[header.value] ? '' : typeof row[header.value] === 'object' ? row[header.value].label : row[header.value]}</td>) : (<Fragment key={index2}></Fragment>)
                                        })}
                                    </tr>)
                                }
                                else return (<Fragment key={index}></Fragment>)
                            })}
                        </tbody>
                    </table>
                </div>

                {isFooter && <div className="card-footer d-flex justify-content-between">
                    <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                        <li className={`page-item ${tablePage === 1 ? 'disabled' : ''}`} style={{ cursor: 'pointer' }}>
                            <div className="page-link pl-0 pr-4 border-right" onClick={() => setTablePage(prev => prev - 1)}>
                                <FiArrowLeft className='mr-1' size={17} /> Prev
                            </div>
                        </li>
                    </ul>

                    <ul className="list-pagination pagination pagination-tabs card-pagination">
                        {tablePage !== 1 && <li><div className='page'><RiSkipBackLine style={{ cursor: 'pointer' }} onClick={() => setTablePage(1)} /> </div></li>}
                        {(1 + Math.floor((tableDataView.length - 1) / numRowsInPages)) > 3 ? <>
                            {tablePage === 1 && <>
                                <li className='active'><div style={{ cursor: 'pointer' }} className='page'>1</div></li>
                                <li><div style={{ cursor: 'pointer' }} className='page' onClick={() => setTablePage(2)}>2</div></li>
                                <li><div style={{ cursor: 'pointer' }} className='page' onClick={() => setTablePage(3)}>3</div></li>
                                <li><div className='page'>...</div></li></>
                            }
                            {tablePage === (1 + Math.floor((tableDataView.length - 1) / numRowsInPages)) && <>
                                <li><div style={{ cursor: 'pointer' }} className='page' onClick={() => setTablePage((Math.floor((tableDataView.length - 1) / numRowsInPages)) - 1)}>{(Math.floor((tableDataView.length - 1) / numRowsInPages)) - 1}</div></li>
                                <li><div style={{ cursor: 'pointer' }} className='page' onClick={() => setTablePage((Math.floor((tableDataView.length - 1) / numRowsInPages)))}>{(Math.floor((tableDataView.length - 1) / numRowsInPages))}</div></li>
                                <li className='active'><div style={{ cursor: 'pointer' }} className='page'>{(1 + Math.floor((tableDataView.length - 1) / numRowsInPages))}</div></li></>
                            }
                            {
                                (tablePage !== 1 && tablePage !== (1 + Math.floor((tableDataView.length - 1) / numRowsInPages))) && <>
                                    <li><div style={{ cursor: 'pointer' }} className='page' onClick={() => setTablePage(tablePage - 1)}>{tablePage - 1}</div></li>
                                    <li className='active'><div className='page'>{tablePage}</div></li>
                                    <li><div style={{ cursor: 'pointer' }} className='page' onClick={() => setTablePage(tablePage + 1)}>{tablePage + 1}</div></li> <li><div className='page'>...</div></li></>
                            }
                        </> :
                            Array(1 + Math.floor((tableDataView.length - 1) / numRowsInPages)).fill(null).map((item, i) =>
                                <li key={i} className={tablePage === i + 1 ? 'active' : ''} style={{ cursor: 'pointer' }}>
                                    <div className="page" onClick={() => setTablePage(i + 1)}>
                                        {i + 1}
                                    </div>
                                </li>
                            )}
                        {tablePage !== 1 + Math.floor((tableDataView.length - 1) / numRowsInPages) && <li><div className='page'><RiSkipForwardLine style={{ cursor: 'pointer' }} onClick={() => setTablePage(1 + Math.floor((tableDataView.length - 1) / numRowsInPages))} /></div></li>}
                    </ul>

                    <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                        <li className={`page-item ${tablePage === (1 + Math.floor((tableDataView.length - 1) / numRowsInPages)) && 'disabled'}`} style={{ cursor: 'pointer' }}>
                            <div className="page-link pl-4 pr-0 border-left" onClick={() => setTablePage(prev => prev + 1)}>
                                Next <FiArrowRight className='ml-1' size={17} />
                            </div>
                        </li>
                    </ul>
                </div>}

            </div>
        </Fragment>
    )
}

const Search = ({ search, searchInput, setSearchInput }) => {

    const searchValue = (value) => {
        setSearchInput(value);
        search(value);
    }

    return (
        <form>
            <div className="input-group input-group-flush">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className="fas fa-search"></i>
                    </span>
                </div>
                <input className="form-control search" type="search" placeholder="Search" value={searchInput} onChange={(e) => searchValue(e.target.value)} />
            </div>
        </form>
    )
}


const CheckBoxHeader = ({ selected, onSelection }) => {
    return (
        <th>
            <div className="custom-control custom-checkbox table-checkbox" onClick={() => onSelection()}>
                <input type="checkbox" className="custom-control-input" checked={selected} readOnly />
                <label className="custom-control-label">&nbsp;</label>
            </div>
        </th>
    )
}


const CheckBox = ({ isChecked, index, onSelection }) => {
    return (
        <td >
            <div className="custom-control custom-checkbox table-checkbox" onClick={() => onSelection(index)} >
                <input type="checkbox" className="custom-control-input" checked={isChecked} readOnly />
                <label className="custom-control-label" style={{ cursor: 'pointer' }}>&nbsp;</label>
            </div>
        </td>
    )
}


const compareValues = (key, order = 'asc') => {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }
        if (!a[key] || a[key] === undefined) {
            return 1
        }
        if (!b[key] || b[key] === undefined) {
            return -1
        }

        if (!a[key].value) {
            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        } else {
            const varA = (typeof a[key].value === 'string')
                ? a[key].value.toUpperCase() : a[key].value;
            const varB = (typeof b[key].value === 'string')
                ? b[key].value.toUpperCase() : b[key].value;

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        }

    };
}







const Description = ({ desc }) => {

    const [fullDesc, setFullDesc] = useState(false);

    return (
        <td>
            <p className='small mb-1'>
                {(desc.length > 50 ? (
                    fullDesc ? (
                        <>{desc} <span className='pointer btn-link' onClick={() => setFullDesc(false)}>Read Less</span></>
                    ) : <>{desc.slice(0, 50)} <span className='pointer btn-link' onClick={() => setFullDesc(true)}>... Read Full</span></>
                ) : (desc))}
            </p>
        </td>
    )
}




export default Table;




const Filters = (props) => {

    const dropdown = React.useRef(null);
    const handleClickOutside = event => {
        if (dropdown.current && !dropdown.current.contains(event.target)) {
            props.setShowFilters(false)
        }
    };

    // eslint-disable-next-line
    useEffect(() => document.addEventListener("mousedown", handleClickOutside), [])


    return (
        <div className="dropdown" ref={dropdown}>
            <button className="btn btn-sm btn-white border d-flex align-items-center" type="button" onClick={() => props.setShowFilters(!props.showFilters)}>
                <FaSlidersH className='mr-1' size={13} />
                Filters
                {props.numFilters !== 0 && <span className="badge badge-primary mt-1 ml-1">{props.numFilters}</span>}
            </button>
            <form className={`dropdown-menu dropdown-menu-right dropdown-menu-card ${props.showFilters ? 'show' : ''}`} >
                <div className="card-header">
                    <h4 className="card-header-title">
                        Filters
                    </h4>
                    <button className="btn btn-sm btn-link text-reset" onClick={(e) => props.clearFilters(e)}>
                        <small>Clear</small>
                    </button>
                </div>
                <div className="card-body">
                    <div className="list-group list-group-flush mt-n4 mb-4">
                        {props.children}
                    </div>
                    <button className="btn btn-block btn-primary" type="submit" onClick={(e) => props.applyFilters(e)}>
                        Apply
                    </button>
                </div>
            </form>
        </div>

    )
}





function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}



const DateFilters = ({ value, onChange, clearDateFilters, applyDateFilters, setShowDateFilters, showDateFilters, dateFilters, setDateFilters }) => {

    const dropdown = React.useRef(null);
    const handleClickOutside = event => {
        if (dropdown.current && !dropdown.current.contains(event.target)) {
            setShowDateFilters(false)
        }
    };

    // eslint-disable-next-line
    useEffect(() => document.addEventListener("mousedown", handleClickOutside), [])


    return (
        <div className="dropdown" ref={dropdown}>
            <button className="btn btn-sm btn-white border d-flex align-items-center" type="button" onClick={() => setShowDateFilters(!showDateFilters)}>
                <FaSlidersH className='mr-1' size={13} />
                Date Filters
            </button>
            <form className={`dropdown-menu dropdown-menu-right dropdown-menu-card ${showDateFilters ? 'show' : ''}`} >
                <div className="card-header">
                    <h4 className="card-header-title">
                        Filters
                    </h4>
                    <button className="btn btn-sm btn-link text-reset" onClick={(e) => clearDateFilters(e)}>
                        <small>Clear</small>
                    </button>
                </div>
                <div className="card-body">
                    <div className="list-group list-group-flush mt-n4 mb-4">
                        <div className="list-group-item" >
                            <div className="row">
                                <div className="col">
                                    <small>Start Date</small>
                                </div>
                                <div className="col-auto">
                                    <InputMaskItemSm
                                        error={false}
                                        setError={() => { }}
                                        errorText=""
                                        placeholder={"DD/MM/YYYY"}
                                        mask={"99/99/9999"}
                                        value={dateFilters.start}
                                        setValue={e => setDateFilters(prev => ({ ...prev, start: e }))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="list-group-item" >
                            <div className="row">
                                <div className="col">
                                    <small>End Date</small>
                                </div>
                                <div className="col-auto">
                                    <InputMaskItemSm
                                        error={false}
                                        setError={() => { }}
                                        errorText=""
                                        placeholder={"DD/MM/YYYY"}
                                        mask={"99/99/9999"}
                                        value={dateFilters.end}
                                        setValue={e => setDateFilters(prev => ({ ...prev, end: e }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-block btn-primary" type="submit" onClick={(e) => applyDateFilters(e)}>
                        Apply
                    </button>
                </div>
            </form>
        </div>

    )

}