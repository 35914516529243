const Doc = () => (
    <div>
        <div class="mb-6">
            <h3 id="tnc-clients">DHUNGURU TERMS OF USE FOR CLIENTS ("CLIENTS TERMS")</h3>
            <p>Last Updated: May 28, 2024</p>
            <p><strong>IMPORTANT NOTICE:  PLEASE READ THROUGH THESE TERMS CAREFULLY.</strong><br />
                This Client Terms (“Agreement”) is entered into between Dhunguru India Private Limited (“the company”) located at WeWork DLF Two Horizon Centre, 5th Floor, DLF Phase 5, Gurugram, Haryana 122002 and (Name of the Client)  resident of (address of the client) is effective of this date (date ).<br /> </p>

            <p> The following document, known as the <strong>(“Client Terms/Agreement”)</strong> outlines the terms and conditions governing your access to and utilization of our online website or <strong>(“Platform”).</strong> This platform serves as a conduit connecting Client and Therapists for Music Therapy. By engaging with the Platform, you enter into a legally binding agreement with Dhunguru, and it is imperative that you thoroughly review and comprehend these Terms of Use for Client.</p>

            <p>By accessing or utilizing the Platform, you hereby acknowledge and agree to adhere to the Client Terms, as well as the applicable Terms of Use for Client and Therapists Applicants ("Therapists Terms"), the policies, and guidelines hyperlinked within these Client Terms, and any prospective modifications thereof (collectively referred to as the <strong>"Agreement"</strong>). This commitment holds true regardless of whether you assume the role of a "Client," defined as an individual participating in one or more Music Therapy Sessions facilitated by a Music Therapist on the Platform.</p>

            <p>A <strong>(“Music Therapist/Therapist”)</strong> as used herein refers to an individual who seeks and obtains Dhunguru’s approval to offer his or her services as a therapist through the Platform.</p>

            <p>A <strong>(“User”)</strong> shall mean any natural or legal person, who is competent to enter into a contract as per the Indian Contract Act, 1872, who uses the Platform, with or without registration, to avail the services offered, for himself or herself and/or for any other person.</p>

            <p>If you find yourself unable to comprehend or align with these Client Terms of Use, we kindly request that you refrain from using the Platform. It is important to note that we bear no obligation to actively enforce the Agreement on your behalf against another user. Nevertheless, we encourage you to communicate with us should you believe that another user has contravened the terms of the Agreement. While we appreciate your cooperation in reporting potential violations, it is imperative to understand that we retain the right to conduct investigations and take appropriate action, at our sole discretion.</p>

            <p>PLEASE CAREFULLY READ THESE TERMS OF USE AND THE PRIVACY POLICY (AS PART OF THE APPLICABLE TERMS) WITH YOUR PARENT OR LEGAL GUARDIAN IF YOU ARE UNDER EIGHTEEN (18) YEARS OF AGE OR THE AGE OF MAJORITY IN YOUR JURISDICTION. BY USING OR ACCESSING THIS SITE, YOU REPRESENT THAT YOU (OR YOUR PARENT OR LEGAL GUARDIAN ON YOUR BEHALF IF YOU ARE A MINOR) HAVE READ, UNDERSTOOD AND AGREE TO THE TERMS OF USE IN THEIR ENTIRETY, INCLUDING THE PRIVACY POLICY. IF YOU (OR YOUR PARENT OR LEGAL GUARDIAN ON YOUR BEHALF IF YOU ARE A MINOR) DO NOT AGREE WITH ANY PART OF THE TERMS OF USE, INCLUDING THE PRIVACY POLICY, THEN PLEASE DO NOT USE OR ACCESS THE SITE.  </p>

            <p>All persons using the Platform at any time expressly agree not to use any aspect of the Platform for any purpose other than its intended Purpose as a Platform for (a) the purchase of Sessions and/or (b) availing Sessions. If you use the Platform for any other purpose, you violate this Agreement. Any violation of this Agreement may be punished by, without limitation, refusal of access to the Platform.</p>

            <p>If you object/disapprove to anything in this Agreement (including anything in our Privacy Policy or other applicable terms), please immediately terminate your use of the Platform. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS PLATFORM IS AT YOUR SOLE RISK. WE RESERVE THE RIGHT TO DISCONTINUE ANY ASPECT OF THE PLATFORM AT ANY TIME. THIS AGREEMENT MAY BE TERMINATED AT ANY TIME BY EITHER PARTY (DHUNGURU OR YOU), FOR ANY REASON. ANY LICENSE YOU GRANT DHUNGURU THROUGH ANY AGREEMENT WITH DHUNGURU WILL SURVIVE TERMINATION OF THE AGREEMENT WITH DHUNGURU. </p>

            <p>This Agreement is published in compliance of, and is governed by the provisions of Indian law, including but not limited to:</p>
            <ul>
                <li>the Indian Contract Act, 1872,</li>
                <li>the (Indian) Information Technology Act, 2000, and</li>
                <li>Rule 3(1)(a) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021</li>
                <li>Rule 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (“SPI Rules”)</li>
            </ul>
            <ol >
                <li className='my-3'>
                    <strong> Client’ Accounts..</strong> <br />
                    <ul>
                        <li>You must register/create an account and provide certain information about yourself in order to use some of the features that are offered through the Platform. You are responsible for maintaining the confidentiality of your account password. You are also responsible for all activities that occur in connection with your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any reason. Your account is for your individual use only. You must provide complete and accurate information about yourself when creating your account. You may not impersonate someone else (e.g., adopt the identity of anyone other than yourself), create or use an account for anyone other than yourself, provide an email address other than your own, or create multiple accounts. You may not use a pseudonym. Please read the applicable Privacy Policy for information concerning how we handle your information. </li>
                        <li>Individuals who have not completed the registration process with Dhunguru and do not hold the status of Client may be denied access to specific areas within the Platform designated for the exclusive use of Client.</li>
                        <li>If you create an account with Dhunguru for and on behalf of any other Party/ third party (other than for your use) like your family member(s)/friend(s)/child/, you acknowledge that you are duly authorized by the other Party/third party to create an account with Dhunguru by furnishing such details as may be required for using the Platform.</li>
                        <li>In case you are creating account on behalf of a minor, you acknowledge that you are legally authorized to act on behalf of such minors. You acknowledge that Dhunguru shall in no case be held responsible/liable for any third party claims/damages if any arising from any action in this regard.</li>
                    </ul>
                </li>
                <li className='my-3'>
                    <strong>Children.</strong> We do not knowingly allow or solicit anyone under the age of 18 to participate directly in our services, unless accompanied and authorized by the parents/guardians, who may or may not be our subscribed members.
                </li>
                <li className='my-3'>
                    <strong>Communications from Dhunguru and other users.</strong>      Upon creating an account, you hereby consent to receiving specific communications related to the Platform. These communications may include messages from Therapists or potential Therapists seeking information about your availability and related matters. You agree and acknowledge that Dhunguru retains the right to access and review any messages sent through the Platform's internal messaging service, without limitation to any reason. Dhunguru reserves the discretion to utilize the information contained in such messages for any purpose. Additionally, you may receive marketing communications from Dhunguru, and you retain the option to opt-out of such communications.<br />
                    If you unsubscribe from the Platform and again intend to subscribe to the Platform, you are authorizing Dhunguru to send such information as may be required, including but not limited to SMS, emails for enabling the User(s) to continue to avail the Services. User(s) hereby explicitly consent and agree that any request pursuant to this clause will override any opt-out/override any opt-out unsubscribe request given by them earlier.

                </li>
                <li className='my-3'>
                    <strong>Proprietary Rights, Materials and Client Content. </strong>      Dhunguru, along with its licensors, exclusively owns and retains all proprietary rights in the Platform. The Platform comprises content, visual interfaces, interactive features, information, graphics, design, compilation, computer code, products, software, services, and other elements (referred to as the “Dhunguru Materials”), all of which are protected by copyright, trade dress, patent, and trademark laws, international conventions, and other pertinent intellectual property and proprietary rights, as well as applicable laws. Unless information is in the public domain or has been expressly granted written permission, you are prohibited from copying, modifying, publishing, transmitting, distributing, licensing, performing, displaying, or selling any Dhunguru Materials. It is essential to note that Dhunguru Materials exclude Client Content (as defined below), or other content submitted by users. Dhunguru retains all rights not explicitly granted in this Agreement, and you shall not acquire any right, title, or interest to the Platform or Dhunguru Materials, except for the limited rights outlined in this Agreement.<br />

                    “Client Content” refers to any content or material of any kind emailed, uploaded or otherwise submitted by a Client to or through the Platform or us, or provided by a Client during or in connection with a Session given through the Platform. As the Client, you bear responsibility for the accuracy and appropriateness of the submitted Client Content. By submitting Client Content, you hereby grant Dhunguru a perpetual, non-exclusive, worldwide license to use all or part of the submitted Client Content in any manner for the Purpose of the Platform. This license, granted by you, shall remain in effect beyond the termination or expiration of this Agreement. Dhunguru reserves the right, at its sole discretion and without notice to you, to moderate, remove, screen, edit, or reinstate Client Content from time to time. We do not have an obligation to retain or provide you with copies of Client Content, and we do not guarantee any confidentiality with respect to Client Content.<br />

                    This Agreement outlines a partial list of Client Content that is deemed illegal or prohibited on the Platform. Dhunguru expressly reserves the right, at its sole discretion, to conduct investigations and take appropriate legal action against any individual who violates this provision. Such actions may include, without limitation, the removal of the offending communication from the Platform and termination of the membership of violators. The prohibited Client Content includes, but is not limited to:<br />
                    <ul>
                        <li>Content that is patently offensive to the online community, promoting racism, bigotry, hatred, or physical harm against any group or individual</li>
                        <li>Content that harasses or advocates harassment of another person</li>
                        <li>Involvement in the transmission of 'junk mail,' 'chain letters,' or ‘unsolicited mass mailing’, or 'spamming'</li>
                        <li>Promotion of information known to be false, misleading, or encouraging illegal activities or conduct that is abusive, threatening, obscene, profane, offensive, sexually oriented, racially offensive, defamatory, or libelous</li>
                        <li>Promotion of an illegal or unauthorized copy of another person’s copyrighted work, including providing pirated computer programs or links to them, information to circumvent manufacture-installed copy-protection devices, or pirated images, audio, or video, or links to pirated images, audio, or video files;</li>
                        <li>Content containing restricted or password-only access pages, hidden pages, or images (those not linked to or from another accessible page);</li>
                        <li>Material that exploits people under the age of 18 in a sexual or violent manner or solicits personal information from anyone under the age of 18;</li>
                        <li>Instructional information about illegal activities such as making or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses;</li>
                        <li>Solicitation of passwords or personal identifying information for commercial or unlawful purposes from other users and</li>
                        <li>Engagement in commercial activities and/or sales without our prior written consent, such as contests, sweepstakes, barter, advertising, and pyramid schemes.</li>
                    </ul>

                </li>
                <li className='my-3'>
                    <strong>Reliance on Content.</strong>  Advice, opinions, statements, offers, or any other information or content, including without limitation Client Content, made available through the Platform but not directly by Dhunguru, are the sole responsibility of their respective authors. Users should exercise discretion and not necessarily rely on such information. Authors bear full responsibility for the accuracy and integrity of their content. Dhunguru explicitly disclaims (i) any guarantee of the accuracy, integrity, quality, legality, safety, completeness, or usefulness of any information on the Platform and/or (ii) adopts, endorses, nor assumes responsibility for the accuracy or reliability of any opinion, advice, or statement made by any party on the Platform. Under no circumstances will Dhunguru or its affiliates be held responsible for any loss or damage resulting from reliance on information or other content posted on the Platform or transmitted to or by any Platform users.<br />
                </li>
                <li className='my-3'>
                    <strong>Adequate Equipment and Bandwidth..</strong>  For the purpose of using the platform a computer and webcam is required. We cannot guarantee that the Platform will function at any given time using any particular hardware or connection. Connectivity greater than or equal to 500 kbps (upload and download speed) is required to use the Platform. Suggested minimum equipment and software requirements are provided below:<br />
                    <strong>Supported OS Windows</strong><br />
                    10, 8.1, 8, 7, XP;
                    Mac: OS X 10.6 or higher;
                    Linux: Debian 7.0; Fedora Core 18, 19; openSUSE 12.2, 12.3; Red Hat 6.1, 6.3; Scientific Linux (SL) 6.1, 6.3; Ubuntu 12.04, 12.10, 13.04, 13.10<br />
                    <strong>Recommended Configuration System</strong><br />
                    Core 2 Duo 2GHz; 2 GB RAM; 40 MB free disk space For HD: Graphics capabilities that support HD resolutions on one or more displays<br />
                    <strong>Webcams</strong><br />
                    Supported: Standard integrated webcams
                    Preferred: External USB webcams that support 720p30 and higher<br />
                    <strong>Microphones and Speakers</strong><br />
                    Supported: Integrated system microphones and speakers, webcam microphones
                    Preferred: Headsets and speakerphones with integrated echo cancellation<br /><br />

                    <strong>It is the sole responsibility of the Client to manage a good audio/video quality for taking the sessions. Dhunguru will have no liability if any discrepancy arises out of the audio or video quality from the end of the Client.</strong><br /><br />
                </li>
                <li className='my-3'>
                    <strong>Journey.</strong> Upon registering as a Client on the Platform for availing Therapy sessions, your journey flows in the below manner –
                    <ul>
                        <li><strong>Connect with the Dhunguru team.</strong>Our team reaches out to you on call/via message to have a brief understanding of your issue in order to further align you with a senior Therapist.</li>
                        <li><strong>Assessment with our senior Therapist.</strong>After the first connect with our team, you will then have your assessment session with one of our senior Therapists to deeply understand your concern and to accordingly curate a unique treatment plan with our therapists.</li>
                        <li><strong>Progress Monitoring.</strong>Your regular therapy sessions will be under the direct supervision of the senior Therapist.</li>
                    </ul>
                </li>
                <li className='my-3'>
                    <strong>Assessment.</strong> The Client agrees, acknowledges and after exercising reasonable knowledge and understanding, consents that – <br />
                    A. The purpose of an assessment is to obtain relevant information through interview(s), questionnaire(s)/test(s), observations, and/or file review, in order to answer questions about the Client’s functioning, identify areas of strengths and relative are explained and summarized in a written report.The assessment process may have an emotional impact (e.g., nervousness related to testing) and may result in a diagnosis(es).  <br />
                    B. The overall time required depends on the nature of the assessment and the consultation question that is being addressed. There can be no guarantees about the outcome of an assessment. Further, undergoing an assessment may involve discussing unpleasant aspects of your life and may lead to unanticipated results and/or conclusions you find to be discomforting. <br />
                    C. We work as a team and the Therapists will consult and discuss with each other information related to your assessment. The Therapist involved in your assessment will supervise the assessment process. Assessment of an individual under the age of 18 requires the consent of parent(s)/guardian(s). Clients under the age of 18 may not have full confidentiality from their parents.  <br />
                    D. There are potential benefits and risks of videoconferencing that differ from in-person sessions. You will need to use a webcam or smartphone during the session. It is important to be in a quiet, private space that is free of distractions (including cell phone or other devices) during the session. It is important to use a secure internet connection rather than public/free Wi-Fi.  <br />
                </li>
                <li className='my-3'>
                    <strong>Session Scheduling.</strong>       A Client has the option to select from the list of available session time on a Therapists’ schedule. Alternatively, a Client may contact a Therapist to propose alternative availability, and the Therapists, at their discretion, may either accept or deny the Client availability request. A Client is permitted to schedule a session up to 6 hours prior to the designated time, contingent upon Therapist’s availability. In the event that the Therapist and Client mutually agree to convene with less notice (i.e., if the Therapist extends an invitation to meet for a session at a time less than 6 hours later), such practice is generally permitted by the Platform.<br />
                    Client are not allowed to use link of any other platform (E.g. Google Meet, Microsoft Teams Meet etc.) except the Zoom meeting link provided by Dhunguru. The Client cooperation in adhering to this guideline is essential for maintaining a professional and effective environment. If you encounter any issues or have concerns accessing the session through Zoom Meeting Link provided by us, please reach out directly to Dhunguru for assistance.<br />

                    In instances where Client choose to participate in sessions through alternative platforms, Dhunguru disclaims all responsibility for the conduct of the Therapists or the session itself. These sessions fall outside the purview of Dhunguru and are strictly prohibited. If a Therapist joins a session and subsequently requests cancellation, we kindly request that you promptly report such instances to us for proper documentation and resolution.
                </li>
                <li className='my-3'>
                    <strong>Re-Scheduling/Cancellation.</strong>      Sessions are intended to proceed as scheduled. Sessions may be rescheduled with Appropriate Notice. In the event of rescheduling or cancelling a session, the party initiating the rescheduling must promptly contact the other party via the Platform's internal messaging service, providing notification at least 2 hours prior (“Appropriate Notice”) to the scheduled session time. This communication should occur as soon as reasonably possible.<br />

                    If you cancel/re-schedule as stated above, the session will not be deducted from your balance. In the event of cancellation or rescheduling within 2 hours of the scheduled session time, your request will be forwarded to your Therapist, who will then have the discretion to either apply charges or waive them for the session. If the decision is to charge, the session fee will be deducted from your balance.<br />

                    If you possess only 1 session remaining, rescheduling beyond the 2-hour deadline will not be permitted. However, you may request cancellation by providing a detailed explanation, and if your Therapist opts not to apply charges, you may then avail the session at a later date and/or time.
                </li>
                <li className='my-3'>
                    <strong> No-show policy.</strong>In cases where the Client delays the session for more than Fifteen (15) Minutes or/and does not attend the session or/and has not rescheduled the Session and/or made other arrangements with the Therapist, will not receive any Refund for the Session. Additionally, that Session shall be deducted from your Session balance.  Client are strongly encouraged to openly communicate with Therapists and, when necessary, utilize the Platform to seek rescheduling of sessions promptly and with Appropriate Notice. It is imperative that Client provide ample time for rescheduling to minimize disruption to Therapist’s schedules. Client repeatedly cancelling sessions without Appropriate Notice may face consequences, including limitations on Platform access, potential prohibition from acting as a Client, or denial of access to the Platform.
                </li>
                <li className='my-3'>
                    <strong>Recorded Sessions.</strong>
                    All sessions conducted by Dhunguru are automatically recorded ("Recorded Session") based on the explicit consent obtained from both Therapist and Client during the sign-up process. By utilizing the Platform, the Client expressly grant Dhunguru the irrevocable right to record each session. Dhunguru may review Recorded Sessions for quality, audit or inspection purposes but is generally not obligated to do so, nor is it expected. Both Therapists and Client acknowledge and agree to the following:<br />
                    <ul>
                        <li>Dhunguru is the sole owner of all rights, including copyright, associated with any Recorded Sessions. Recorded Sessions are intended to be used exclusively for the Purpose of the Platform, specifically for Music Therapy Sessions.</li>
                        <li>Recorded Sessions will be utilized in accordance with the purposes outlined herein.</li>
                        <li>Audio and/or video from Recorded Sessions may not be posted on any third-party media-hosting website (e.g., SoundCloud, YouTube, or Vimeo) without the express permission of Dhunguru.</li>
                        <li>The sessions recordings will not be furnished to either the Client or the Therapists.</li>
                    </ul><br />
                    <strong>PLEASE DO NOT USE THE PLATFORM IF YOU DO NOT CONSENT FOR THE SESSIONS TO BE RECORDED.</strong>
                </li>
                <li className="my-3">
                    <strong>Payments</strong>
                    Dhunguru Music India Private Limited exclusively reserves the right to receive payment from a Client at the time of purchasing single or multiple Music Therapy Sessions, and only upon completion of this payment is the transaction considered finalized. Payment by the Client creates Platform credit equivalent to the number of Sessions bought, that the Client may use with the Therapists(s) on the Platform. Dhunguru will disburse payment to Therapists in the manner prescribed in the <strong>(“Therapist Terms”)</strong>.

                    The Client shall remit the session price established by their selected Therapists for Platform usage through credit card/Debit Card (MasterCard, Visa, or American Express, processed via Razorpay) or Net Banking or UPI transactions. By utilizing the Platform, the Client consents to the payment of the price designated by the Therapist for their chosen Music Therapy Session. Furthermore, the Client authorizes Dhunguru to charge the selected payment provider (MasterCard, Visa, or American Express) for the Client’s use of the Platform in connection with each selected session, thereby committing to fulfil the corresponding payment using the chosen payment method. Dhunguru retains the right to rectify any errors or mistakes, even in cases where payment has already been requested or received. Failure to remit the session price at the time of payment processing may result in the Client being denied access to the respective session through the Platform. (For information concerning Client’s payment information handling, please see the <strong>Privacy Policy</strong>.)
                </li>
                <li className='my-3'><strong>Refund Request Procedure.</strong> Every request for a refund of Client’s payment for a session ("Refund") will be approached with meticulous attention on a case-by-case basis. In the event of dissatisfaction following a Music Therapy Session, we kindly request that you contact our dedicated support team at +918045683666 within forty-eight (48) hours of the session or before scheduling the subsequent session. In such instances, we are committed to offering you a complimentary replacement session with a Therapist of your choice or facilitating the selection of an alternative Therapists. Should dissatisfaction persist, we will credit a session to your balance, allowing you to redeem it for another session of equal value. It is imperative to note that the Satisfaction Guarantee does not extend to cases where a Client misses a session or arrives late due solely to their own actions.<br />
                    In situations where a Therapist is unable to conduct a session due to a Client's (i) inadequate hardware/bandwidth or (ii) inappropriate conduct, Dhunguru typically does not issue a refund to the Client. If a Client perceives that a Therapist's conduct makes the session unfeasible, it is essential for the Client to promptly inform Dhunguru. All instances of this nature, including any discussions related to potential refunds, will be thoroughly examined and addressed on a case-by-case basis.<br />
                    Additionally, if a Therapist cancels a Music Therapy Session, the Client has the option to request a refund for the cancelled session. Alternatively, the Client may choose to reschedule the session with the same Therapist.
                </li>
                <li className='my-3'><strong>User Conduct.</strong>
                    The Client is urged to uphold a standard of respectful conduct during sessions, refraining from any form of misbehaviour, harassment, or verbal abuse directed towards the Therapists. In the event that Client observe inappropriate behaviour from a Therapists, it is expected that they promptly report such incidents to Dhunguru for appropriate action and resolution. Dhunguru expressly disclaims responsibility for the conduct, whether online or offline, of any user of the Platform. Under no circumstances will Dhunguru or any of its affiliates, advertisers, promoters, or distribution partners be held responsible for any loss or damage, including personal injury or death, resulting from anyone's use of the Platform, any content posted on the Platform or transmitted to users, or any interactions between users, whether online or offline. Dhunguru is not liable for any loss or damage, including personal injury or death, resulting from any events or actions or any interaction between a user of the Platform and any third party. Dhunguru cannot guarantee and does not promise any specific results from the use of the Platform.
                </li>
                <li className='my-3'><strong>Platform Use. </strong><br />
                    <strong>A.</strong> The utilization of the Platform is intended for individuals aged 18 and above, or for users under the age of 18 who have proper authorization and supervision from their parent and/or legal guardian and are otherwise allowed by the terms outlined herein.<br />
                    <strong>B.</strong> Registration and participation on the Platform are limited to individuals aged 18 and above, emancipated minors, or those with valid parental or guardian consent, who are fully capable of agreeing to the terms, conditions, obligations, affirmations, representations, and warranties outlined herein. By registering or participating in services or functions on the Platform, you affirm that you are either over 18 years of age, an emancipated minor, or possess consent from a legal parent or guardian, demonstrating the authority to enter into the terms herein.<br />
                    <strong>C.</strong> Parents and legal guardians should be cognizant/aware that, while Dhunguru mandates users under 18 to affirm through these terms that they are either emancipated or have the authorization of their parents or legal guardians, and despite Dhunguru's precautions to prevent users under 13 from interacting with the platform without parental or legal guardian consent, the ultimate responsibility/liability lies with parents and legal guardians to supervise the online activities of their children. Parental control mechanisms are widely available for those interested in regulating their children's access to online materials. Dhunguru cannot be held accountable for the activities of platform users.<br />
                    <strong>D.</strong> If your utilization of the Platform results in the exposure of any user under the age of 18 to sexually explicit material, and Dhunguru becomes aware of such conduct, we are obligated by law to report your actions to the appropriate authorities. Furthermore, other forms of conduct that violate this Agreement may be reported to the relevant authorities at our discretion, determined on a case-by-case basis.<br />
                    <strong>E.</strong> The use of this Platform must be for lawful purposes only and in accordance with the Purpose of the Platform. You are prohibited from assisting others in engaging in any activities that are not permissible under the terms of the Agreement. You must refrain from (a) submitting or transmitting any content or material through the Platform or (b) engaging in any conduct that Dhunguru, at its sole discretion, deems:<br />
                    <div className='ml-5'>
                        i. violates or infringes the rights of others, including but not limited to patent, trademark, trade secret, 	copyright, privacy, publicity, or other proprietary rights;<br />
                        ii. is unlawful or objectionable at our sole discretion, including but not limited to materials or conduct(s) that is threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, obscene, invasive of another’s privacy, pornographic, sexually explicit, or tortiously interferes with another;<br />
                        iii. forges email headers or disguises the origin of any communication;<br />
                        iv. sends unsolicited bulk messages within the internal messaging service of the Platform;<br />
                        v. use of automated “bots” to access or compile information posted with respect to the Platform;<br />
                        vi. victimizes, harasses, degrades, or intimidates any individual or group of individuals;<br />
                        vii. impersonates any person, business, or entity;<br />
                        viii. expresses or implies that any statements made by you are endorsed by Dhunguru without our specific prior written consent;
                        ix. that any statement(s)/conduct(s) by you are endorsed by Dhunguru without our specific prior written consent;<br />
                        x. “frames” or “mirrors” any part of the Platform, without Dhunguru’s prior written authorization. Additionally, You must not use meta tags or code or other devices containing any reference to Dhunguru or the Platform in order to direct any person to other web site for any purpose;<br />
                        xi. modifies, adapts, sublicenses, translates, sells, reverse engineers, deciphers, decompiles or otherwise disassembles any portion of the Platform or any software used on or for the Platform, or cause others to do so;<br />
                        xii. encourages any conduct that constitutes a criminal act or that gives rise to a civil liability;<br />
                        xiii. promotes any business other than the Platform or your services as offered through the Platform in accordance with the Purpose of the site; or<br />
                        xiv. violates the Agreement or otherwise interferes with the rights of others.
                    </div>
                    <strong>F.</strong> You additionally agree not to post or transmit any image of another person without obtaining that person's explicit consent. Furthermore, you may not utilize the Platform in any manner that has the potential to damage, disable, overburden, or impair Dhunguru's servers, or disrupt the use and enjoyment of the Platform by any other party. Unauthorized attempts to access services or information for which you have not been granted access, including password mining or any other illicit processes, are strictly prohibited.<br />
                    <strong>G.</strong> We may take any legal and technical remedies to prevent the violation of this Agreement and to otherwise enforce the Agreement at our sole discretion.
                </li>
                <li className='my-3'><strong>No Therapist-Patient Relationship between Us. </strong><br />
                    <strong>A.</strong> Please note that some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, “Information”) that may be available on the Platform (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between Dhunguru and you and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is only provided to assist you with locating appropriate medical care from a qualified practitioner.<br />
                    <strong>B.</strong> We do not practice therapy of any kind and do not provide medical consultation. We merely provide a technology platform and infrastructure that enables you to connect with participating Music Therapy practitioners listed on the Service and to use the messaging or communication facilities of the Service that are designed to enable you to consult with a Music Therapist.<br />
                    <strong>C.</strong> Music Therapists listed on the Service have signed / accepted contracts with us and represented to us that they are duly qualified to practice Music Therapy in India. While we make reasonable enquiries to confirm the veracity of this representation made to us by the Music Therapy practitioners, we shall not be responsible for any misrepresentation or fraud in this regard. We do not recommend or endorse any Music Therapy practitioners or make any representations or warranties with respect to the quality of the medical services a medical practitioner may provide to you.<br />
                    <strong>D.</strong> We make no guarantees, representations or warranties, whether expressed or implied, with respect to professional qualifications, quality of work, expertise or other information provided on the Website. In no event shall we be liable to you or anyone else for any decision made or action taken by you in reliance on such information.<br />
                    <strong>E.</strong> Any plans or advice or information that may be provided on or through the Service is for information only, does not constitute a medical opinion or advice or a diagnosis or treatment and is not a substitute for professional medical advice, diagnosis or treatment.<br />
                    <strong>F.</strong> We are not responsible for the accuracy of the results of any Self-Assessment Tests that may be made available as part the Service. These tests are solely for information purposes to suggest probable risks to users having a particular health issue and the results of the tests do not constitute a medical opinion or diagnosis. You should consult a medical professional before acting on the results of any such tests.<br />
                    <strong>G.</strong> The Services are not intended to be a substitute for getting in touch with emergency healthcare. If you are an End-User facing a medical emergency (either on your or a another person’s behalf), please contact an ambulance service or hospital directly

                </li>
                <li className='my-3'><strong>Engagement/ Interactions with other users. </strong><br />
                    <strong>A.</strong> Users of the Platform, including Therapists, Client, prospective Therapists, and prospective Client, are individually and solely responsible for their interactions. Dhunguru explicitly disclaims any representations or warranties regarding the conduct of Platform users and shall not be held liable for any user's behaviour. Users agree to exercise reasonable precautions in all interactions, especially in offline or in-person meetings. Under no circumstances should financial information, such as credit card or bank account details, be shared with any other Platform user.<br />
                    <strong>B.</strong> Release. You hereby release Dhunguru from any and all claims, demands, damages (actual, consequential, nominal, punitive, or otherwise), equitable relief, and any other legal, equitable, and administrative remedy, of every kind and nature, whether known or unknown, suspected or unsuspected, disclosed or undisclosed, past, present, or future, arising out of or in any way connected with your interaction with other users of the Platform.<br />
                    <strong>C.</strong> DHUNGURU HAS NO DUTY TO MONITOR COMMUNICATIONS AND/OR INTERACTIONS TAKING PLACE ON OR THROUGH THE PLATFORM.<br />
                    <strong>D.</strong> SEXUALLY EXPLICIT MATERIAL IS NOT ALLOWED TO BE USED/TRANSMITTED THROUGH THE PLATFORM.

                </li>
                <li className='my-3'><strong>Exchange of Personal Information.</strong>
                    Exchange of contact information including but not limited to Mobile/Phone Number, Email, YouTube Account, Instagram Account, Address, between Clients and Therapists is strictly prohibited. It is irrelevant whether the contact details were provided by the Clients or acquired from the Therapists; any form of exchange is strictly prohibited. Clients found in violation of this policy will be blocked from using the Platform and scheduling further sessions. Furthermore, in the event of a Client's breach of these Terms, including but not limited to this section, any remaining sessions in the Client's enrolment will be forfeited, and Dhunguru shall bear no liability for providing additional sessions or Refund to the Clients.
                </li>
                <li className='my-3'><strong>Sharing of Information </strong><br />
                    A. Information including but not limited to contact details, email ID health information and such other information as may be required will be shared with Third Party Service Provider(s), retailers, logistics service providers, E-mail Service Providers and other providers for providing the Services for the User(s) pursuant to the user(s) requests, especially while facilitating the services. Information is likely to be exchanged while fulfilling orders, delivering medicines, laboratory reports etc. and for sending communications on the Digital Platform and through e-mailers.<br />
                    B. User(s) Information/data/consultation transcripts and/or summary (including but not limited to telephonic, Video and chat conversations) that might be generated during their interaction with Healthcare Professionals (HCPs)/transactions on the Platform may be used by Dhunguru or shared with Third Party(ies) for the purpose of (1) review with the intent of improving the overall quality of services and care, training of Healthcare Professionals, for providing enhanced consultation experience to User(s), (2) handling complaints, and (3) meeting the regulatory or statutory requirements. Dhunguru assures that any information exchanged for the said purpose shall be subject to stringent confidentiality obligations imposed on Third Party(ies).<br />
                    C. User(s) Information/data in de identified form may be used by Dhunguru or shared with Third Party(ies) either in India or outside India for analytical purposes and for improving User(s) experience and User(s) use of the services.<br />
                    D. User(s) Information/Data may also be used for providing (a) assistance, (b) effective search results and links (including listings and paid services) and (c) customer experience/service. Dhunguru assures that any information exchanged herein shall be in accordance with its Privacy Policy. Dhunguru may share the information given by the User(s) if directed by any judicial or a statutory authority or against receipt of judicial or statutory order or to comply with applicable law. Please be assured that no such information will be provided to any Third Party for any commercial purpose in any such way which is contrary to the commitments made under the Privacy Policy.<br />
                    E. Dhunguru may share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of this Agreement and/or privacy policy, or as otherwise required law. In the event, if there is any information which has to be provided outside the scope of this Privacy Policy, a prior notification and request for consent /approval will be shared with the user(s).

                </li>
                <li className='my-3'><strong>Amendments to the Terms.</strong>
                    Dhunguru retains the right to modify, amend, add, or remove sections of this Agreement at our sole discretion. Any such changes will be published on the Platform website and communicated to you through the email address you have provided to Dhunguru. It is crucial to maintain an up-to-date email address with Dhunguru for this purpose (Please note that your submission of personal information, including your email address, is governed by our Privacy Policy). We advise you to periodically review the Agreement for any updates. Your ongoing use of the Platform following the posting of changes signifies your binding acceptance of the modified terms.
                </li>
                <li className='my-3'><strong>Disclaimer of Warranties and Limitation of Liability/Other Disclaimers. </strong><br />
                    A. The information available on the Platform could include inaccuracies or typographic errors. Dhunguru has endeavoured to ensure that all the information on the Platform is correct, but Dhunguru neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data or information contained. Dhunguru makes no warranty, express or implied, concerning the website or App and/or its contents and disclaims all warranties of fitness for a particular purpose and warranties of merchantability in respect of Services, including any liability, responsibility or any other claim, whatsoever, in respect of any loss, whether direct or consequential, to any user or any other person, arising out of or from the use of the information contained in the website.<br />
                    B. In no event shall Dhunguru be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting from: (a) the use or the inability to use the Services; (b) unauthorized access to or alteration of the User's transmissions or data; (c) any other matter relating to the services; including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the website.<br />
                    C. Neither shall Dhunguru be responsible for the delay or inability to use the Platform or any related services, the provision of or failure to provide services, or for any information, software, products, services and related graphics obtained through the Platform, or otherwise arising out of the use of the Platform whether based on contract, tort, negligence, strict liability or otherwise.<br />
                    D. Further, Dhunguru shall not be held responsible for non-availability or access to the Platform during periodic maintenance operations or any unplanned suspension of access to the Platform that may occur due to technical reasons or for any reason beyond Dhunguru’s control. You understand and agree that any material and/or data downloaded or otherwise obtained through the website is done entirely at your own discretion and risk and you will be solely responsible for any damage to your computer systems or loss of data that results from the download of such material and/or data. These limitations, disclaimer of warranties and exclusions apply without regard to whether the damages arise from (a) breach of contract, (b) breach of warranty, (c) negligence, or (d) any other cause of action, to the extent such exclusion and limitations are not prohibited by applicable law.<br />
                    E. Dhunguru does not guarantee an error free performance under this agreement shall not be responsible or liable in any manner to the users for the losses, damage, injuries or expenses incurred by the users as result of any disclosures made by Dhunguru.<br />
                    F. Dhunguru does not warrant that this Platform, it’s servers, or email sent by Dhunguru or on Dhunguru’s behalf are virus free. Dhunguru will not be liable for any damages of any kind arising from the use of this Platform, including, but not limited to compensatory, direct, indirect, incidental, punitive, special and consequential damages, loss of data, goodwill, business opportunity, income or profit, loss of or damage to property and claims of third parties.<br />
                    G. In no event will Dhunguru be liable for any damages whatsoever in an amount in excess of an amount received from you in lieu of the services provided through the Platform.<br />
                    H. No warranty of any kind, implied, expressed or statutory, including but not limited to the warranties of non-infringement of third-party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given with respect to the contents available through the Platform or its links to other internet resources as may be available to you through the Platform.<br />
                    I. Dhunguru makes no claims, promises or guarantees about the accuracy, completeness, or adequacy of the contents available through the Platform and expressly disclaim liability for errors and omissions in the contents available through the Platform.<br />
                    J. Dhunguru does not warrant that this Platform and services, it’s servers, or email sent by us or on our behalf are virus free. We will not be liable for any damages of any kind arising from the use of this Platform and services, including, but not limited to compensatory, direct, indirect, incidental, punitive, special and consequential damages, loss of data, goodwill, business opportunity, income or profit, loss of or damage to property and claims of third parties.<br />
                    K. Dhunguru makes no claims, promises or guarantees about the accuracy, completeness, or adequacy of the contents available through the Platform and expressly disclaim liability for errors and omissions in the contents available through the Platform. Reference on the Platform to any specific commercial products, processes, or services, or the use of any trade, firm or corporation name is for the information and convenience of the public, and does not constitute endorsement, recommendation, or favouring by us.

                </li>
                <li className='my-3'><strong>No Result Guarantee.</strong>
                    Dhunguru under no event guarantees any results from use of the platform. Dhunguru does not endorse any music, language, or consultation program offered through the platform. Any statements, guarantees, or other information that may be provided to you by Therapists or other users of the platform are solely attributable to the Therapists or user. Dhunguru is not liable for any statements or guarantees made by Therapists or other users.
                </li>
                <li className='my-3'><strong>Operation and Content. </strong>
                    Dhunguru disclaims responsibility for any incorrect or inaccurate content posted on or in connection with the Platform, whether caused by users, members, or any equipment or programming associated with or utilized in the Platform. Dhunguru assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communication line failure, theft, or destruction, or unauthorized access to, or alteration of, user communications. Additionally, Dhunguru is not accountable for any problems or technical malfunction of any telephone network or lines, computer online systems, servers or providers, computer equipment, software, failure of email, or players, due to technical problems or traffic congestion on the Internet or at any website, or a combination thereof. This includes any injury or damage to users or any other person's computer related to or resulting from participating or downloading materials in connection with the web and/or in connection with the Platform.
                </li>
                <li className='my-3'><strong>Other website links. </strong>
                    The Platform may contain links to other websites. Interactions that occur between you and the third-party sites are strictly between you and such sites and are not the responsibility of Dhunguru. We are not responsible for examining or evaluating, and do not warrant the offerings of, any of these businesses or individuals or the content of their sites. Dhunguru does not assume any liability or responsibility for the actions or omissions, product, availability, or content of these outside resources. We encourage you to read those third-party sites’ applicable terms of use and privacy policies.
                </li>
                <li className='my-3'><strong>Choice of Law. </strong>
                    The Agreement shall be governed, construed, and enforced in accordance with the laws of India, including but not limited to: <br />
                    <ul>
                        <li>The Indian Contact,1872;</li>
                        <li>The (Indian) Information Technology Act, 2000;</li>
                        <li>The (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011;</li>
                        <li>The (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 and</li>
                        <li>The (Indian) Digital Data Protection Act, 2023.</li>
                    </ul>
                    <br />
                    The parties mutually agree that all actions and proceedings arising out of or related to this Agreement shall be exclusively litigated in the courts located in Gurugram. Each party hereby submits to the personal jurisdiction of such courts for the purposes of such actions or proceedings. Furthermore, each party agrees to irrevocably waive, to the fullest extent permitted by applicable law, any right to a jury trial in any legal proceeding arising out of or relating to this Agreement.
                </li>
                <li className='my-3'><strong>Assignment.</strong>
                    The Agreement, and any rights and licenses granted hereunder, must not be transferred or assigned by you to any other party, but may be assigned by Dhunguru without restriction. Any assignment made or attempted to be made in violation of this Agreement shall be void.
                </li>
                <li className='my-3'><strong>Warranty.</strong>
                    You expressly warrant that you are over the age of eighteen (18) or/and in case of a minor, a parent, legal guardian and have the capacity to enter into this Agreement, that any and all information (including without limitation Client Content) provided by you through the Platform is accurate and does not infringe the intellectual property rights of any other party and that your conduct and use of the Platform will conform with the terms of this Agreement.
                </li>
                <li className='my-3'><strong>Indemnification.</strong>
                    You agree to indemnify and hold Dhunguru and/or any of Dhunguru’s subsidiaries, members, affiliates, officers, agents, licensors, partners, and employees harmless from any loss, liability, claim, damages, obligations, or demand, including reasonable attorney’s fees, made by any third party due to or arising out of your use or interaction with the Platform including without limitation: (i) your violation or breach of this Agreement or Law, (ii) your Therapist/Client Content, (iii) any intellectual property infringement or other infringement of the rights of third parties, or (iv) any breach of your representations and warranties outlined above. Dhunguru reserves the right, with no obligation, to assume, at your expense, the exclusive defence and control of any matter for which you are required to indemnify us. You agree to cooperate fully with our defence of these claims and not to settle any matter without the prior written consent of Dhunguru. We will make reasonable efforts to notify you of any such claim, action, or proceeding upon becoming aware of it.
                </li>
                <li className='my-3'><strong>Notice.</strong>
                    All notices given to you by the Company or by you to the Company shall be in writing and in the English language. Such notice shall be sent by e-mail or mailed by a prepaid internationally recognized courier service to the intended recipient at the address set out below, or any changed address that is notified by either Party:<br />
                    <strong>Notice to the Company:</strong><br />
                    Dhunguru Music India Pvt Ltd<br />
                    WeWork<br />
                    DLF Two Horizon Centre,<br />
                    5th Floor, DLF Phase 5,<br />
                    Gurugram, Haryana 122002<br />
                    M: +918045683666<br />
                    <strong>Notice to User:</strong><br />
                    At the email address provided by you at the time of registration on the Website.
                </li>
                <li className='my-3'><strong>Severability.</strong>
                    If any provision of this Agreement is deemed unlawful, void, or unenforceable by a judge or tribunal body, then that provision shall be considered severable from this Agreement. The invalidity or unenforceability of any provision shall not affect the validity and enforceability of the remaining provisions.
                </li>
                <li className='my-3'><strong>Claims.</strong>
                    You and Dhunguru agree that any cause of action arising out of or related to the platform must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
                </li>
                <li className='my-3'><strong>Grievance Redressal</strong>
                    If you have any questions or grievances regarding the Platform, or the contents thereof, you may reach out to ‘Aditi Raj Sawhney’ the Company’s <strong>Data Protection Officer</strong> at <a href='support@dhunguru.com'>support@dhunguru.com</a> .
                </li>
                <li className='my-3'><strong>Repeat Infringer</strong>
                    Please note that Dhunguru will promptly terminate without notice any Client’s access to the Platform if that user or Client is determined by Dhunguru to be a “repeat infringer.” A “repeat infringer” is a Platform user who has been notified by Dhunguru of infringing activity violations more than twice and/or who has had their Client Content or any other user- submitted content removed from the Platform more than twice. In addition, Dhunguru accommodates and does not interfere with standard technical measures used by copyright owners to protect their materials.
                </li>
                <li className='my-3'><strong>Blocking of IP Addresses/Users.</strong>
                    In order to protect the integrity of the Platform, Dhunguru reserves the right at any time in its sole discretion to block users from certain IP addresses from accessing the Platform. Additionally, Dhunguru reserves the right to take appropriate measures to deny Platform access to any user who attempts to create a second Platform registration after being denied Platform access by Dhunguru.
                </li>
                <li className='my-3'><strong>Use outside India.</strong>
                    While the Platform may be accessible globally, the Company does not assert that the materials on the Platform are suitable or available for use in locations outside India, nor does it affirm compliance with the laws applicable in jurisdictions beyond India. Accessing the Platform from territories where the content or provision of Services is considered illegal is expressly prohibited. Users accessing the Platform from locations outside India do so at their own peril and are obligated to adhere to local laws applicable to them. Any offer for Services or information presented in connection with the Platform is null and void where prohibited.
                </li>
                <li className='my-3'><strong>International Transactions.</strong>
                    All amounts on the Platform are stated in Indian rupees, and exchange rates applicable to international transactions, which may change at any time, are determined by Dhunguru's third-party payment processors.
                </li>
                <li className='my-3'><strong>Force Majeure.</strong>
                    The Company bears no responsibility for the cessation, interruption, or delay in fulfilling its obligations herein, arising from events such as earthquakes, floods, fires, storms, pandemics, state-imposed lockdown restrictions, natural disasters, acts of God, wars, terrorism, armed conflicts, labour strikes, lockouts, or boycotts. The Company reserves the right, at its sole discretion and without prior notice, to discontinue the operation of the Platform at any time.
                </li>
                <li className='my-3'><strong>No Waiver. </strong>
                    The Company's delay or failure to exercise or enforce any right or provision stipulated in this Agreement shall not be construed as a waiver of such right or provision.
                </li>
                <li className='my-3'><strong>Termination.</strong>
                    The Company reserves the right to terminate your access to the Platform without providing notice if, at its sole discretion, there is a reasonable belief that you have violated any terms and conditions outlined in this Agreement.
                </li>
                <li className='my-3'><strong>Entire Agreement.</strong>
                    This Agreement along with the Privacy Policy comprises the entire agreement between the Client and the Company with respect to the use of the Platform.
                </li>
                <li className='my-3'><strong>Contact.</strong>
                    If you have any query or comments with respect to the terms of this Agreement, drop a WhatsApp query or contact us at: <strong>+918045683666</strong> or <br />
                    <strong>Dhunguru Music India Pvt Ltd</strong><br />
                    <strong>WeWork</strong><br />
                    <strong>DLF Two Horizon Centre,</strong><br />
                    <strong>5th Floor, DLF Phase 5,</strong><br />
                    <strong>Gurugram, Haryana 122002</strong><br />
                    <br />
                    The Parties agree to the terms and conditions set forth above as demonstrated by their signatures as follows:<br />
                    <strong>Client : </strong><br />
                    Signed: _____________________________________<br />
                    Name: _____________________________________<br />
                    Date: _____________________________________<br /><br />
                    <strong>Company : </strong><br />
                    Signed: _____________________________________<br />
                    Name: _____________________________________<br />
                    Date: _____________________________________<br />
                </li>
            </ol>
        </div>
        <hr class="mb-6" />
    </div>
)

export default Doc
