import React, { useEffect, useState, useContext } from 'react'
import { Store } from '../../../../../store'
import DashboardLayout from '../../index'
import Basic from './Basic'
import Pricing from './Pricing'
import Resources from './Resources'
import axios from '../../../../../utilis/axios';
import { saveCourses } from '../../../../../store/actions/instructor'
import Swal from 'sweetalert2'
import cogoToast from 'cogo-toast'
import instructor_content from '../../../../../content/instructor_content.json'
import imageCompression from 'browser-image-compression';


const Course = (props) => {

    const courseId = (props.match.params.id)
    const [tab, setTab] = useState('basics');
    const { state, dispatch } = useContext(Store);
    const [loading, setLoading] = useState('');
    const [loading1, setLoading1] = useState('');
    const [course, setCourse] = useState()
    const [headerImg, setHeaderImg] = useState([])
    const [photos, setPhotos] = useState([]);
    const [videos, setVideos] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0)


    useEffect(() => {
        if (state.instructor.courses.filter(item => item._id === courseId).length > 0) {
            setCourse(state.instructor.courses.filter(item => item._id === courseId)[0])
        } else fetchCourse();
        // eslint-disable-next-line
    }, [])


    const fetchCourse = async () => {

        try {
            const res = await axios.get(`/api/instructor/course/${courseId}`);
            setCourse(res.data)
        } catch (err) {
            console.log(err)
        }
    }



    const hideSubject = async (e) => {
        e.preventDefault();
        const courseId = (props.match.params.id)
        try {

            const response = await Swal.fire({
                title: course.isVisible ? instructor_content.course.hide_course.title : instructor_content.course.make_course_visible.title,
                text: course.isVisible ? instructor_content.course.hide_course.description : instructor_content.course.make_course_visible.description,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: course.isVisible ? 'Yes, hide it!' : 'Yes'
            });
            if (response.isConfirmed) {
                setLoading1(true);
                await axios.put(`/api/instructor/course/hide/${courseId}`, { response: !course.isVisible })
                cogoToast.success(course.isVisible ? instructor_content.course.hide_course.success : instructor_content.course.make_course_visible.success);
                if (state.instructor.courses.length > 0) {
                    const allCourses = state.instructor.courses;
                    const index = allCourses.findIndex(item => item._id === courseId);
                    if (index > -1) {
                        allCourses[index].isVisible = !course.isVisible;
                    }
                    saveCourses(allCourses, dispatch)
                }
                props.history.push('/instructor/courses')
            }
            setLoading1(false)
        } catch (err) {
            setLoading1(false)
            console.log(err)
        }
    }


    const saveCourse = async () => {
        const courseId = (props.match.params.id)
        try {
            const response = await Swal.fire({
                title: instructor_content.course.edit_toast.title,
                text: instructor_content.course.edit_toast.description,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, edit it!'
            });
            if (response.isConfirmed) {

                setLoading(true)

                let res = await axios.put(`/api/instructor/course/`, course);
                const { newHeaderImg, photoslink, videoslink } = await saveFileData();

                if (state.instructor.courses.length > 0) {
                    const allCourses = state.instructor.courses;
                    const index = allCourses.findIndex(item => item._id === courseId);
                    if (index > -1) {
                        allCourses[index] = {
                            ...res.data,
                            headerImg: newHeaderImg ? newHeaderImg : res.data.headerImg,
                            videos: videoslink ? videoslink : res.data.videos,
                            photos: photoslink ? photoslink : res.data.photos
                        }
                    }
                    saveCourses(allCourses, dispatch)
                }
                cogoToast.success(instructor_content.course.edit_toast.success)
                setLoading(false)
            }

        } catch (err) {
            setLoading(false)
            console.log(err)
        }
    }


    const saveFileData = async () => {
        let hideToast = () => { };
        try {

            let newHeaderImg = '', photoslink = '', videoslink = '';
            if (headerImg.length > 0) {
                const formData = new FormData()
                formData.append('course', headerImg[0])
                const res2 = await axios.post(`/api/instructor/course/uploadHeader/${courseId}`, formData)
                newHeaderImg = res2.data
            }
            if (photos.length > 0) {
                const formData = new FormData()
                for (let i = 0; i < photos.length; i++) {
                    const file = await imageCompression(photos[i], compressionConfig);
                    formData.append(`photos${i}`, file)
                }
                const { hide } = cogoToast.loading("Uploading photos...", { hideAfter: 0 });
                hideToast = hide;
                const res2 = await axios.post(`/api/instructor/course/upload/photos/${courseId}`, formData)
                photoslink = res2.data
                hide();

            }
            if (videos.length > 0) {
                const formData = new FormData()
                for (let i = 0; i < videos.length; i++) {
                    formData.append(`video${i}`, videos[i])
                }
                var { hide } = cogoToast.loading(`Uploading videos...`, { hideAfter: 0 });
                // eslint-disable-next-line
                hideToast = hide;
                const res2 = await axios.post(`/api/instructor/course/upload/videos/${courseId}`, formData, {
                    onUploadProgress: progressEvent => {
                        let progress = parseFloat((progressEvent.loaded / progressEvent.total) * 100).toFixed(10);
                        setUploadProgress(progress)
                    }
                })
                setUploadProgress(0)
                videoslink = res2.data
                hide();
            }
            return { newHeaderImg, photoslink, videoslink };

        } catch (err) {
            console.log(err)
        }
    }



    return (
        <DashboardLayout showTitle={false} {...props}>
            {loading && <div class='progress-bar-1' style={{ width: uploadProgress + 'vw' }}></div>}
            <div className='mt-4 px-4 px-lg-0'>
                <div className='row justify-content-center'>

                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 class="header-pretitle">Overview</h6>
                                        {course && <h1 class="header-title">{course.title}</h1>}
                                    </div>
                                    <div className="col-auto">
                                        <button class='btn btn-primary' onClick={e => hideSubject(e)} disabled={loading1}>
                                            {loading1 && <span class='spinner-border spinner-border-sm mr-2'></span>}
                                            {course && course.isVisible ? 'Hide Subject' : 'Make Subject Visible'}
                                        </button>
                                    </div>
                                </div>
                                <AccountNav tab={tab} setTab={setTab} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {course && <div className="row justify-content-center px-4 px-lg-0">
                <div className='col-12 col-lg-10 col-xl-8'>
                    {tab === 'basics' && <Basic
                        loading={loading}
                        course={course}
                        setCourse={setCourse}
                        headerImg={headerImg}
                        setHeaderImg={setHeaderImg}
                        saveCourse={saveCourse}
                        videos={videos}
                        photos={photos}
                        setVideos={setVideos}
                        setPhotos={setPhotos}
                    />}
                    {tab === 'pricing' && <Pricing
                        loading={loading}
                        course={course}
                        setCourse={setCourse}
                        saveCourse={saveCourse}
                    />}
                    {tab === 'resources' && <Resources
                        loading={loading}
                        course={course}
                        setCourse={setCourse}
                        courseId={courseId}
                    />}
                </div>
            </div>}
        </DashboardLayout>
    )
}

export default Course




const AccountNav = ({ tab, setTab }) => {
    return (
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'basics' && 'active'}`} onClick={() => setTab('basics')}>Basics</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'pricing' && 'active'}`} onClick={() => setTab('pricing')}>Pricing</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'resources' && 'active'}`} onClick={() => setTab('resources')}>Notes</div>
                    </li>
                </ul>
            </div>
        </div>
    )
}





const compressionConfig = {
    maxSizeMB: 0.1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
}