import React, {useState, useContext} from 'react'
import moment from 'moment'
import {Modal} from 'react-bootstrap'
import axios from '../../../../../utilis/axios';
import { Store } from '../../../../../store'
import { saveSchedule } from '../../../../../store/actions/student'
import { RiErrorWarningLine } from 'react-icons/ri'
import student_content from '../../../../../content/student_content.json'
import cogoToast from 'cogo-toast'

const Cancel = ({show, setShow, data}) => {

    const {state, dispatch} = useContext(Store)
    const [loading, setLoading] = useState(false);
    const [draft, setDraft] = useState('');
    const [error, setError] = useState(false);

    const cancelRequest = async (id) => {
        try{
            if(draft.length === 0){
                setError(true);
                return;
            } else{
                setLoading(true)
                setError(false)
                const res = await axios.post(`/api/student/schedule/cancel/${data._id}`)
                cogoToast.success(student_content.schdule_modals.cancel.toast)

                const newSchedule = [...state.student.schedule];
                const index = newSchedule.findIndex(item => item._id === data._id)
                newSchedule[index].status = res.data.status
                newSchedule[index].cancelled_by = res.data.cancelled_by
                newSchedule[index].lateCancel = res.data.lateCancel
                saveSchedule(newSchedule, dispatch)
                await sendMessage()
                setShow(false);
                setLoading(false)
            }
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }


    const sendMessage = async () => {
        try{
            setLoading(true)
            // console.log(data.instructor._id)
            const data1 = {
                message: draft,
                instructor: data.instructor._id,
            }
            await axios.post('/api/student/messages/send', data1);
            setLoading(false)
            setDraft('');
            // editMessage(user, draft, moment(), 'student', state, dispatch, user, res.data)
            
        } catch(err){
            setLoading(false)
            console.log(err);
        }
    }

    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered>
            <div className = 'modal-content' >
                <div className = 'modal-body '>
                <div class = 'close pointer' onClick = {() => setShow(false)}>&times;</div>
                    <div class = 'mb-4 text-center px-lg-5 pt-4'>
                        <div className = 'h2 mb-1'>{student_content.schdule_modals.cancel.title}</div>
                        <div className = 'h8-unbold mb-1'>
                            {student_content.schdule_modals.cancel.description}
                        </div>
                    </div>
                    {(moment(data.date_time).diff(moment(), 'hours') < parseInt(process.env.REACT_APP_CANCELLATION_POLICY) && data.type === 'paid') && <div class = 'card bg-danger mx-lg-4'>
                            <div class = 'card-body'>
                                <div class = 'row no-gutters text-white align-items-center'>
                                    <div class = 'col-auto pr-3'>
                                        <RiErrorWarningLine class = 'display-3 mb-0'/>
                                    </div>
                                    <div class = 'col'>
                                    {student_content.schdule_modals.cancel.late}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    <form class = 'px-lg-4'>  
                        <div class = 'form-group'>
                            <textarea 
                                value = {draft}
                                onChange = {e => setDraft(e.target.value)}
                                class = {`form-control ${error ? 'is-invalid' : ''}`}
                                style = {{minHeight: '14vh'}}
                                placeholder = 'Please share the reason for cancellation with your guru'
                            />
                            <div class = 'invalid-feedback'>Please write a message</div>
                        </div>
                        
                        <div class = "btn btn-primary btn-block mb-4" onClick = {() => cancelRequest()}>
                            {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                            Cancel session and send message
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

export default Cancel


