import { useState, useContext, useRef } from 'react';
import { InputControl, DateFormInput1 } from '../../../../components/Forms'
import axios from '../../../../utilis/axios'
import { validatePhone, validateEmail } from '../../../../utilis/validation';
import { Store } from '../../../../store';
import { fetchUser } from '../../../../store/actions/auth';
// import TnC from '../../../../components/Modals/TnC'
import Swal from 'sweetalert2'

const Profile = () => {

    const fileRef = useRef(null)
    const { state, dispatch } = useContext(Store)
    const [user, setUser] = useState(state.user.data);
    const [errors, setErrors] = useState(Array(5).fill(false));
    const [avatar, setAvatar] = useState(state.user.data.avatar);
    const [loading, setLoading] = useState(false);
    // const [showTnc, setShowTnc] = useState(false)

    const updateAvatar = async () => {
        setAvatar(URL.createObjectURL(fileRef.current.files[0]));
    }


    const saveData = async () => {
        if (!validate()) return
        await saveChanges();
    }

    const saveChanges = async () => {
        try {
            setLoading(true);
            await axios.put('/api/student/profile/userSettings/', { user })
            fetchUser(user, dispatch)
            if (fileRef.current.files.length > 0) {
                const formData = new FormData();
                formData.append('avatar', fileRef.current.files[0]);
                const res = await axios.post('/api/student/profile/avatar/', formData)
                fetchUser({ ...user, avatar: res.data }, dispatch)
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log(err)
        }
    }


    const validate = () => {
        let newErrors = Array(5).fill(false);
        if (user.firstName === "") newErrors[0] = true;
        if (user.lastName === "") newErrors[1] = true;
        if (!validateEmail(user.email)) newErrors[2] = true;
        if (!validatePhone(user.phone)) newErrors[3] = true;
        if (user.birthday === "") newErrors[4] = true;
        setErrors(newErrors)
        if (newErrors.includes(true)) return false;
        else return true;
    }


    const deleteUser = async () => {
        const response = await Swal.fire({
            title: 'Are you sure you want to delete your account?',
            text: 'This action cannot be undone. Once deleted, all the user data will also be deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if (response.isConfirmed) {
            await Swal.fire({
                title: 'Please reach out to us at support@dhunguru.com to proceed with the request',
                showCancelButton: true,
            });
        }
    }



    return (
        <div className='card shadow-sm border'><div className='card-body px-lg-6 py-lg-5'>
            <form>
                <div className="row justify-content-between align-items-center">
                    <div className="col">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <div className="avatar">
                                    <img className="avatar-img rounded-circle" src={avatar} alt="..." />
                                </div>
                            </div>
                            <div className="col ml-n2">
                                <h4 class="mb-1">Your avatar</h4>
                                <small class="text-muted">Use PNG or JPG images under 1000 pixels in width and height.</small>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="col-auto">
                            <form>
                                <input type="file" name="avatar" accept="image/png, image/jpeg" hidden='hidden' ref={fileRef} onChange={e => { updateAvatar() }} />
                                <button type='button' className="btn btn-sm btn-primary" onClick={() => { fileRef.current.click(); }}>Upload</button>
                            </form>
                        </div>
                    </div>
                </div>
                <hr class="my-5" />
                <div className="row">
                    <div className="col-12">
                        <div class='form-group'>
                            <label>Email</label>
                            <input class='form-control disabled-input' disabled value={user.email} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <InputControl
                            label='First Name'
                            value={user.firstName}
                            setValue={(val) => setUser(prev => ({ ...prev, firstName: val }))}
                            error={errors[0]}
                            setError={(val) => setErrors(prev => { prev[0] = val; return prev })}
                            errorText="Please enter a valid first name"
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputControl
                            label='Last Name'
                            value={user.lastName}
                            setValue={(val) => setUser(prev => ({ ...prev, lastName: val }))}
                            error={errors[1]}
                            setError={(val) => setErrors(prev => { prev[1] = val; return prev })}
                            errorText="Please enter a valid last name"
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputControl
                            label='Phone Number'
                            value={user.phone}
                            setValue={(val) => setUser(prev => ({ ...prev, phone: val }))}
                            error={errors[3]}
                            setError={(val) => setErrors(prev => { prev[3] = val; return prev })}
                            errorText="Please enter a valid phone"
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <DateFormInput1
                            placeholder="Birthday"
                            error={errors[4]}
                            setError={(val) => setErrors(prev => { prev[4] = val; return prev })}
                            errorMessage={"Please enter a valid birthday"}
                            label="Birthday"
                            setValue={(val) => setUser(prev => ({ ...prev, birthday: val }))}
                            value={user.birthday}
                        />
                    </div>
                    <div className="col-12 col-md-12">
                        <InputControl 
                            placeholder = "Your Address"
                            error = {false}
                            setError = {(val) => {}}
                            errorMessage = {""}
                            label = "Your Address"
                            setValue = {(val) => setUser(prev => ({...prev, fullAddress: {...prev.fullAddress, firstLine: val}}))}
                            value = {user.fullAddress.firstLine}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <InputControl
                            placeholder="City"
                            error={false}
                            setError={(val) => { }}
                            errorMessage={""}
                            label="City"
                            setValue={(val) => setUser(prev => ({ ...prev, fullAddress: { ...prev.fullAddress, city: val } }))}
                            value={user.fullAddress.city}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <InputControl
                            placeholder="State"
                            error={false}
                            setError={(val) => { }}
                            errorMessage={""}
                            label="State"
                            setValue={(val) => setUser(prev => ({ ...prev, fullAddress: { ...prev.fullAddress, state: val } }))}
                            value={user.fullAddress.state}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <InputControl
                            placeholder="Zip Code"
                            error={false}
                            setError={(val) => { }}
                            errorMessage={""}
                            label="Zip Code"
                            setValue={(val) => setUser(prev => ({ ...prev, fullAddress: { ...prev.fullAddress, zipCode: val } }))}
                            value={user.fullAddress.zipCode}
                        />
                    </div>
                </div>
                <div className="btn btn-primary" onClick={() => saveData()}>
                    {loading && <span className='mr-3 spinner-border spinner-border-sm'></span>}
                    Save changes
                </div>
            </form>
            <hr class="mt-4 mb-5" />
            <div class="row justify-content-between">
                <div class="col-12 col-md-6">
                  <h4>Permanently Delete Account</h4>
                  <p class="small text-muted mb-md-0">
                  Please note: Deleting the account is permanent and cannot be undone.
                  </p>
                </div>
                <div class="col-auto">
                    <div class="btn btn-danger" onClick={deleteUser}>
                        Delete
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default Profile;


