import axios from 'axios';
import cogoToast from 'cogo-toast';

let api = axios.create({
  baseURL: ''
});


api.interceptors.response.use((response) => response, (error) => {
  if(error.response.data === 'NOT_LOGGED_IN') throw error;
  if(error.response.status === 404) notification(`Error occured: ${error.response.data}`)
  if(error.response.status === 500) notification(`Error occured: ${error.response.data}`)
  if(error.response.status === 401) notification(`Error occured: ${error.response.data}`)
  if(error.response.status === 400) notification(`Error occured: ${error.response.data}`)
  throw error;
});


export default api;



const notification = (title) => {
  cogoToast.error(
    title, { position: 'top-center', hideAfter: 10, 
      onClick: () => {
          window.location.reload();
      } 
    }
  );
}
