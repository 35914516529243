import {SET_INSTRUMENT, SET_COURSES, SET_INSTRUCTORS} from '../actions/landing'

const landingReducer = (state, action) => {
    switch (action.type) {
      case SET_INSTRUMENT:
        return {
          ...state,
          instrument: action.payload.instrument
      }
      case SET_COURSES:
        return {
          ...state,
          courses: action.payload.courses
      }
      case SET_INSTRUCTORS:
        return {
          ...state,
          instructors: action.payload.instructors
      }
      default:
        return state;
    }
  }
  
  export default landingReducer;
  
  