import {useState, useContext} from 'react';
import {InputControl} from '../../../../components/Forms'
import axios from '../../../../utilis/axios'
import {validatePhone, cleanPhone} from '../../../../utilis/validation';
import { Store } from '../../../../store';
import { fetchUser } from '../../../../store/actions/auth';
import cogoToast from 'cogo-toast'

const Profile = () => {

    const {state, dispatch} = useContext(Store)
    const [gst, setGst] = useState(state.user.data.gst ? state.user.data.gst:{name:"", gstNumber:"", phone:"", address:""});
    const [errors, setErrors] = useState(Array(5).fill(false));
    const [loading, setLoading] = useState(false);

    const saveData = async () => {
        if(!validate()) return
        await saveChanges();
    }

    const saveChanges = async () => {
        try{
            setLoading(true);
            await axios.put('/api/student/profile/gst/', {...gst, phone: cleanPhone(gst.phone)})
            cogoToast.success("Your GST details have been saved")
            fetchUser({...state.user.data, gst:gst}, dispatch)
            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }


    const validate = () => {
        let newErrors = Array(5).fill(false);
        if(gst.gstNumber === "")  newErrors[0] = true;
        if(gst.name === "")  newErrors[1] = true;
        if(!validatePhone(gst.phone)) newErrors[2] = true;
        if(gst.address === "")  newErrors[3] = true;
        setErrors(newErrors)
        if(newErrors.includes(true)) return false;
        else return true;
    }

    
    

    return (
        <div className = 'card shadow-sm border'><div className ='card-body px-lg-6 py-lg-5'>
           <form>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <InputControl 
                            label = 'GST Number'
                            value = {gst.gstNumber}
                            setValue = {(val) => setGst(prev => ({...prev, gstNumber: val}))}
                            error = {errors[0]}
                            setError = {(val) => setErrors(prev => {prev[0]=val; return prev})}
                            errorText = "Please enter a valid GST number"
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputControl 
                            label = 'Full Name'
                            value = {gst.name}
                            setValue = {(val) => setGst(prev => ({...prev, name: val}))}
                            error = {errors[1]}
                            setError = {(val) => setErrors(prev => {prev[1]=val; return prev})}
                            errorText = "Please enter a valid name"
                        />
                    </div>
                    <div className="col-12 col-md-6">      
                        <InputControl 
                            label = 'Phone Number'
                            value = {gst.phone}
                            setValue = {(val) => setGst(prev => ({...prev, phone: val}))}
                            error = {errors[2]}
                            setError = {(val) => setErrors(prev => {prev[3]=val; return prev})}
                            errorText = "Please enter a valid phone"
                        />
                    </div>
                    <div className="col-12 col-md-12">      
                        <InputControl 
                            label = 'Address'
                            value = {gst.address}
                            setValue = {(val) => setGst(prev => ({...prev, address: val}))}
                            error = {errors[3]}
                            setError = {(val) => setErrors(prev => {prev[3]=val; return prev})}
                            errorText = "Please enter a valid address"
                        />
                    </div>
                </div> 
                <div className="btn btn-primary" onClick = {() => saveData()}>
                    {loading && <span className = 'mr-3 spinner-border spinner-border-sm'></span>}
                    Save changes
                </div>
            </form>
        </div></div>
    )
}

export default Profile;


