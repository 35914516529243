import axios from '../../../../utilis/axios'
import React, { useState, useEffect, useContext } from 'react'
import DashboardLayout from '../index'
import { Link } from 'react-router-dom'
import { Store } from '../../../../store'
import { saveCourses } from '../../../../store/actions/instructor'
import Skeleton from 'react-loading-skeleton';


const Courses = (props) => {
  const { state, dispatch } = useContext(Store);
  const [courses, setCourses] = useState(false);

  const fetchCourses = async () => {
    try {
      const res = await axios.get("/api/instructor/courses");
      saveCourses(res.data, dispatch)
      setCourses(res.data)
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    if (state.instructor.courses.length === 0) {
      fetchCourses()
    } else {
      setCourses(state.instructor.courses)
    }
    // eslint-disable-next-line
  }, [])


  // eslint-disable-next-line


  return (
    <DashboardLayout
      showTitle={true}
      title="Your expertise"
      subTitle="Overview"
      sideButton={<Link to="/instructor/courses/add" class="btn btn-primary" >Add new expertise</Link>}
      padding="high"
      {...props}
    >
      <div className='row px-4 px-lg-0'>
        {(courses && courses.length > 0) && courses.sort((a, b) => (b.isVisible ? 1 : 0) - (a.isVisible ? 1 : 0)).map(item => <CourseTile data={item} />)}
        {!courses && Array(3).fill().map(item => <CourseSkeleton />)}
        {(courses && courses.length === 0) && <NoCourse />}
      </div>
    </DashboardLayout>
  )
};

export default Courses;


const CourseTile = ({ data }) => {
  return (
    <div className='col-12 col-lg-6 col-xl-4'>
      <div className={`card shadow-sm border card-fill ${data.isVisible ? '' : 'bg-light'}`}>
        <img alt="" src={data.headerImg} class="card-img-top" style={{ height: '200px', objectFit: 'cover' }} />
        <div className="card-body pt-2">
          <div className="text-center mt-3 border-bottom pb-4">
            <h2 className="card-title">
              <Link to={`/instructor/course/${data._id}`} className="item-name">{data.title}</Link>
            </h2>
            <Bio bio={data.details} />
          </div>
          <div className="list-group list-group-flush mb-4">
            <div className="list-group-item">
              <div className="row">
                <div className="col-7">
                  <small>Subject</small>
                </div>
                <div className="col-5 text-right small">
                  {data?.instrument && ( data?.instrument[0]?.toUpperCase() + data?.instrument?.slice(1))}
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <small>
                <span class={data.approve ? "text-success" : 'text-danger'}>●</span> {data.approve ? 'Approved' : 'Awaiting approval'}
              </small>
            </div>
            <div className="col-auto">
              <Link to={`/instructor/course/${data._id}`} class="btn btn-sm btn-primary">
                Edit
              </Link>
            </div>
          </div>
        </div>
        <div className="col-5 text-right small">
        {data?.instrument && (data.instrument[0].toUpperCase() + data.instrument.slice(1))}
        </div>
      </div>
      <div className="row align-items-center justify-content-between">
        <div className="col-auto">
          <small>
            <span class={data.approve ? "text-success" : "text-danger"}>
              ●
            </span>{" "}
            {data.approve ? "Approved" : "Awaiting Approval"}
          </small>
        </div>
        <div className="col-auto">
          <Link
            to={`/instructor/course/${data._id}`}
            class="btn btn-sm btn-primary"
          >
            Edit
          </Link>
        </div>
      </div>
    </div>
  )
}






const Bio = ({ bio }) => {
  const [more, setMore] = useState(false);

  return (
    <p class="card-text text-muted">
      {more ? bio : bio && bio.length > 60 ? bio.slice(0, 60) + "..." : bio}
      {bio && bio.length > 60 && (
        <span class="btn-link pointer" onClick={() => setMore((prev) => !prev)}>
          {more ? "Read less" : "Read more"}
        </span>
      )}
    </p>
  );
};

const CourseSkeleton = () => {
  return (
    <div class='col-12 col-lg-6 col-xl-4'>
      <Skeleton height={400} />
    </div>
  )
}



const NoCourse = () => {
  return (
    <div class='col-12'>
      <div className='card mb-4 py-6 '>
        <div className='card-body d-flex align-items-center justify-content-center my-6'>
          <div className='h1 text-uppercase text-muted mb-0 py-5'>
            Please click 'Add new expertise' to get started
          </div>
        </div>
      </div>
    </div>
  );
};
