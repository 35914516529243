import React, {useState, useEffect, useContext} from 'react'
import DashboardLayout from '../index'
import {Calendar} from '@hassanmojab/react-modern-calendar-datepicker';
import RequestSchedule from './Modals/Schedule'
import Requests from './Requests'
import Scheduled from './Scheduled'
import Cancelled from './Cancelled'
import Completed from './Completed'
import axios from '../../../../utilis/axios'
import { Store } from '../../../../store';
import { saveRequests, saveSchedule } from '../../../../store/actions/student';
import cogoToast from 'cogo-toast';
import student_content from '../../../../content/student_content.json'

const Schedule = (props) => {

    const {state, dispatch} = useContext(Store);
    const [scheduled, setScheduled] = useState();
    const [requests, setRequests] = useState();
    const [requestNew, setRequestNew] = useState(false)
    const [selectedDay, setSelectedDay] = useState();
    const [tab, setTab] = useState('scheduled')

    useEffect(() => {
        if(state.student.requests && state.student.requests.length > 0 ) setRequests(state.student.requests)
        if(state.student.schedule && state.student.schedule.length > 0 ) setScheduled(state.student.schedule)
        fetchRequests()
        fetchSchedule()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(state.student.schedule) setScheduled(state.student.schedule)
    }, [state.student.schedule])
    
    useEffect(() => {
        if(state.student.requests) setRequests(state.student.requests)
    }, [state.student.requests])

    const fetchSchedule = async () => {
        try{
            
            const res = await axios.get('/api/student/schedule');
            // console.log(res.data)
            setScheduled(res.data)
            saveSchedule(res.data, dispatch)

        } catch(err){
            console.log(err)
        }   
    }

    const fetchRequests = async () => {
        try{

            const res = await axios.get('/api/student/schedule/request');
            saveRequests(res.data, dispatch)
            setRequests(res.data)

        } catch(err){
            console.log(err)
        }   
    }

    const sendConfirmationMail = async () => {
        try{
            await axios.post('/api/auth/send-confirmation-email')
            cogoToast.info(student_content.toasts.send_confirmation_email, {position: 'top-right'})
        } catch(err){
            console.log(err)
        }
    }

    return (
        <DashboardLayout>
            <div className = "px-3 px-lg-6 mx-lg-6 px-md-3 mx-md-3 mt-5">
                <Hero 
                    setRequestNew = {setRequestNew}
                />
                {state.user.data.isEmailConfirm ? <div className = 'row mt-5'>
                    <div className = 'col-auto d-none d-lg-block'>
                        <DateCard 
                            selectedDay = {selectedDay} 
                            setSelectedDay = {setSelectedDay}
                        />
                        {selectedDay && <div class = 'btn-link pointer text-center mt-3' onClick = {() => setSelectedDay(null)}>Clear Date</div>}
                    </div>
                    <div className = 'col mx-lg-3  ml-lg-5'>
                        <ScheduleClasses 
                            scheduled = {scheduled} 
                            requests = {requests} 
                            setRequests = {setRequests} 
                            setScheduled = {setScheduled} 
                            selectedDay = {selectedDay} 
                            setRequestNew = {setRequestNew} 
                            tab = {tab} 
                            setTab = {setTab} 
                            {...props}
                        />
                    </div>
                </div> : <div class = 'card bg-soft-primary'>
                    <div class = 'card-body text-center py-6'>
                        <p class = 'h3'>{student_content.utilis.confirmation_email.description}</p>
                        <div class = 'small'>Didn't recieve the email, <span class= 'btn-link pointer' onClick = {() => sendConfirmationMail()}>click here!</span></div>
                    </div>
                </div>}
            </div>
            <RequestSchedule 
                show = {requestNew} 
                setShow = {setRequestNew}
            />
        </DashboardLayout>
    )
}

export default Schedule


const Hero = ({setRequestNew}) => {
    return (
        <div className = 'card '>
            <div className="card-body py-5">
                <div className="row align-items-center">
                  <div className="col-12 col-lg-4 order-lg-2">
                    <div className="text-center">
                        <img 
                            src={student_content.schedule_view.hero.img} 
                            alt="..." 
                            className="img-fluid mt-n5 mt-lg-0 mb-4 mr-md-n5" 
                            // style={{maxWidth: '272px'}} 
                        />
                    </div>
                  </div>
                  <div className="col-12 col-lg-8 px-4 py-3 order-lg-1">
                    <h1 class = 'display-4'>{student_content.schedule_view.hero.title}</h1>
                    <p className="text-muted">
                      {student_content.schedule_view.hero.description}
                    </p>
                    <div class = 'btn btn-primary ' onClick = {() => setRequestNew(true)}>Schedule a session</div>
                  </div>
                </div> 
            </div>
        </div>
    )
}

const DateCard = ({selectedDay, setSelectedDay}) => {
    return (
        <div class = 'd-none d-lg-block'>
            <Calendar
                value={selectedDay}
                onChange={setSelectedDay}
                shouldHighlightWeekends
            />
        </div>
    )
}

const ScheduleClasses = ({scheduled, setScheduled, selectedDay, requests, setRequests, setRequestNew, tab, setTab, ...props}) => {


    return(
        <div>
            <div className="row">
                <div className="col-12 col-lg">
                    <ul className="nav nav-tabs nav-overflow nav-tabs-sm header-tabs  my-0 mb-lg-3">
                        <li className="nav-item ">
                            <div class={`nav-link h4 pointer ${tab === 'completed' && 'active'}`} onClick = {() => setTab('completed')}>
                            Completed Sessions
                            </div>
                        </li>
                        <li className="nav-item ">
                            <div class={`nav-link h4 pointer ${tab === 'scheduled' && 'active'}`} onClick = {() => setTab('scheduled')}>
                            Scheduled Sessions
                            </div>
                        </li>
                        <li className="nav-item ">
                            <div class={`nav-link h4 pointer ${tab === 'requests' && 'active'}`} onClick = {() => setTab('requests')}>
                            Requested Sessions
                            </div>
                        </li>
                        <li className="nav-item ">
                            <div class={`nav-link h4 pointer ${tab === 'cancelled' && 'active'}`} onClick = {() => setTab('cancelled')}>
                            Cancelled Sessions
                            </div>
                        </li>
                    </ul>
                </div>
                {/* <div class = 'col-auto pt-lg-3 d-none d-lg-block'>
                    <div class = 'btn btn-primary btn-sm' onClick = {() => setRequestNew(true)}>Schedule a session</div>
                </div> */}
            </div>
            <div>
                    {tab === 'completed' && <Completed 
                        selectedDay = {selectedDay}
                        scheduled = {scheduled}
                        setScheduled = {setScheduled}
                        {...props}
                    />}
                    {tab === 'requests' && <Requests 
                        selectedDay = {selectedDay}
                        requests = {requests}
                        setRequests = {setRequests}
                        {...props}
                    />}
                    {tab === 'scheduled' && <Scheduled 
                        selectedDay = {selectedDay}
                        scheduled = {scheduled}
                        setScheduled = {setScheduled}
                        {...props}
                    />}
                    {tab === 'cancelled' && <Cancelled 
                        selectedDay = {selectedDay}
                        scheduled = {scheduled}
                        setScheduled = {setScheduled}
                        {...props}
                    />}
            </div>
        </div>
    )
}





