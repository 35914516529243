import { useState, useEffect } from 'react'
import { GiPencil } from 'react-icons/gi';
import { DateFormFlush, DateFormRangeFlush } from '../../../../components/Forms'
import moment from 'moment';
import instructor_content from '../../../../content/instructor_content.json'

const AdditionalInfo = ({ profile, setProfile, avatarRef, saveProfile, loading }) => {


    const moveNext = async e => {
        try {
            e.preventDefault();
            await saveProfile();

        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div class="">
            <div class=''>
                <Experiece profile={profile} setProfile={setProfile} />
                <Education profile={profile} setProfile={setProfile} />
                <AwardsCertificate profile={profile} setProfile={setProfile} />
                <Affilications profile={profile} setProfile={setProfile} />
            </div>
            <button disabled={loading} className='btn btn-block my-5 btn-primary px-6' onClick={(e) => moveNext(e)}>
                {loading && <span class="spinner-border spinner-border-sm mr-2"></span>}
                Save
            </button>
        </div>
    )
}

export default AdditionalInfo;





const Experiece = ({ profile, setProfile }) => {

    const [addNew, setAddNew] = useState(false);

    const [tempData, setTempData] = useState({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' })

    const placeholders = {
        title: 'Job Title', description: 'Job Description', dateRange: 'Time Period', subTitle: 'Company/Institute'
    }

    const saveData = () => {
        const newExperience = [...profile.experience, tempData]
        const sortedByDate = newExperience.sort((a, b) => {
            return (new Date(b.dateRange.from) - new Date(a.dateRange.from)) * -1;
        })
        setProfile(prev => ({ ...prev, experience: sortedByDate }));
        setAddNew(false);
        setTempData({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' })
    }

    const editData = (index) => {
        const currentProfile = { ...profile }
        const newData = currentProfile.experience.splice(index, 1);
        // console.log(currentProfile)
        setProfile(currentProfile);
        setTempData(newData[0])
        setAddNew(true)
    }

    const deleteData = () => {
        setAddNew(false);
        setTempData({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' });

    }


    return (
        <div className='form-group mb-5 pb-2'>
            <div className='row justify-content-between align-items-center mb-3'>
                <div class="col">
                    <label class='bold mb-1'>Experience</label>
                    <div class='text-muted small'>{instructor_content.profile.additional.experience}</div>
                </div>
                <div class="col-auto col-lg-3">
                    <div class='btn btn-sm btn-block btn-primary' onClick={() => setAddNew(true)}>Add</div>
                </div>
            </div>
            <div className='card shadow-sm border'>
                <div className='card-body pt-0 pb-0'>
                    {profile.experience.map((item, index) => (
                        <DataRowFilled
                            title={item.title}
                            description={item.description}
                            subTitle={item.subTitle}
                            dateText={moment(item.dateRange.from).format('Do MMM YY') + ' - ' + moment(item.dateRange.to).format('Do MMM YY')}
                            editData={editData}
                            index={index}
                        />))}
                </div>
            </div>
            {addNew ? <DataRow deleteData={deleteData} placeholders={placeholders} tempData={tempData} setTempData={setTempData} saveData={saveData} /> : ''}
        </div>
    )
}

const Education = ({ profile, setProfile }) => {

    const [addNew, setAddNew] = useState(false);
    const [tempData, setTempData] = useState({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' })
    const placeholders = {
        title: 'Degree/Field of Study', description: 'Description', dateRange: 'Date range', subTitle: 'School/Institute'
    }

    const saveData = () => {
        const newEducation = [...profile.education, tempData]
        const sortedByDate = newEducation.sort((a, b) => {
            return (new Date(b.dateRange.from) - new Date(a.dateRange.from)) * -1;
        })
        setProfile(prev => ({ ...prev, education: sortedByDate }));
        setAddNew(false);
        setTempData({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' })
    }

    const editData = (index) => {
        const currentProfile = { ...profile }
        const newData = currentProfile.education.splice(index, 1);
        setProfile(currentProfile);
        setTempData(newData[0])
        setAddNew(true)
    }

    const deleteData = () => {
        setAddNew(false);
        setTempData({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' })
    }


    return (
        <div className='form-group mb-5 pb-2'>
            <div className='row justify-content-between align-items-center mb-3'>
                <div class="col">
                    <label class='bold mb-1'>Education</label>
                    <div class='text-muted small'>{instructor_content.profile.additional.education}</div>
                </div>
                <div class="col-auto col-lg-3">
                    <div class='btn btn-sm btn-block btn-primary' onClick={() => setAddNew(true)}>Add</div>
                </div>
            </div>
            <div className='card shadow-sm border'>
                <div className='card-body pt-0 pb-0'>
                    {profile.education.map((item, index) => (
                        <DataRowFilled
                            title={item.title}
                            description={item.description}
                            subTitle={item.subTitle}
                            dateText={moment(item.dateRange.from).format('Do MMM YY') + ' - ' + moment(item.dateRange.to).format('Do MMM YY')}
                            editData={editData}
                            index={index}
                        />))}
                </div>
            </div>
            {addNew ? <DataRow deleteData={deleteData} placeholders={placeholders} tempData={tempData} setTempData={setTempData} saveData={saveData} /> : ''}
        </div>
    )
}

const AwardsCertificate = ({ profile, setProfile }) => {

    const [addNew, setAddNew] = useState(false);
    const [tempData, setTempData] = useState({ title: '', description: '', date: '', subTitle: '' })
    const placeholders = {
        title: 'Award Name', description: 'Description', date: 'Date received', subTitle: 'Entity name'
    }

    const saveData = () => {
        const newAwards = [...profile.awards, tempData]
        const sortedByDate = newAwards.sort((a, b) => {
            return (new Date(b.date) - new Date(a.date)) * -1;
        })
        setProfile(prev => ({ ...prev, awards: sortedByDate }));
        setAddNew(false);
        setTempData({ title: '', description: '', date: '', subTitle: '' })
    }

    const editData = (index) => {
        const currentProfile = { ...profile }
        const newData = currentProfile.awards.splice(index, 1);
        setProfile(currentProfile);
        setTempData(newData[0])
        setAddNew(true)
    }

    const deleteData = () => {
        setAddNew(false);
        setTempData({ title: '', description: '', date: '', subTitle: '' })
    }

    return (
        <div className='form-group mb-5 pb-2'>
            <div className='row justify-content-between align-items-center mb-3'>
                <div class="col">
                    <label class='bold mb-1'>Awards and Certification</label>
                    <div class='text-muted small'>{instructor_content.profile.additional.awards}</div>
                </div>
                <div class="col-auto col-lg-3">
                    <div class='btn btn-sm btn-block btn-primary' onClick={() => setAddNew(true)}>Add</div>
                </div>
            </div>
            <div className='card shadow-sm border'>
                <div className='card-body pt-0 pb-0'>
                    {profile.awards.map((item, index) => (
                        <DataRowFilled
                            title={item.title}
                            description={item.description}
                            subTitle={item.subTitle}
                            dateText={moment(item.date).format('Do MMM YY')}
                            editData={editData}
                            index={index}
                        />))}
                </div>
            </div>
            {addNew ? <DataRow deleteData={deleteData} placeholders={placeholders} tempData={tempData} setTempData={setTempData} saveData={saveData} /> : ''}
        </div>
    )
}


const Affilications = ({ profile, setProfile }) => {

    const [addNew, setAddNew] = useState(false);
    const [tempData, setTempData] = useState({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' })
    const placeholders = {
        title: 'Affiliation Title', description: 'Description', dateRange: 'Period of Affiliation', subTitle: 'Institute Name'
    }

    const saveData = () => {
        const newAffiliations = [...profile.affiliations, tempData]
        const sortedByDate = newAffiliations.sort((a, b) => {
            return (new Date(b.dateRange.from) - new Date(a.dateRange.from)) * -1;
        })
        setProfile(prev => ({ ...prev, affiliations: sortedByDate }));
        setAddNew(false);
        setTempData({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' })
    }

    const editData = (index) => {
        const currentProfile = { ...profile }
        const newData = currentProfile.affiliations.splice(index, 1);
        setProfile(currentProfile);
        setTempData(newData[0])
        setAddNew(true)
    }

    const deleteData = () => {
        setAddNew(false);
        setTempData({ title: '', description: '', dateRange: { from: '', to: '' }, subTitle: '' })
    }

    return (
        <div className='form-group mb-5 pb-2'>
            <div className='row justify-content-between align-items-center mb-3'>
                <div class="col">
                    <label class='bold mb-1'>Affiliations</label>
                    <div class='text-muted small'>{instructor_content.profile.additional.affiliation}</div>
                </div>
                <div class="col-auto col-lg-3">
                    <div class='btn btn-sm btn-block btn-primary' onClick={() => setAddNew(true)}>Add</div>
                </div>
            </div>
            <div className='card shadow-sm border'>
                <div className='card-body pt-0 pb-0'>
                    {profile.affiliations.map((item, index) => (
                        <DataRowFilled
                            title={item.title}
                            description={item.description}
                            subTitle={item.subTitle}
                            dateText={moment(item.dateRange.from).format('Do MMM YY') + ' - ' + moment(item.dateRange.to).format('Do MMM YY')}
                            editData={editData}
                            index={index}
                        />))}
                </div>
            </div>
            {addNew ? <DataRow deleteData={deleteData} placeholders={placeholders} tempData={tempData} setTempData={setTempData} saveData={saveData} /> : ''}
        </div>
    )
}




const DataRow = ({ tempData, placeholders, setTempData, saveData, deleteData }) => {

    const [error, setError] = useState('')

    const validate = () => {
        let error = false
        if (placeholders.subTitle && tempData.subTitle === '') error = true;
        if (placeholders.date && tempData.date === '') error = true;
        if (placeholders.title && tempData.title === '') error = true;
        if (placeholders.description && tempData.description === '') error = true;
        if (placeholders.dateRange && (tempData.dateRange.from === '' || tempData.dateRange.to === '')) error = true;
        setError(true);
        if (!error) saveData();
    }

    useEffect(() => {
        setError(false);
    }, [tempData])

    return (
        <div className='card shadow-sm border'>
            <div className='card-body py-3'>
                <div class="">
                    <div class="row align-items-center">
                        <div class="col">
                            <input
                                placeholder={placeholders.title}
                                class="form-control form-control-flush h4"
                                value={tempData.title}
                                onChange={e => setTempData(prev => ({ ...prev, title: e.target.value }))}
                            />
                        </div>
                        <div className="col-12 col-lg-3">
                            {placeholders.date ? (
                                <DateFormFlush
                                    value={tempData.date}
                                    setValue={(value) => setTempData(prev => ({ ...prev, date: value }))}
                                    placeholder={placeholders.date}
                                />) : (
                                <DateFormRangeFlush
                                    value={tempData.dateRange}
                                    setValue={(value) => setTempData(prev => ({ ...prev, dateRange: value }))}
                                    placeholder={placeholders.dateRange}
                                />)}
                        </div>
                    </div>
                    <input
                        placeholder={placeholders.subTitle}
                        class="form-control form-control-flush mt-0"
                        value={tempData.subTitle}
                        onChange={e => setTempData(prev => ({ ...prev, subTitle: e.target.value }))}
                    />
                    <div className="row">

                    </div>
                    <textarea
                        placeholder={placeholders.description}
                        class=" small form-control form-control-flush"
                        style={{ fontSize: '0.8125rem' }}
                        value={tempData.description}
                        onChange={e => setTempData(prev => ({ ...prev, description: e.target.value }))}
                    />
                </div>
                {error ? <div class='invalid-feedback d-block'>You missed out some info</div> : ''}
                <div class='d-flex justify-content-start mt-3'>
                    <div class='btn btn-sm btn-primary mr-3' onClick={() => validate()}>Save</div>
                    <div class='btn btn-sm btn-outline-secondary mr-3' onClick={() => deleteData()}>Delete</div>
                </div>
            </div>
        </div>
    )
}


const DataRowFilled = ({ title, subTitle, dateText, description, editData, index }) => {
    return (

        <div class="border-bottom mt-4 py-3">
            <div class="row align-items-center">
                <div class="col-12 col-lg">
                    <div class="h4 mb-2">
                        {title}
                        <span class="d-inline-block d-lg-none pb-1 ml-3">
                            <GiPencil class="pointer" onClick={() => editData(index)} />
                        </span>
                    </div>
                    <div>{subTitle}</div>
                </div>
                <div class="col-auto text-center mt-3 mt-lg-0">
                    {dateText}
                </div>
                <div class="col-auto d-none d-lg-block">
                    <GiPencil class="pointer" onClick={() => editData(index)} />
                </div>
            </div>
            <div class="small mt-3 mt-lg-2 pb-3">{description}</div>
        </div>
    )
}



