import {useRouteMatch, Switch } from "react-router-dom";

import ProtectedRoute from '../ProtectedRoute';
import Home from '../../pages/Dashboards/Instructor/Home'
import Schedule from '../../pages/Dashboards/Instructor/Schedule'
import Availability from '../../pages/Dashboards/Instructor/Availability/Availability'
import Account from '../../pages/Dashboards/Instructor/Account'
import Earnings from '../../pages/Dashboards/Instructor/Earnings'
import Students from '../../pages/Dashboards/Instructor/Students'
import Classes from '../../pages/Dashboards/Instructor/Classes'
import Messages from '../../pages/Dashboards/Instructor/Messages'
import Courses from '../../pages/Dashboards/Instructor/Courses'
import Course from '../../pages/Dashboards/Instructor/Courses/Course/index'
import AddCourse from '../../pages/Dashboards/Instructor/Courses/AddCourse'
import Profile from '../../pages/Dashboards/Instructor/Profile'
 
const AdminPagesRoutes = () => {

    let {path} = useRouteMatch();


    return (
        <>
        <Switch>
            <ProtectedRoute exact path={`${path}`} component={Home} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/calendar/schedule`} component={Schedule} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/calendar/availability`} component={Availability} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/students/`} component={Students} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/classes/`} component={Classes} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/courses/add`} component={AddCourse} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/course/:id`} component={Course} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/courses/`} component={Courses} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/messages/`} component={Messages} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/account/:id`} component={Account} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/profile/`} component={Profile} role = 'instructor'/>
            <ProtectedRoute exact path={`${path}/earnings/`} component={Earnings} role = 'instructor'/>
        </Switch>
        </>
    );
}

export default AdminPagesRoutes;



