import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import SelectUser from './SelectUsers'
import moment from 'moment'

const Messages = (props) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({messages: [], user: {}});

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
             <div class = 'header '>
                <div class = 'header-body'>
                    <div className = 'row align-items-end'>
                        <div className = 'col'>
                            <h3 class = 'h1 mb-0'>Messages</h3>
                        </div>
                        <div class = 'col-auto'>
                            <div class = 'btn btn-primary' onClick = {() => setShow(true)}>Select Users</div>
                        </div>
                    </div>
                </div>
            </div>

            {data.messages.length > 0 && <AllMessages data = {data}/>}

            <SelectUser
                show = {show}
                setShow = {setShow}
                setMessages = {setData}
            />
        </AdminDashboard>
    )
}


export default Messages




const AllMessages = ({data}) => {


    const [selected, setSelected] = useState('')
    const [selectedMessages, setSelectedMessages] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const role = data.user.role;
        const distinctUsers = getDistinct(data.messages.map(item => item[role === 'student' ? 'instructor': 'student']))
        setUsers(distinctUsers)
        setSelected(distinctUsers[0]._id)
        const filteredMessages = data.messages.filter(item => {
            if(item[data.user.role === 'student' ? 'instructor': 'student']._id === distinctUsers[0]._id) return true;
            else return false;
        })
        setSelectedMessages(filteredMessages)
    }, [data])

    useEffect(() => {
        const filteredMessages = data.messages.filter(item => {
            if(item[data.user.role === 'student' ? 'instructor': 'student']._id === selected) return true;
            else return false;
        })
        setSelectedMessages(filteredMessages)
        // eslint-disable-next-line
    }, [selected])



    return (
        <div className = 'row pt-5' style = {{maxHeight: "87vh"}}>
            <div className = {`col-lg-5 col-xl-4 d-lg-block ${data.user ? "d-none" : ""}`}>
                
                <div class = 'row align-items-center mb-4'> 
                    <div className = 'col h1 mb-0'>
                        Chats
                    </div>
                </div>
                <MessageNavigation 
                    users = {users} 
                    setSelected = {setSelected} 
                    selected = {selected}
                /> 
            </div>
            <div className = {`col d-lg-block ${data.user ? "" : "d-none"}`}>
                <MessageBody 
                    messages = {selectedMessages}
                /> 
            </div>
        </div>
    )
}




const MessageNavigation = ({users, setSelected, selected}) => {

    return (
    <div class = 'py-2 px-2' style = {{maxHeight: '70vh', overflow: 'scroll'}}>
        <nav class="nav" >
            {users
                .map((item, index) => <SideMessagesCard 
                    name = {item.firstName + ' ' + item.lastName}
                    avatar = {item.avatar}
                    setSelected = {setSelected}
                    id = {item._id}
                    selected = {selected}
                />)
            }
        </nav>
    </div>
    )
}
    
const SideMessagesCard = ({name, avatar, setSelected, selected, id}) => {
    return (
        <div className="text-reset nav-link p-0 mb-1 w-100" >
            <div class={`card pointer py-0 my-0`} onClick = {() => setSelected(id)} style = {{border: selected === id ? '0.5px solid rgba(0, 0, 0, 0.3)' : ''}}>
                <div className="card-body py-3">
                    <div className = 'row align-items-center'>
                        <div className = 'col-auto'>
                            <div className = 'avatar avatar-md'>
                                <img class="avatar-img rounded-circle" src={avatar} alt="" />
                            </div>
                        </div>
                        <div className = 'col'>
                            <div className="">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6 class="mb-0 mr-auto h4">{name}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    }




const MessageBody = ({messages}) => {
    

    return (
        <div className = {`card border shadow `}>
            <div className = 'card-header py-5'>
                <div className = 'row align-items-center '>
                    {messages.length > 0 && <div className = "col-auto d-flex align-items-center">
                        <div className = 'avatar avatar-md mr-2'>
                            <img class="avatar-img rounded-circle" src={messages[0].instructor.avatar} alt="" />
                        </div>
                        <div class = "">
                            {messages[0].instructor.firstName + ' ' + messages[0].instructor.lastName}
                            <div class = 'small text-muted'>Instructor</div>
                        </div>
                    </div>}
                    <div className = 'col'></div>
                    {messages.length > 0 && <div className = "col-auto d-flex align-items-center">
                        <div className = 'avatar avatar-md mr-2'>
                            <img class="avatar-img rounded-circle" src={messages[0].student.avatar} alt="" />
                        </div>
                        <div class = "">
                            {messages[0].student.firstName + ' ' + messages[0].student.lastName}
                            <div class = 'small text-muted'>Student</div>
                        </div>
                    </div>}
                </div>
            </div>
            <div className = 'card-body py-0'>
                <div className = 'pb-0 mb-0' style = {{height: '70vh', overflow: 'scroll', display: 'flex', flexDirection: 'column-reverse'}}>
                   {messages.sort((a, b) => new Date(b.sent_date) - new Date(a.sent_date)).map(item => <Message message = {item.message} time = {item.sent_date} from = {item.from}/>)}
                </div>
            </div>
        </div>
    )
}



const Message = ({message, time, from}) => {
    if(from === 'student') return <RightMessage message = {message} time = {time}/>
    else return <LeftMessage message = {message} time = {time}/>
}



const LeftMessage = ({message, time}) => {
    return (
        <div className = 'row mt-4'>
            <div className = 'col-10 col-xl-7'>
                <div className = 'card bg-secondary-soft mb-0'>
                    <div className = 'card-body'>
                   {message}
                    <div className = 'text-muted small mt-2'>{moment(time).fromNow()}</div>
                    </div>
                </div>
            </div>
            <div className = 'col'></div>
        </div>
    )
}


const RightMessage = ({message, time}) => {
    return (
        <div className = 'row mt-4'>
            <div className = 'col'></div>
            <div className = 'col-10 col-xl-7'>
                <div className = 'card bg-primary text-white mb-0'>
                    <div className = 'card-body py-4'>
                    {message}
                        <div className = 'text-white small mt-2'>{moment(time).fromNow()}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export const getDistinct = (array) => {
    const result = [];
    const map = new Map();
    for (const item of array) {
        if(!map.has(item._id)){
            map.set(item._id, true);    // set any id to Map
            result.push(item);
        }
    }
    return result
}



