import cogoToast from 'cogo-toast';
import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import { Switch } from '../../../../components/Forms'
import Swal from 'sweetalert2'

const EditLogsDetails = ({show, setShow, data, discounts, setDiscounts}) => {

    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false)

    const toggleDiscountStatus = async () => {
        try{
            setLoading1(true)
            const res = await axios.put(`/api/admin/discount/${data._id}/toggleStatus`);
            const newDiscounts = [...discounts];
            const index = newDiscounts.findIndex(item => item._id === data._id);
            const newStatus = res.data.status
            newDiscounts[index].status = newStatus
            newDiscounts[index].status1 = {value: newStatus, label: <div class={`badge badge-${newStatus === 'active' ? 'success' : 'danger'}`}>{newStatus}</div>}
            setDiscounts(newDiscounts)
            setShow(false);
            setLoading1(false)
        } catch(err){
            setLoading1(false)
            console.log(err);
        }
    }
    
    
    const deleteDiscount = async () => {
        const response = await Swal.fire({
            title: 'Are you sure you want to delete the discount',
            text: 'This action cannot be undone, once deleted',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if(response.isConfirmed){
            try{
                setLoading(true)
                await axios.delete(`/api/admin/discount/${data._id}`);
                const newDiscounts = [...discounts];
                const index = newDiscounts.findIndex(item => item._id === data._id);
                newDiscounts.splice(index, 1)
                setDiscounts(newDiscounts)
                setShow(false);
                setLoading(false)
                
            } catch(err){
                setLoading(false)
                console.log(err);
            }
        }
    }
    
    

    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'mb-5' id = 'details'>
                <div className = 'h2'>Discount - {data.discountCode}</div>
            </div>


            <div class = "row mt-4">
                <div class = "col-5">Discount Type:</div>
                <div class = "col-7">{data.discountType1.label}</div>
            </div>
            <div class = "row mt-4">
                <div class = "col-5">Discount Amount:</div>
                <div class = "col-7">{data.amount}</div>
            </div>
            <div class = "row mt-4">
                <div class = "col-5">{data.discountType === 'AMOUNT' ? 'Min Amount required' : 'Max Amount off'}</div>
                <div class = "col-7">{data.requirement}</div>
            </div>
            <div class = "row mt-4">
                <div class = "col-5">Applies To:</div>
                <div class = "col-7">{data.appliesTo1.label}</div>
            </div>
            <div class = "row mt-4">
                <div class = "col-5">Status:</div>
                <div class = "col-7">{data.status1.label}</div>
            </div>
            <div class = "row mt-4">
                <div class = "col-5">Redeem By:</div>
                <div class = "col-7">{data.redeemBy1.label}</div>
            </div>
            <div class = "row mt-4">
                <div class = "col-5">Number of times applied:</div>
                <div class = "col-7">{data.numApplied1}</div>
            </div>
            <div class = "row mt-4">
                <div class = "col-5">Terms and Conditions:</div>
                <div class = "col-7">{data.tnc}</div>
            </div>
            <div class = "row mt-4">
                <div class = "col-5">Comments:</div>
                <div class = "col-7">{data.comments}</div>
            </div>



            <div class='row mt-5'>
                <div class = "col-12">
                    <button disabled = {loading1} class = "btn btn-block btn-outline-danger mt-3" onClick = {(e) => toggleDiscountStatus(e)}>
                        {loading1 && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        {data.status === 'active' ? 'Deactivate Discount Code' : 'Activate Discount Code'}
                    </button>
                </div>
                <div class = "col-12">
                    <button disabled = {loading} class = "btn btn-block btn-outline-danger mt-3" onClick = {(e) => deleteDiscount(e)}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Delete Discount Code
                    </button>
                </div>
            </div>



          

        </RightModal>
    )
}

export default EditLogsDetails


