import axios from 'axios'
import cogoToast from 'cogo-toast'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ImCheckboxChecked, ImCheckboxUnchecked} from 'react-icons/im'
import {Link} from 'react-router-dom'
import instructor_content from '../../../../../content/instructor_content.json'


const Resources = ({show, setShow, enrollment}) => {

    const [loading, setLoading] = useState(false)
    const [resources, setResources] = useState(enrollment.course.resources ? enrollment.course.resources.map(item => ({...item, selected: enrollment.resources.findIndex(item2 => item2.url === item.url) > -1 ? true : false})) : []);


    const shareResources = async e => {
        e.preventDefault();
        try{
            setLoading(true);
            const req_body = {
                enrollmentId: enrollment._id,
                resourceIds: resources.filter(item => item.selected).map(item => item._id)
            }
            // console.log(req_body)
            const res = await axios.post('/api/instructor/resource/share', req_body)
            // console.log(res.data)
            cogoToast.success(instructor_content.student.resource.success)
            setShow(false)
            setLoading(false);
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

 

    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered>
            <div className = 'modal-content' >
                <div className = 'modal-body py-5 px-lg-5'>
                <div class = 'close pointer' onClick = {() => setShow(false)}>&times;</div>
                    <div class = 'mb-4 text-center '>
                        <div className = 'h2 mb-3'>{instructor_content.student.resource.title} "{enrollment.course.title}"</div>
                        <div className = 'p mb-4'>
                            Select and share study material with {enrollment.student.firstName} here. Wish to upload more relevant material? Please visit this <Link to = {`/instructor/course/${enrollment.course._id}`}>link</Link>, and go to the ‘Resources’ tab for the same.
                        </div>
                    </div>

                    <ul class="list-group list-group-active " style = {{maxHeight: '250px', overflow: 'scroll'}}>
                        {resources.length > 0 ? resources.map(item => (
                            <ResourceItem 
                                data = {item} 
                                setResources = {setResources}
                                resources = {resources}
                            />
                        )) : <div class = 'alert alert-light text-center text-muted h3 mb-0 py-5 text-uppercase'>You have not uploaded any resource yet.</div>}
                    </ul>

                    <button disabled = {loading || resources.length === 0} onClick = {e => shareResources(e)} class = 'btn btn-primary btn-block mt-4'>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        {instructor_content.student.resource.cta}
                    </button>
                  
                </div>
            </div>
        </Modal>
    )
}

export default Resources




const ResourceItem = ({data, setResources, resources}) => {


    const toggleSelection = () => {
        const index = resources.findIndex(item => item._id === data._id);
        const newResources = [...resources];
        newResources[index].selected = !newResources[index].selected;
        setResources(newResources)
    }

    return (
        <div className="list-group-item">
            <div className="row align-items-center">
                <div className = 'col-auto col-md-auto text-center'>
                    <div class = "h3 mb-0" onClick = {() => toggleSelection()}>
                        {data.selected ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
                    </div>
                </div>
                <div className="col-12 col-md ml-n2 mb-4 mb-md-0 d-flex align-items-center">
                    <div class = 'ml-3'>
                    <h4 class="text-body text-focus mb-2 name">{data.title}</h4>
                    <p class=" text-muted mb-2">{data.description}</p>
                    </div>
                </div>

                <div className = 'col-auto col-md-auto text-center'>
                    <a href = {data.url} target = "_blank" rel="noreferrer" class ="small">Click here</a>
                </div>
              
            </div>
        </div>
    )
}