import {Route, Redirect} from 'react-router-dom'
import React, { useEffect, useState, useContext } from 'react';
import {Store} from '../store'
import {getUser} from '../services/auth'
import { fetchUser, loginFailure } from '../store/actions/auth';
import BeatSpinner from '../components/Spinners/BeatSpinner'

const ProtectedRoute =  ({ component: Component, onboarding,  role, ...rest }) => {
    
    const {state, dispatch } = useContext(Store);
    const [loading, setLoading] = useState(true);
    const [didMount, setDidMount] = useState(true);


    useEffect(() => {
        setDidMount(true);
        fetching();
        return () => setDidMount(false);
    //eslint-disable-next-line
    }, [])

    const fetching = async () => {
        if(state.user.isLoggedIn === null){
            try{
                const user = await getUser();
                fetchUser(user, dispatch)
            } catch(err){
                loginFailure(dispatch)
            }
        } 
    }

    useEffect(() => {
        if(state.user.isLoggedIn === true){
            setLoading(false)
        } 
        else if(state.user.isLoggedIn === false){
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [state.user])



    if(!didMount) {
        return null;
    }  


    return(
        <React.Fragment>
        {!loading ? <Route {...rest} render = {
            (props) => {
                    if(state.user.isLoggedIn === true){
                        if(state.user.data.role === role)
                            return <Component {...props} />
                        else
                            return <Redirect to = "/" />
                    } else if(state.user.isLoggedIn === false){
                        return <Redirect to={{pathname: '/', state: props.location}} />
                    }
                }
            } 
        /> : <BeatSpinner loading = {true} />}
        </React.Fragment>
    )
}






export default ProtectedRoute;