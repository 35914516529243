import React, {useState} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import customStyles from '../../../../utilis/customStyles'
import AsyncSelect from 'react-select/async';

const SelectUser = ({show, setShow, setMessages}) => {

    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({value: '', label: ''});
    const [error, setError] = useState(false);



    const fetchUsers = async (inputValue, callback) => {
        try{
            const res = await axios.get(`/api/admin/enrollment/user/${inputValue}`);
            callback(res.data.map(item => ({
                value: item._id, 
                label: <NameWithAvatar email = {item.email} name = {item.firstName + ' ' + item.lastName} role = {item.role}/> 
            })))
        } catch(err){
            console.log(err)
        }
    }





    const fetchMessages = async (e) => {
        e.preventDefault();
        if(!user | user === '' || !user.value) {
            setError(true);
            return;
        }
        try{
            setLoading(true);
           
            const res = await axios.post('/api/admin/messages', {
                userId: user.value,
            })
            setMessages(res.data)
            setShow(false)

            setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }




 
    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Select the user</div>
                </div>

                <form class = "mt-3 ">
                    
                    <div class = "form-group mb-5">
                        <label>Select the user</label>
                        <AsyncSelect
                            cacheOptions
                            defaultOptions={[{value: '', label: 'Please type to search'}]}
                            styles = {customStyles.xxlSelect}
                            loadOptions={fetchUsers}
                            value = {user}
                            onChange={e => {
                                setError(false)
                                if(e && e.value && e.value !== '') {
                                    setUser(e)
                                } else setUser(null)
                            }}
                            isClearable
                        />
                        
                        {error && <div class = 'invalid-feedback d-block'>Please select a user</div>}
                    </div>
                   
                 
                  
                    
                    <button class = "btn btn-primary btn-block mt-6" onClick = {(e) => fetchMessages(e)} disabled = {loading}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Fetch Messages
                    </button>
                    
                </form>
                
            </div>
        </RightModal>
    )
}

export default SelectUser;






const NameWithAvatar = ({name, email, avatar, role}) => {
    return (
        <div class="row align-items-center no-gutters">
            <div class = "col">
                <div class="small mb-n1">{name}</div>
                <div class=" text-muted small">{email}, {role}</div>
            </div>
        </div>
    )
}