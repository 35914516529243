import React, { useState } from "react";
import axios from "../../../utilis/axios"

const ResetPassword = (props) => {

  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const [showPassword, setShowPassword] = useState([false, false]);
  const [errorPassword, setErrorPassword] = useState(['', '']);
  const [loading, setLoading] = useState(false);

  const resetPassword = async (e) => {
    e.preventDefault();
    if(errorPassword[0] !== '' || errorPassword[1] !== ''){
      return;
    }
    if(password !== password1) return;
    if(password.length < 8) return;
    try {
      setLoading(true);
      const hash = props.match.params.hash.split('-')
      await axios.post(`/api/auth/reset-password/${props.match.params.id}/${hash[0]}-${hash[1]}`, {password});
      setLoading(false);
      props.history.push('/login')
    } catch (err) {
      console.log(err)
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-5 col-xl-5 my-5">
            <h1 className="display-4 text-center mb-3">Reset Password</h1>
            <p className="text-muted text-center mb-5">
              Please enter a new password.
            </p>
            <form>
            
            
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>New Password</label>
                </div>
              </div>
              
              <div className="input-group input-group-merge">
                <input
                  type={showPassword[0] ? 'text' : 'password'}
                  className={`form-control form-control-appended ${errorPassword[0] !== '' ? "is-invalid" : ""}`}
                  placeholder={"New Password"}
                  value={password}
                  onChange={(e) => {
                    setErrorPassword(['', ''])
                    setPassword(e.target.value);
                  }}
                  onBlur = {() => {
                    if(password.length < 8){
                      const arr = [...errorPassword];
                      arr[0] = 'Password needs to be at least 8 characters'
                      setErrorPassword(arr)
                    }
                    if(password === ''){
                      const arr = [...errorPassword];
                      arr[0] = 'Please enter password'
                      setErrorPassword(arr)
                    }
                  }}
                />
                <div className="input-group-append">
                  <span className="input-group-text" onClick={() => {const arr = [...showPassword]; arr[0] = !arr[0]; setShowPassword(arr)}}>
                    {!showPassword[0] ? (<i className="far pointer fa-eye"></i>) : (<i className="far pointer fa-eye-slash"></i>)}
                  </span>
                </div>
                <div className="invalid-feedback">{errorPassword[0]}</div>
              </div>
            </div>



  
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label>Re-enter New Password</label>
                </div>
              </div>
              
              <div className="input-group input-group-merge">
                <input
                  type={showPassword[1] ? 'text' : 'password'}
                  className={`form-control form-control-appended ${errorPassword[1] !== '' ? "is-invalid" : ""}`}
                  placeholder={"New Password"}
                  value={password1}
                  onChange={(e) => {
                    setErrorPassword(['', ''])
                    setPassword1(e.target.value);
                  }}
                  onBlur = {() => {
                    if(password1 !== password){
                      const arr = [...errorPassword];
                      arr[1] = "Passwords don't match"
                      setErrorPassword(arr)
                    }
                  }}
                />
                <div className="input-group-append">
                  <span className="input-group-text" onClick={() => {const arr = [...showPassword]; arr[1] = !arr[1]; setShowPassword(arr)}}>
                    {!showPassword[1] ? (<i className="far pointer fa-eye"></i>) : (<i className="far pointer fa-eye-slash"></i>)}
                  </span>
                </div>
                <div className="invalid-feedback">{errorPassword[1]}</div>
              </div>
            </div>
            

              <button disabled = {errorPassword[0] !== '' || errorPassword[1] !== ''} className="btn btn-lg btn-block btn-primary mb-3" onClick={(e) => resetPassword(e)}>
                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Reset Password
              </button>

            </form>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-none d-lg-block align-self-center">
        <div class="text-center">
            <img src="https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/login.svg" alt="..." class="img-fluid" style = {{height: '400px'}}/>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
