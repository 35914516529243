import React, {useState, useContext, useEffect} from 'react'
import moment from 'moment'
import {DateFormInput1} from '../../../../../components/Forms'
import Select from 'react-select'
import {Modal} from 'react-bootstrap'
import axios from '../../../../../utilis/axios'
import { Store } from '../../../../../store'
import { saveRequests, saveSchedule } from '../../../../../store/actions/student'
import {RiErrorWarningLine} from 'react-icons/ri'
import student_content from '../../../../../content/student_content.json'


const Reschedule = ({show, setShow, data}) => {

    const {state, dispatch} = useContext(Store)
    const [draft, setDraft] = useState('');
    const [schedule, setSchedule] = useState({date: '', time: ''})
    const [loading, setLoading] = useState(false);
    const [mainLoading, setMainLoading] = useState(true);
    const [errors, setErrors] = useState([false, false])
    const [availability, setAvailability] = useState({});
    const [classesLeft, setClassesLeft] = useState(true);


    useEffect(() => {
        if(show && data.type === 'paid') {
            fetchEnrollment();
            setMainLoading(true)
        } else setMainLoading(false)
        // eslint-disable-next-line 
    }, [show])

    useEffect(() => {
        setErrors([false, false])
    }, [schedule])

    const fetchEnrollment = async () => {
        try{

            const res = await axios.get(`/api/student/enrollment/${data.enrollment._id}`);
            const enrollment = res.data;
            if(enrollment.classes_completed + enrollment.classes_requested + enrollment.classes_scheduled + enrollment.classes_late_charged >= enrollment.num_classes || enrollment.classes_left <= 0){
                if(moment(data.date_time).diff(moment(), 'hours') < 6){
                    setClassesLeft(false)
                }
            }
            setMainLoading(false)
        } catch(err){
            console.log(err);
        }
    }


    const fetchAvailability = async (date1) => {
        try{
            if(availability[date1] === undefined){
                setSchedule(prev => ({...prev, time: {value: null, label: 'Loading...'}}))
                const res = await axios.post(`/api/student/schedule/instructor_availability/${data.instructor._id}`, {date: date1})
                const newAvailability = {...availability};
                newAvailability[date1] = getAvailability(res.data);
                setAvailability(newAvailability)
                setSchedule(prev => ({...prev, time: {value: null, label: 'Please select a time slot'}}))
            }

        } catch(err){
            console.log(err)
        }
    }


    const finalStep = () => {
        const err = [false, false, false];
        if(!classesLeft) {
            scheduleSession()
            return;
        }
        if(schedule.date === '') err[0] = true;
        if(!schedule.time || !schedule.time.value || schedule.time.value === '') err[1] = true;
        if(draft === '') err[2] = true;
        if(err.includes(true)) {
            setErrors(err);
            return;
        } else{
            scheduleSession();
        }
    }

    const scheduleSession = async () => {
        try{
            setLoading(true);
            const scheduleISODate = classesLeft ? moment(schedule.date + "-" + schedule.time.label.slice(0, 8), "DD/MM/YYYY-hh:mm A") : null;
            const res = await axios.post(`/api/student/schedule/reschedule/${data._id}`, {new_date: scheduleISODate})
            
            const newSchedule = [...state.student.schedule]
            const index = newSchedule.findIndex(item => item._id === data._id)

            if(moment(data.date_time).diff(moment(), 'hours') < 6){
                newSchedule[index].status = 'rescheduled'
                newSchedule[index].cancelled_by = 'student'
                newSchedule[index].lateCancel = true
            } else{
                newSchedule.splice(index, 1)
            }
            saveSchedule(newSchedule, dispatch)
            
            if(classesLeft){
                const newRequests = [...state.student.requests]
                newRequests.push(res.data)
                saveRequests(newRequests, dispatch)
            }

            await sendMessage()
            
            setShow(false);
            setLoading(false)
            
        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }



    const sendMessage = async () => {
        try{
            setLoading(true)
            // console.log(data.instructor._id)
            const data1 = {
                message: draft,
                instructor: data.instructor._id,
            }
            await axios.post('/api/student/messages/send', data1);
            setLoading(false)
            setDraft('');
            
        } catch(err){
            setLoading(false)
            console.log(err);
        }
    }


    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered>
            <div className = 'modal-content' >
                <div className = 'modal-body py-5'>
                <div class = 'close pointer' onClick = {() => setShow(false)}>&times;</div>
                    <div class = 'mb-4 text-center px-lg-5'>
                        <div className = 'h2 mb-1'>{student_content.schdule_modals.reschedule.title}</div>
                        <div className = 'h8-unbold mb-1'>{student_content.schdule_modals.reschedule.description}</div>
                    </div>
                    {(moment(data.date_time).diff(moment(), 'hours') < 6 && data.type === 'paid') && <div class = 'card bg-danger mx-lg-4'>
                        <div class = 'card-body'>
                            <div class = 'row no-gutters text-white align-items-center'>
                                <div class = 'col-auto pr-3'>
                                    <RiErrorWarningLine class = 'display-3 mb-0'/>
                                </div>
                                <div class = 'col'>
                                    <p>{student_content.schdule_modals.reschedule.late}</p>
                                    {classesLeft === false && <p>{student_content.schdule_modals.reschedule.no_class_left}</p>}
                                </div>
                            </div>
                        </div>
                    </div>}
                    {mainLoading ? <div class = 'd-flex align-items-center justify-content-center' style = {{height: '20vh'}}><div class = 'spinner-border'></div></div> : 
                    
                    <form class = 'px-lg-4'>  
                        <div class = 'form-group'>
                        <label>Draft a message</label>
                            <textarea 
                                value = {draft}
                                onChange = {e => setDraft(e.target.value)}
                                class = {`form-control ${errors[2] ? 'is-invalid' : ''}`}
                                style = {{minHeight: '8vh'}}
                                placeholder = 'Please share the reason for rescheduling with your Guru'
                            />
                            <div class = 'invalid-feedback'>Please write a message</div>
                        </div>
                        {classesLeft && <>
                        <DateFormInput1 
                            placeholder = "Select a date"
                            error = {errors[0]}
                            setError = {(val) => setErrors(prev => {prev[0]=val; return prev})}
                            errorMessage = {"Please enter a valid date"}
                            label = "Date"
                            setValue = {(val) => {
                                setSchedule(prev => ({...prev, date: val}));
                                fetchAvailability(val)
                            }}
                            value = {schedule.date}
                            minimumDate = {moment().add(1,'d')}
                        />
                        <div class = "form-group my-4">
                            <label>Time Slot</label>
                            <Select 
                                value = {schedule.date ? schedule.time : {value: '', label: 'Please select a date'}}
                                options = {availability[schedule.date] ? availability[schedule.date] : [{value: '', label: '..'}]}
                                onChange = {e => setSchedule(prev => ({...prev, time: e}))}
                                isDisabled = {(schedule.date && availability[schedule.date]) ? false : true}
                            />
                            {errors[1] && <div class = 'invalid-feedback d-block'>Please choose a slot</div>}
                        </div>
                        </>}
                        <div class = "btn btn-primary btn-block mt-5" onClick = {() => finalStep()}>
                            {loading && <span class = 'spinner-border spinner-border-sm mr-1'></span>}
                            Reschedule your class
                        </div>
                    </form>}
                </div>
            </div>
        </Modal>
    )
}

export default Reschedule




export const getAvailability = (array) => {
    let temp = []
    let start1 = 0
    for(let i=0; i<array.length; i++){
        if(array[i]){
            temp.push({value:start1, label: timeFormat(start1) + ' - ' + timeFormat(start1, true)})
            start1++
        } else{
            start1++
        }
    }
    return temp
}



const timeFormat = (time, isSecond) => {
    if(time < 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " AM"
    } else if(time === 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " PM"
    } else if(time > 12){
        return time1(time-12) + ":" + (isSecond ? "45":"00") + " PM"
    }
}

const time1 = (time) => time >= 10 ? time : "0"+time