import React, {useState, useRef, useEffect, useContext, useCallback} from 'react'
import {Link} from 'react-router-dom'
import AnimateHeight from 'react-animate-height'
import {RiArrowDropDownLine} from 'react-icons/ri'
import {BsStarHalf, BsStarFill} from 'react-icons/bs'
import {FaQuoteLeft} from 'react-icons/fa'
import {AiFillPlayCircle} from 'react-icons/ai'
import { StickyContainer, Sticky } from 'react-sticky';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Nav from '../Nav'
import LgFooter from '../Footer'
import axios from '../../../utilis/axios'
import moment from 'moment'
import { Store } from '../../../store'
import cogoToast from 'cogo-toast'
import ReactPlayer from 'react-player'
import { Modal } from 'react-bootstrap'
import BeatSpinner from '../../../components/Spinners/BeatSpinner'
import ImageViewer from "react-simple-image-viewer";
import content from '../../../content/landingPage.json'
import faqs from '../../../content/faqs.json'
import { increasePriceByPercentage } from '../../../utilis/helpers'


const Course = (props) => {
    
    const [id, setId] = useState(props.match.params.id)
    const {state} = useContext(Store)
    const [course, setCourse] = useState()
    const [allInstruments, setAllInstruments] = useState([]);
    const [tab, setTab] = useState('bio')
    const [loading, setLoading] = useState(false)
    const detailsRef = useRef(null)
    const profileRef = useRef(null)
    const reviewRef = useRef(null)

    useEffect(() => {
        setId(props.match.params.id)
    }, [props.match.params.id])

    useEffect(() => {
        setCourse()
        if(state.landing.courses && state.landing.courses.length > 0){
            if(state.landing.courses.filter(item => item._id === id).length > 0){
                setCourse(state.landing.courses.filter(item => item._id === id)[0])
                getAllInsturments(state.landing.courses.filter(item => item._id === id)[0].instructor._id);
            } else fetchCourse();
        }
        else fetchCourse();
        // eslint-disable-next-line
    }, [id])

    const fetchCourse = async () => {
        try{
            const res = await axios.get(`/api/student/lesson/${id}`)
            setCourse(res.data)
            getAllInsturments(res.data.instructor._id)
        } catch(err){
            console.log(err)
        }
    }
    
    const getAllInsturments = async (instructor_id) => {
        if(state.landing.courses && state.landing.courses.length > 0){
            const temp = state.landing.courses.filter(item => item.instructor._id === instructor_id).map(item => ({instrument: item.instrument[0].toUpperCase() + item.instrument.slice(1), lesson_id: item._id}))
            setAllInstruments(temp)
        } else{
            try{
                const res = await axios.get(`/api/student/lessons/${instructor_id}/instruments`)
                setAllInstruments(res.data.map(item => ({instrument: item.instrument[0].toUpperCase() + item.instrument.slice(1), lesson_id: item._id})))
            } catch(err){
                console.log(err)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', updatePosition);
        return () => {
            window.removeEventListener('scroll', updatePosition);
        }
        // eslint-disable-next-line 
    }, [])

    const updatePosition = () => {
        if(isTop(detailsRef)){
            if(isTop(profileRef)){
                if(isTop(reviewRef)){
                    setTab("reviews")
                }
                else setTab("instructor")
            } else setTab("bio")
        }
    }

    const isTop = (el) => {
        if(el && el.current) return el.current.getBoundingClientRect().top <= 200;
    }

    const bookFreeSession = async () => {
        setLoading(true)
        if(state.user.isLoggedIn && state.user.data.role === 'student'){
            if(state.user.data.hasTakenDemo){
                cogoToast.error(content.bookFreeSession.toasts.already_registered)
            } else props.history.push(`/book-free-session/${course._id}`)
        } else props.history.push(`/book-free-session/${course._id}`)
        setLoading(false)
    }
 
    
    const messageInstructor = () => {
        if(state.user.isLoggedIn){
            props.history.push({
                pathname: '/my-dashboard/messages',
                search: `?user=${course.instructor._id}`,
                query: { instructor: course.instructor }
            })
        } else{
            props.history.push({
                pathname: '/login',
                query: {
                    message: true,
                    pathname: '/my-dashboard/messages',
                    search: `?user=${course.instructor._id}`,
                    instructor: course.instructor
                }
            })
        }
    }


    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback(() => {
        setIsViewerOpen(true);
    }, []);
    
    const closeImageViewer = () => {
        setIsViewerOpen(false);
    };

   
    return(
        <div>
        <Nav {...props}/>
        {!course ? <div class = 'mt-n6'><BeatSpinner /></div> : <>
        <div className = 'container pt-lg-3 pt-md-5 py-4 mt-5 px-lg-6'>
            <StickyContainer className = "">
                <div className = 'row'>
                    <div className = 'col-lg-8'>
                        <img 
                            alt ="headerImg" 
                            src = {course.headerImg} 
                            className = "rounded mb-3 pointer" 
                            style = {{maxHeight: "30vh", width: "100%", objectFit: "cover", objectPosition: 'top'}}
                            onClick = {() => openImageViewer()}
                        />
                        {isViewerOpen && (
                            <ImageViewer
                                src={[course.headerImg]}
                                currentIndex={0}
                                onClose={closeImageViewer}
                                backgroundStyle={{
                                    backgroundColor: "rgba(0,0,0,0.9)",
                                    zIndex: '1000122987349283'
                                }}
                        />)}
                        <div className = 'card rounded shadow-sm'>
                            <div className = 'card-body'>
                                <div className = 'row align-items-center'>
                                    <div className = 'col-auto'>
                                        <div className = 'avatar avatar-xl '>
                                            <img alt="" src ={course.instructor.avatar} className = 'avatar-img rounded-circle'/>
                                        </div>
                                    </div>
                                    <div className = 'col'>
                                        <h2 className = 'card-title h1 mb-2 '>{course.instructor.firstName + ' ' +course.instructor.lastName}</h2>
                                        <div className = 'text-muted h2 mb-0'>{course.title} </div>
                                        <div class = 'btn btn-sm btn-primary small pointer mt-3' onClick = {() => messageInstructor()}>Message your guru</div>
                                    </div>
                                </div>

                                <StickyContainer className = "">
                                    <Sticky>
                                        {({ style }) => (
                                            <div className = 'one-edge-shadow' style={{...style, backgroundColor: 'white', zIndex: '10000'}} >
                                                <NavTabs tab = {tab} setTab = {setTab} />
                                        </div>
                                    )}
                                    </Sticky>
                                    <div className = ''>
                                        <Details detailsRef = {detailsRef} data = {course}/>
                                        <Profile profileRef = {profileRef} instructor = {course.instructor} data = {course.instructor_profile} />
                                        {course.videos && course.videos.length > 0 && <Videos data = {course} />}
                                        {course.photos && course.photos.length > 0 && <Photos data = {course}/>}
                                        {course.reviews && course.reviews.length>0&& <Reviews reviewRef = {reviewRef} data = {course}/> }
                                        <BookFreeSession data = {course} bookFreeSession = {bookFreeSession} loading = {loading}/>
                                    </div>
                                </StickyContainer>
                            </div>
                        </div>
                    </div>
                    <div className = 'col-lg-4 d-none d-lg-block'>
                        <Sticky >
                            {({style, isSticky}) => 
                                <div style = {{...style, paddingTop: isSticky ? '15px' : '0px'}}>
                                    <Summary id = {id} data = {course} bookFreeSession = {bookFreeSession} loading = {loading} allInstruments = {allInstruments} {...props}/>
                                </div>
                            }
                        </Sticky>
                    </div>
                </div>
            </StickyContainer>
        </div>
        <div className = 'container px-lg-6'>
            <FAQs/>
        </div>
        <LgFooter />
        <div className = 'd-lg-none pb-1 px-3 w-100' style = {{position: 'fixed', bottom: '0px', zIndex: '10000000'}}>
            <div className = 'card card-hover shadow bg-primary' style = {{borderRadius: '10px'}}>
                <div className = 'card-body py-3'>
                    <div className= 'row align-items-center'>
                        <div className = 'col'>
                            <div className = 'text-uppercase text-light small mb-n1'>Price Starts</div>
                            <div className = 'font-weight-bold text-light'>Rs {parseInt(course.price_per_class * (1-Math.max(...course.discount.map(item => item.discount))/100))}</div>
                        </div>
                        <div className=  'col-auto'>
                            <Link to = {`/book-package/${id}`} className = 'btn btn-block btn-light'>Enroll Now</Link>
                        </div>
                    </div>  
                </div>
            </div>
        </div></>}
        </div>
    )
}


export default Course;



const Summary = ({id, data, bookFreeSession, loading, allInstruments, ...props}) => {


    const [availability, setAvailability] = useState({m: [], tu: [], w: [], th: [], f: [], sa: [], su: []});
    const [selectedDay, setSelectedDay] = useState('m');
    const [selectedInstrument, setSelectedInstrument] = useState(data._id)

    useEffect(() => {
        if(selectedInstrument !== '' && selectedInstrument.length > 10){
            props.history.push(`/lesson/${selectedInstrument}`)
        }
        // eslint-disable-next-line
    }, [selectedInstrument])
 
    useEffect(() => {
        const avail = (data.instructor_profile.availability);
        const newAvail = {...availability}
        if(avail){
            const availArray = Object.values(avail)
            availArray.map((item, index) => {
                newAvail[Object.keys(avail)[index]] = [
                    {type: 'Morning', duration: getAvailability(item.slice(6, 14), 6).split(',')},
                    {type: 'Afternoon', duration: getAvailability(item.slice(14, 18), 14).split(',')},
                    {type: 'Evening', duration: getAvailability(item.slice(18, 23), 18).split(',')},
                ];
                return item
            })
            setAvailability(newAvail)
        } 
        // eslint-disable-next-line
    }, [data])
    
    return(
        <div className = 'card shadow rounded' >
            <div className = 'card-body'>
                <h5 className = 'card-title mb-3 h2'>Book your session now!</h5>
               
                <div className = 'mt-4 pb-2 border-bottom d-flex justify-content-between'>
                    <div className = 'h8 mb-1'>Change Instrument</div>
                    <div className = 'mb-1'>
                        <select class = 'select-flush' value = {selectedInstrument} onChange = {(e) => {setSelectedInstrument(e.target.value)}}>
                            {allInstruments.map(item => <option value = {item.lesson_id}>{item.instrument}</option>)}
                        </select>
                    </div>
                </div>
                <div className = 'mt-4 pb-2 border-bottom'>
                    <div className = 'h8 mb-1'>Availability</div>
                    <div className = 'd-flex align-items-center justify-content-between mb-3'>
                        <div className = {`btn btn-block px-0 py-1 btn-${selectedDay !== 'm' ? 'outline-' : ''}dark mt-0 mr-1`} onClick = {() => setSelectedDay('m')}>M</div>
                        <div className = {`btn btn-block px-0 py-1 btn-${selectedDay !== 'tu' ? 'outline-' : ''}dark mt-0 mr-1`} onClick = {() => setSelectedDay('tu')}>T</div>
                        <div className = {`btn btn-block px-0 py-1 btn-${selectedDay !== 'w' ? 'outline-' : ''}dark mt-0 mr-1`} onClick = {() => setSelectedDay('w')}>W</div>
                        <div className = {`btn btn-block px-0 py-1 btn-${selectedDay !== 'th' ? 'outline-' : ''}dark mt-0 mr-1`} onClick = {() => setSelectedDay('th')}>T</div>
                        <div className = {`btn btn-block px-0 py-1 btn-${selectedDay !== 'f' ? 'outline-' : ''}dark mt-0 mr-1`} onClick = {() => setSelectedDay('f')}>F</div>
                        <div className = {`btn btn-block px-0 py-1 btn-${selectedDay !== 'sa' ? 'outline-' : ''}dark mt-0 mr-1`} onClick = {() => setSelectedDay('sa')}>S</div>
                        <div className = {`btn btn-block px-0 py-1 btn-${selectedDay !== 'su' ? 'outline-' : ''}dark mt-0 mr-1`} onClick = {() => setSelectedDay('su')}>S</div>
                    </div>
                    {availability[selectedDay].length > 0 ? availability[selectedDay].map(item => {
                        if(item.duration.length === 1 && item.duration[0] === "") return <></>
                        return <div className = 'd-flex pb-2 align-items-center justify-content-between px-3' >
                            <div class = 'small'>{item.type}</div>
                            <div class = 'small text-right'>{item.duration.map(item => <>{item} <br /></>)}</div>
                        </div>
                    }) : <div class = ' small pb-2'>No slot available</div>}
                </div>

                <div className = 'mt-4 pb-2'>
                    <div className = 'h8 mb-1'>Price Per Class</div>
                    <div className = 'd-flex pb-2 align-items-center justify-content-between' >
                        <div class = 'small'>45 minutes</div>
                        {/* <div class = 'small'>Rs {data.price_per_class}</div> */}
                        <div class = 'small'>Rs {increasePriceByPercentage(data.price_per_class)}</div>
                    </div>
                </div>
                <div className = 'mt-2 pb-2'>
                    <div className = 'h8 mb-1'>Available Discount</div>
                    <div className = 'd-flex pb-2 align-items-center justify-content-between' >
                        <div class = 'small'>For {data.discount.filter(item => parseInt(item.discount) === parseInt(Math.max(...data.discount.map(item => item.discount))))[0].num_classes} classes</div>
                        <div class = 'small'>{Math.max(...data.discount.map(item => item.discount))}%</div>
                    </div>
                </div>
               
                <div onClick = {() => bookFreeSession()}className = 'btn btn-block btn-outline-primary mt-3 mb-2'>
                    {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                    Book a free session
                </div>
                <Link to = {`/book-package/${id}`} className = 'btn btn-block btn-primary'>Enroll Now</Link>
                <div class = 'small text-center mt-2'>Musical fulfilment awaits </div>
            </div>
        </div>
    )
}





const NavTabs = (props) => {

    const {tab, setTab } = props;

    
    return(
        <ul class="nav nav-tabs no-scroll flex-nowrap text-nowrap mb-0 " style = {{overflow: 'scroll'}}>
            <li className="nav-item mb-0 ml-md-3">
            <AnchorLink offset='80' href='#bio' class={`nav-link px-1 px-md-3 ${tab === 'bio' ? "activee" : ""}`} style = {{borderWidth:"0px 0px 3px 0px", borderRadius: "0px", }} onClick = {() => setTab('bio')} >
                Curriculum 
            </AnchorLink>
            </li>
            <li className="nav-item mb-0">
            <AnchorLink offset='80' href='#instructor' onClick = {() => setTab('instructor')} class={`nav-link px-1 px-md-3 ${tab === 'instructor' ? "activee" : ""}`} style = {{borderWidth:"0px 0px 3px 0px", borderRadius: "0px", }}>
                Profile
            </AnchorLink>
            </li>
            <li className="nav-item mb-0">
            <AnchorLink offset='80' href='#reviews' onClick = {() => setTab('reviews')} class={`nav-link px-1 px-md-3 ${tab === 'reviews' ? "activee" : ""}`} style = {{borderWidth:"0px 0px 3px 0px", borderRadius: "0px", }}>
                Reviews
            </AnchorLink>
            </li>
        </ul>

)

}



const FAQs = ({faqsRef}) => {
    const [indexx, setIndexx] = useState("")
  
    return (
        <div  ref = {faqsRef} className = "mt-4" id = 'faqs'>
            <div className = 'py-3 lead font-weight-bold' >FAQs</div>
            {faqs[0].faqs.filter(item => item.active).map((item, index) => (<FAQItem type = {'faq'} indexx= {indexx} setIndexx = {setIndexx} key1  = {index} data = {item}/>))}
            
        </div>
    )
}

const Reviews = ({reviewRef, data}) => {

    const reviews = data.reviews.sort((a, b) => b.rating - a.rating);

    return (
        <div id = 'reviews' class = 'my-4' ref = {reviewRef}>
            <div className = 'h2 pb-1 mt-5' >Feedback on the course</div>
            {reviews.length === 0 && <div>No reviews yet</div>}
            {reviews.length > 0 && <div className = 'card rounded shadow mb-4 '>
                <div className = 'card-body pb-1'>
                <div className = 'row no-gutters mb-0 pb-0'>
                    <div className = 'col-auto mr-3'><FaQuoteLeft /></div>
                    <div className = 'col'>
                        <blockquote class="blockquote px-0">
                            <Bio bio = {reviews[0].review} />
                        </blockquote>
                    </div>
                </div>    
                    <footer class="row align-items-center no-gutters mb-4 mt-n2 ml-4">
                        <div className = 'col-auto'>
                            <div class = 'avatar avatar-sm'>
                                <img alt="" class = 'avatar-img rounded-circle' src = {reviews[0].avatar}/>
                            </div>
                        </div>
                        <div className="col pl-2">
                            <h3 class="h4 mb-0">{reviews[0].name}</h3>
                            <Stars numStars = {reviews[0].rating}/>
                        </div>
                    </footer>
                </div>
            </div>}

            {reviews.length >1 && reviews.slice(1).map((item, index) => 
                <ReviewItem 
                    indexx= {index} 
                    name = {item.name} 
                    rating = {item.rating} 
                    review = {item.review} 
                    time = {moment(item.date).fromNow()}
                />)}

        </div>
       
    )
}



const Profile = ({profileRef, data, instructor}) => {
    return (
        <div id = 'instructor' ref = {profileRef} >
            <div class = "h2 mt-5">About {instructor.firstName + ' ' + instructor.lastName}</div>
            <div class = "mb-3 preline">
                {data.bio}
            </div>
            <div class = "mb-3 mt-5">
                <strong>Languages</strong>: {data.languages.map(item => item[0].toUpperCase() + item.slice(1)).join(', ')}
            </div>
            
            
            {data.experience && data.experience.length>0 && <ProfileField 
                data = {data.experience}
                title = "Experience"
            />}
            {data.education && data.education.length>0 && <ProfileField 
                data = {data.education}
                title = "Education"
            />}
            {data.awards && data.awards.length>0 && <ProfileField 
                data = {data.awards}
                title = "Awards and Reco"
            />}
            {data.affiliations && data.affiliations.length>0 && <ProfileField 
                data = {data.affiliations}
                title = "Affiliation"
            />}
        </div>
    )
}


const ProfileField = ({data, title}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div>
            
            <div className = 'row align-items-center pointer py-2' onClick = {() => setIsOpen(prev => !prev)}>
                <div className = 'col'>
                    <div className = "h4 mb-0 font-weight-bold">{title}</div>
                </div>
                <div className = 'col-auto d-flex align-items-center justify-content-center'>
                    <div className = "h1 mb-0 "><RiArrowDropDownLine /></div>
                </div>
            </div>
            <AnimateHeight height = {isOpen ? 'auto' : 0} duration = {150}>
                <div class = "list-group list-group shadow mb-4">
                    {data.map(item => <ListItem data = {item}/>)}
                </div>
            </AnimateHeight>
        </div>
    )
}


const ListItem = ({data}) => {
    return (
    <div class = "list-group-item">
        <div class = "d-flex justify-content-between">
            <div class = "h4 mb-0">{data.title}</div>
            {data.dateRange && <div>{moment(data.dateRange.from).format('MMM YY')} - {moment(data.dateRange.to).format('MMM YY')}</div>}
        </div>
        <div>{data.subTitle}</div>
        <div class = "small">{data.description}</div>
    </div>
    )
}



const Photos = ({data}) => {

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback(index => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      }, []);
    
      const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      };


    return (
        <div id = 'photos'>
            <div class = "h2 mt-5">Photos</div>
            {data.photos.length > 0 && <div class = "row">
                <div class = "col-lg-4 col-6">
                    <img 
                        alt="" 
                        src = {data.photos[0].url} 
                        class = 'img-fluid rounded pointer' 
                        style = {{width: '100%', height: '210px', objectFit: 'cover', marginBottom: '10px'}}
                        onClick={() => openImageViewer(0)}
                    />
                </div>
                <div class = "col">
                    <div class = "row">
                    {data.photos.slice(1).map((item, index) => (
                        <div class = "col-lg-4 col-6">
                            <img 
                                alt="" 
                                src = {item.url} 
                                class = 'img-fluid rounded pointer border' 
                                style = {{width: '100%', height: '100px', objectFit: 'cover', marginBottom: '10px'}} 
                                onClick={() => openImageViewer(index+1)}
                            />
                        </div>
                    ))}
                    </div>
                </div>
            </div>}
            {isViewerOpen && (
                <ImageViewer
                    src={data.photos.map(item => item.url)}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                        zIndex: '1000122987349283'
                    }}
            />)}
        </div>
    )
}


const Videos = ({data}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState()
    const playVideo = (id) => {
        setUrl(id);
        setIsOpen(true)
    }


    return (
        <div id = 'videos'>
            <div class = "h2 mt-5">Videos</div>
            {data.videos.length > 0 && <div class = "row">
                <div class = "col-lg-4 col-6">
                    <div class = 'video-wrapper' onClick = {() => playVideo(data.videos[0].url)}>
                        <video class = 'img-fluid rounded video-overlay' style = {{width: '100%', height: '210px', objectFit: 'cover', marginBottom: '10px'}} >
                            <source src = {data.videos[0].url} type="video/mp4"/>
                        </video>
                        <AiFillPlayCircle className = 'video-play mt-n2 text-white h6 mb-0'/>
                    </div>
                </div>
                <div class = "col">
                    <div class = "row">
                        {data.videos.slice(1).map(item => (
                            <div class = "col-lg-4 col-6">
                                <div class = 'video-wrapper' onClick = {() => playVideo(item.url)}>
                                    <video class = 'img-fluid rounded video-overlay' style = {{width: '100%', height: '100px', objectFit: 'cover', marginBottom: '10px'}} >
                                        <source src = {item.url} type="video/mp4"/>
                                    </video>
                                    <AiFillPlayCircle className = 'video-play-2 mt-n2 text-white h6 mb-0'/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>}
            <Modal show = {isOpen} onHide = {() => setIsOpen(false)} centered  contentClassName ='bg-transparent'>
                <ReactPlayer 
                    config={{ file: { attributes: { disablepictureinpicture: 'true', controlsList: 'nodownload' }}}} 
                    onContextMenu={e => e.preventDefault()} 
                    url={url} 
                    controls = {true} 
                    width = {'100%'} 
                    height = {'100%'} 
                    style = {{width: '100%', height: '100%'}}
                />
            </Modal>
        </div>
    )
}



const Details = ({detailsRef, data}) => {
    return (
        <div id = 'bio' ref = {detailsRef}>
            <div className = 'py-3 lead font-weight-bold mt-4 h2 mb-0' >What you will learn</div>
            <div className = "mt-1 mb-5">
                <div className = 'p mb-4 preline'>{data.details}</div>
            </div>
            {data.speciality && <div class = "">
                <strong>Specialities</strong>: {data.speciality}
            </div>}
        </div>
    )
}








const FAQItem = ({indexx, setIndexx, key1, type, data}) => {

    return (
        <div className = 'border-bottom mb-4 pb-2' >
        
            <div className = 'row align-items-center pointer' onClick = {() => setIndexx(prev => prev === key1 ? "" : key1)}>
                <div className = 'col'>
                    <div className = "p mb-0 font-weight-bold">{data.question}</div>
                </div>
                <div className = 'col-auto d-flex align-items-center justify-content-center'>
                    <div className = "h5 mb-1 "><RiArrowDropDownLine /></div>
                </div>
            </div>
            <AnimateHeight height = {indexx===key1 ? 'auto' : 0} duration = {150}>
                <div class="mb-5  pt-3 pl-2" >
                    {data.answer.map(item => <div className = 'text-secondary mt-1 mb-3' style = {{fontSize: '16px'}}>
                        {typeof item === 'object' ? 
                        
                            (item.length ===1 ? <strong>{item}</strong> : <ul>{item.map(item2 => <li>{item2}</li>)}</ul>) : 
                        
                        item}
                    </div> )}
                </div>
            </AnimateHeight>
        </div>
    )
}


export const Stars = ({numStars}) => {
    return (
        <div>
            {Array(parseInt(numStars)).fill(null).map(item => <BsStarFill className ='text-primary'/>)}
            {!Number.isInteger(numStars) ? <BsStarHalf className ='text-primary'/> : ""}
            {/* {Array(Number.isInteger(numStars) ? 5-parseInt(numStars) : 4-parseInt(numStars)).fill(null).map(item => <BsStar className ='text-primary'/>)} */}
        </div>

    )
}



const ReviewItem = ({name, time, rating, review, indexx}) => {

    return (
        <div class={`mb-4 pt-4 ${indexx === 0 ? ""  : "border-top"} px-3`}>
        <div className="row no-gutters mb-sm-0 mb-3 ">
            <div className="col-md-3 col-sm-4 mt-2">
                <h3 class="mb-2 font-size-lg">{name}</h3>
                <span class="d-block mb-2 font-size-sm text-muted">{time}</span>
                <div className="mt-n1 star-rating">
                    <Stars numStars = {rating}/>
                </div>
            </div>
            <div className="col">
                <Bio2 bio = {review} />
            </div>
        </div>
        </div>
    )
}


const BookFreeSession = ({data, bookFreeSession, loading}) => {
    return (
        <div className = ''>
        <div className = ''>
            <div className = 'card bg-primary px-md-5 mt-5' style = {{backgroundImage: 'url("https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/banner-bg.png")', backgroundSize: 'cover'}}>
                <div className = 'row no-gutters align-items-center justify-content-center'>
                    <div className = 'col-12 col-md-7'>
                        <div className = 'card-body '>
                            <h4 className = ' mb-1' style = {{fontWeight: '800'}}>{content.lesson.freeSession.title}</h4>
                            <div className = 'mb-0 pr-lg-5' style = {{fontSize: '14px'}}>{content.lesson.freeSession.description}</div>
                            
                        </div>
                    </div>
                    <div className = 'col-11 col-md-5 mb-3 mb-md-0'>
                    <div onClick = {() => bookFreeSession()} className = 'btn btn-block btn-primary'>
                    {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                        {content.lesson.freeSession.cta}
                    </div>
                    </div>
                </div>
                
            </div>
        </div>
        </div>
    )
}








export const getAvailability = (array, start) => {
    let temp = []
    for(let i=0; i<array.length; i++){
        if(array[i]){
            if(temp.length === 0){
                temp.push(1)
            } else{
                if(temp[temp.length-1] <0){
                    temp.push(1)
                } else{
                    temp[temp.length-1] = temp[temp.length-1] + 1
                }
            }
        } else{
            if(temp.length === 0){
                temp.push(-1)
            } else{
                if(temp[temp.length-1] > 0){
                    temp.push(-1)
                } else{
                    temp[temp.length-1] = temp[temp.length-1] - 1
                }
            }
        }
    }
    const time = [];
    let start1 = start
    for(let i=0; i<temp.length; i++){
        if(temp[i] > 0){
            time.push(timeFormat(start1) + " - " + timeFormat(start1+temp[i]))
            start1 = start1+temp[i]
        } else{
            start1 = start1 + (temp[i]*-1)
        }
    }
    return time.join(',')

}

const timeFormat = (time) => {
    if(time < 12){
        return (time < 10 ? '0' + time : time) + ":00 AM"
    } else if(time === 12){
        return (time < 10 ? '0' + time : time) + ":00 PM"
    } else if(time > 12){
        return ((time-12) < 10 ? '0' + (time-12) : (time-12)) + ":00 PM"
    }
}


const Bio = ({bio}) => {

    const [more, setMore] = useState(false)

    return (
        <p class = "pb-0 mb-2" style = {{fontSize: '1.2rem'}}>
            {more ? bio : (bio.length > 160 ? bio.slice(0, 160) + '...' : bio)}
            {bio.length > 160 && <small class = 'btn-link pointer' onClick = {() => setMore(prev => !prev)}>{more ? 'Read less' : 'Read more'}</small>}
        </p>
    )
}


const Bio2 = ({bio}) => {

    const [more, setMore] = useState(false)

    return (
        <p class = "mb-3">
            {more ? bio : (bio.length > 160 ? bio.slice(0, 160) + '...' : bio)}
            {bio.length > 160 && <span class = 'btn-link pointer' onClick = {() => setMore(prev => !prev)}>{more ? 'Read less' : 'Read more'}</span>}
        </p>
    )
}