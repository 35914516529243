import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import {validateEmail, validatePhone, cleanPhone} from '../../../../utilis/validation'
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'


const AddAccount = ({show, setShow, accounts, setAccounts}) => {

    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState({firstName: '', phone: '', email: '', password: ''});
    const [errors, setErrors] = useState(Array(4).fill(false));
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setErrors(Array(4).fill(false))
    }, [user])

    useEffect(() => {
        setUser({firstName: '', phone: '', email: '', password: ''})
    }, [show])



    const validate = () => {
        let new_errors = Array(4).fill(false)
        if(user.firstName.length < 3) new_errors[0] = true;
        if(!validateEmail(user.email)) new_errors[1] = true;
        if(!validatePhone(user.phone)) new_errors[2] = true;
        if(user.password.length < 8) new_errors[3] = true;
        
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true;
      }


    const createAdmin = async (e) => {
        e.preventDefault()
        if(!validate()) return;
        try{
            setLoading(true);
            const res = await axios.post('/api/admin/admin-accounts', {...user, phone: cleanPhone(user.phone)})
            const newUsers = [...accounts];
            newUsers.push(res.data);
            setAccounts(newUsers)
            setLoading(false);
            setShow(false)
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

 
    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Create a Schedule</div>
                </div>

                <form class = "mt-3 ">

                    <div className="form-group">
                        <label>First Name</label>
                        <input
                            className={`form-control ${errors[0] && 'is-invalid'} `}
                            value = {user.firstName}
                            onChange = {(e) => setUser(prev => ({...prev, firstName: e.target.value}))}
                        />
                        <div class = 'invalid-feedback'>Name should be min 3 characters</div>
                    </div>

                    <div className="form-group">
                        <label>Email</label>
                        <input
                            class = {`form-control ${errors[1] && 'is-invalid'} `}
                            value = {user.email}
                            onChange = {(e) => setUser(prev => ({...prev, email: e.target.value}))}
                            autoComplete = 'email'
                        />
                        <div class = 'invalid-feedback'>Enter a right email</div>
                    </div>

                    <div className="form-group">
                        <label>Phone</label>
                        <input
                            className={`form-control ${errors[2] && 'is-invalid'} `}
                            value = {user.phone}
                            onChange = {(e) => setUser(prev => ({...prev, phone: e.target.value}))}
                            autoComplete="new-password"
                        />
                        <div class = 'invalid-feedback'>Phone is invalid</div>
                    </div>


                    <div className="form-group">
                        <label>Password</label>
                        <div className="input-group input-group-merge">
                        <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control form-control-append ${errors[3] && 'is-invalid'} `}
                            placeholder='Password'
                            value={user.password}
                            onChange={(e) => setUser(prev => ({...prev, password:  e.target.value}))}
                            autoComplete="new-password"
                        />
                        <div className="input-group-append">
                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                            {!showPassword ? (<AiOutlineEye className = 'pointer' size = {15} />) : (<AiOutlineEyeInvisible className = 'pointer' size = {15}/>)}
                            </span> 
                        </div>
                        <div className="invalid-feedback">Please enter a password</div>
                        </div>
                    </div>
                                        
                    
                    <button class = "btn btn-primary btn-block mt-6" onClick = {(e) => createAdmin(e)} disabled = {loading}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Create Admin
                    </button>
                    
                </form>
                
            </div>
        </RightModal>
    )
}

export default AddAccount;





