import React, {useState, useContext, useEffect} from 'react'
import DashboardLayout from '../index'
import Table from '../../../../components/Table'
import axios from '../../../../utilis/axios'
import { Store } from '../../../../store'
import Skeleton from 'react-loading-skeleton'
import { saveClasses } from '../../../../store/actions/instructor'

const Classes = (props) => {

    const {state, dispatch} = useContext(Store)
    const [data, setData] = useState();
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        if(state.instructor.classes && state.instructor.classes.length > 0){
            formatData(state.instructor.classes)
        } else fetchEnrollments();
        // eslint-disable-next-line
    }, [])

    const fetchEnrollments = async () => {
        try{
            const res = await axios.get('/api/instructor/classes');
            // console.log(res.data)
            saveClasses(res.data, dispatch)
            formatData(res.data)
        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data1) => {
        
        setData(data1)

    }

    return (
        <DashboardLayout  
            showTitle = {false} 
            {...props}
        >
             <div className = 'mt-2'>
                <div className = 'row justify-content-center'>

                    <div className = 'col-12 col-xl-11'>
                        <div className = 'header mt-md-5'>
                            <div className = 'header-body'>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 class="header-pretitle">Overview</h6>
                                        <h1 class="header-title">Completed Sessions</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data ? <Table 
                            data = {data}
                            isSelectable = {false}
                            headers = {classesHeader}
                            isSearchable = {true}
                            handleRowClick = {(id) => console.log(id)}
                            isHeader = {true}
                            isFooter = {true}
                            isSortable = {true}
                            searchInput = {searchInput}
                            setSearchInput = {setSearchInput}
                            filters = {[]}
                        /> : <LoadingSkeleton />}
                    </div>
                </div>
            </div>
            
        </DashboardLayout>
    )
}

export default Classes






const classesHeader = [
    {value: 'name', active: true, label: 'Name'},
    {value: 'course_name', active: true, label: 'Subject Enrolled'},
    {value: 'billing_status', active: true, label: 'Total Amount Paid'},
    {value: 'classes_left', active: true, label: 'Sessions Left'},
    {value: 'enrolledDate', active: true, label: 'Enrolled Date'},
    {value: 'chat', active: true, label: 'Chat'},
]



const LoadingSkeleton = () => {
    return (
        <Skeleton height = {600}/>
    )
}