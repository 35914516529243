import React, {useState, useEffect} from 'react'
import moment from 'moment'
import { AiOutlineClockCircle, AiFillSetting } from 'react-icons/ai';
import Reschedule from './Modals/Reschedule'
import Cancel from './Modals/Cancel'
import Skeleton from 'react-loading-skeleton'
import student_content from '../../../../content/student_content.json'
import axios from '../../../../utilis/axios'
import Pagination from '../../../../components/Dashboard/Pagination'

const Scheduled = ({scheduled, selectedDay, ...props}) => {
    
    const [filteredData, setFilteredData] = useState()
    const [totalPages, setTotalPages] = useState();
    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        if(scheduled){
            let temp = scheduled.filter(item => {
                if(moment(item.date_time).diff(moment(), 'minutes') < -45) return false;
                if(item.status !== 'scheduled') return false;
                if(!selectedDay) return true;
                else {
                    const day = (moment({...selectedDay, month: selectedDay.month - 1}).format('DD/MM/YYYY'))
                    if(day === moment(item.date_time).format('DD/MM/YYYY')) return true;
                    else return false;
                }
            })
            .sort((a, b) => moment(a.date_time) - moment(b.date_time))

            setTotalPages(temp.length%10 === 0 ? parseInt(temp.length/10) : parseInt(temp.length/10)+1)
            if(temp.length === 0) setPageNum(0)
            else setPageNum(1)
            setFilteredData(temp)
        }
    }, [scheduled, selectedDay])

    return (
        <div>
            <div class = 'alert alert-light'>{student_content.schedule_view.schedule_help}</div>
            {filteredData ? (filteredData.length > 0 ? (
                filteredData.length > 10 ? filteredData.slice(pageNum*10-10, pageNum*10).map(item => <ScheduleItem data = {item} {...props}/>) : filteredData.map(item => <ScheduleItem data = {item} {...props}/>)
            ): <NoSchedule />)  : <LoadingSchedule />}
            <Pagination pageNum = {pageNum} setPageNum = {setPageNum} totalPages = {totalPages}/>
        </div>
    )
}

export default Scheduled



const ScheduleItem = ({data, ...props}) => {

    const [showCancel, setShowCancel] = useState(false)
    const [showReschedule, setShowReschedule] = useState(false);

    const joinNow = async () => {
        try{
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = data.zoom.join_url;
            a.target = "_blank"
            a.click();
            document.body.removeChild(a);
            await axios.get(`/api/student/schedule/clicked_join/${data._id}`)
        } catch(err){
            console.log(err)
        }
    }

    return (
        <div className = 'card mb-4'>
            <div className = 'card-header'>
                <div class = 'd-flex align-items-center'>
                    <AiOutlineClockCircle class = 'mr-3'/> 
                    <strong class = 'mr-2'>{moment(data.date_time).format('Do MMM YYYY')},</strong>
                    {moment(data.date_time).format('hh:mm A')} - {moment(data.date_time).add(45, 'm').format('hh:mm A')}
                </div>
                <div className = "dropdown">
                    <div class = 'navbar-user-link show-notif'>
                    <AiFillSetting class = ' mb-0 h3 '/>
                    <ul class="dropdown-menu show-notif-hover" style = {{right: '0%', left: 'auto'}}>
                        {/* <li><div className="dropdown-item pointer" onClick = {() => setShowReschedule(true)}>Reschedule</div></li> */}
                        <li><div className="dropdown-item pointer" onClick = {() => setShowCancel(true)}>Cancel</div></li>
                    </ul>
                    </div>
                </div>
            </div>
            <div className = 'card-body py-3'>
                <div className = 'row align-items-center'>
                    <div className = 'col-auto'>
                        <div className="avatar avatar-md"  >
                            <img src={data.instructor.avatar} alt="..." class="avatar-img rounded-circle" />
                        </div>
                    </div>
                    <div className = 'col'>
                        <div class = "h4 mb-1">{data.instructor.firstName + ' ' + data.instructor.lastName}</div>
                        <div class = "text-muted">{data.course.title}</div>
                        <small class = "small text-muted">Session ID: {data.bookingId}</small><br/>
                        <div class = "badge badge-primary my-1">{ data.type === 'demo' ? 'Assessment':makeFirstLetterCap(data.type)} session</div>
                    </div>
                    {moment(data.date_time).diff(moment(), 'minutes') < parseInt(process.env.REACT_APP_JOIN_NOW)  && <div className = 'col-auto'>
                        <div className = "btn btn-primary btn-sm" onClick = {() => joinNow()}>Join now</div>
                    </div>}
                </div>
            </div>
            <Reschedule show = {showReschedule} setShow = {setShowReschedule} data = {data}/>
            <Cancel show = {showCancel} setShow = {setShowCancel} data = {data}/>
        </div>
    )
}



const LoadingSchedule = () => {
    return (
        <div>
            <Skeleton height = {100}/>
            <div class = 'mb-4'></div>
            <Skeleton height = {100}/>
            <div class = 'mb-4'></div>
            <Skeleton height = {100}/>
        </div>
    )
}


const NoSchedule = () => {
    return (
        <div className = 'card mb-4'>
        <div className = 'card-body d-flex align-items-center justify-content-center'>
            <div className = 'h3 text-uppercase text-muted mb-0 py-5'>
            {student_content.schedule_view.no_schedule_sent}
            </div>
        </div>
    </div>
    )
}
const makeFirstLetterCap = (text) => text ? text[0].toUpperCase() + text.slice(1) : ''