import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { AiOutlineClockCircle } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import axios from '../../../../utilis/axios'
import { saveSchedule } from '../../../../store/actions/student'
import { Store } from '../../../../store/'
import student_content from '../../../../content/student_content.json'
import Pagination from '../../../../components/Dashboard/Pagination'
import AttendanceModal from './Modals/Attendance'

const Completed = ({ selectedDay, scheduled, setScheduled, ...props }) => {

    const [filteredData, setFilteredData] = useState()
    const [totalPages, setTotalPages] = useState();
    const [pageNum, setPageNum] = useState(1);
    const [mainLoading, setMainLoading] = useState(false);



    useEffect(() => {
        if (scheduled) {
            const temp = scheduled
                .filter(item => {
                    // This checks if the event is in the past by more than 45 minutes and its status
                    const isPastEvent = moment(item.date_time).isBefore(moment().subtract(45, 'minutes'));
                    const isValidStatus = item.status === 'scheduled' || item.status === 'completed';

                    if (!isPastEvent || !isValidStatus) return false;

                    // Filter by selectedDay if it is specified
                    if (selectedDay) {
                        const selectedDayFormatted = moment(selectedDay).format('DD/MM/YYYY');
                        const itemDayFormatted = moment(item.date_time).format('DD/MM/YYYY');
                        return selectedDayFormatted === itemDayFormatted;
                    }
                    
                    return true;
                })
                .sort((a, b) => {
                    // Combine both sorts into one for better performance
                    const dateComparison = moment(b.date_time).diff(moment(a.date_time));
                    if (dateComparison !== 0) return dateComparison;

                    const aConfirm = a.confirm_by_student === undefined ? 0 : 1;
                    const bConfirm = b.confirm_by_student === undefined ? 0 : 1;
                    return aConfirm - bConfirm;
                });

            setTotalPages(Math.ceil(temp.length / 10));
            setPageNum(temp.length > 0 ? 1 : 0);
            setFilteredData(temp);
        }
    }, [scheduled, selectedDay]);


    // useEffect(() => {
    //     if (scheduled) {
    //         const temp = scheduled
    //             .filter(item => {
    //                 if (moment(item.date_time).diff(moment(), 'minutes') < -45 && (item.status === 'scheduled' || item.status === 'completed')) {
    //                     if (!selectedDay) return true;
    //                     else {
    //                         const day = (moment({ ...selectedDay, month: selectedDay.month - 1 }).format('DD/MM/YYYY'))
    //                         if (day === moment(item.date_time).format('DD/MM/YYYY')) return true;
    //                         else return false;
    //                     }
    //                 } else return false;
    //             })
    //             .sort((a, b) => moment(b.date_time) - moment(a.date_time))
    //             .sort((a, b) => {
    //                 const a1 = a.confirm_by_student === undefined ? 0 : 1
    //                 const b1 = b.confirm_by_student === undefined ? 0 : 1
    //                 return a1 - b1
    //             })

    //         setTotalPages(temp.length % 10 === 0 ? parseInt(temp.length / 10) : parseInt(temp.length / 10) + 1)
    //         if (temp.length === 0) setPageNum(0)
    //         else setPageNum(1)
    //         setFilteredData(temp)
    //     }
    // }, [scheduled, selectedDay])

    return (
        <div>
            <div class='alert alert-light'>{student_content.schedule_view.complete_help}</div>
            {filteredData ? (filteredData.length > 0 ? (
                filteredData.length > 10 ? filteredData.slice(pageNum * 10 - 10, pageNum * 10).map(item => <CompletedItem data={item} mainLoading={mainLoading} setMainLoading={setMainLoading} {...props} />) : filteredData.map(item => <CompletedItem data={item} mainLoading={mainLoading} setMainLoading={setMainLoading} {...props} />)
            ) : <NoSchedule />) : <LoadingSchedule />}
            <Pagination pageNum={pageNum} setPageNum={setPageNum} totalPages={totalPages} />
        </div>
    )
}

export default Completed



const CompletedItem = ({ data, mainLoading, setMainLoading }) => {
    const { state, dispatch } = useContext(Store);
    const [loading, setLoading] = useState('')
    const [showAttendance, setShowAttendance] = useState(false);

    return (
        <div className='card mb-4'>
            <div className='card-header'>
                <div class='d-flex align-items-center'>
                    <AiOutlineClockCircle class='mr-3' />
                    <strong class='mr-2'>{moment(data.date_time).format('Do MMM YYYY')},</strong>
                    {moment(data.date_time).format('hh:mm A')} - {moment(data.date_time).add(45, 'm').format('hh:mm A')}
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='row align-items-center'>
                    <div className='col-auto'>
                        <div className="avatar avatar-md"  >
                            <img src={data.instructor.avatar} alt="..." class="avatar-img rounded-circle" />
                        </div>
                    </div>
                    <div className='col'>
                        <div class="h4 mb-1">{data.instructor.firstName + ' ' + data.instructor.lastName}</div>
                        <div class="text-muted">{data.course.title}</div>
                        <small class="small text-muted">Session ID: {data.bookingId}</small><br />
                        <div class="badge badge-primary my-1">{data.type === 'demo' ? 'Assessment' : makeFirstLetterCap(data.type)} session</div>


                        {data.flagDirection === 'student' ?
                            (data.confirm_by_student === 'student_joined' && <div class="badge badge-success my-1 ml-2">Completed</div>) :
                            (data.confirm_by_instructor === 'student_joined' && <div class="badge badge-success my-1 ml-2">Completed</div>)
                        }

                        {data.flagDirection === 'student' ?
                            (data.confirm_by_student === 'student_no_show' && <div class="badge badge-primary my-1 ml-2">Member did not join</div>) :
                            (data.confirm_by_instructor === 'student_no_show' && <div class="badge badge-primary my-1 ml-2">Mentor did not join</div>)
                        }


                        {data.flagDirection === 'student' ?
                            ((data.confirm_by_student === 'student_no_show' && data.lateCharges) && <div class="badge badge-danger my-1 ml-2">Session Charged</div>) :
                            ((data.confirm_by_instructor === 'student_no_show' && data.lateCharges) && <div class="badge badge-danger my-1 ml-2">Session Charged</div>)
                        }



                        {data.flagDirection === 'student' ?
                            ((data.confirm_by_student === 'instructor_no_show' && (data.lateCharges === false || data.lateCharges === undefined)) && <div class="badge badge-success my-1 ml-2">Session Not Charged</div>) :
                            ((data.confirm_by_instructor === 'student_no_show' && (data.lateCharges === false || data.lateCharges === undefined)) && <div class="badge badge-success my-1 ml-2">Session Not Charged</div>)
                        }

                        {data.flagDirection === 'student' ?
                            (data.confirm_by_student === 'instructor_no_show' && <div class="badge badge-danger my-1 ml-2">Mentor did not join</div>) :
                            (data.confirm_by_instructor === 'instructor_no_show' && <div class="badge badge-danger my-1 ml-2">Mentor did not join</div>)
                        }

                        {data.flagDirection === 'student' ?
                            (data.confirm_by_student === 'internet_issue_student' && <div class="badge badge-danger my-1 ml-2">Student internet issue</div>) :
                            (data.confirm_by_instructor === 'internet_issue_student' && <div class="badge badge-danger my-1 ml-2">Student internet issue</div>)
                        }
                        {data.flagDirection === 'student' ?
                            (data.confirm_by_student === 'internet_issue_instructor' && <div class="badge badge-danger my-1 ml-2">Instructor internet issue</div>) :
                            (data.confirm_by_instructor === 'internet_issue_instructor' && <div class="badge badge-danger my-1 ml-2">Instructor internet issue</div>)
                        }

                        {(data.status === 'completed' && data.lessonDetails) && <div class="">Session Details: {data.lessonDetails}</div>}

                        {(data.isFlagged) && <div class="my-1 small ">Note: Meeting flagged due to different responses from you and your instructor. We are working to rectify it as soon as possible</div>}
                    </div>

                </div>
            </div>
            {(data.status === 'completed' && data.notes && data.notes.length > 0) && <div class='card-footer py-3 bg-light'>
                <div class="">
                    <div class='p bold'>Notes: </div>
                    <ul class=' mt-0 list-group pl-4'>{data.notes.map(item => <li class=''>{item}</li>)}</ul>
                </div>
            </div>}
            {(data.confirm_by_student === undefined && moment(data.date_time).diff(moment(), 'days') > -14) && <div class='card-footer'>
                <div class='row align-items-center '>
                    <div class='col-12 col-lg-4 pt-1'>
                        <h3 class='h5 mb-0'>Was this session attended or missed by you?</h3>
                    </div>
                    <button class='btn btn-sm btn-primary border' onClick={() => setShowAttendance(true)}>
                        Mark Attendance
                    </button>
                </div>
            </div>}
            <AttendanceModal setLoading={setLoading} data={data} state={state} dispatch={dispatch} show={showAttendance} setShow={setShowAttendance} loading={loading} mainLoading={mainLoading} setMainLoading={setMainLoading} />
        </div>
    )
}





const LoadingSchedule = () => {
    return (
        <div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
        </div>
    )
}


const NoSchedule = () => {
    return (
        <div className='card mb-4'>
            <div className='card-body d-flex align-items-center justify-content-center'>
                <div className='h3 text-uppercase text-muted mb-0 py-5'>
                    {student_content.schedule_view.no_completed_classes}
                </div>
            </div>
        </div>
    )
}



const makeFirstLetterCap = (text) => text ? text[0].toUpperCase() + text.slice(1) : ''

