import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import { BeatSpinner2 } from '../../../../components/Spinners/BeatSpinner'
import moment from 'moment'
import AddAnnouncement from './AddAnnouncement'
import { AiFillDelete } from 'react-icons/ai'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const AdminAccounts = (props) => {

    const [data, setData] = useState();
    const [announcement, setAnnouncement] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [show, setShow] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState()


    useEffect(() => {
        fetchAnnouncement()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(data) formatData(data)
        // eslint-disable-next-line
    }, [data])

    const fetchAnnouncement = async () =>{
        try{

            const res = await axios.get('/api/announcements');
            setData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        setAnnouncement(data.map(item => ({
            ...item,
            link: {value: '', label: <a href = {item.link} >Link</a>},
            createdAt: moment(item.createdAt).format('Do MMM YY, hh:mm A'),
            img: {value: '', label: <img src = {item.img} class = "img-fluid" style = {{height: '100px', objectFit: 'cover'}} alt= ""/>},
            delete: {value: '', label: 
                <OverlayTrigger overlay = {<Tooltip>Delete</Tooltip>}>
                    <div class = "h3 mb-0 pointer" onClick = {() => setSelectedAnnouncement(item._id)}>
                        <AiFillDelete />
                    </div>
                </OverlayTrigger>
            }
        })))  
    }

    useEffect(() => {
        if(selectedAnnouncement && selectedAnnouncement.length > 5) deleteAnnouncements(selectedAnnouncement)
         // eslint-disable-next-line 
    }, [selectedAnnouncement])


    const deleteAnnouncements = async (id) => {
        try{

            setSelectedAnnouncement()
            const newAnnouncments = [...announcement];
            const index = newAnnouncments.findIndex(item => item._id === id);
            newAnnouncments.splice(index, 1)
            setAnnouncement(newAnnouncments);
            
            const newData = [...data];
            const index1= newData.findIndex(item => item._id === id);
            newData.splice(index1, 1);
            setData(newData)

            await axios.delete(`/api/admin/announcement/${id}`);

        } catch(err){
            console.log(err)
        }
    }

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
            <div className = 'row align-items-center mt-5 border-bottom pb-3 mb-4'>
                <div className = 'col'>
                    <h3 class = 'h1'>Announcement</h3>
                </div>
                <div class = 'col-auto'>
                    <div class = 'btn btn-primary' onClick = {() => setShow(true)}>Create New Announcement</div>
                </div>
            </div>
            {announcement ? <Table 
                data = {announcement}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => console.log('')}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
            /> : <BeatSpinner2 />}


            <AddAnnouncement
                show = {show}
                setShow = {setShow}
                announcements = {data}
                setAnnouncements = {setData}
            />
        </AdminDashboard>
    )
}

export default AdminAccounts




const headers = [
    {value: 'title', active: true, label: 'Title'},
    {value: 'description', active: true, label: 'Description'},
    {value: 'img', active: true, label: 'Image'},
    {value: 'link', active: true, label: 'Link'},
    {value: 'cta', active: true, label: 'CTA'},
    {value: 'userType', active: true, label: 'User Type'},
    {value: 'createdAt', active: true, label: 'Created At'},
    {value: 'delete', active: true, label: 'Delete'},
]




