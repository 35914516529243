import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { AiOutlineClockCircle } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import axios from '../../../../utilis/axios'
import { Modal } from 'react-bootstrap'
import { saveSchedule } from '../../../../store/actions/instructor'
import { Store } from '../../../../store/'
import instructor_content from '../../../../content/instructor_content.json'
import Pagination from '../../../../components/Dashboard/Pagination'
import AttendanceModalInst from './Attendance.inst'

const Completed = ({ selectedDay, scheduled, setScheduled, ...props }) => {

    const [filteredData, setFilteredData] = useState()
    const [totalPages, setTotalPages] = useState();
    const [pageNum, setPageNum] = useState(1);
    const [mainLoading, setMainLoading] = useState(false);


    useEffect(() => {
        if (scheduled) {
            let temp = scheduled.filter(item => {
                if (moment(item.date_time).diff(moment(), 'minutes') < -45 && (item.status === 'scheduled' || item.status === 'completed')) {
                    if (!selectedDay) return true;
                    else {
                        if (selectedDay === moment(item.date_time).format('DD/MM/YYYY')) return true;
                        else return false;
                    }
                } else return false;
            })

            let temp1 = [];
            let temp2 = [];


            for (const data of temp) {
                if (data.status === "scheduled" && !data.confirm_by_instructor && moment(data.date_time).diff(moment(), 'days') > -14) {
                    temp1.push(data)
                }
                else {
                    temp2.push(data)
                }
            }
            // const temp1 = temp.filter(item => item.status != "completed" && !item.confirm_by_instructor);
            // const temp2 = temp.filter(item => item.status === "completed"  );

            temp2.sort((a, b) => moment(b.date_time) - moment(a.date_time))
            temp1.sort((a, b) => moment(a.date_time) - moment(b.date_time))

            temp = [...temp1, ...temp2];

            // .sort((a, b) => moment(b.date_time) - moment(a.date_time))
            // .sort((a, b) => {
            //     const a1 = a.status === 'completed' ? 1 : 0
            //     const b1 = b.status === 'completed' ? 1 : 0
            //     return a1-b1
            // }).sort((a, b)=> {
            //     return 
            // })

            setTotalPages(temp.length % 10 === 0 ? parseInt(temp.length / 10) : parseInt(temp.length / 10) + 1)
            if (temp.length === 0) setPageNum(0)
            else setPageNum(1)
            setFilteredData(temp)
        }
    }, [scheduled, selectedDay])


    return (
        <div>
            <div class='alert alert-light'>
                {/* {instructor_content.schedule.complete_help} */}
                Completed sessions are listed here.<br />
                Note: Mentors are required to manually mark their session attendance by selecting the appropriate response within 14 days. Failure to meet the 14-day deadline may result in session charges being applied to you.
            </div>
            {filteredData ? (filteredData.length > 0 ? (
                filteredData.length > 10 ? filteredData.slice(pageNum * 10 - 10, pageNum * 10).map(item => <CompletedItem mainLoading={mainLoading} setMainLoading={setMainLoading} data={item} {...props} />) : filteredData.map(item => <CompletedItem mainLoading={mainLoading} setMainLoading={setMainLoading} data={item} {...props} />)
            ) : <NoSchedule />) : <LoadingSchedule />}
            <Pagination pageNum={pageNum} setPageNum={setPageNum} totalPages={totalPages} />
        </div>
    )
}

export default Completed




const CompletedItem = ({ data, mainLoading, setMainLoading }) => {


    const { state, dispatch } = useContext(Store);
    const [showAddNotes, setShowAddNotes] = useState(false)
    const [loading, setLoading] = useState('')
    const [draft, setDraft] = useState('')
    const [notes, setNotes] = useState('')
    const [showAttendance, setShowAttendance] = useState(false);


    // const sendConfirmation = async (e, response, isCharged) => {
    //     e.preventDefault();
    //     try{
    //         setLoading(response)
    //         setMainLoading(true)
    //         const data1 = {
    //             draft: draft,
    //             response: response,
    //             isCharged: isCharged
    //         }
    //         const res = await axios.post(`/api/instructor/schedule/complete_class/${data._id}`, data1)
    //         const newSchedule = [...state.instructor.schedule];
    //         const index = newSchedule.findIndex(item => item._id === data._id);
    //         newSchedule[index].status = res.data.status
    //         newSchedule[index].lessonDetails = res.data.lessonDetails
    //         newSchedule[index].confirm_by_instructor = res.data.confirm_by_instructor
    //         newSchedule[index].confirm_by_student = res.data.confirm_by_student
    //         newSchedule[index].isFlagged = res.data.isFlagged
    //         newSchedule[index].isCharged = res.data.isCharged
    //         newSchedule[index].lateCharges = res.data.lateCharges
    //         saveSchedule(newSchedule, dispatch)
    //         setMainLoading(false)
    //         setLoading('')
    //         setDraft('')
    //     } catch(err){
    //         setMainLoading(false)
    //         setLoading('')
    //         console.log(err)
    //     }
    // }



    const saveNotes = async () => {
        try {

            const res = await axios.post(`/api/instructor/schedule/complete_class/notes/${data._id}`, { notes: notes })
            const newSchedule = [...state.instructor.schedule];
            const index = newSchedule.findIndex(item => item._id === data._id);
            newSchedule[index].notes = [...newSchedule[index].notes, notes]
            saveSchedule(newSchedule, dispatch)
            setShowAddNotes(false)
            setDraft('')

        } catch (err) {
            console.log(err)
        }
    }


    return (
        <>
            <div className='card mb-4'>
                <div className='card-header'>
                    <div class='d-flex align-items-center justify-content-between'>
                        <div>
                            <AiOutlineClockCircle class='mr-3' />
                            <strong class='mr-2'>{moment(data.date_time).format('Do MMM YYYY')},</strong>
                            {moment(data.date_time).format('hh:mm A')} - {moment(data.date_time).add(45, 'm').format('hh:mm A')}
                        </div>
                        <div>
                            {data.status === 'completed' && <span
                                className='btn btn-sm btn-outline-primary'
                                onClick={() => setShowAddNotes(true)}
                            >Add session notes</span>}
                        </div>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='row align-items-center'>
                        <div className='col-auto'>
                            <div className="avatar avatar-md"  >
                                <img src={data.student.avatar} alt="..." class="avatar-img rounded-circle" />
                            </div>
                        </div>
                        <div className='col'>
                            {/* {data.isFlagged && <div class = "badge badge-danger mb-3">!!! Flagged because of a different response from student</div>} */}
                            <div class="h4 mb-1">{data.student.firstName + ' ' + data.student.lastName}</div>
                            <div class="text-muted">{data.course.title}</div>
                            <small class="small text-muted">Session ID: {data.bookingId}</small><br />
                            <div class="badge badge-primary my-1">{makeFirstLetterCap(data.type)} session</div>

                            {data.flagDirection === 'student' ?
                                (data.confirm_by_student === 'student_joined' && <div class="badge badge-success my-1 ml-2">Completed</div>) :
                                (data.confirm_by_instructor === 'student_joined' && <div class="badge badge-success my-1 ml-2">Completed</div>)
                            }

                            {data.flagDirection === 'student' ?
                                (data.confirm_by_student === 'student_no_show' && <div class="badge badge-primary my-1 ml-2">Member did not join </div>) :
                                (data.confirm_by_instructor === 'student_no_show' && <div class="badge badge-primary my-1 ml-2">Member did not join </div>)
                            }

                            {data.flagDirection === 'student' ?
                                ((data.confirm_by_student === 'student_no_show' && data.lateCharges) && <div class="badge badge-danger my-1 ml-2">Session charged</div>) :
                                ((data.confirm_by_instructor === 'student_no_show' && data.lateCharges) && <div class="badge badge-danger my-1 ml-2">Session charged</div>)
                            }

                            {data.flagDirection === 'student' ?
                                ((data.confirm_by_student === 'student_no_show' && (data.lateCharges === false || data.lateCharges === undefined)) && <div class="badge badge-success my-1 ml-2">Session not charged</div>) :
                                ((data.confirm_by_instructor === 'student_no_show' && (data.lateCharges === false || data.lateCharges === undefined)) && <div class="badge badge-success my-1 ml-2">Session not charged</div>)
                            }

                            {data.flagDirection === 'student' ?
                                (data.confirm_by_student === 'instructor_no_show' && <div class="badge badge-danger my-1 ml-2"> Mentor did not join</div>) :
                                (data.confirm_by_instructor === 'instructor_no_show' && <div class="badge badge-danger my-1 ml-2"> Mentor did not join</div>)
                            }

                            {(data.status === 'completed' && data.lessonDetails) && <div class="p mb-0">Session Details: {data.lessonDetails}</div>}
                            {data.isFlagged && <p class='small my-2'>Note: We've noticed different responses from the member and mentor for this session. This has raised a concern and our team is working on fixing the issue.</p>}
                        </div>
                    </div>
                </div>
                {(data.status === 'completed' && data.notes && data.notes.length > 0) && <div class='card-footer py-3 bg-light'>
                    <div class="">
                        <div class='p bold'>Assigned Homework: </div>
                        <ul class=' mt-0 list-group pl-4'>{data.notes.map(item => <li class=''>{item}</li>)}</ul>
                    </div>
                </div>}
                {(data.status !== 'completed' && moment(data.date_time).diff(moment(), 'days') > -14) && <div class='card-footer'>
                    <input class="form-control form-control-flush  py-2" placeholder="Add notes of the session" value={draft} onChange={e => setDraft(e.target.value)} />
                    <div class='row align-items-center mt-3 border-top pt-3'>
                        <div class='col-12 col-lg-4 mb-2 mb-lg-0 pt-1'>
                            <h3 class='h5 mb-0'>Was this session completed by the member?</h3>
                        </div>

                        <button class='btn btn-sm btn-primary border' onClick={() => setShowAttendance(true)}>
                            Mark session attendance
                        </button>
                        {/* <div class = 'col d-flex w-100 pt-1'>
                        <div class = 'btn-group btn-group-sm w-100 '>
                            <button disabled = {mainLoading} class = 'btn btn-sm btn-success border' onClick = {(e) => sendConfirmation(e, 'student_joined', true)}>
                                {loading === 'student_joined' && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                                Attended
                            </button>
                            <button disabled = {mainLoading} class = 'btn btn-sm btn-danger border' onClick = {(e) => studentMissedClass(e)}>
                                {loading === 'student_no_show' && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                                Missed
                            </button>
                            <button disabled = {mainLoading} class = 'btn btn-sm btn-secondary border' onClick = {(e) => sendConfirmation(e, 'instructor_no_show', false)}>
                                {loading === 'instructor_no_show' && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                                I couldn't join this session
                            </button>
                        </div>
                    </div> */}
                    </div>
                </div>}
            </div>

            <AddNotesModel
                show={showAddNotes}
                setShow={setShowAddNotes}
                saveNotes={saveNotes}
                notes={notes}
                setNotes={setNotes}
            />
            <AttendanceModalInst setLoading={setLoading} data={data} state={state} dispatch={dispatch} show={showAttendance} setShow={setShowAttendance} loading={loading} mainLoading={mainLoading} setMainLoading={setMainLoading} />

        </>
    )
}





const LoadingSchedule = () => {
    return (
        <div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
        </div>
    )
}


const NoSchedule = () => {
    return (
        <div className='card mb-4'>
            <div className='card-body d-flex align-items-center justify-content-center'>
                <div className='h3 text-uppercase text-muted mb-0 py-5'>
                    {instructor_content.schedule.no_completed}
                </div>
            </div>
        </div>
    )
}








const AddNotesModel = ({ show, setShow, saveNotes, notes, setNotes }) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    const saveResponse = async () => {
        try {
            if (notes.length === 0) {
                setError(true);
                return;
            }
            setLoading(true)
            await saveNotes()
            setLoading(false)
        } catch (err) {
            setLoading(false)
        }
    }

    useEffect(() => {
        setError(false)
    }, [notes])

    return (
        <Modal show={show} onHide={() => setShow(false)} centered>
            <div className='modal-content' >
                <div className='modal-body '>
                    <div onClick={() => setShow(false)} class="close pointer">&times;</div>
                    <div class='p-lg-3'>
                        <div class="h2">
                            Add your Homework here
                        </div>
                        <form class=''>
                            <div class='form-group'>
                                <textarea
                                    value={notes}
                                    onChange={e => setNotes(e.target.value)}
                                    onInput={e => {
                                        if (e.target.value.length > 500) {
                                            e.target.value = e.target.value.slice(0, 500);
                                        }
                                    }}
                                    className={`form-control ${error ? 'is-invalid' : ''}`}
                                    style={{ minHeight: '14vh' }}
                                />
                                <div className='text-muted'>{notes.length}/500</div>
                                <div class='invalid-feedback'>Please enter your notes here</div>
                            </div>
                            <div class="btn btn-primary btn-block" onClick={() => saveResponse()}>
                                {loading && <span class='spinner-border spinner-border-sm mr-2'></span>}
                                Save Homework
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Modal>
    )
}


const makeFirstLetterCap = (text) => text ? text[0].toUpperCase() + text.slice(1) : ''