import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {validateEmail, validatePhone} from '../../../utilis/validation'
import content from '../../../content/landingPage.json'

const Form = ({setStep, user, setUser, login, isExistingUser, setIsExistingUser, loading}) => {


    const [errors, setErrors] = useState(Array(4).fill(false))

    const validate = () => {
        let new_errors = Array(4).fill(false)
        if(isExistingUser){
            if(!validateEmail(user.email)) new_errors[0] = true;
            if(user.password.length < 8) new_errors[1] = true;
            setErrors(new_errors)
            if(new_errors[0] || new_errors[1]) return false;
            login()
        } else{
            if(user.firstName < 3) new_errors[0] = true;
            if(!validateEmail(user.email)) new_errors[1] = true;
            if(!validatePhone(user.phone)) new_errors[2] = true;
            if(user.password.length < 8) new_errors[3] = true;
            setErrors(new_errors)
            if(new_errors.includes(true)) return false;
            else login()
        }
    }

    useEffect(() => {
        setErrors(Array(4).fill(false))
    }, [user])



    return (
        <div className = 'h-100 d-flex align-items-center justify-content-center' style = {{backgroundImage: `url('${content.bookFreeSession.user_details.img}')`, backgroundColor: 'rgba(0, 0, 0, 0.5)', backgroundSize: 'cover', minHeight: 'calc(100vh - 60px)'}}>
        <div class = 'bg-layer'>
        </div>
        <div className = 'card shadow-sm border' style = {{width: '500px'}}>
            <div className = 'card-body '>
                <div class = 'my-4 text-center px-lg-5'>
                    <div className = 'h4 mb-1'>{content.bookFreeSession.user_details.title}</div>
                    <div className = 'h8-unbold mb-1'>{content.bookFreeSession.user_details.description}</div>
                </div>
                {isExistingUser ? (
                <LoginForm 
                    errors = {errors} 
                    setErrors = {setErrors} 
                    user = {user} 
                    setUser = {setUser} 
                    setIsExistingUser = {setIsExistingUser} 
                    validate = {validate}
                    loading = {loading}
                />) : (
                <RegistrationForm 
                    errors = {errors} 
                    setErrors = {setErrors}  
                    user = {user} 
                    setUser = {setUser} 
                    setIsExistingUser = {setIsExistingUser} 
                    validate = {validate}
                    loading = {loading}
                />)}
            </div>
        </div>
        </div>
    )
}

export default Form



const RegistrationForm = ({user, setUser, errors, setErrors, loading, setIsExistingUser, validate}) => {
    return (
        <form class = 'px-lg-4 '>
            
            <div class = 'row mb-0'>
                <div className = 'col-12 col-lg-6'>
                    <div className = 'form-group'>
                        <label class = 'small'>First Name</label>
                        <input class = {`form-control ${errors[0] && 'is-invalid'} `} value = {user.firstName} onChange = {e => setUser(prev => ({...prev, firstName: e.target.value}))}/>
                        <div class = 'invalid-feedback'>Name should be min 3 characters</div>
                    </div>
                </div>
                <div className = 'col-12 col-lg-6'>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input
                            className='form-control'
                            value = {user.lastName}
                            onChange = {(e) => setUser(prev => ({...prev, lastName: e.target.value}))}
                        />
                    </div>
                </div>
            </div>
            <div className = 'form-group '>
                <label class = 'small'>Email</label>
                <input class = {`form-control ${errors[1] && 'is-invalid'} `} value = {user.email} onChange = {e => setUser(prev => ({...prev, email: e.target.value}))}/>
                <div class = 'invalid-feedback'>Please enter a valid email address</div>
            </div>
            <div className = 'form-group '>
                <label class = 'small'>Phone Number</label>
                <input class = {`form-control ${errors[2] && 'is-invalid'} `} value = {user.phone} onChange = {e => setUser(prev => ({...prev, phone: e.target.value}))}/>
                <div class = 'invalid-feedback'>Please enter a valid phone number</div>
            </div>
            <div className = 'form-group '>
                <label class = 'small'>Password</label>
                <input type = 'password' class = {`form-control ${errors[3] && 'is-invalid'} `} value = {user.password} onChange = {e => setUser(prev => ({...prev, password: e.target.value}))}/>
                <div class = 'invalid-feedback'>Password should be min 8 characters</div>
            </div>
            <div>
                <div className = 'btn btn-block btn-primary btn-pill mt-4' onClick = {() => validate()}>
                    {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                    Book a free session
                </div>
                <div class = 'small text-center mt-4 px-lg-5'>
                By clicking "Book a free session", you agree to the Dhunguru <Link to = "/privacy">Terms of Use and Privacy Policy</Link>
                </div>
                <div class = 'small text-center mt-4 px-lg-6'>
                    Are you a returning customer? <span className = 'btn-link pointer' onClick = {() => setIsExistingUser(true)}>Log in</span>
                </div>
            </div>
        </form>
                    
    )
}


const LoginForm = ({user, setUser, errors, setErrors, loading, setIsExistingUser, validate}) => {
    return (
        <form class = 'px-lg-4 '>
            <div className = 'form-group mb-2'>
                <label class = 'small'>Email</label>
                <input class = {`form-control ${errors[0] && 'is-invalid'}`} value = {user.email} onChange = {e => setUser(prev => ({...prev, email: e.target.value}))}/>
                <div class = 'invalid-feedback'>Please enter a correct email address</div>
            </div>
            <div className = 'form-group mb-2'>
                <label class = 'small'>Password</label>
                <input class = {`form-control ${errors[1] && 'is-invalid'}`} type = 'password' value = {user.password} onChange = {e => setUser(prev => ({...prev, password: e.target.value}))}/>
                <div class = 'invalid-feedback'>Password should be min 8 characters</div>
            </div>
            <div>
                <div className = 'btn btn-block btn-primary btn-pill mt-4' onClick = {() => validate()}>
                    {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                    Book a free session
                </div>
                <div class = 'small text-center mt-4 px-lg-5'>
                    By clicking "Book a free session", you agree to the Dhunguru <Link to = "/privacy">Terms of Use and Privacy Policy</Link>
                </div>
                <div class = 'small text-center mt-4 px-lg-6'>
                    Don't have an account with us? <span className = 'btn-link pointer' onClick = {() => setIsExistingUser(false)}>Sign up</span>
                </div>
            </div>
        </form>
    )
} 