import React from 'react'
import axios from '../../../../../utilis/axios';
import cogoToast from 'cogo-toast'
import instructor_content from '../../../../../content/instructor_content.json'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiFillDelete} from 'react-icons/ai'

const Resources = ({course, setCourse, courseId}) => {


    const deleteResource = async (resource_id) => {
        try{

            const new_course = {...course};
            const index = new_course.resources.findIndex(item => item._id === resource_id);
            new_course.resources.splice(index, 1);
            setCourse(new_course);
            await axios.delete(`/api/instructor/course/resource/delete/${courseId}/${resource_id}`)
            cogoToast.success(instructor_content.course.resources.successDelete)

        } catch(err){
            console.log(err)
        }
    }


    return (
        <div class = "">
            {course.resources.length > 0 && <ul class="list-group list-group-active shadow-sm bg-white rounded mt-0">
                {course.resources.map(item => <ResourceItem data = {item} deleteResource = {deleteResource}/>)}
            </ul>}
            {course.resources.length === 0 && <NoResource />}
        </div>
    )
}

export default Resources





const ResourceItem = ({data, deleteResource}) => {

    return (
        <div className="list-group-item py-4">
            <div className="row align-items-center">
                <div className="col-12 col-md ml-n2 mb-4 mb-md-0 d-flex align-items-center">
                    <div class = 'ml-3'>
                    <h4 class="text-body text-focus mb-2 name">{data.title}</h4>
                    <p class=" text-muted mb-2">{data.description}</p>
                    <a href = {data.url} target = "_blank" rel="noreferrer" class ="small">Click here</a>
                    </div>
                </div>

                <div className = 'col-auto col-md-auto text-center'>
                    <OverlayTrigger overlay = {<Tooltip>Delete</Tooltip>}>
                        <div class = "h3 mb-0 pointer" onClick = {() => deleteResource(data._id)}>
                            <AiFillDelete />
                        </div>
                    </OverlayTrigger>
                </div>
              
            </div>
        </div>
    )
}



const NoResource = () => {
    return (
        <div className = 'card mb-4'>
        <div className = 'card-body d-flex align-items-center justify-content-center'>
            <div className = 'h3 text-uppercase text-muted mb-0 py-5'>
                {instructor_content.course.resources.no_resource}
            </div>
        </div>
    </div>
    )
}