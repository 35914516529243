import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import { BeatSpinner2 } from '../../../../components/Spinners/BeatSpinner'
import moment from 'moment'

const Instructors = (props) => {

    const [data, setData] = useState();
    const [instructors, setInstructors] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [selectedUser, setSelectedUser] = useState()
    const [selectedUser1, setSelectedUser1] = useState()
    const [tab, setTab] = useState('active')

    useEffect(() => {
        fetchInstructors()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(data) formatData(data.instructor, data.courses)
    }, [data])

    const fetchInstructors = async () =>{
        try{

            const res = await axios.get('/api/admin/instructors');
            setData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data, courses) => {
        setInstructors(data.map(item => {
            const teacher_courses = courses.filter(item1 => {
                return item1.instructor_profile._id === item._id
            })
            // console.log(teacher_courses)
            return {
                ...item,
                name: {value: item.user.firstName + ' ' + item.user.lastName, label: <NameWithAvatar name = {item.user.firstName + ' ' + (item.user.lastName || '')} avatar = {item.user.avatar} />},
                email: item.user.email,
                phone: item.user.phone,
                signupDate: {value: moment(item.createdAt).unix(), label: moment(item.createdAt).format('Do MMM YY, hh:mm A')},
                approved: item.approve ? 'Approved' : 'Not Approved',
                teachesChildren: item.teachesChildren ? 'Yes': 'No',
                isRecordingAllowed: item.isRecordingAllowed ? 'Yes' : 'No',
                isEdited: {value: item.isEdited ? 'b' : 'a', label: item.isEdited ? <div class = "btn btn-primary btn-sm" onClick = {() => setSelectedUser1(item._id)}>Mark</div> : "Not edited"},
                rating: parseFloat((teacher_courses.reduce((a, b) => a + b.reviews.reduce((a1, b1) => a1 + b1.rating, 0), 0))/(teacher_courses.reduce((a, b) => a + b.reviews.length, 0))).toFixed(2),
                courses: teacher_courses.map(item => item.instrument),
                onboarded: item.onboarding.complete === undefined ? 'Pending' : 'Completed',
                hideUser: {value: '', label: <div class = "btn btn-primary btn-sm" onClick = {() => setSelectedUser(item._id)}>{item.hideAdminDashboard ? "Unhide" : "Hide"} </div>},
                instrument_teach: item.user.instrumentToTeach
            }
        }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
    }


    useEffect(() => {
        if(selectedUser && selectedUser.length > 5) blockAccount(selectedUser)
         // eslint-disable-next-line 
    }, [selectedUser])
   
    useEffect(() => {
        if(selectedUser1 && selectedUser1.length > 5) markAccount(selectedUser1)
         // eslint-disable-next-line 
    }, [selectedUser1])


    const blockAccount = async (id) => {
        try{

            setSelectedUser()
            const newData = [...data.instructor];
            const index1= newData.findIndex(item => item._id === id);
            let value = newData[index1].hideAdminDashboard;
            newData[index1].hideAdminDashboard = !value
            setData(prev => ({instructor: newData, ...prev}))

            if(!value){
                await axios.get(`/api/admin/instructor/hide/${id}`);

            } else{
                await axios.get(`/api/admin/instructor/unhide/${id}`);
            }

        } catch(err){
            console.log(err)
        }
    }
    
    
    const markAccount = async (id) => {
        try{

            setSelectedUser1()
            const newData = [...data.instructor];
            const index1= newData.findIndex(item => item._id === id);
            // let value = newData[index1].hideAdminDashboard;
            newData[index1].isEdited = false;
            setData(prev => ({instructor: newData, ...prev}))
            await axios.get(`/api/admin/instructor/mark/${id}`);

        } catch(err){
            console.log(err)
        }
    }

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
            <div class = 'header mt-n4'>
                <div class = 'header-body'>
                    <div className = 'row align-items-end'>
                        <div className = 'col'>
                            <h3 class = 'h1 mb-0'>Instructors</h3>
                        </div>
                        <div class = 'col-auto'>
                            <AccountNav tab = {tab} setTab = {setTab}/>
                        </div>
                    </div>
                </div>
            </div>
          
            {!instructors && <BeatSpinner2 />}
            {(instructors && tab === 'all') && <Table 
                data = {instructors}
                isSelectable = {false}
                headers = {headers.filter(item => item.value !== 'hideUser')}
                isSearchable = {true}
                handleRowClick = {(id) => window.open(`/admin/instructor/${id}`, "_blank")}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
            />}
            {(instructors && tab === 'active') && <Table 
                data = {instructors.filter(item => !item.hideAdminDashboard)}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => window.open(`/admin/instructor/${id}`, '_blank')}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
            />}
            {(instructors && tab === 'archieved') && <Table 
                data = {instructors.filter(item => item.hideAdminDashboard)}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => window.open(`/admin/instructor/${id}`, '_blank')}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
            />}
        </AdminDashboard>
    )
}

export default Instructors





const AccountNav = ({tab, setTab}) => {
    return(
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'all' && 'active'}`} onClick = {() => setTab('all')}>All Instructors</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'active' && 'active'}`} onClick = {() => setTab('active')}>Active</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'archieved' && 'active'}`} onClick = {() => setTab('archieved')}>Archived</div>
                    </li>
                </ul>
        </div>
    </div>
    )
}






const headers = [
    {value: 'name', active: true, label: 'Name'},
    {value: 'email', active: true, label: 'Email'},
    {value: 'phone', active: true, label: 'Phone'},
    {value: 'signupDate', active: true, label: 'Sign up Date'},
    {value: 'rating', active: true, label: 'Rating'},
    {value: 'approved', active: true, label: 'Is Approved?'},
    {value: 'onboarded', active: true, label: 'Is Onboarded?'},
    {value: 'instrument_teach', active: true, label: 'Instrument'},
    {value: 'hideUser', active: true, label: 'Hide'},
    {value: 'isEdited', active: true, label: 'Edited?'},
]


const NameWithAvatar = ({name, avatar}) => {
    return (
        <>
       
        <span class="item-name text-reset">{name}</span>
        </>
    )
}