import React, {useState} from 'react'
import LgFooter from '../Footer'
import Nav from '../Nav'
import AnimateHeight from 'react-animate-height'
import {Link} from 'react-router-dom'
import {RiArrowDropDownLine } from 'react-icons/ri'
import content from '../../../content/landingPage.json'
import faqs from '../../../content/faqs.json'

const TeachWithUs = () => {
    return (
        <div>
            <Nav/>
            <Hero />
            <HowItWorks />
            <ValueProps />
            <MakeImpact />
            {/* <Testimonials /> */}
            <FAQs />
            <LgFooter/>
        </div>
    )
}

export default TeachWithUs


const Hero = () => {
    return (
        <section class="py-6 " style={{paddingTop: '40vh', paddingBottom: '40vh', backgroundImage: `url(${content.teachWithUs.hero.img})`, backgroundSize: 'cover', boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0,.2)'}}>
            <div className="container my-6 text-center text-white px-lg-6">
                <h3 class="h3 mb-2 text-uppercase pt-5">{content.teachWithUs.hero.title}</h3>
                <h1 class="display-3 ">{content.teachWithUs.hero.description}</h1>
                <Link to ='/signup/instructor' class = 'btn btn-lg btn-primary text-uppercase h3 mb-5'>Get Started</Link>
            </div>
        </section>
    )
}

const HowItWorks = () => {
    return (
        <section class="container pt-lg-6 py-sm-5 py-4 border-bottom">
        <h6 class="mb-4 h1 text-center">{content.teachWithUs.howItWorks.title}</h6>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-12 mb-md-0 mb-sm-grid-gutter mb-3 px-4">
              <div className="text-center mx-auto mb-sm-0 mb-grid-gutter" style={{maxWidth: '260px'}}>
                <img class="mb-4 py-3 my-5" src={content.teachWithUs.howItWorks.steps[0].img} height="128" alt="" />
                <h3 class="h3 mb-2">{content.teachWithUs.howItWorks.steps[0].title}</h3>
                <p class="mb-0 text-secondary">{content.teachWithUs.howItWorks.steps[0].description}</p>
              </div>
            </div>
            <span class="divider-vertical d-md-block d-none"></span>
            <div className="col-md-4 col-sm-12 mb-md-0 mb-sm-grid-gutter mb-3 px-4">
              <div className="text-center mx-auto" style={{maxWidth: '260px'}}>
                <img class="mb-4  my-5" src={content.teachWithUs.howItWorks.steps[1].img} height="128" alt="" />
                <h3 class="h3 mb-2">{content.teachWithUs.howItWorks.steps[1].title}</h3>
                <p class="mb-0 text-secondary">{content.teachWithUs.howItWorks.steps[1].description}</p>
              </div>
            </div>
            <span class="divider-vertical d-sm-block d-none"></span>
            <div className="col-md-4 col-sm-12 mb-md-0 mb-sm-grid-gutter mb-3 px-4">
              <div className="text-center mx-auto" style={{maxWidth: '260px'}}>
                <img class="mb-4  my-5" src={content.teachWithUs.howItWorks.steps[2].img} height="128" alt="" />
                <h3 class="h3 mb-2">{content.teachWithUs.howItWorks.steps[2].title}</h3>
                <p class="mb-0 text-secondary">{content.teachWithUs.howItWorks.steps[2].description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

const ValueProps = () => {
    return (
        <div className = 'container px-lg-6 mt-6 mb-5'>
            <div className = 'h1 mb-5 text-center'>{content.teachWithUs.valueProps.title}</div>
            <div className = 'row mx-lg-3'>
                {(content.teachWithUs.valueProps.steps.map(item => <div className = 'col-12 col-lg-6  mb-5 pb-3'>
                    <div class = 'row no-gutters px-4'>
                        <div class = 'col-3 mr-4 pb-4'>
                            <img alt="" src = {item.img} className = 'img-fluid' style = {{height: '100px'}}/>
                        </div>
                        <div class = 'col'>
                            <div class = 'h3 mb-2'>{item.title}</div>
                            <div class = ' mt-2'>{item.description}</div>
                        </div>
                    </div>
                </div>))}
               
            </div>
        </div>
    )
}

const MakeImpact = () => {
    return (
        <div className="PartnerPublic_Location pt-6">
            <div className="Container px-lg-6 text-center">
                <h1 >{content.teachWithUs.makeImpact.title}</h1>
                <h4>{content.teachWithUs.makeImpact.description}</h4>
                <div className="PartnerPublic_Location_Local">
                    <div className="PartnerPublic_Location_Image">
                        <img src={content.teachWithUs.makeImpact.steps[0].img}  style = {{ objectPosition: 'center', height: '200px'}} alt="Tch-lp-inperson" />        
                    </div>
                    <div className="PartnerPublic_Location_Content">
                        <div class = 'h2'>{content.teachWithUs.makeImpact.steps[0].title}</div>
                        <p>{content.teachWithUs.makeImpact.steps[0].description}</p>
                    </div>
                </div>
                <div class = "d-none d-lg-block">
                <div className="PartnerPublic_Location_Online">
                    <div className="PartnerPublic_Location_Content">
                    <div class = 'h2'>{content.teachWithUs.makeImpact.steps[1].title}</div>
                        <p>{content.teachWithUs.makeImpact.steps[1].description}</p>
                    </div>
                    <div className="PartnerPublic_Location_Image">
                        <img src={content.teachWithUs.makeImpact.steps[1].img}  style = {{ objectPosition: 'center', height: '200px'}} alt="Tch-lp-inperson" />        
                    </div>
                </div>
                </div>
                <div class = "d-block d-lg-none">
                <div className="PartnerPublic_Location_Local">
                    <div className="PartnerPublic_Location_Image">
                        <img src={content.teachWithUs.makeImpact.steps[1].img}  style = {{ objectPosition: 'center', height: '200px'}} alt="Tch-lp-inperson" />        
                    </div>
                    <div className="PartnerPublic_Location_Content">
                        <div class = 'h2'>{content.teachWithUs.makeImpact.steps[1].title}</div>
                        <p>{content.teachWithUs.makeImpact.steps[1].description}</p>
                    </div>
                </div>
                </div>
                <div className="PartnerPublic_Location_Local">
                    <div className="PartnerPublic_Location_Image">
                        <img src={content.teachWithUs.makeImpact.steps[2].img} style = {{ objectPosition: 'center', height: '200px'}} alt="Tch-lp-inperson" />        
                    </div>
                    <div className="PartnerPublic_Location_Content">
                        <div class = 'h2'>{content.teachWithUs.makeImpact.steps[2].title}</div>
                        <p>{content.teachWithUs.makeImpact.steps[2].description}</p>
                    </div>
                </div>
                
            </div>
        </div>
    )
}



const FAQs = () => {
    const [indexx, setIndexx] = useState("")
  
    return (
        <div  className = "mt-6 container px-lg-6 mb-6" id = 'faqs'>
            <div className = 'py-3 h1 font-weight-bold' >FAQs</div>
            {faqs[1].faqs.filter(item => item.active).map((item, index) => (<FAQItem type = {'faq'} indexx= {indexx} setIndexx = {setIndexx} key1  = {index} data = {item}/>))}
            
        </div>
    )
}


const FAQItem = ({indexx, setIndexx, key1, type, data}) => {

    return (
        <div className = 'border-bottom mb-4 pb-2' >
        
            <div className = 'row align-items-center pointer' onClick = {() => setIndexx(prev => prev === key1 ? "" : key1)}>
                <div className = 'col'>
                    <div className = "p mb-0 font-weight-bold">{data.question}</div>
                </div>
                <div className = 'col-auto d-flex align-items-center justify-content-center'>
                    <div className = "h5 mb-1 "><RiArrowDropDownLine /></div>
                </div>
            </div>
            <AnimateHeight height = {indexx===key1 ? 'auto' : 0} duration = {150}>
                <div>
                    {data.answer.map(item => <div className = 'text-secondary mt-1 mb-3' style = {{fontSize: '16px'}}>
                        {typeof item === 'object' ? 
                        
                            (item.length ===1 ? <strong>{item}</strong> : <ul>{item.map(item2 => <li>{item2}</li>)}</ul>) : 
                        
                        item}
                    </div> )}
                </div>
            </AnimateHeight>
        </div>
    )
}









/*
  const Testimonials = () => {
    return (
        <div className = 'container px-lg-6'>
            <div class = 'h1 mb-3'>What Our teachers have to say</div>
            <div className = 'PartnerPublic_Testimonials_Holder'>
                <div className="PartnerPublic_Testimonials_Entry">
                    <div className="PartnerPublic_Testimonials_Entry_Image" style={{backgroundImage: "url('https://prod-takelessons.netdna-ssl.com/images/public/teachers/tch-lp-testimonial-reina.png')"}}></div>
                    <div className="PartnerPublic_Testimonials_Entry_Text">
                        "Just had my highest earning week on TL since I joined the community April 2015!! TakeLessons is life!!
                        Love you guys so much!"
                    </div>
                    <div className="PartnerPublic_Testimonials_Entry_Info">
                        <div className="PartnerPublic_Testimonials_Entry_Name">Reina M.</div>
                        <div className="PartnerPublic_Testimonials_Entry_Earnings">Earned $20k</div>
                    </div>
                </div>
                <div className="PartnerPublic_Testimonials_Entry">
                    <div className="PartnerPublic_Testimonials_Entry_Image" style={{backgroundImage: "url('https://prod-takelessons.netdna-ssl.com/images/public/teachers/tch-lp-testimonial-reina.png')"}}></div>
                    <div className="PartnerPublic_Testimonials_Entry_Text">
                        "Just had my highest earning week on TL since I joined the community April 2015!! TakeLessons is life!!
                        Love you guys so much!"
                    </div>
                    <div className="PartnerPublic_Testimonials_Entry_Info">
                        <div className="PartnerPublic_Testimonials_Entry_Name">Reina M.</div>
                        <div className="PartnerPublic_Testimonials_Entry_Earnings">Earned $20k</div>
                    </div>
                </div>
                <div className="PartnerPublic_Testimonials_Entry">
                    <div className="PartnerPublic_Testimonials_Entry_Image" style={{backgroundImage: "url('https://prod-takelessons.netdna-ssl.com/images/public/teachers/tch-lp-testimonial-reina.png')"}}></div>
                    <div className="PartnerPublic_Testimonials_Entry_Text">
                        "Just had my highest earning week on TL since I joined the community April 2015!! TakeLessons is life!!
                        Love you guys so much!"
                    </div>
                    <div className="PartnerPublic_Testimonials_Entry_Info">
                        <div className="PartnerPublic_Testimonials_Entry_Name">Reina M.</div>
                        <div className="PartnerPublic_Testimonials_Entry_Earnings">Earned $20k</div>
                    </div>
                </div>
                <div className="PartnerPublic_Testimonials_Entry">
                    <div className="PartnerPublic_Testimonials_Entry_Image" style={{backgroundImage: "url('https://prod-takelessons.netdna-ssl.com/images/public/teachers/tch-lp-testimonial-reina.png')"}}></div>
                    <div className="PartnerPublic_Testimonials_Entry_Text">
                        "Just had my highest earning week on TL since I joined the community April 2015!! TakeLessons is life!!
                        Love you guys so much!"
                    </div>
                    <div className="PartnerPublic_Testimonials_Entry_Info">
                        <div className="PartnerPublic_Testimonials_Entry_Name">Reina M.</div>
                        <div className="PartnerPublic_Testimonials_Entry_Earnings">Earned $20k</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
*/