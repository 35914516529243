import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import { validateEmail } from '../../../../utilis/validation'



const AddAnnouncement = ({show, setShow, zoomAccounts, setZoomAccounts}) => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({email: '', type: 'licenced',});
    const [errors, setErrors] = useState(Array(2).fill(false));

    
    useEffect(() => {
        setErrors(Array(2).fill(false))
    }, [data])

    useEffect(() => {
        setData({email: '', type: 'licenced',})
    }, [show])



    const validate = () => {
        let new_errors = Array(2).fill(false)
        if(!validateEmail(data.email)) new_errors[0] = true
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true;
      }


    const addAccount = async (e) => {
        e.preventDefault()
        if(!validate()) return;
        try{
            setLoading(true);

            const res = await axios.post('/api/admin/zoomAccounts', data)
            // console.log(res.data)
            
            const newAccounts = [...zoomAccounts];
            newAccounts.push({...res.data, counter: "0"});
            setZoomAccounts(newAccounts)
            
            setLoading(false);
            setShow(false)
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

 
    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Add a zoom account</div>
                </div>

                <form class = "mt-3 ">

                    <div className="form-group">
                        <label>Email</label>
                        <input
                            className={`form-control ${errors[0] && 'is-invalid'} `}
                            value = {data.email}
                            onChange = {(e) => setData(prev => ({...prev, email: e.target.value}))}
                        />
                        <div class = 'invalid-feedback'>Please add a email</div>
                    </div>
                    
                    <div className="form-group">
                        <label>Type</label>
                        <select
                            className={`form-control ${errors[1] && 'is-invalid'} `}
                            value = {data.type}
                            onChange = {(e) => setData(prev => ({...prev, type: e.target.value}))}
                        >
                            <option value = "basic">Basic</option>
                            <option value = "licenced">Licenced</option>
                        </select>
                        <div class = 'invalid-feedback'>Please add a type</div>
                    </div>
                  
                 
                    
                    <button class = "btn btn-primary btn-block mt-6" onClick = {(e) => addAccount(e)} disabled = {loading}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Add Zoom Account
                    </button>
                    
                </form>
                
            </div>
        </RightModal>
    )
}

export default AddAnnouncement;





