import cogoToast from "cogo-toast";
import React, { useState } from "react";
import axios from "../../../utilis/axios";
import {validateEmail} from '../../../utilis/validation'

const ConfirmAccount = (props) => {

  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false);

  const forgotPassword = async (e) => {
    e.preventDefault();
    if(!validateEmail(email)){
      setError(true);
      return;
    }
    try {
      setLoading(true);
      await axios.post(`/api/auth/forgot-password`, {email: email.trim().toLowerCase()});
      cogoToast.success("The Password reset link with detailed instructions has been sent to your specified email address.")
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div
      className="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
      style={{ minHeight: "100vh" }}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-10 col-md-6 col-xl-6 my-5 px-lg-6">
            <h1 className="display-4 text-center mb-3">Password reset request</h1>
            <p className="text-muted text-center mb-5">
              Enter your email address below and we will mail you the password reset instructions
            </p>
            <form>

            <div className="form-group">
                <label>Email Address</label>
                <input
                    className={error ? 'form-control is-invalid' : 'form-control'}
                    placeholder='Email address'
                    value = {email}
                    onBlur = {() => {
                      if(!validateEmail(email)) setError(true)
                    }}
                    onChange = {(e) => {setError(false); setEmail(e.target.value)}}
                />
                <div className = 'invalid-feedback'>Invalid Email Address</div>
          </div>

     
              <button className="btn btn-lg btn-block btn-primary mb-3" onClick={(e) => forgotPassword(e)}>
                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}Request
              </button>

            </form>
          </div>
        
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-none d-lg-block align-self-center">
        <div class="text-center">
            <img src="https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/login.svg" alt="..." class="img-fluid" style = {{height: '400px'}}/>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmAccount;
