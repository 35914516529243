export const FETCH_CHAT = 'FETCH_USER'
export const FETCH_SCHEDULE = 'FETCH_SCHEDULE'
export const FETCH_REQUESTS = 'FETCH_REQUESTS'
export const FETCH_ENROLLMENT = 'FETCH_ENROLLMENT'
export const FETCH_COURSES = 'FETCH_COURSES'
export const ADD_COURSES = 'ADD_COURSES'
export const EDIT_COURSES = 'EDIT_COURSES'
export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const FETCH_INSTRUCTORS = 'FETCH_INSTRUCTORS'
export const FETCH_CLASSES = 'FETCH_CLASSES'



export const saveSchedule = async (schedule, dispatch) =>{
     return dispatch({
        type: FETCH_SCHEDULE,
        payload: {
            schedule: schedule
        }
    })
}


export const saveRequests = async (requests, dispatch) =>{
    return dispatch({
       type: FETCH_REQUESTS,
       payload: {
           requests: requests
       }
   })
}






export const saveInstructors = async (instructors, dispatch) =>{
    return dispatch({
       type: FETCH_INSTRUCTORS,
       payload: {
           instructors: instructors,
       }
   })
}



export const saveMessages = async (messages, dispatch) =>{
    return dispatch({
       type: FETCH_MESSAGES,
       payload: {
           messages: messages,
       }
   })
}



export const saveEnrollment = async (enrollments, dispatch) =>{
    return dispatch({
       type: FETCH_ENROLLMENT,
       payload: {
           enrollments: enrollments,
       }
   })
}

export const saveClasses = async (classes, dispatch) =>{
    return dispatch({
       type: FETCH_CLASSES,
       payload: {
           classes: classes,
       }
   })
}

