import { percentageIncrease } from "./pricingConfig";

export const onlyUnique = (value, index, self) => { 
    return self.indexOf(value) === index;
}


export const increasePriceByPercentage = (value)=>{
  const percentage = percentageIncrease; 
  const changeIncrease = value*(percentage/100);
  return (value + changeIncrease);
}

export const isObjectEquivalent = (a, b) => {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export const compareValues = (key, order = 'asc') => {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
}



export const getDistinct = (array, key) => {
  const result = [];
  const map = new Map();
  for (const item of array) {
      if(!map.has(item[key])){
          map.set(item[key], true);    // set any id to Map
          result.push(item);
      }
  }
  return result
}


export const combineReducers = (slices) => (state, action) =>
  Object.keys(slices).reduce( 
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
);