import React, {useState, useEffect, useContext} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import { BeatSpinner2 } from '../../../../components/Spinners/BeatSpinner'
import moment from 'moment'
import Swal from 'sweetalert2'
import { Store } from '../../../../store'
import EditUser from './EditUser'

const Users = (props) => {

    const [data, setData] = useState();
    const {state} = useContext(Store)
    const [accounts, setAccounts] = useState();
    const [searchInput, setSearchInput] = useState('');
    const [selectedUser, setSelectedUser] = useState()
    const [deletedUserId, setDeletedUserId] = useState()
    const [show, setShow] = useState(false);
    const [selectedUser1, setSelectedUser1] = useState('')


    useEffect(() => {
        fetchUsers()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(data) formatData(data)
        // eslint-disable-next-line
    }, [data])

    const fetchUsers = async () =>{
        try{

            const res = await axios.get('/api/admin/allAccounts');
            setData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        setAccounts(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => ({
            ...item,
            createdAt: moment(item.createdAt).format('Do MMM YY, hh:mm A'),
            lastLoginDate: moment(item.lastLoginDate).format('Do MMM YY, hh:mm A'),
            isEmailConfirm: item.isEmailConfirm ? 'Yes':'No',
            actions11: {value: '', label: <div class = "d-flex">
                {state.user.data.isSuperAdmin && <span class = "btn btn-primary btn-sm mr-2" onClick = {() => setDeletedUserId(item._id)}>Delete</span>}
                <span class = "btn btn-primary btn-sm" onClick = {() => setSelectedUser(item._id)}>{item.isBlocked ? "Unblock" : "Block"} </span>
            </div>}
        })))  
    }

    useEffect(() => {
        if(selectedUser && selectedUser.length > 5) blockAccount(selectedUser)
         // eslint-disable-next-line 
    }, [selectedUser])
    
    useEffect(() => {
        if(deletedUserId && deletedUserId.length > 5) deleteAccount(deletedUserId)
         // eslint-disable-next-line 
    }, [deletedUserId])


    const blockAccount = async (id) => {
        try{
            const newAccounts = [...accounts];
            const index = newAccounts.findIndex(item => item._id === id);
            const value = !newAccounts[index].isBlocked
            newAccounts[index].isBlocked = !newAccounts[index].isBlocked
            setAccounts(newAccounts);
            
            const newData = [...data];
            const index1= newData.findIndex(item => item._id === id);
            newData[index1].isBlocked = !newData[index1].isBlocked
            setData(newData)

            if(value){
                await axios.post(`/api/admin/block_account/${id}`);

            } else{
                await axios.post(`/api/admin/unblock_account/${id}`);
            }

        } catch(err){
            console.log(err)
        }
    }


    const deleteAccount = async (id) => {
        setDeletedUserId()
        const response = await Swal.fire({
            title: 'Are you sure you want to delete the user',
            text: 'This action cannot be undone. Once deleted, all the enrollments and classes will be deleted as well.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if(response.isConfirmed){
            try{
                await axios.delete(`/api/admin/delete_account/${id}`);


                const newAccounts = [...accounts];
                const index = newAccounts.findIndex(item => item._id === id);
                newAccounts.splice(index, 1)
                setAccounts(newAccounts);
                
                
                const newData = [...data];
                const index1= newData.findIndex(item => item._id === id);
                newData.splice(index1, 1)
                setData(newData)


            } catch(err){
                console.log(err)
            }
        }
    }

    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
            <div className = 'row align-items-center mt-5 border-bottom pb-3 mb-4'>
                <div className = 'col'>
                    <h3 class = 'h1'>All Accounts</h3>
                </div>
            </div>
            {accounts ? <Table 
                data = {accounts}
                isSelectable = {false}
                headers = {headers}
                isSearchable = {true}
                handleRowClick = {(id) => {
                    setSelectedUser1(id)
                    setShow(true)
                }}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {['role', 'createdAt']}
                isDownloadable = {true}
            /> : <BeatSpinner2 />}
            
            {(accounts && selectedUser1) && <EditUser 
                show = {show}
                setShow = {setShow}
                user = {accounts.filter(item => item._id === selectedUser1)[0]}
            />}

        </AdminDashboard>
    )
}

export default Users




const headers = [
    {value: 'firstName', active: true, label: 'Name'},
    {value: 'email', active: true, label: 'Email'},
    {value: 'phone', active: true, label: 'Phone'},
    {value: 'role', active: true, label: 'Role'},
    {value: 'createdAt', active: true, label: 'Creation Date'},
    {value: 'lastLoginDate', active: true, label: 'Last Login'},
    {value: 'isEmailConfirm', active: true, label: 'Email confirm'},
    {value: 'actions11', active: true, label: 'Actions'},
]


