import {
  FETCH_SCHEDULE,
  FETCH_REQUESTS,
  FETCH_ENROLLMENT,
  FETCH_COURSES,
  FETCH_MESSAGES,
  FETCH_INSTRUCTORS,
  FETCH_CLASSES
} from '../actions/student'


const userReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INSTRUCTORS:
      return {
        ...state,
        chat_instructors: action.payload.instructors,
      }

    case FETCH_MESSAGES:
      return {
        ...state,
        chat_messages: action.payload.messages,
      }

    case FETCH_SCHEDULE:
      return {
        ...state,
        schedule: action.payload.schedule
      }

    case FETCH_REQUESTS:
      return {
        ...state,
        requests: action.payload.requests
      }

    case FETCH_ENROLLMENT:
      return {
        ...state,
        enrollments: action.payload.enrollments
      }

    case FETCH_COURSES:
      return {
        ...state,
        courses: action.payload.courses
      }
    case FETCH_CLASSES:
      return {
        ...state,
        classes: action.payload.classes
      }

    default:
      return state;
  }
}

export default userReducer;

