import React, {useState, useEffect} from 'react'
import TableDragSelect from 'react-table-drag-select'



const Availability = ({data}) => {

    const [cells, setCells] = useState(Array(25).fill().map(_ =>  Array(8).fill(false)));
    const profile = data

    useEffect(() => {
        if(profile.availability && profile.availability.m){
            const arr = [
                Array(25).fill(false),
                [false, ...profile.availability.m],
                [false, ...profile.availability.tu],
                [false, ...profile.availability.w],
                [false, ...profile.availability.th],
                [false, ...profile.availability.f],
                [false, ...profile.availability.sa],
                [false, ...profile.availability.su],
            ]
            const tranposedCells = arr[0].map((_, colIndex) => arr.map(row => row[colIndex]));
            setCells(tranposedCells)
        }
        //eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className = 'card '>
                <div className = 'table-body mb-0'>
                    <TableDragSelect class = "table table-sm table-nowrap" value={cells} >
                        <tr class = 'thead'>
                            <td disabled />
                            <td disabled>
                                <div class = 'd-block d-lg-none'>M</div>
                                <div class = 'd-none d-lg-block'>Monday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>Tu</div>
                                <div class = 'd-none d-lg-block'>Tuesday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>W</div>
                                <div class = 'd-none d-lg-block'>Wednesday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>Th</div>
                                <div class = 'd-none d-lg-block'>Thursday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>F</div>
                                <div class = 'd-none d-lg-block'>Friday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>Sa</div>
                                <div class = 'd-none d-lg-block'>Saturday</div>
                                
                            </td>
                            <td disabled>
                                <div class = 'd-block d-lg-none'>SU</div>
                                <div class = 'd-none d-lg-block'>Sunday</div>
                                
                            </td>
                        </tr>
                        {cells.slice(1).map((item, index) => <tr class = "py-1">
                            <td class ="tr-pad" disabled>{formaTime(index)}</td>
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                            <td class ="tr-pad" />
                        </tr>)}
                    </TableDragSelect>
                </div>
            </div>
        </div>
    )
}

export default Availability




const formaTime = (index) => {
    if(index === 12) return index+' PM'
    return index > 12 ? (index-12 < 10 ? '0' + (index-12) : index-12) + ' PM' : (index < 10 ? '0' + index : index)  + ' AM'
}