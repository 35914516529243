import Navigation from '../../../components/Dashboard/NavAdmin'

const AdminDashboard = (props) => {

    return (
        <div className = "">
        <Navigation {...props}/>
        <div className = {`main-content ${props.padding === 'high' && 'px-lg-6 mt-6'}`}>
            {props.showTitle && <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-end">
                            <div className="col">
                                <h6 class="header-pretitle text-secondary">{props.subTitle}</h6>
                                <h1 class="header-title">{props.title}</h1>
                            </div>
                            <div className="col-auto">{props.sideButton}</div> 
                        </div> 
                    </div>
                </div>
            </div>}
            <div className = 'container-fluid'>
                {props.children}
            </div>
        </div>
    </div>
    )
}

export default AdminDashboard;