import React, {useEffect, useContext} from 'react'
import {Store} from '../../store'
import {Modal} from 'react-bootstrap'
import moment from 'moment';
import axios from '../../utilis/axios';
import { fetchUser } from '../../store/actions/auth';

const Notifications = ({show, setShow}) => {


  const {state, dispatch} = useContext(Store);


  useEffect(() => {
    if(show) {
      readNotifications()
      getNotifications()
    }
    // eslint-disable-next-line
  }, [show])

  const readNotifications = async () => {
      const newUser = {...state.user.data, notifications: state.user.data.notifications.map(item => ({...item, isRead: true}))}
      fetchUser(newUser, dispatch)
      await axios.post('/api/notifications/read')
  }


  const getNotifications = async () => {
    try{
      
      if(state.user.data.notifications.length <= 20){
        const res = await axios.get('/api/notifications/')
        const newUser = {...state.user.data, notifications: res.data}
        fetchUser(newUser, dispatch)
      }

    } catch(err){
      console.log(err)
    }
  }



  return(
    <Modal 
      className = 'modal' 
      style = {{height: '100vh'}} 
      dialogClassName="modal-dialog modal-dialog-vertical "  
      show={show} 
      onHide = {()=>setShow(false)}
    > 
      <Modal.Header >
        <h4 className="modal-title">Notifications</h4>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="list-group list-group-flush list-group-activity my-n3">
          {state.user.data.notifications.sort(compareValues1('date')).map((item, index) => <NotificationItems key = {index} payload = {item}/>)}
        </div>
      </Modal.Body>
    </Modal>
  )
}
        

const NotificationItems = ({payload}) => {
  return(
    <div className="list-group-item text-reset">
      <div className="row">
        <div className="col-auto">
          <div className="avatar avatar-sm">
            <img alt = "" className="avatar-img rounded-circle" src = {payload.avatar} />
          </div>
        </div>
        <div className="col ml-n2">
          <h5 className="mb-1">
            {payload.title}
          </h5>
          <p className="small text-gray-700 mb-0">
            {payload.description}
          </p>
          <small className="text-muted">
            {moment(payload.date).fromNow()}
          </small>
        </div>
      </div> 
    </div>
  )
}

export default Notifications;       
    


const compareValues1 = (key) => {
  return function innerSort(a, b) {

    const varA = moment(a[key]);
    const varB = moment(b[key]);

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return comparison  * -1
  };
}

