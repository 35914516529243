import { useState, useContext } from "react";
import { InputControl } from "../../../../components/Forms";
import axios from "../../../../utilis/axios";
import {
  validatePhone,
  cleanPhone,
  validateEmail,
} from "../../../../utilis/validation";
import cogoToast from "cogo-toast";

const Profile = () => {
  const [user, setUser] = useState({ name: "", email: "", phone: "" });
  const [errors, setErrors] = useState(Array(5).fill(false));
  const [loading, setLoading] = useState(false);

  const saveData = async () => {
    if (!validate()) return;
    await saveChanges();
  };

  const saveChanges = async () => {
    try {
      setLoading(true);
      await axios.post("/api/student/profile/referral/", {
        ...user,
        phone: cleanPhone(user.phone),
      });
      cogoToast.success("Thank you for referring your friend!");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const validate = () => {
    let newErrors = Array(5).fill(false);
    if (user.name.length === 0) newErrors[0] = true;
    if (!validatePhone(user.phone)) newErrors[1] = true;
    if (!validateEmail(user.email)) newErrors[2] = true;
    setErrors(newErrors);
    if (newErrors.includes(true)) return false;
    else return true;
  };

  return (
    <>
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-12 col-lg-4 order-lg-2">
              <div class="text-center">
                <img
                  src="/assets/refferal.png"
                  alt="..."
                  class="img-fluid mt-n6 mt-lg-0 mb-4 mb-md-n5"
                  style={{ maxWidth: "292px" }}
                />
              </div>
            </div>
            <div class="col-12 col-lg-8 px-4 py-3 order-lg-1">
              <h1>
                Refer your friends and family to get exclusive discounts today!
              </h1>
              <p class="text-muted">
                If you know someone who would benefit from the positive effects
                of music, recommend them to us and get exclusive discounts on
                your next bundle purchase.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow-sm border">
        <div className="card-body px-lg-6 py-lg-5">
          <form>
            <h2> Invite a friend</h2>
            <div className="row">
              <div className="col-12 col-md-12">
                <InputControl
                  label="Name"
                  value={user.name}
                  setValue={(val) =>
                    setUser((prev) => ({ ...prev, name: val }))
                  }
                  error={errors[0]}
                  setError={(val) =>
                    setErrors((prev) => {
                      prev[0] = val;
                      return prev;
                    })
                  }
                  errorText="Please enter a valid name"
                />
              </div>
              <div className="col-12 col-md-6">
                <InputControl
                  label="Phone"
                  value={user.phone}
                  setValue={(val) =>
                    setUser((prev) => ({ ...prev, phone: val }))
                  }
                  error={errors[1]}
                  setError={(val) =>
                    setErrors((prev) => {
                      prev[1] = val;
                      return prev;
                    })
                  }
                  errorText="Please enter a valid phone"
                />
              </div>
              <div className="col-12 col-md-6">
                <InputControl
                  label="Email"
                  value={user.email}
                  setValue={(val) =>
                    setUser((prev) => ({ ...prev, email: val }))
                  }
                  error={errors[2]}
                  setError={(val) =>
                    setErrors((prev) => {
                      prev[3] = val;
                      return prev;
                    })
                  }
                  errorText="Please enter a valid email"
                />
              </div>
            </div>
            <div className="btn btn-primary" onClick={() => saveData()}>
              {loading && (
                <span className="mr-3 spinner-border spinner-border-sm"></span>
              )}
              Send invite
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;
