import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { Store } from '../../../../store'
import axios from '../../../../utilis/axios';
import { saveRequests } from '../../../../store/actions/instructor';
import Reschedule from '../Schedule/RequestModals/Reschedule'
import Cancel from '../Schedule/RequestModals/Cancel'
import { AiOutlineClockCircle } from 'react-icons/ai'
import cogoToast from 'cogo-toast'
import instructor_content from '../../../../content/instructor_content.json'
import Skeleton from 'react-loading-skeleton'


const Alerts = () => {

    const { state, dispatch } = useContext(Store)
    const [requests, setRequests] = useState();
    const [loading, setLoading] = useState('');

    useEffect(() => {
        fetchRequests()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (state.instructor.requests) setRequests(state.instructor.requests)
        // eslint-disable-next-line
    }, [state.instructor.requests])

    const fetchRequests = async () => {
        try {

            const res = await axios.get('/api/instructor/schedule/request');
            saveRequests(res.data, dispatch)

        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div className=''>
            <div class='header mb-0'>
                <div class='header-body'>
                    <h2 class="header-title mb-0">Session requested</h2>
                </div>
            </div>
            <div className='' >
                {requests ? (requests.length > 0 ? (
                    requests.length > 0 ? requests.slice(0, 5).map(item => <RequestItem data={item} loading={loading} setLoading={setLoading} />) : requests.map(item => <RequestItem data={item} loading={loading} setLoading={setLoading} />)
                ) : <NoRequests />) : <LoadingRequests />}
            </div>
        </div>
    )
}

export default Alerts





const RequestItem = ({ data, loading, setLoading }) => {

    const [showCancel, setShowCancel] = useState(false);
    const [showReschedule, setShowReschedule] = useState(false);

    const { state, dispatch } = useContext(Store)

    const approveRequest = async (e) => {
        e.preventDefault();
        try {
            setLoading(data._id)
            const res = await axios.post(`/api/instructor/schedule/request/approve/${data._id}`)
            if (res.data === 'NO_ZOOM_ACCOUNT_AVAILABLE') {
                cogoToast.error(instructor_content.schedule.no_zoom_account)
                setLoading(false)
                return;
            }
            if (res.data === 'SLOT_NOT_AVAILABLE') {
                cogoToast.error(instructor_content.schedule.no_slot_available)
                setLoading(false)
                return;
            }
            const newRequests = [...state.instructor.requests];
            const index = newRequests.findIndex(item => item._id === data._id)
            newRequests.splice(index, 1)
            saveRequests(newRequests, dispatch)
            setLoading('')

        } catch (err) {
            console.log(err)
            setLoading('')
            console.log(err)
        }
    }


    return (
        <div className='card mb-4'>
            <div className='card-header'>
                <div class='d-flex align-items-center'>
                    <AiOutlineClockCircle class='mr-3' />
                    <strong class='mr-2'>{moment(data.date_time).format('Do MMM YYYY')},</strong>
                    {moment(data.date_time).format('hh:mm A')} - {moment(data.date_time).add(45, 'm').format('hh:mm A')}
                </div>
                <div className="d-flex">
                    <button className=" btn btn-outline-primary btn-sm mr-1" onClick={(e) => { e.preventDefault(); setShowCancel(true) }} disabled={loading !== ''}>
                        Cancel
                    </button>
                    {data.type.toLowerCase() === 'demo' && <button className=" btn btn-outline-primary btn-sm mr-1" onClick={(e) => { e.preventDefault(); setShowReschedule(true) }} disabled={loading !== ''}>
                        Reschedule
                    </button>}
                    {moment(data.date_time).diff(moment(), 'minutes') > 10 && <button className=" btn btn-primary btn-sm" onClick={(e) => approveRequest(e)} disabled={loading !== ''}>
                        {loading === data._id && <span class='spinner-border spinner-border-sm mr-1'></span>}
                        Accept
                    </button>}
                </div>
            </div>
            <div className='card-body py-3'>
                <div className='row align-items-center'>
                    <div className='col-auto'>
                        <div className="avatar avatar-md"  >
                            <img src={data && data.student && data.student.avatar} alt="..." class="avatar-img rounded-circle" />
                        </div>
                    </div>
                    <div className='col'>
                        <div class="h4 mb-1">{data.student && data.student.firstName + ' ' + data.student.lastName}</div>
                        <div class="text-muted">{data.course.title}</div>
                        <small class="small text-muted">Session ID: {data.bookingId}</small><br />
                        <div class="badge badge-primary my-1">{makeFirstLetterCap(data.type)} session</div>
                        <div class="badge badge-primary my-1 ml-2">{makeFirstLetterCap(data.requestType)} requested</div>

                    </div>
                </div>
            </div>
            <Cancel show={showCancel} setShow={setShowCancel} data={data} />
            <Reschedule show={showReschedule} setShow={setShowReschedule} data={data} />
        </div>
    )
}



const makeFirstLetterCap = (text) => text ? text[0].toUpperCase() + text.slice(1) : ''



const LoadingRequests = () => {
    return (
        <div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
            <div class='mb-4'></div>
            <Skeleton height={100} />
        </div>
    )
}


const NoRequests = () => {
    return (
        <div className='card mb-4'>
            <div className='card-body d-flex align-items-center justify-content-center'>
                <div className='h3 text-uppercase text-muted mb-0 py-5'>
                    {instructor_content.schedule.no_request}
                </div>
            </div>
        </div>
    )
}