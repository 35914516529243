import React, {useState, useEffect, useContext} from 'react'
import {Modal} from 'react-bootstrap'
import { Store } from '../../../store';
import { saveInstrument } from '../../../store/actions/landing';

const InfoModal = ({show, setShow, ...props}) => {

    const {state, dispatch} = useContext(Store)
    const [selected, setSelected] = useState(state.landing.instrument);
    const [error, setError] = useState(false)
    const [tab, setTab] = useState('western')

    useEffect(() => {
        setError(false)
    }, [selected])

    const proceed = () => {
        if(selected === '') setError(true);
        else {
            setShow(false)
            saveInstrument(selected, dispatch)
        }
    }

    const onHide = () => {
        if(selected === '') props.history.push('/')
        else setShow(false)
    }

    return (
        <Modal show={show} centered dialogClassName = '' onHide = {onHide}>
            <div className = 'modal-body my-3 px-lg-5'>
                <div class = 'header mt-n6 pt-3'>
                    <div class = 'header-body'>
                        <div className = 'row align-items-end'>
                            <div className = 'col'>
                                <h3 class = 'h1 mb-0'>Pick your choice</h3>
                            </div>
                            <div class = 'col-auto'>
                                    <AccountNav tab = {tab} setTab = {setTab}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className = 'row no-gutters'>
                    {instruments[tab].map(item => (<div className = {`col-6 col-lg-${tab === 'others1' ? 4 : 3} mt-2 px-2`}>
                        <div class = {`card card-lift pointer ${selected !== item.value ? '' : 'bg-primary text-white'}`} onClick = {() => setSelected(item.value)}>
                            <img alt="" src = {item.img} class = "card-img-top" style = {{height: tab === 'others1' ? '130px' : '90px', objectFit: 'cover'}}/>
                            <div class = 'card-body text-center py-2 '>
                                <div className = "font-weight-bold mx-n3" >{item.label}</div>
                            </div>
                        </div>
                    </div>))}
                </div>
                {error && <div class = 'invalid-feedback d-block'>Please select a instrument</div>}

                <div className = 'btn btn-block btn-primary mt-4' onClick = {() => proceed()}>
                    Proceed
                </div>

            </div>
        </Modal>
    )
}

export default InfoModal



const AccountNav = ({tab, setTab}) => {
    return(
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'western' && 'active'}`} onClick = {() => setTab('western')}>Western</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'indian' && 'active'}`} onClick = {() => setTab('indian')}>Indian Classical</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'others' && 'active'}`} onClick = {() => setTab('others')}>Others</div>
                    </li>
                </ul>
        </div>
    </div>
    )
}



const instruments = {
    "western": [
        {label: 'Guitar', value: 'Guitar', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/guitar.webp'},
        {label: 'Drums', value: 'Drums', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/drums.jpg'},
        {label: 'Piano', value: 'Piano', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/piano.jpg'},
        {label: 'Violin', value: 'Violin', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/violen.jpg'},
        {label: 'Ukulele', value: 'Ukulele', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/Ukulele.jpg'},
        {label: 'Cajon', value: 'Cajon', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/Clapbox.jpg'},
        {label: 'Keyboard', value: 'Keyboard', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/Keyboard.jpg'},
        {label: 'Harmonica', value: "Harmonica", img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/harmonica.jpeg' },
        {label: 'Mandolin', value: "Mandolin", img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/mandolin.jpeg' },
        {label: 'Double Bass', value: "DoubleBass", img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/doublebass.jpeg' },
        {label: 'Cello', value: "Cello", img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/cello.jpeg' },
        
        
    ],
    "indian": [
        {label: 'Harmonium', value: 'Harmonium', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/Harmonium.jpg'},
        {label: 'Flute', value: 'Flute', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/flute.jpg'},
        {label: 'Tabla', value: 'Tabla', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/Tabla.jpg'},
        {label: 'Veena', value: 'Veena', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/veena.jpeg'},
        {label: 'Trumpet', value: 'Trumpet', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/Trumpet.jpg'},
        {label: 'Mridangam', value: 'Mridangam', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/mridangam.jpeg'},
        {label: 'Sitar', value: 'Sitar', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/sitar.jpeg'},
        {label: 'Dhol', value: "Dhol", img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/dhol.jpeg' },
    ],
    "others": [
        {label: 'Singing', value: 'Singing', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/Singing1.jpg'},
        {label: 'Song Writing', value: 'SongWriting', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/SongWriting.jpg'},
        {label: 'Music Production', value: 'MusicComposition', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/MusicComposition.jpg'},
        {label: 'Music Theory', value: 'MusicTheory', img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/MusicTheory.jpg'},
        {label: 'Rap', value: "Rap", img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/rap.jpeg' },
        {label: 'Music Therapy', value: "Music Therapy", img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/IMG_0310.jpeg' },
        {label: 'DJing', value: "DJing", img: 'https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/instruments/dj.jpeg' },
    ]
}



