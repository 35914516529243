import {useContext, useEffect} from 'react'
import Navigation from '../../../components/Dashboard/NavInstructor'
import { Store } from '../../../store'
import { fetchUser } from '../../../store/actions/auth'
import { saveMessages, saveStudents } from '../../../store/actions/instructor';
import cogoToast from 'cogo-toast'
import {useChannel, useEvent} from '@harelpls/use-pusher'
import autosize from 'autosize';



const InstructorDashboard = (props) => {

    const {state, dispatch} = useContext(Store)
    
    const channel = useChannel(state.user.data._id);

    useEffect(() => {
        autosize(document.querySelectorAll('textarea'));
    }, [])


    useEvent(channel, "notifications", async (data) =>{
        if(state.user.data.notifications.filter(item => item._id === data._id).length === 0){
            if(data.type === 'NEW_MESSAGE_T' && !props.location.pathname.includes('/instructor/messages')){
                editMessage(data.data.sender_id, data.data.message, new Date(), data.data.messageId)
            }
            if(data.type === "NEW_MESSAGE_T" && props.location.pathname === '/instructor/messages'){
            } else notification(data.title, data.description, data.url)
            const newUser = {...state.user.data}
            newUser.notifications.push(data);
            fetchUser(newUser, dispatch)
        }
    });

    const notification = (title, description, url) => {
        const {hide} = cogoToast.info(
          description, { position: 'top-right', title: title, hideAfter: 6, 
            onClick: () => {
              if(url){
                if(url.slice(0, 4) === 'http') window.open(url, '_blank');
                else {
                  props.history.push(url)
                  window.location.reload();
                }
                hide();
              } else hide();
            } 
          }
        );
    }


    const editMessage =  (userId, message, time, message_id) => {
        try{
    
            const allMessages = Object.values(state.instructor.chat_messages).flat()
            if(!state.instructor.chat_messages || allMessages.length === 0) return;
            if(allMessages.filter(item => item._id === message_id).length>0){   
                // console.log("Duplicate")
                return;
            }
            const newMessages = {...state.instructor.chat_messages}; 
            let newStudents = [...state.instructor.chat_students]
            if(!newMessages[userId]) {
                newMessages[userId] = []
            };
            newMessages[userId] = [{
                from: 'student',
                message: message,
                time: time,
                isRead: false,
                _id: message_id
            }, ...newMessages[userId]]
            
            const index = newStudents.findIndex(item => item._id === userId);
            if(index < 0){
                return;
            }
            newStudents[index].lastMsgTime = new Date();
            newStudents[index].lastMsg = message.length > 20 ? message.slice(0, 20) + '...' : message;
            newStudents[index].unreadMsg = newMessages[userId].reduce((a, b) => {
                if(!b.isRead && b.from === 'student') return a + 1
                else return a
            }, 0)
        
            newStudents = newStudents.sort((a, b) => {
                if(b.lastMsgTime){
                    return new Date(b.lastMsgTime) - new Date(a.lastMsgTime)
                } else return -1
            })
        
            saveStudents(newStudents, dispatch)
            saveMessages(newMessages, dispatch);
        }
        catch(err){
            console.log(err)
        }
        
    }


 
    return (
        <div className = "">
            <Navigation {...props}/>
            <div className = {`main-content ${props.padding === 'high' && 'px-lg-6 mt-lg-6 mt-5'}`}>
                {props.showTitle && <div className="header">
                    <div className="container-fluid px-5 px-lg-0">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col-12 col-lg">
                                    <h6 class="header-pretitle text-secondary">{props.subTitle}</h6>
                                    <h1 class="header-title">{props.title}</h1>
                                </div>
                                <div className="col-12 col-lg-auto mt-4 mt-lg-0">{props.sideButton}</div> 
                            </div> 
                        </div>
                    </div>
                </div>}
                <div className = 'container-fluid'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default InstructorDashboard;