import React from "react";
import { Modal } from "react-bootstrap";
import {GrFormClose} from 'react-icons/gr'

const RightSideModal = (props) => {
  return (
    <Modal
      className="modal fade fixed-right"
      dialogClassName="modal-dialog modal-dialog-vertical "
      show={props.show}
      onHide={() => props.setShow(false)}
    >
      <div className=" h-100">
        <Modal.Body className="modal-body">
          <div className="close pointer">
            <span onClick={() => props.setShow(false)}><GrFormClose /></span>
          </div>
          {props.children}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default RightSideModal;

