import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import Select from 'react-select'
import {DateFormInput1} from '../../../../components/Forms'
import axios from '../../../../utilis/axios'
import moment from 'moment'
import cogoToast from 'cogo-toast'

const RequestSchedule = ({show, setShow, sessions, setSessions}) => {

    const [loading, setLoading] = useState(false)
    const [schedule, setSchedule] = useState({enrollment: '', date: '', time: ''});
    const [errors, setErrors] = useState(Array(3).fill(false));

    useEffect(() => {
        setErrors(Array(3).fill(false))
    }, [schedule])

    useEffect(() => {
        setSchedule({enrollment: '', date: '', time: ''})
    }, [show])



    const validate = () => {
        const newErrors = Array(3).fill(false);
        if(schedule.enrollment.length < 3) newErrors[0] = true;
        if(schedule.date === '' ) newErrors[1] = true;
        if(!schedule.time || schedule.time ==='' || !schedule.time.value) newErrors[2] = true;
        setErrors(newErrors)
        if(newErrors.includes(true)) return true;
        else return false
    }


    const createSchedule = async (e) => {
        e.preventDefault()
        if(validate()) return;
        try{
            setLoading(true);
            const date_time = moment(schedule.date + "-" + schedule.time.label.slice(0, 8), "DD/MM/YYYY-hh:mm A");
            const res = await axios.post('/api/admin/session', {enrollmentId: schedule.enrollment, date: date_time})
            // console.log(res.data)

            const newSessions = [...sessions];
            newSessions.push(res.data);
            setSessions(newSessions)

            setShow(false);
            cogoToast.success("Session created successfully")

            setLoading(false);
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

 
    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Create a Schedule</div>
                </div>

                <form class = "mt-3 ">

                    <div class = "form-group ">
                        <label>Enter the enrollment id</label>
                        <input
                            class = 'form-control'
                            value = {schedule.enrollment}
                            onChange={e => setSchedule(prev => ({...prev, enrollment: e.target.value}))}
                        />
                        {errors[0] && <div class = 'invalid-feedback d-block'>Please select a valid enrollment id</div>}
                    </div>
                    

                    <div>
                        <DateFormInput1 
                            placeholder = "Select a date"
                            error = {errors[1]}
                            setError = {(val) => console.log('')}
                            errorMessage = {"Please enter a valid date"}
                            label = "Date"
                            setValue = {(val) => {
                                setSchedule(prev => ({...prev, date: val}));
                            }}
                            value = {schedule.date}
                            // minimumDate = {new Date()}
                        />
                        <div class = "form-group my-4">
                            <label>Time Slot</label>
                            <Select 
                                value = {schedule.time}
                                options = {timeOptions}
                                onChange = {e => setSchedule(prev => ({...prev, time: e}))}
                            />
                            {errors[2] && <div class = 'invalid-feedback d-block'>This field is mandatory</div>}
                        </div>
                    </div>
                            
                    
                    <button class = "btn btn-primary btn-block mt-6" onClick = {(e) => createSchedule(e)} disabled = {loading}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Create Schedule
                    </button>
                    
                </form>
                
            </div>
        </RightModal>
    )
}

export default RequestSchedule;







const time1 = (time) => time >= 10 ? time : "0"+time


const timeFormat = (time, isSecond) => {
    if(time < 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " AM"
    } else if(time === 12){
        return time1(time) + ":" + (isSecond ? "45":"00") + " PM"
    } else if(time > 12){
        return time1(time-12) + ":" + (isSecond ? "45":"00") + " PM"
    }
}

const timeOptions = Array(24).fill().map((item, index) => {
    return {value: index, label: timeFormat(index, false) + ' - ' + timeFormat(index, true)}
})



