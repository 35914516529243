import React, {useState, useEffect, useContext} from 'react'
import DashboardLayout from '../index'
import {Modal} from 'react-bootstrap'
import axios from '../../../../utilis/axios';
import { Store } from '../../../../store';
import Review from './Review'
import { saveEnrollment } from '../../../../store/actions/student';
import cogoToast from 'cogo-toast';
import student_content from '../../../../content/student_content.json'

const Billing = () => {

    const {state, dispatch} = useContext(Store)
    const [showModal, setShowModal] = useState(false);
    const [enrollment, setEnrollment] = useState({ num_classes: '', discount: ''});
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState({})
    const [course, setCourse] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(state.student.enrollments && state.student.enrollments.length > 0){
            setCourses(state.student.enrollments.filter(item => item.type === 'paid'));
            fetchCourses();
        } else{
            fetchCourses();
        }
        // eslint-disable-next-line
    }, [])

    const fetchCourses = async () => {
        try{
            const res = await axios.get(`/api/student/enrollments`)
            saveEnrollment(res.data, dispatch)
            setCourses(res.data.filter(item => item.type === 'paid' && item.num_classes > 0))
        } catch(err){
            console.log(err)
        }
    }


    const clearCart = () => {
        setEnrollment({ num_classes: '', discount: ''});
        setCourse({})
    }

    const purchaseNow = async (e) => {
        e.preventDefault()
        try{
            setLoading(true);
            const res = await axios.post('/api/student/enrollment/paid', {enrollment, course: course.course})
            const enrollment_id = res.data.enrollmentId
            const { amount, id: order_id, currency, index1 } = res.data;
            await displayRazorpay(order_id, currency, amount, enrollment_id, index1)
            setLoading(false);
        } catch(err){
            console.log(err)
            setLoading(false);
        }
    }

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    const displayRazorpay = async (order_id, currency, amount, enrollment_id, index1) => {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        const total_price = (enrollment.num_classes * course.price_per_class)*(1-(enrollment.discount/100))
        // console.log('Total Price ', total_price)

        const options = {
            // key: "rzp_test_2j87UbWiuxCiej", // Enter the Key ID generated from the Dashboard
            key: process.env.REACT_APP_RAZOR_PAY_KEY,
            amount: total_price*100,
            currency: currency,
            name: "Dhunguru.",
            description: "Learn Music from the best online Music teachers",
            image: "https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/logo.png",
            order_id: order_id,
            handler: async function (response) {
                try{
                    setLoading(true)
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                        enrollmentId: enrollment_id,
                        index1: index1,
                    };
                    await axios.post("/api/student/enrollment/payment/success", data);
                    cogoToast.success(student_content.billing.congrats);
                    clearCart();
                    setLoading(false);
                    
                    fetchCourses()
                } catch(err){
                    setLoading(false)
                    console.log(err)
                }
            },
            modal: {
                "ondismiss": async function(){
                    await axios.post("/api/student/enrollment/payment/failure", {enrollment_id, index1});
                }
            },
            prefill: {
                name: state.user.data.firstName,
                email: state.user.data.email,
                contact: state.user.data.phone ? '91'+state.user.data.phone : '',
            },
            theme: {
                color: "#ff6700",
            },
        };

        const paymentObject = new window.Razorpay(options);

        paymentObject.on('payment.failed', function (response){
            alert('Payment failed')
        });

        paymentObject.open();
    }

    return (
        <DashboardLayout>
            <div className = 'row mt-6 px-xl-6 mx-xl-6 px-3 mx-3'>
                <div className = 'col-12 col-md-7 col-lg-8'>
                    <Courses 
                        courses = {courses} 
                        setCourse = {setSelectedCourse}
                        setShowModal = {setShowModal}
                    />
                </div>
                <div className = 'col-md-5 col-lg-4 d-none d-md-block'>
                    <Summary 
                        enrollment = {enrollment}
                        purchaseNow = {purchaseNow}
                        course = {course}
                        loading = {loading}
                        clearCart = {clearCart}
                    />
                </div>
            </div>
            {selectedCourse && <PackageModal 
                showModal = {showModal} 
                setShowModal = {setShowModal} 
                enrollment = {enrollment}
                setEnrollment = {setEnrollment}
                course = {selectedCourse}
                setCourse = {setCourse}
            />}
            <SummaryStikcy 
                enrollment = {enrollment}
                purchaseNow = {purchaseNow}
                loading = {loading}
                course = {course}
            />
        </DashboardLayout>
    )
}

export default Billing


const Courses = ({courses, setCourse, setShowModal}) => {
    return (
        <div>
            <h4 class = 'h1'>{student_content.billing.title}</h4>
            {/* <div class = ' mt-4 h5 text-uppercase text-muted'>{student_content.billing.cta}</div> */}
            <div className = 'row'>
                {courses.map(item => (<div className = 'col-12 col-lg-6'>
                    <CourseCard 
                        course = {item}
                        setCourse = {setCourse}
                        setShowModal = {setShowModal}
                    />
                </div>))}
            </div>
        </div>
    )
}


const Summary = ({enrollment, purchaseNow, course, loading, clearCart}) => {
    return (
        <div>
            <h3>Cart</h3>
            <div className = 'card shadow-sm border' style = {{minHeight: '40vh'}}>
                {!enrollment.num_classes ? 
                <div className = 'card-body d-flex align-items-center justify-content-center'>
                    <div className = 'text-muted text-center text-uppercase h4 px-md-3 px-lg-4' style = {{verticalAlign:'middle'}}>You have not selected any membership yet.</div>
                </div>:
                <div className = 'card-body'>
                   <div className = 'row mb-4 align-items-center'>
                        <div className = 'col-auto'>
                            <div class = 'avatar avatar-lg'>
                                <img alt="" src = {course.instructor.avatar} class = 'avatar-img rounded-circle' />
                            </div>
                        </div>
                        <div className = 'col'>
                            <div class = 'h2 mb-1'>{course.course.title}</div>
                            <div class = 'h3 mb-0'>{course.instructor.firstName + ' ' + course.instructor.lastName}</div>
                        </div>
                    </div>

                    <div className = 'border-bottom py-3 '>
                    <div className = 'h7 mb-2'>Session details</div>
                        <div className = 'd-flex align-items-center justify-content-between  py-1'>
                            <span className = 'h8-unbold'>Session duration:</span>
                            <span className = 'h8'>45 mins</span>
                        </div>
                        <div className = 'd-flex align-items-center justify-content-between  py-1'>
                            <span className = 'h8-unbold'>Number of sessions:</span>
                            <span className = 'h8'>{enrollment.num_classes}</span>
                        </div>
                        <div className = 'd-flex align-items-center justify-content-between  py-1'>
                            <span className = 'h8-unbold'>Price per session:</span>
                            <span className = 'h8'>{course.course ? '₹' + course.course.price_per_class : ''}</span>
                        </div>
                    </div>
                    <div className = 'pt-4 mb-3 '>
                        <div className = 'h7 mb-2'>Total</div>
                        <div className = 'd-flex align-items-center justify-content-between  py-1'>
                            <span className = 'h8-unbold'>Bundle price:</span>
                            <span className = 'h8'>₹{course.course ? course.course.price_per_class*enrollment.num_classes : ''}</span>
                        </div>
                        <div className = 'd-flex align-items-center justify-content-between  py-1'>
                            <span className = 'h8-unbold'>Discount <span class = "text-muted">({enrollment.discount} %):</span></span>
                            <span className = 'h8'>- ₹{course.course ?  course.course.price_per_class*enrollment.num_classes*((enrollment.discount/100)) : ''}</span>
                        </div>
                        <div className = 'd-flex align-items-center justify-content-between  py-1 mt-3 border-top pt-3'>
                            <span className = 'h8-unbold'>Subtotal:</span>
                            <span className = 'h8'>₹{course.course ? parseFloat(course.course.price_per_class*enrollment.num_classes*(1-(enrollment.discount/100))).toFixed(2) : ''} </span>
                        </div>
                        
                        
                        <div className = 'd-flex align-items-center justify-content-between  py-1'>
                            <span className = 'h8-unbold'>GST <span class = "text-muted">(18 %)</span>:</span>
                            <span className = 'h8'>₹{course.course ? parseFloat((course.course.price_per_class*enrollment.num_classes*(1-(enrollment.discount/100)))*(18/100)).toFixed(2) : ''} </span>
                        </div>
                        <div className = 'pt-4 d-flex mb-3 align-items-center justify-content-between  py-1'>
                            <span className = 'h8-unbold'>Order Total:</span>
                            <span className = 'h8'>₹{course.course ? parseFloat(course.course.price_per_class*enrollment.num_classes*(1-(enrollment.discount/100))*1.18).toFixed(2) : ''}</span>
                        </div>
                    </div>
                    <div className = 'text-center mt-5'>
                        <button className = 'btn  btn-primary btn-block' onClick = {(e) => purchaseNow(e)} disabled = {loading}>
                            {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                            Check out
                        </button>
                        <div className = 'small pointer btn-link mt-2 text-dark' onClick = {() => clearCart()}>Clear cart</div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}


const PackageModal = ({showModal, setShowModal, setEnrollment, course, setCourse}) => {

    const [temp, setTemp] = useState({ num_classes: '', discount: ''});
    const [error, setError] = useState(false);


    useEffect(() => {
        setTemp({ num_classes: '', discount: ''})
    }, [showModal])


    useEffect(() => {
        setError(false)
    }, [temp])

    const addToCart = () => {
        if(temp.num_classes === '') {
            setError(true)
            return;
        }
        setShowModal(false);
        setEnrollment(temp);
        setCourse(course)
    }

    return (
        <Modal show = {showModal} centered dialogClassName = 'modal-80vw'>
            <div className = 'modal-body my-3 px-lg-6 '>
                <LessonPackage 
                    enrollment = {temp} 
                    setEnrollment = {setTemp}
                    course = {course.course}
                />
                {error && <div class = 'invalid-feedback d-block'>Please select a package first</div>}
                <div class = 'row mt-5'>
                    <div class = 'col'>
                        <div class = 'btn btn-block btn-outline-primary' onClick = {() => {
                            setShowModal(false);
                        }}>Cancel</div>
                    </div>
                    <div class = 'col'>
                        <div class = 'btn btn-block btn-primary' onClick = {addToCart}>Add to Cart</div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}








export const LessonPackage = ({enrollment, setEnrollment, course}) => {
    return (
        <div>  
            <div class = 'text-center'>
                <div class = 'h1 mb-2'>Choose your preferred bundle at ₹{course.price_per_class} per session</div>
                <div class = 'p'>With bigger bundle comes bigger discount </div>
            </div>
            

            <div class = 'mt-5 px-4 px-lg-0'>
                <div class = 'h3 mb-2'>How many sessions would you like to purchase?</div>
                <div class = 'row '>
                    {course.discount.map(item => 
                        <div 
                            class  = {`col-12 col-md py-3 border ${enrollment.num_classes === item.num_classes ? 'btn-primary' : 'btn-white'}`} 
                            onClick = {() => {
                                setEnrollment(prev => ({...prev, num_classes: item.num_classes, discount: item.discount}))
                            }}
                        >
                            <div class = 'card-body text-left text-lg-center'>
                                <div className = 'row align-items-center'>
                                    <div className = 'col-6 col-lg-12'>
                                        <div class = 'bold h1 mb-1 d-flex d-lg-block '>{item.num_classes < 9 ? '0' + item.num_classes : item.num_classes} <span className = 'ml-2 d-inline-block d-lg-none'>Lessons</span></div>
                                    </div>
                                    <div className = 'col-6 col-lg-12  mt-n2'>
                                    <div class = 'small'>{item?.num_classes === '1' ? 'session' : 'sessions'}</div>
                                    </div>
                                    <div className = 'col-6 col-lg-12  mt-n2'>
                                    <div class = 'small'>{item.discount === '0' ? 'no' : `${item?.discount}%`} discount</div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                </div>
                {/* <div class = 'small mt-3 '>
                <div class = 'small text-muted mb-2'>The bigger the bundle, the higher the discount</div>
                </div> */}
            </div>
            
        </div>
    )
}

























const SummaryStikcy = ({enrollment, purchaseNow, loading, course}) => {

    const [seeDetails, setSeeDetails] = useState(false)

    return (
        <div class = 'd-block d-md-none' style = {{height: '20vh'}}>
            <div className = 'card mb-0' style = {{position: 'fixed', bottom: '0', left: '0', width: '100%', zIndex: '9'}}>
                {enrollment.num_classes ? <div className ='card-body'>
                    <div className = 'see-details-pill bg-info' onClick = {() => setSeeDetails(prev => !prev)}>
                        See Details ^
                    </div>
                    {seeDetails && <div className = 'border-bottom py-2 px-3'>
                        <div className = 'h7'>Price Summary</div>
                        <div className = 'd-flex align-items-center justify-content-between  py-1'>
                            <span className = 'h8'>Selected Sessions</span>
                            <span className = 'h8'>Rs {course.course ? course.course.price_per_class*enrollment.num_classes : ''}</span>
                        </div>
                        <div className = 'd-flex align-items-center justify-content-between  py-1'>
                            <span className = 'h8'>Discount</span>
                            <span className = 'h8'>{enrollment.discount} %</span>
                        </div>
                    </div>}
                    <div className = 'd-flex align-items-center justify-content-between px-3 py-2'>
                        <span className = 'h8'>Order Total</span>
                        <span className = 'h8'>Rs {course.course ? course.course.price_per_class*enrollment.num_classes*(1-(enrollment.discount/100)) : ''}</span>
                    </div>
                    <button disabled = {loading} className = 'btn btn-block btn-primary btn-pill' onClick = {(e) => purchaseNow(e)}>
                        {loading && <span class = "spinner-border spinner-border-sm mr-2"></span>}
                        Enroll Now
                    </button>
                    <div className = 'text-center mt-2 small'>
                    Musical fulfilment awaits 
                    </div>
                </div> : ''}
            </div>
        </div>
    )
}


const CourseCard = ({setCourse, course, setShowModal}) => {


    const [showReview, setShowReview] = useState(false);


    return (
        <>
        <div className = 'card shadow-sm border'>
            <div className = 'card-body'>
                <div className = 'row'>
                    <div className = 'col-auto'>
                        <div className="avatar avatar-xl"  >
                            <img src={course.instructor.avatar} alt="..." class="avatar-img rounded-circle" />
                        </div>
                    </div>
                    <div className = 'col'>
                        <h3 class = 'mb-2'>{course.course.title}</h3>
                        <h4 class = "h4 text-muted mb-1">{course.instructor.firstName + ' ' + course.instructor.lastName}</h4>
                        <div class = "small text-dark mb-2 mt-2">You have purchased {course.num_classes} sessions from {course.instructor.firstName}</div>
                    </div>
                </div>
                <dv class = "d-flex justify-content-center">
                    <div class = 'btn-block btn btn-outline-primary btn-sm small pointer mt-3 mr-3' onClick = {() => {setShowReview(true)}}>Write a review</div>
                    <div class = 'btn-block btn btn-primary btn-sm small pointer mt-3' onClick = {() => {setCourse(course); setShowModal(true)}}>Add More</div>
                </dv>
            </div>
        </div>
        <Review show = {showReview} setShow = {setShowReview} courseId = {course && course.course && course.course._id} />
        </>
    )
}



