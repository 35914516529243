import React, {useState, useEffect} from 'react'
import AdminDashboard from '../index'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'
import InfoModal from './InfoModal'
import AddSession from './AddSession'
import moment from 'moment'
import Player from './Player'
import {BeatSpinner2} from '../../../../components/Spinners/BeatSpinner'

const Sessions = (props) => {

    const [searchInput, setSearchInput] = useState('');
    const [tab, setTab] = useState('all')
    const [fetchedData, setFetchedData] = useState();
    const [sessions, setSessions] = useState([]);
    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [showPlayer, setShowPlayer] = useState(false);
    const [selectedVideos, setSelectedVideos] = useState()
    const [selectedSession, setSelectedSession] = useState()


    useEffect(() => {
        fetchSessions();
        // eslint-disable-next-line
    }, [])
    
    
    useEffect(() => {
        if(fetchedData) formatData(fetchedData);
        // eslint-disable-next-line
    }, [fetchedData])


    const fetchSessions = async () => {
        try{

            const res = await axios.get('/api/admin/sessions');
            // console.log(res.data)
            setFetchedData(res.data)
            

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        const formatData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => ({
            ...item,
            studentName: {value: item.student.firstName + ' ' + item.student.lastName, label: <NameWithAvatar name = {item.student.firstName + ' ' + item.student.lastName} avatar = {item.student.avatar}/>},
            instructorName: {value: item.instructor.firstName + ' ' + item.instructor.lastName, label: <NameWithAvatar name = {item.instructor.firstName + ' ' + item.instructor.lastName} avatar = {item.instructor.avatar}/>},
            // courseName: item.course.instrument,
            courseName: item.course.title.slice(0, 16) + '...',
            date: {value: moment(item.date_time).unix(), label: moment(item.date_time).format('Do MMM YY, hh:mm A')},
            email1: item.student.email,
            email2: item.instructor.email,
            enrollmentId:  item.enrollment && item.enrollment.enrollmentId,
            confirm_by_instructor: item.confirm_by_instructor || "",
            confirm_by_student: item.confirm_by_student || "",
            lateCharges: item.lateCharges ? "Yes" : "No",
            lateCancel: item.lateCancel ? "Yes" : "No",
            status: {value: item.isDeleted ? 'Deleted' : item.status , label: <div class = {`badge badge-${item.isDeleted ? 'danger' : (item.status === 'completed' ? 'success' : (item.status === 'scheduled' ? 'warning' : 'danger'))}`}>{item.isDeleted ? 'Deleted' : item.status}</div>},
            type: {value: item.type, label: <div class = "badge badge-primary">{item.type}</div>},
            recording_links: {value: '', label: <div className = 'btn btn-sm btn-outline-primary' onClick = {() => playVideo(item.zoom.recording_links)}>{item.zoom.recording_links.length} recordings</div>},
            flag_response: {value: '', label: <div className = 'btn btn-sm btn-primary' onClick = {() => flagResponse(item)}>Resolve Flag</div>},
            createdAt: {value: moment(item.createdAt).unix(), label: moment(item.createdAt).format('Do MMM YY, hh:mm A')},
            invoice_amount: item.invoice.reduce((a, b) => a + parseInt(b.amount), 0),
            updatedAt: {value: moment(item.createdAt).unix(), label: moment(item.createdAt).format('Do MMM YY, hh:mm A')}
        }))
        setSessions(formatData)
    }


    const playVideo = (links) => {
        if(links.length > 0){
            setSelectedVideos(links)
            setShowPlayer(true)
        }
    }


    const flagResponse = (data) => {
        setSelectedSession(data);
        setShow(true)
    }
  


    return (
        <AdminDashboard showTitle = {false} padding = "low" {...props}> 
        <div class = 'header mt-4'>
            <div class = 'header-body'>
                <div className = 'row align-items-end'>
                    <div className = 'col'>
                        <h3 class = 'h1 mb-0'>Sessions</h3>
                    </div>
                    <div class = 'col-auto'>
                        <div class = 'btn btn-primary' onClick = {() => setShowAdd(true)}>Create Session</div>
                    </div>
                </div>
                <AccountNav tab = {tab} setTab = {setTab}/>
            </div>
        </div>
            {!fetchedData && <BeatSpinner2 />}

            {(fetchedData && tab === 'all') && <Table 
                data = {sessions}
                headers = {allHeaders}
                isSearchable = {true}
                handleRowClick = {(id) => {setSelectedSession(sessions.filter(item => item._id === id)[0]); setShow(true)}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
                dateFilter = 'date'
            />}
            {(fetchedData && tab === 'completed') && <Table 
                data = {sessions.filter(item => moment(item.date_time).diff(moment(), 'minutes') < 0 && ( item.status.value === 'completed'))}
                headers = {completedHeaders}
                isSearchable = {true}
                handleRowClick = {(id) => {setSelectedSession(sessions.filter(item => item._id === id)[0]); setShow(true)}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {['confirm_by_student', 'confirm_by_instructor', 'status']}
                isDownloadable = {true}
                dateFilter = 'date'
            />}
            {(fetchedData && tab === 'completed_unmarked') && <Table 
                data = {sessions.filter(item => moment(item.date_time).diff(moment(), 'minutes') < 0 && (item.status.value === 'scheduled'))}
                headers = {completedHeaders}
                isSearchable = {true}
                handleRowClick = {(id) => {setSelectedSession(sessions.filter(item => item._id === id)[0]); setShow(true)}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {['confirm_by_student', 'confirm_by_instructor', 'status']}
                isDownloadable = {true}
                dateFilter = 'date'
            />}
            {(fetchedData && tab === 'flagged') && <Table 
                data = {sessions.filter(item => item.isFlagged)}
                headers = {flagHeaders}
                isSearchable = {true}
                handleRowClick = {(id) => {setSelectedSession(sessions.filter(item => item._id === id)[0]); setShow(true)}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
                dateFilter = 'date'
            />}
            {(fetchedData && tab === 'upcoming') && <Table 
                data = {sessions.filter(item => {
                    if (moment(item.date_time).format('DD/MM/yyyy') === moment().add(1, 'days').format('DD/MM/yyyy')) return true;
                    else return false;
                })}
                headers = {upcomingHeaders}
                isSearchable = {true}
                handleRowClick = {(id) => {setSelectedSession(sessions.filter(item => item._id === id)[0]); setShow(true)}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
                dateFilter = 'date'
            />}
            {(fetchedData && tab === 'late') && <Table 
                data = {sessions.filter(item => {
                    return item.status.value === 'rescheduled' || item.status.value === 'cancelled'
                })}
                headers = {lateHeaders}
                isSearchable = {true}
                handleRowClick = {(id) => {setSelectedSession(sessions.filter(item => item._id === id)[0]); setShow(true)}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {['lateCharges', 'lateCancel']}
                isDownloadable = {true}
                dateFilter = 'date'
            />}
            
            
            
            
            
        
            
            {selectedSession && <InfoModal
                show = {show}
                setShow = {setShow}
                data = {selectedSession}
                sessions = {fetchedData}
                setSessions = {setFetchedData}
            />}


            <AddSession
                show = {showAdd}
                setShow = {setShowAdd}
                sessions = {fetchedData}
                setSessions = {setFetchedData}
            />
            
            {selectedVideos && <Player
                show = {showPlayer}
                setShow = {setShowPlayer}
                data = {selectedVideos}
            />}
        </AdminDashboard>
    )
}

export default Sessions



const AccountNav = ({tab, setTab}) => {
    return(
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'all' && 'active'}`} onClick = {() => setTab('all')}>All Sessions</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'completed' && 'active'}`} onClick = {() => setTab('completed')}>Completed</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'completed_unmarked' && 'active'}`} onClick = {() => setTab('completed_unmarked')}>Completed (Unmarked)</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'flagged' && 'active'}`} onClick = {() => setTab('flagged')}>Flagged</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'upcoming' && 'active'}`} onClick = {() => setTab('upcoming')}>Tomorrow</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'late' && 'active'}`} onClick = {() => setTab('late')}> Cancelled/Rescheduled</div>
                    </li>
                </ul>
        </div>
    </div>
    )
}



const allHeaders = [
    {value: 'bookingId', active: true, label: 'Booking Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'courseName', active: true, label: 'Instrument'},
    {value: 'type', active: true, label: 'Type'},
    {value: 'status', active: true, label: 'Status'},
    {value: 'date', active: true, label: 'Session Time'},
    {value: 'createdAt', active: true, label: 'Created At'},
    {value: 'price', active: true, label: 'price'},
    {value: 'invoice_amount', active: true, label: 'Charged Price'},
]
const completedHeaders = [
    {value: 'bookingId', active: true, label: 'Booking Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'courseName', active: true, label: 'Instrument'},
    {value: 'type', active: true, label: 'Type'},
    {value: 'status', active: true, label: 'Status'},
    {value: 'date', active: true, label: 'Date'},
    {value: 'confirm_by_student', active: true, label: 'Student Response'},
    {value: 'confirm_by_instructor', active: true, label: 'Instructor Response'},
    {value: 'invoice_amount', active: true, label: 'Charged Price'},
    {value: 'recording_links', active: true, label: 'Recordings'},
]
const upcomingHeaders = [
    {value: 'bookingId', active: true, label: 'Booking Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'courseName', active: true, label: 'Instrument'},
    {value: 'type', active: true, label: 'Type'},
    {value: 'date', active: true, label: 'Date'},
]

const lateHeaders = [
    {value: 'bookingId', active: true, label: 'Booking Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'courseName', active: true, label: 'Instrument'},
    {value: 'date', active: true, label: 'Date'},
    {value: 'updatedAt', active: true, label: 'Cancellation Time'},
    {value: 'status', active: true, label: 'Status'},
    {value: 'cancelled_by', active: true, label: 'cancelled_by'},
    {value: 'lateCharges', active: true, label: 'lateCharges'},
    {value: 'lateCancel', active: true, label: 'lateCancel'},

    //charged/not_charged
]


const flagHeaders = [
    {value: 'bookingId', active: true, label: 'Booking Id'},
    {value: 'studentName', active: true, label: 'Student'},
    {value: 'instructorName', active: true, label: 'Instructor'},
    {value: 'courseName', active: true, label: 'Instrument'},
    {value: 'date', active: true, label: 'Date'},
    {value: 'recording_links', active: true, label: 'Recordings'},
    {value: 'flag_response', active: true, label: 'Flag Response'},
]




const NameWithAvatar = ({name, avatar}) => {
    return (
        <>
        <span class="item-name text-reset">{name}</span>
        </>
    )
}