import React, {useState, useEffect, useContext} from 'react'
import DashboardLayout from '../index'
import { Store } from '../../../../store';
import axios from '../../../../utilis/axios'
import cogoToast from 'cogo-toast'
import ScheduleGlance from './Schedule'
import {Link} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';
import StarTeachers from './StarTeachers'
import TeacherList from './TeacherList'
import {RiErrorWarningLine} from 'react-icons/ri'
import student_content from '../../../../content/student_content.json'
import {AiFillCaretRight, AiFillCaretLeft} from 'react-icons/ai'

const Home = () => {

    const {state} = useContext(Store);

    const sendConfirmationMail = async () => {
        try{
            await axios.post('/api/auth/send-confirmation-email')
            cogoToast.info(student_content.toasts.send_confirmation_email, {position: 'top-right'})
        } catch(err){
            console.log(err)
        }
    }


    return (
        <DashboardLayout>
            <div className = "mt-md-6 mt-5 px-lg-6 mx-lg-6 mx-md-3 px-3">
                {!state.user.data.isEmailConfirm && <div class = 'card bg-danger-soft mb-lg-5 mt-lg-n4'>
                    <div class = 'card-body  py-lg-5'>
                        <div class = 'row align-items-center px-lg-6'>
                            <div class = 'col-auto'>
                                <RiErrorWarningLine class = 'display-2 text-muted mb-0'/>
                            </div>
                            <div class= 'col'>
                                <p class = 'h3 mb-2'>{student_content.utilis.confirmation_email.description}</p>
                                <div class = 'small '>Didnt recieve the email, <span class= 'btn-link pointer' onClick = {() => sendConfirmationMail()}>click here!</span></div>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className = 'row'>
                    <div className = 'col-12 col-lg-8'>
                        <ScheduleGlance />
                    </div>
                    <div className = 'col-12 col-lg-4'>
                        <Announcements />
                    </div>
                </div>
                <TeacherList />
                <StarTeachers />
            </div>
            
        </DashboardLayout>
    )
}

export default Home






const Announcements = () => {


    const [announcements, setAnnouncements] = useState();
    const [index, setIndex] = useState(-1)

    useEffect(() => {
        fetchAnnouncements()
    }, [])

 

    const fetchAnnouncements = async () => {
        try{
            const res = await axios.get('/api/announcements/student');
            // console.log(res.data)
            if(res.data.length > 0){
                setAnnouncements(res.data)
                setIndex(res.data.length-1)
            } 
        } catch(err){
            console.log(err)
        }
    }

    return (
        <div>
        {(announcements && index > -1) ? <div className="card shadow-sm border" >
            <div className="card-body">
                <img src={announcements[index].img} alt="..." class="card-img" style={{maxHeight: '200px', objectFit: 'cover'}} />
                <div className="row align-items-center">
                    <div className="col-12 ">
                    <div className="text-center">
                    </div>
                    </div>
                    <div className="col-12  px-4 py-3 ">
                    <h2>{announcements[index].title}</h2>
                    <p class="text-muted">
                        {announcements[index].description}
                    </p>
                    {announcements[index].cta && <Link to = {announcements[index].link} class="btn btn-primary lift">
                        {announcements[index].cta}
                    </Link>}
                    </div>
                </div> 
                <div class ="" style = {{position: 'absolute', bottom: '30px', right: '30px'}}>
                    <AiFillCaretLeft class = "pointer h4 mb-0 " onClick = {() => {
                        if(announcements[index-1]) setIndex(prev => prev-1)
                    }}/>
                    <AiFillCaretRight class = "pointer h4 mb-0 " onClick = {() => {
                        if(announcements[index+1]) setIndex(prev => prev+1)
                    }}/>
                </div>
            </div>
        </div>: <Skeleton height = {450} />}
        </div>
    )
}



