import React, {useState} from 'react'
import LgFooter from '../Footer'
import Nav from '../Nav'
import AnimateHeight from 'react-animate-height'
import {RiArrowDropDownLine } from 'react-icons/ri'
import {Link} from 'react-router-dom'
import content from '../../../content/landingPage.json'
import faqs from '../../../content/faqs.json'

const HowItWorks = () => {
    return (
        <div>
            <Nav/>
            <Hero />
            <HowItWorksSteps />
            <FAQs />
            <LgFooter/>
        </div>
    )
}

export default HowItWorks


const Hero = () => {
    return (
        <section class="py-6 d-flex align-items-center" style={{height: '80vh', backgroundImage: `url(${content.howItWorks.hero.img})`, backgroundPosition: 'center', backgroundSize: 'cover', boxShadow: 'inset 0 0 0 1000px rgba(0, 0, 0,.4)'}}>
        <div className="container my-6 text-center text-white px-lg-6">
                <h3 class="h3 mb-2 text-uppercase pt-5">{content.howItWorks.hero.title}</h3>
                <h1 class="display-3 pb-5">{content.howItWorks.hero.description}</h1>
            </div>
    </section>
    )
}




const HowItWorksSteps = () => {
    return (
        <div class = 'container px-lg-6 py-5 mt-6'>
            <div class = 'text-center h1 mb-2'>{content.howItWorks.learningProcess.title}</div>
            <div class = 'text-center h2 mb-5 text-dark'>{content.howItWorks.learningProcess.description}</div>
            <div className = 'container px-lg-6 mt-6'>

                <div className = 'row mx-lg-6 align-items-end'>
                    <div className = 'col-lg-6 col-12 text-center text-lg-right mb-4 order-2 order-lg-1'>
                        <h3 class = 'h2 mb-2'>{content.howItWorks.learningProcess.steps[0].title}</h3>
                        <div class = 'p ' style = {{fontSize: '15px'}}>{content.howItWorks.learningProcess.steps[0].description}</div>
                    </div>
                    <div className = 'col-lg-6 col-12 text-center order-1 order-lg-2'>
                        <img alt="" src = {content.howItWorks.learningProcess.steps[0].icon} class = 'img-fluid object-fit' style = {{width: '250px'}}/>
                    </div>
                </div>

                <div className = 'row mx-lg-6 align-items-end py-6'>
                    <div className = 'col-lg-6 col-12 text-center text-lg-left order-2 mb-4'>
                        <h3 class = 'h2 mb-2'>{content.howItWorks.learningProcess.steps[1].title}</h3>
                        <div class = 'p ' style = {{fontSize: '15px'}}>{content.howItWorks.learningProcess.steps[1].description}</div>
                    </div>
                    <div className = 'col-lg-6 col-12 text-center order-1'>
                        <img alt="" src = {content.howItWorks.learningProcess.steps[1].icon} class = 'img-fluid object-fit' style = {{width: '250px'}}/>
                    </div>
                </div>

                <div className = 'row mx-lg-6 align-items-end'>
                    <div className = 'col-lg-6 col-12 text-center text-lg-right mb-4 order-2 order-lg-1'>
                        <h3 class = 'h2 mb-2'>{content.howItWorks.learningProcess.steps[2].title}</h3>
                        <div class = 'p ' style = {{fontSize: '15px'}}>{content.howItWorks.learningProcess.steps[2].description}</div>
                    </div>
                    <div className = 'col-lg-6 col-12 text-center order-1 order-lg-2'>
                        <img alt="" src = {content.howItWorks.learningProcess.steps[2].icon} class = 'img-fluid object-fit' style = {{width: '250px'}}/>
                    </div>
                </div>

            </div>
           <div class = 'd-flex justify-content-center mt-6'>
                <Link to = "/instructors" className = 'btn btn-primary text-center btn-pill w-25'>Find your guru</Link>

           </div>

        </div>
    )
}

const FAQs = () => {
    const [indexx, setIndexx] = useState("")
  
    return (
        <div  className = "mt-4 container px-lg-6 mb-6" id = 'faqs'>
            <div className = 'py-3 h1 font-weight-bold' >FAQs</div>
            {faqs[0].faqs.filter(item => item.active).map((item, index) => (<FAQItem type = {'faq'} indexx= {indexx} setIndexx = {setIndexx} key1  = {index} data = {item}/>))}
            
        </div>
    )
}


const FAQItem = ({indexx, setIndexx, key1, type, data}) => {

    return (
        <div className = 'border-bottom mb-4 pb-2' >
        
            <div className = 'row align-items-center pointer' onClick = {() => setIndexx(prev => prev === key1 ? "" : key1)}>
                <div className = 'col'>
                    <div className = "p mb-0 font-weight-bold">{data.question}</div>
                </div>
                <div className = 'col-auto d-flex align-items-center justify-content-center'>
                    <div className = "h5 mb-1 "><RiArrowDropDownLine /></div>
                </div>
            </div>
            <AnimateHeight height = {indexx===key1 ? 'auto' : 0} duration = {150}>
                <div>
                    {data.answer.map(item => <div className = 'text-secondary mt-1 mb-3' style = {{fontSize: '16px'}}>
                        {typeof item === 'object' ? 
                        
                            (item.length ===1 ? <strong>{item}</strong> : <ul>{item.map(item2 => <li>{item2}</li>)}</ul>) : 
                        
                        item}
                    </div> )}
                </div>
            </AnimateHeight>
        </div>
    )
}


