import React, {useState} from 'react';
import Nav from '../Nav'
import Footer from '../Footer'
import {AiFillCaretRight} from 'react-icons/ai';
import faqs from '../../../content/faqs.json'
import AnimateHeight from 'react-animate-height';
import AnchorLink from 'react-anchor-link-smooth-scroll';


const FAQs = () => {
    return (
        <div>
            <Nav />
            <div className = ''>
                <Hero/>
                <FAQList />
                <Footer />
            </div>
        </div>
    )
}
export default FAQs




const Hero = () => {
    return (
        <div class="pt-6 pb-5">
            <div class="container text-center">
                <div class="row justify-content-center mb-5">
                    <div class="col-lg-8">
                        <h1 class="mt-5 display-2 mb-2"  >FAQs</h1>
                        <p class="mb-5 lead">Got questions? Here are some common ones we'd love to answer for you.</p>
                    </div>
                </div>
                <hr />
            </div>
        </div>
    )
}



const FAQList = () => {
    return (
        <section class = "mt-5 mb-5">
            <div class = "container">
                <div class = "row">
                    <div class = "col-12 col-lg-3 mb-6 mb-lg-0">
                        <h5 class = "mt-0 h1 ">Categories</h5>
                        {faqs.map(item => (
                            <div class = "mb-3">
                            <AnchorLink href = {`#${item.id}`} className = "h2 font-weight-light  pointer">{item.title}</AnchorLink>
                            </div>
                        ))}
                    </div>
                    <div class = "col-12 col-lg-9 pl-lg-40">
                        <Questions />
                    </div>
                </div>
            </div>
        </section>
    )
}


const Questions = () => {
    return (
        <div class="">
            {faqs.map(item => (<div class="mb-5 border-bottom pb-0">
                <h3 class="h2" id = {item.id}>{item.title}</h3>
                <div class="">
                    <div class="">
                        {item.faqs.map(item2 => (<div  class="">
                            <FAQitem question= {item2.question} answer = {item2.answer}/>
                        </div>))}
                    </div>
                </div>
            </div>))}
        </div>
    )
}



const FAQitem = ({question, answer}) => {

    const [collapsed, setCollapsed] = useState(true);

    return (
        <div class="border-bottom mb-4 pb-2">
            <div class="row justify-content-between align-items-center pointer pb-0 mb-2" onClick = {() => setCollapsed(prev => !prev)}>
                <div className = "col-11">
                    <h4 class="lead mb-0">{question}</h4>
                </div>
                <div class = "col-1 text-right">
                    <AiFillCaretRight className = {`faq-accordion-icon ${!collapsed && 'tilt-90'}`} style = {{color: '#25282a', fontSize: '14px'}} />
                </div>
            </div>
            <AnimateHeight height = {collapsed ? '0' : 'auto'} duration = {200}>
            <div class="mb-5  pt-3 pl-2" >
                {answer.map(item => <div className = 'text-secondary mt-1 mb-3' style = {{fontSize: '16px'}}>
                    {typeof item === 'object' ? 
                    
                        (item.length ===1 ? <strong>{item}</strong> : <ul>{item.map(item2 => <li>{item2}</li>)}</ul>) : 
                    
                    item}
                </div> )}
            </div>
            </AnimateHeight>
        </div>
    )
}