export const FETCH_CHAT = 'FETCH_USER'
export const FETCH_SCHEDULE = 'FETCH_SCHEDULE'
export const FETCH_REQUESTS = 'FETCH_REQUESTS'
export const FETCH_ENROLLMENT = 'FETCH_ENROLLMENT'
export const FETCH_COURSES = 'FETCH_COURSES'
export const ADD_COURSES = 'ADD_COURSES'
export const EDIT_COURSES = 'EDIT_COURSES'
export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const FETCH_STUDENTS = 'FETCH_STUDENTS'
export const ALL_STUDENTS = 'ALL_STUDENTS'
export const ALL_CLASSES = 'ALL_CLASSES'



export const saveSchedule = async (schedule, dispatch) =>{
     return dispatch({
        type: FETCH_SCHEDULE,
        payload: {
            schedule: schedule
        }
    })
}


export const saveRequests = async (requests, dispatch) =>{
    return dispatch({
       type: FETCH_REQUESTS,
       payload: {
           requests: requests
       }
   })
}


export const saveEnrollments = async (enrollments, dispatch) =>{
    return dispatch({
       type: FETCH_ENROLLMENT,
       payload: {
           enrollments: enrollments
       }
   })
}


export const saveCourses = async (courses, dispatch) =>{
    return dispatch({
       type: FETCH_COURSES,
       payload: {
           courses: courses
       }
   })
}




export const saveStudents = async (students, dispatch) =>{
    return dispatch({
       type: FETCH_STUDENTS,
       payload: {
           students: students,
       }
   })
}


export const saveAllStudents = async (students, dispatch) =>{
    return dispatch({
       type: ALL_STUDENTS,
       payload: {
           students: students,
       }
   })
}


export const saveClasses = async (classes, dispatch) =>{
    return dispatch({
       type: ALL_CLASSES,
       payload: {
           classes: classes,
       }
   })
}



export const saveMessages = async (messages, dispatch) =>{
    return dispatch({
       type: FETCH_MESSAGES,
       payload: {
           messages: messages,
       }
   })
}


