import React, {useState, useEffect} from 'react'
import axios from '../../../../utilis/axios'
import Table from '../../../../components/Table'


const GST = () => {

    const [gst, setGst] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        fetchGST();
        // eslint-disable-next-line
    }, [])

    const fetchGST = async () => {
        try{

            const res = await axios.get('/api/admin/users/gst')
            console.log(res.data)
            formatData(res.data)

        } catch(err){
            console.log(err)
        }
    }

    const formatData = (data) => {
        setGst(data.map(item => ({
            student_name: item.firstName + ' ' + item.lastName,
            gst_name: item.gst.name,
            gst_number: item.gst.gstNumber,
            gst_address: item.gst.address,
            gst_phone: item.gst.phone,
        })))
    }

    return (
        <div>
            <Table 
                data = {gst}
                headers = {tableHeaders}
                isSelectable = {false}
                isSearchable = {true}
                handleRowClick = {(id) => {console.log('')}}
                isHeader = {true}
                isFooter = {true}
                isSortable = {true}
                searchInput = {searchInput}
                setSearchInput = {setSearchInput}
                filters = {[]}
                isDownloadable = {true}
            />
        </div>
    )
}

export default GST



const tableHeaders = [
    {value: 'student_name', active: true, label: 'Student Name'},
    {value: 'gst_name', active: true, label: 'GST Name'},
    {value: 'gst_number', active: true, label: 'GST Number'},
    {value: 'gst_address', active: true, label: 'Address'},
    {value: 'gst_phone', active: true, label: 'Phone'},
]