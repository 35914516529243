export const SET_INSTRUMENT = 'SET_INSTRUMENT'
export const SET_COURSES = 'SET_COURSES'
export const SET_INSTRUCTORS = 'SET_INSTRUCTORS'


export const saveInstrument = async (instrument, dispatch) => {
     return dispatch({
            type: SET_INSTRUMENT,
            payload: {
                instrument: instrument
            }
        })
}



export const saveCourses = async (courses, dispatch) => {
    return dispatch({
           type: SET_COURSES,
           payload: {
               courses: courses
           }
       })
}


export const saveInstructors = async (instructors, dispatch) => {
    return dispatch({
           type: SET_INSTRUCTORS,
           payload: {
               instructors: instructors
           }
       })
}