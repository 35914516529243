import {useState, useEffect} from 'react'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "react-datepicker/dist/react-datepicker-cssmodules.css";
import {FiHelpCircle} from 'react-icons/fi'
import InputMask from 'react-input-mask'
import moment from 'moment'

export const InputControl = ({label, value, setValue, error, setError, errorText, placeholder, help, type}) => {
    return (
        <div className = 'form-group'>
            {label && <label>{label}</label>}
            {help && <OverlayTrigger overlay = {<Tooltip>{help}</Tooltip>}>
                <FiHelpCircle className = 'ml-2'/>
            </OverlayTrigger>}
            <input 
                className = {`form-control ${error && 'is-invalid'}`} 
                type = {type} 
                value = {value} 
                onChange = {e => {setError(false); setValue(e.target.value)}}
                placeholder = {placeholder || ""}
            />
            <div className = 'invalid-feedback'>{errorText}</div>
        </div>
    )
}


export const CheckBox = ({value, setValue}) => {
    return(
        <div className="custom-control custom-checkbox mr-n3" onClick = {() => setValue(!value)} >
            <input type="checkbox" class="custom-control-input" checked = {value} />
            <label class="custom-control-label"></label>
        </div>
    )
}

export const Switch = ({value, setValue}) => {
    return (
        <div className="custom-control custom-switch" onClick = {() => setValue(!value)} >
            <input type="checkbox" class="custom-control-input" checked = {value} />
            <label class="custom-control-label"></label>
        </div>
    )
}




export const DateFormInput = ({placeholder, error, setError, errorMessage, label, setValue, value, minimumDate}) => {

    const [dateText, setDateText] = useState(value || '');

    const handleChange = (value) => {
        if(!value) return;
        setError(false);
        let day = value.day, month = value.month;
        if(value.day < 9){
            day = '0' + value.day
        }
        if(value.month < 9){
            month = '0' + value.month
        }
        setDateText(day + '/' + month + '/' + value.year)
        setValue(day + '/' + month + '/' + value.year)
    }

    return(
        <div className="form-group">
            <label>{label}</label>
            <div className = 'customDatePickerWidth' style = {{position: 'relative'}}>
            <DatePicker  
                onChange = {handleChange} 
                value = {dateText ? {day: parseInt(dateText.split('/')[0]), month: parseInt(dateText.split('/')[1]), year: parseInt(dateText.split('/')[2])} : null}
                minimumDate={minimumDate ? {day: moment().date(), month: moment().month() + 1, year: moment().year()} : null}
                shouldHighlightWeekends
                className = 'w-100'
                popperClassName="calendar-popout"
                inputClassName = 'w-100'
                popperPlacement="top-end"
                popperModifiers={{
                  offset: { enabled: true, offset: '5px, 10px' },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport'
                  }
                }}
                renderInput = {
                    ({ref}) => 
                    <div className = 'input-group  w-100'>
                      <input 
                          placeholder = {placeholder}
                          value = {dateText} 
                          ref = {ref} 
                          readOnly = "true"
                          className = {error ? 'form-control is-invalid' : 'form-control'}
                          onBlur = {() => {
                            if(dateText === '') setError(true)
                          }}
                      />
                    </div>
                }
            />
            </div>
            <div className = 'invalid-feedback' style = {error ? {display: 'block'} : {}}>{errorMessage}</div>
        </div>
    )
}

export const CalenderInput = (props) => {
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <DatePicker
      selected={props.selected}
      onChange={(date) => props.setValue(date)}
      className="form-control"
      isClearable
      showTimeSelect
      filterTime={filterPassedTime}
      timeIntervals={props.interval || 60}
      dateFormat="MMM d, yy h:mm aa"
      minDate={new Date()}
      isDisabled={props.isDisabled}
    />
  );
};

export const DateFormInput1 = ({placeholder, error, setError, errorMessage, label, setValue, value, minimumDate, isDisabled}) => {

    const [dateText, setDateText] = useState(value || '');
  
    const handleChange = (value) => {
        if(!value) return;
        setError(false);
        let day = value.day, month = value.month;
        if(value.day < 9){
            day = '0' + value.day
        }
        if(value.month < 9){
            month = '0' + value.month
        }
        setDateText(day + '/' + month + '/' + value.year)
        setValue(day + '/' + month + '/' + value.year)
    }
  
    useEffect(() => {
      setDateText(value)
    }, [value])
  
    return(
        <div className="form-group mb-0">
            {label && <label>{label}</label>}
            <div className = 'customDatePickerWidth' style = {{position: 'relative'}}>
            <DatePicker  
                onChange = {handleChange} 
                value = {dateText ? {day: parseInt(dateText.split('/')[0]), month: parseInt(dateText.split('/')[1]), year: parseInt(dateText.split('/')[2])} : null}
                minimumDate={minimumDate ? {day: moment(minimumDate).date(), month: moment(minimumDate).month() + 1, year: moment(minimumDate).year()} : null}
                shouldHighlightWeekends
                className = 'w-100'
                popperClassName="calendar-popout"
                inputClassName = 'w-100'
                popperPlacement="top-end"
                popperModifiers={{
                  offset: { enabled: true, offset: '5px, 10px' },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport'
                  }
                }}
                isDisabled = {isDisabled}
                renderInput = {
                    ({ref}) => 
                    <div className = 'input-group  w-100'>
                      <input 
                          placeholder = {placeholder}
                          value = {dateText} 
                          ref = {ref} 
                          className = {`form-control pointer ${error && 'is-invalid'} ${isDisabled && 'disabled-input'}`}
                          disabled = {isDisabled}
                          readOnly = "true"
                      />

                    </div>
                }
            />
            </div>
            <div className = 'invalid-feedback' style = {error ? {display: 'block'} : {}}>{errorMessage}</div>
        </div>
    )
}
  


export const InputMaskItem = ({label, error, setError, errorText, placeholder, mask , value, setValue}) => {
    return (
        <div className = 'form-group'>
            <label>{label}</label>
            <InputMask mask={mask} value ={value} onChange = {e => {setError(false); setValue(e.target.value)}} maskChar = ''>
                {
                (inputProps) => (
                    <input className={`form-control ${error ? 'is-invalid' : ''} `} {...inputProps} type="text" placeholder = {placeholder} />
                )}
            </InputMask>
            <div className = 'invalid-feedback'>{errorText}</div>
        </div>
    )
  }
  

export const InputMaskItem1 = ({placeholder, mask , value, setValue}) => {
    return (
        <InputMask mask={mask} value ={value} onChange = {e => {setValue(e.target.value)}} maskChar = '_'>
            {
            (inputProps) => (
                <input className={`form-control form-control-flush`} {...inputProps} type="text" placeholder = {placeholder} />
            )}
        </InputMask>
    )
  }
  



export const DateFormFlush = ({placeholder, setValue, value}) => {

    const [dateText, setDateText] = useState(moment(value).format('Do MMM YY') || '');
  
    const handleChange = (value) => {
        if(!value) return;
        setDateText(moment(value).format('Do MMM YY'))
        setValue(moment(value).format())
    }
  
    useEffect(() => {
      setDateText(value ? moment(value).format('Do MMM YY') : '')
    }, [value])
  
    return(
        <div className = 'customDatePickerWidth' style = {{position: 'relative'}}>
            <DatePicker  
                onChange = {handleChange} 
                value = {value ? {day: moment(value).get('date'), month: moment(value).get('month'), year: moment(value).get('year')} : null}
                shouldHighlightWeekends
                className = 'w-100'
                popperClassName="calendar-popout"
                inputClassName = 'w-100'
                popperPlacement="top-end"
                popperModifiers={{
                  offset: { enabled: true, offset: '5px, 10px' },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport'
                  }
                }}
                renderInput = {
                    ({ref}) => 
                      <input 
                          placeholder = {placeholder}
                          value = {dateText} 
                          ref = {ref} 
                          className = 'form-control form-control-flush'
                          readOnly = "true"
                      />
                }
            />
        </div>
    )
}
  

export const DateFormRangeFlush = ({placeholder, setValue, value}) => {


    const [selectedDayRange, setSelectedDayRange] = useState({
        from: value.from ? convertISOToModern(value.from) : null,
        to: value.to ? convertISOToModern(value.to) : null,
    });

    useEffect(() => {
        if(selectedDayRange.from && selectedDayRange.to ){
            setValue({
                from: moment({
                    day: selectedDayRange.from.day,
                    month: selectedDayRange.from.month -1,
                    year: selectedDayRange.from.year,
                }).format(),
                to: moment({
                    day: selectedDayRange.to.day,
                    month: selectedDayRange.to.month -1,
                    year: selectedDayRange.to.year,
                }).format()
            })
        }
        // eslint-disable-next-line
    }, [selectedDayRange])
  
    return(
        <div className = 'customDatePickerWidth' style = {{position: 'relative'}}>
            <DatePicker  
                onChange = {setSelectedDayRange} 
                value = {selectedDayRange}
                shouldHighlightWeekends
                className = 'w-100'
                popperClassName="calendar-popout"
                inputClassName = 'w-100'
                popperPlacement="top-end"
                popperModifiers={{
                  offset: { enabled: true, offset: '5px, 10px' },
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false,
                    boundariesElement: 'viewport'
                  }
                }}
                renderInput = {
                    ({ref}) => 
                      <input 
                          placeholder = {placeholder}
                          value = {selectedDayRange.from ? ModernToIso(selectedDayRange.from) + ' - ' + ModernToIso(selectedDayRange.to) : ''} 
                          ref = {ref} 
                          className = 'form-control form-control-flush'
                          readOnly = "true"
                      />
                }
            />
        </div>
    )
}
  

const convertISOToModern = (date) => {
    return ({
        day: parseInt(moment(date).get('date')),
        month: parseInt(moment(date).get('month')) + 1,
        year: parseInt(moment(date).get('year')),
    })
}

const ModernToIso = (date) => {
    if(date){
        return moment({
            day: date.day,
            month: date.month -1,
            year: date.year
        }).format('DD MMM YY')
    } else{
        return ''
    }
}




export const InputMaskItemSm = ({error, setError, errorText, placeholder, mask , value, setValue}) => {
    return (
        <div className = ''>
            <InputMask mask={mask} value ={value} onChange = {e => {setError(false); setValue(e.target.value)}} maskChar = ''>
                {
                (inputProps) => (
                    <input className={`form-control form-control-sm ${error ? 'is-invalid' : ''} `} {...inputProps} type="text" placeholder = {placeholder} />
                )}
            </InputMask>
            <div className = 'invalid-feedback'>{errorText}</div>
        </div>
    )
  }