import {useState, useEffect, useContext} from 'react';
import {CheckBox} from '../../../../components/Forms'
import axios from '../../../../utilis/axios';
import { Store } from '../../../../store';
import { fetchUser } from '../../../../store/actions/auth';

const Notifications = () => {

    const {state, dispatch} = useContext(Store)
    const [notifications, setNotifications] = useState(state.user.data.notifConfig)


    useEffect(() => { 
        updateNotifications() 
        // eslint-disable-next-line
    }, [notifications])

    const updateNotifications = async () => {
        try{
            const res = await axios.put('/api/instructor/profile/userSettings', {user: {notifConfig: notifications}})
            fetchUser(res.data, dispatch)
        } catch(err){
            console.log(err)
        }
    }

    const editNotifs = (category, type, value) => {
        setNotifications(prev => ({...prev, [category]: {...prev[category], [type]: value}}))
    }


    return (
        <div>
            <div className="card shadow-sm border">
              <div className="card-header">
                <h4 class="card-header-title">Notifications</h4>
                <div className="btn btn-sm btn-white" onClick = {() => setNotifications(notifConfig)}>Enable all</div>
              </div>
              <div className="table-responsive">
                <table class="table table-sm table-nowrap card-table">
                    <thead>
                        <tr>
                        <th>Type</th>
                        <th class="text-center">Web</th>
                        <th class="text-center">SMS</th>
                        <th class="text-center">Email</th>
                        </tr>
                    </thead>
                    <tbody class="font-size-base">
                        <tr>
                            <td>Session Schedule</td>
                            <td class="text-center"><CheckBox value = {notifications.schedule.web} setValue = {(value) => editNotifs('schedule', 'web', value)}/></td>
                            <td class="text-center"><CheckBox value = {notifications.schedule.sms} setValue = {(value) => editNotifs('schedule', 'sms', value)}/></td>
                            <td class="text-center"><CheckBox value = {notifications.schedule.email} setValue = {(value) => editNotifs('schedule', 'email', value)}/></td>
                        </tr>
                        <tr>
                            <td>New Messages</td>
                            <td class="text-center"><CheckBox value = {notifications.messages.web} setValue = {(value) => editNotifs('messages', 'web', value)}/></td>
                            <td class="text-center"><CheckBox value = {notifications.messages.sms} setValue = {(value) => editNotifs('messages', 'sms', value)}/></td>
                            <td class="text-center"><CheckBox value = {notifications.messages.email} setValue = {(value) => editNotifs('messages', 'email', value)}/></td>
                        </tr>
                        <tr>
                            <td>Recommendations &amp; Special Offers</td>
                            <td class="text-center"><CheckBox value = {notifications.recommendations.web} setValue = {(value) => editNotifs('recommendations', 'web', value)}/></td>
                            <td class="text-center"><CheckBox value = {notifications.recommendations.sms} setValue = {(value) => editNotifs('recommendations', 'sms', value)}/></td>
                            <td class="text-center"><CheckBox value = {notifications.recommendations.email} setValue = {(value) => editNotifs('recommendations', 'email', value)}/></td>
                        </tr>
                    </tbody>
                </table>
              </div>
            </div>
        </div>
    )
}

export default Notifications;




const notifConfig = {
    schedule: { web: true, email: true, sms: true },
    messages: { web: true, email: true, sms: true },
    recommendations:  { web: true, email: true, sms: true },
}
