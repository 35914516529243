import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { loginUser } from '../../../services/auth'
import { loginUserByOtp } from '../../../services/auth'
import { loginFailure, loginSuccess } from '../../../store/actions/auth'
import { Store } from '../../../store'
import { FcGoogle } from 'react-icons/fc'
import { ImFacebook } from 'react-icons/im'
import cogoToast from 'cogo-toast'
import { validateEmail } from '../../../utilis/validation'
import axios from "../../../utilis/axios"
const Login = (props) => {

  const { dispatch } = useContext(Store);
  const [state, setState] = useState({ email: "", password: "" });
  const [userInputValue, setUserInputvalue] = useState({ userInput: "" })
  const [errors, setErrors] = useState([false, false])
  const [error, setError] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showOtpLoginModal, setShowOtpLoginModal] = useState(false);
  const [otp, setOtp] = useState({ otp: "" });
  const [seconds, setSeconds] = useState(0);



  const [resendActive, setResendActive] = useState(false)


  useEffect(() => {
    setErrors([false, false]);
    setError(false)
  }, [state, userInputValue, otp])


  const validate = () => {
    const newErrors = [false, false];
    if (!validateEmail(state.email)) newErrors[0] = true;
    if (state.password.length < 8) newErrors[1] = true;
    setErrors(newErrors)
    if (newErrors.includes(true)) return true;
    else return false;
  }

  const sendOtp = async (e) => {
    e.preventDefault()
    if (userInputValue.userInput) {
      const res = await axios.post('/api/auth/otp/send', { userInput: userInputValue.userInput });
      if (res.status == 200) {
        setResendActive(true);
        setSeconds(60)
        return true
      }
    }
  }

  const signInByOtp = async (e) => {
    e.preventDefault()
    // if(validate()) return;
    // if(userInputValue.userInput && otp.otp){
    //   const res = await axios.post('/api/auth/otp/login', {userInput: userInputValue.userInput, otp: otp.otp});
    //   console.log(res, "res")
    // if(res.status == 200){
    //   setOtpInputField(true)
    // }
    // }
    try {
      setLoading(true);
      const res = await loginUserByOtp({ userInput: userInputValue.userInput, otp: otp.otp })
      loginSuccess(res, dispatch);
      switch (res.role) {
        case 'student':
          if (props.location && props.location.query && props.location.query.message) {
            props.history.push({
              pathname: props.location.query.pathname,
              search: props.location.query.search,
              query: { instructor: props.location.query.instructor }
            })
          }
          else props.history.push('/my-dashboard');
          break;
        case 'instructor':
          props.history.push('/instructor'); break;
        case 'admin':
          props.history.push('/admin'); break;
        case 'super-admin':
          props.history.push('/super-admin'); break;
        default:
          break;
      }
      setLoading(false);

    } catch (err) {
      setLoading(false);
      loginFailure(dispatch);
      if (err.response.data.message) {
        cogoToast.error(err.response.data.message, { position: 'top-right' })
      }
      else setError(true);
    }
  }


  const signIn = async (e) => {
    e.preventDefault()
    if (validate()) return;
    try {
      setLoading(true);
      const res = await loginUser({ email: state.email.trim().toLowerCase(), password: state.password })
      loginSuccess(res, dispatch)
      switch (res.role) {
        case 'student':
          if (props.location && props.location.query && props.location.query.message) {
            props.history.push({
              pathname: props.location.query.pathname,
              search: props.location.query.search,
              query: { instructor: props.location.query.instructor }
            })
          }
          else props.history.push('/my-dashboard');
          break;
        case 'instructor':
          props.history.push('/instructor'); break;
        case 'admin':
          props.history.push('/admin'); break;
        case 'super-admin':
          props.history.push('/super-admin'); break;
        default:
          break;
      }
      setLoading(false);

    } catch (err) {
      setLoading(false);
      loginFailure(dispatch);
      if (err.response.data.message) {
        cogoToast.error(err.response.data.message, { position: 'top-right' })
      }
      else setError(true);
    }
  };

  const loginGoogle = () => {
    window.open(`${process.env.REACT_APP_WEBSITE}/api/auth/google`, "_self");
  }

  const loginFacebook = () => {
    window.open(`${process.env.REACT_APP_WEBSITE}/api/auth/facebook`, "_self");
  }

  const registerStudent = () => {
    if (props.location && props.location.query && props.location.query.message) {
      props.history.push({
        pathname: '/signup',
        query: {
          message: true,
          pathname: props.location.query.pathname,
          search: props.location.query.search,
          instructor: props.location.query.instructor
        }
      })
    }
    else props.history.push('/signup');
  }


  useEffect(() => {
    let interval;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [resendActive, seconds]);

  useEffect(() => {
    if (seconds <= 0) {
      setResendActive(false)
    }
  }, [seconds]);

  return (
    <div
      className="d-flex align-items-center bg-auth border-top border-top-2 border-primary"
      style={{ minHeight: "100vh" }}
    >
      <div className="container-fluid">
        <div className="row justify-content-center">

          <div className="col-12 col-md-6 col-lg-6 col-xl-6 align-self-center px-xl-6 my-5 ">
            <h1 className="display-4 text-center mb-3">Sign in</h1>
            <p className="text-muted text-center mb-5">
              Free access to our dashboard.
            </p>
            <form className='mx-xl-6'>
              {!showOtpLoginModal ?
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    className={`form-control ${errors[0] && 'is-invalid'}`}
                    value={state.email}
                    onChange={(e) => setState(prev => ({ ...prev, email: e.target.value }))}
                    name="email"
                  />
                  <div className='invalid-feedback'>Please enter a valid email address</div>
                  <div className="row mt-2" >
                    <div className="col">
                      <label>Password</label>
                    </div>
                    <div className='col-auto'>
                      <Link to="/forgot-password" className='form-text small btn-link pointer'>Forgot Password?</Link>
                    </div>
                  </div>
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control form-control-appended ${errors[1] && 'is-invalid'}`}
                      placeholder='Password'
                      value={state.password}
                      onChange={(e) => setState(prev => ({ ...prev, password: e.target.value }))}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                        {!showPassword ? (<AiOutlineEye className='pointer' size={15} />) : (<AiOutlineEyeInvisible className='pointer' size={15} />)}
                      </span>
                    </div>
                    <div className="invalid-feedback">Password should be minimum 8 char long</div>
                  </div>
                  {error && <div className='invalid-feedback d-block text-center mb-3 mt-n2'>Invalid credentials!</div>}
                </div> :
                <div className="form-group">
                  <label>Email Address/Phone Number</label>
                  <input
                    className={`form-control ${errors[0] && 'is-invalid'}`}
                    value={userInputValue.userInput}
                    onChange={(e) => setUserInputvalue(prev => ({ ...prev, userInput: e.target.value }))}
                    name="userInput"
                  />
                  <div className='invalid-feedback'>Please enter a valid email address or phone number</div>
                  <div className="d-flex justify-content-between align-items-center" >
                    <div className="text-right small mt-1">
                      {resendActive && seconds != 0 && (
                        `Resend your OTP in ${seconds} seconds`
                      )}
                    </div>
                    <button className="btn btn-primary mt-2 mb-3" style={{ float: "right" }} onClick={(e) => sendOtp(e)} disabled={resendActive}>
                      Send Otp
                    </button>
                  </div>
                  <div className="form-group">
                    <label>OTP</label>
                    <input
                      className={`form-control ${errors[0] && 'is-invalid'}`}
                      value={otp.otp}
                      onChange={(e) => setOtp(prev => ({ ...prev, otp: e.target.value }))}
                      name="otp"
                      disabled={!resendActive}
                    />
                    <div className='invalid-feedback'>Enter OTP</div>
                  </div>
                </div>
              }
              {showOtpLoginModal ?
                <button className="btn btn-lg btn-block btn-primary mt-2 mb-3" onClick={(e) => signInByOtp(e)} disabled={!resendActive}>
                  <span className={loading ? `spinner-border spinner-border-sm mr-2` : ''}></span>
                  Sign in
                </button> :
                <button className="btn btn-lg btn-block btn-primary mt-2 mb-3" onClick={(e) => signIn(e)}>
                  <span className={loading ? `spinner-border spinner-border-sm mr-2` : ''}></span>
                  Sign in
                </button>
              }
              <div className="text-center" >
                <small className="text-muted text-center">
                  Don't have an account yet?{" "}
                  <span onClick={registerStudent}>Register</span>.
                </small>
              </div>
              {!showOtpLoginModal ?
                <div className="text-center mt-3" onClick={() => setShowOtpLoginModal(true)}>
                  <span className='form-text fw-bold btn-link pointer'>Sign In with OTP</span>
                </div> : <div className="text-center mt-3" onClick={() => setShowOtpLoginModal(false)}>
                  <span className='form-text fw-bold btn-link pointer'>Sign In with Password</span>
                </div>}
              <hr />

            </form>
            <div className='mx-xl-6 mt-5 d-flex align-items-center justify-content-center'>
              <div onClick={loginGoogle} className="btn btn-outline-secondary mt-2 mb-3 d-inline-flex align-items-center justify-content-center mr-3">
                <FcGoogle class='h2 mb-0 mr-2' /> Sign in with google
              </div>
              <div onClick={loginFacebook} className="btn btn-outline-secondary mt-2 mb-3 d-inline-flex align-items-center justify-content-center">
                <ImFacebook class='h2 mb-0 mr-2' style={{ color: '#3B5998' }} /> Sign in with Facebook
              </div>
              {/* <div onClick={() => setShowOtpLoginModal(true)} className="btn btn-outline-secondary mt-2 mb-3 d-inline-flex align-items-center justify-content-center ml-3">
                <b>Sign in with OTP</b>
              </div> */}
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 col-xl-6 d-none d-lg-block align-self-center">
            <div class="text-center">
              <img src="https://dhunguru-client-images.s3.ap-south-1.amazonaws.com/login.svg" alt="..." class="img-fluid" style={{ height: '400px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
