import React, { useState, useEffect } from "react";
import MyDropzoneImage from "../../../../../components/Dropzone/MyDropzoneImage";
import MyDropzone from "../../../../../components/Dropzone/MyDropzone";
import instructor_content from "../../../../../content/instructor_content.json";

const Basics = ({
  setCourse,
  course,
  headerImg,
  setHeaderImg,
  saveCourse,
  loading,
  videos,
  setVideos,
  photos,
  setPhotos,
}) => {
  const [errors, setErrors] = useState(Array(6).fill(false));

  useEffect(() => {
    setErrors(Array(6).fill(false));
  }, [
    course.instrument,
    course.title,
    course.details,
    photos,
    videos,
    course.headerImg,
    course.photos,
    course.videos,
  ]);

  const validate = () => {
    const new_errors = Array(6).fill(false);
    if (course.details === "") new_errors[1] = true;
    if (course.title === "") new_errors[2] = true;
    if (!course.headerImg && (!headerImg || headerImg.length === 0))
      new_errors[3] = true;
    if (course.photos.length + photos.length > 5) new_errors[4] = true;
    if (course.videos.length + videos.length > 1) new_errors[5] = true;
    setErrors(new_errors);
    if (new_errors.includes(true)) return false;
    else return true;
  };

  const save = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    saveCourse();
  };

  return (
    <div>
      <div class="mb-6">
        <CourseLevel
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseDetails
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseImage
          headerImg={headerImg}
          setHeaderImg={setHeaderImg}
          course={course}
          errors={errors}
          setErrors={setErrors}
        />
        <CourseMapping course={course} setCourse={setCourse} />
        <Images
          photos={photos}
          setPhotos={setPhotos}
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
        <Videos
          videos={videos}
          setVideos={setVideos}
          course={course}
          setCourse={setCourse}
          errors={errors}
          setErrors={setErrors}
        />
      </div>

      <div className="row align-items-center mb-5">
        <div className="col-4">
          <button
            class="btn btn-block btn-lg btn-primary mb-6"
            disabled={loading}
            onClick={(e) => save(e)}
          >
            {loading && (
              <span class="spinner-border spinner-border-sm mr-2"></span>
            )}
            Save
          </button>
        </div>
        <div className="col-auto"></div>
      </div>
    </div>
  );
};

export default Basics;

const CourseLevel = ({ course, setCourse, errors, setErrors }) => {
  return (
    <div class="">
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.subject_title.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.subject_title.description}
        </div>
        <input
          class={errors[2] ? "form-control is-invalid" : "form-control"}
          placeholder=""
          value={course.title}
          onChange={(e) => {
            setErrors((prev) => [prev[0], prev[1], false]);
            setCourse((prev) => ({ ...prev, title: e.target.value }));
          }}
        />
        <div class="invalid-feedback">
          {instructor_content.course.basics.subject_title.error}
        </div>
      </div>
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.comfort_level.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.comfort_level.description}
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Beginner</div>
          <div className="col-5 col-lg-3">
            <select
              class="form-control form-control-sm"
              value={course.level.beginner}
              onChange={(e) => {
                console.log(e.target.value);
                setCourse((prev) => ({
                  ...prev,
                  level: { ...prev.level, beginner: e.target.value },
                }));
              }}
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Intermediate</div>
          <div className="col-5 col-lg-3">
            <select
              class="form-control form-control-sm"
              value={course.level.intermediate}
              onChange={(e) =>
                setCourse((prev) => ({
                  ...prev,
                  level: { ...prev.level, intermediate: e.target.value },
                }))
              }
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-4 col-lg-2">Advanced</div>
          <div className="col-5 col-lg-3">
            <select
              class="form-control form-control-sm"
              value={course.level.advance}
              onChange={(e) =>
                setCourse((prev) => ({
                  ...prev,
                  level: { ...prev.level, advance: e.target.value },
                }))
              }
            >
              <option value="Very Comfortable">Very Comfortable</option>
              <option selected value="Comfortable">
                Comfortable
              </option>
              <option value="Not Comfortable">Not Comfortable</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

const CourseDetails = ({ course, setCourse, errors, setErrors }) => {
  return (
    <div class="">
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.details.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.details.description}
        </div>
        <textarea
          class={errors[1] ? "form-control is-invalid" : "form-control"}
          placeholder={instructor_content.course.basics.details.placeholder}
          style={{ minHeight: "150px" }}
          value={course.details}
          onChange={(e) => {
            setErrors((prev) => [prev[0], false]);
            setCourse((prev) => ({ ...prev, details: e.target.value }));
          }}
        ></textarea>
        <div class="invalid-feedback ">
          {instructor_content.course.basics.details.error}
        </div>
      </div>
    </div>
  );
};

const CourseImage = ({
  headerImg,
  setHeaderImg,
  course,
  errors,
  setErrors,
}) => {
  return (
    <div class="">
      <div className="row mb-5 pb-2">
        <div className="col-5">
          <div className="form-group">
            <label class="bold mb-1">
              {instructor_content.course.basics.image.title}
            </label>
            <div class="text-muted small mb-3">
              {instructor_content.course.basics.image.description}
            </div>
          </div>
        </div>
        <div className="col-3">
          <div style={{ width: "200px" }}>
            <MyDropzoneImage
              file={headerImg}
              onFileChange={(file) => setHeaderImg(file)}
              height="200px"
              link={course.headerImg}
            />
          </div>
        </div>
        {errors[3] && (
          <div class="invalid-feedback d-block">
            {instructor_content.course.basics.image.error}
          </div>
        )}
      </div>
    </div>
  );
};

const CourseMapping = ({ course, setCourse }) => {
  return (
    <div>
      <div className="form-group mb-5 pb-2">
        <label class="bold mb-1">
          {instructor_content.course.basics.speciality.title}
        </label>
        <div class="text-muted small mb-3">
          {instructor_content.course.basics.speciality.description}
        </div>
        <input
          class="form-control"
          value={course.speciality}
          placeholder={instructor_content.course.basics.speciality.placeholder}
          onChange={(e) =>
            setCourse((prev) => ({ ...prev, speciality: e.target.value }))
          }
        />
      </div>
    </div>
  );
};

const Images = ({
  photos,
  setPhotos,
  course,
  setCourse,
  errors,
  setErrors,
}) => {
  const deleteImg = (index) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
  };

  const deleteSavedImage = (index) => {
    const newPhotos = [...course.photos];
    newPhotos.splice(index, 1);
    setCourse((prev) => ({ ...prev, photos: newPhotos }));
  };

  return (
    <div className="form-group mb-6 mt-6 pb-2">
      <label class="bold mb-1">
        {instructor_content.course.basics.photos.title}
      </label>
      <div class="text-muted small mb-3">
        {instructor_content.course.basics.photos.description}
      </div>
      <MyDropzone
        files={photos}
        onFileChange={(data) => setPhotos(data)}
        height="100px"
        isMulti={true}
        type="image/jpeg, image/png"
      />
      <div className="row no-gutters mt-4">
        {course.photos &&
          course.photos.length > 0 &&
          course.photos.map((item, index) => (
            <div className="col-auto pr-3">
              <div class="d-flex align-items-start">
                <img
                  alt=""
                  class="rounded"
                  src={item.url}
                  style={{ height: "50px", width: "50px", objectFit: "cover" }}
                />
                <div
                  class="close1 mt-n3"
                  onClick={() => deleteSavedImage(index)}
                >
                  &times;
                </div>
              </div>
            </div>
          ))}
        {photos.map((item, index) => (
          <div className="col-auto pr-2">
            <div class="d-flex align-items-start">
              <img
                alt=""
                class="rounded"
                src={URL.createObjectURL(item)}
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
              />
              <div class="close1 mt-n3" onClick={() => deleteImg(index)}>
                &times;
              </div>
            </div>
          </div>
        ))}
      </div>
      {errors[4] && (
        <div class="invalid-feedback d-block mt-4">
          You cant add more than 5 photos
        </div>
      )}
    </div>
  );
};

const Videos = ({
  videos,
  setVideos,
  course,
  setCourse,
  errors,
  setErrors,
}) => {
  const deleteVideos = (index) => {
    const newVideos = [...videos];
    newVideos.splice(index, 1);
    setVideos(newVideos);
  };

  const deleteSavedVideos = (index) => {
    const newVideos = [...course.videos];
    newVideos.splice(index, 1);
    setCourse((prev) => ({ ...prev, videos: newVideos }));
  };

  return (
    <div className="form-group mb-5 pb-2">
      <label class="bold mb-1">
        {instructor_content.course.basics.videos.title}
      </label>
      <div class="text-muted small mb-3">
        {instructor_content.course.basics.videos.description}
      </div>
      <MyDropzone
        files={videos}
        onFileChange={(data) => setVideos(data)}
        height="100px"
        isMulti={false}
        type=".mp4"
      />
      <div className="row no-gutters mt-4">
        {course.videos &&
          course.videos.length > 0 &&
          course.videos.map((item, index) => (
            <div className="col-auto pr-2">
              <div class="d-flex align-items-start">
                <video
                  class="rounded"
                  style={{ height: "50px", width: "50px", objectFit: "cover" }}
                >
                  <source src={item.url} />
                </video>
                <div
                  class="close1 mt-n3"
                  onClick={() => deleteSavedVideos(index)}
                >
                  &times;
                </div>
              </div>
            </div>
          ))}
        {videos.map((item, index) => (
          <div className="col-auto pr-2">
            <div class="d-flex align-items-start">
              <video
                class="rounded"
                style={{ height: "50px", width: "50px", objectFit: "cover" }}
              >
                <source src={URL.createObjectURL(item)} />
              </video>
              <div class="close1 mt-n3" onClick={() => deleteVideos(index)}>
                &times;
              </div>
            </div>
          </div>
        ))}
      </div>
      {errors[5] && (
        <div class="invalid-feedback d-block mt-4">
          You cant add more than 1 video
        </div>
      )}
    </div>
  );
};
