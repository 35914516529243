import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import axios from '../../../../utilis/axios';
import cogoToast from 'cogo-toast'
import Swal from 'sweetalert2'


const EditEnrollment = ({show, setShow, enrollmentId, enrollments, setEnrollments}) => {

    const [enrollment, setEnrollment] = useState()
    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState('')
    const [cordinator, setCordinator] = useState()
    const [changes, setChanges] = useState([])
    const [errors, setErrors] = useState(Array(10).fill(false))

    useEffect(() => {
        const index = enrollments.findIndex(item => item._id === enrollmentId)
        setEnrollment(enrollments[index])
        setChanges(enrollments[index].payment_details.map(item => ({receipt: item.receipt, classes: 0, price_per_class: item.price, total_price: item.total_price})))
        setComment('')
        setCordinator(enrollments[index].cordinator)
    }, [enrollmentId, enrollments, show])


    const validate = () => {
        const new_error = Array(10).fill(false)
        // console.log(new_error)
        if(comment.length === 0) new_error[0] = true;
        for(let i=0; i<changes.length; i++){
            const index = enrollment.payment_details.findIndex(item => item.receipt === changes[i].receipt);
            const classes_left = enrollment.payment_details[index].num_classes - (enrollment.payment_details[index].classes_completed + enrollment.payment_details[index].classes_requested + enrollment.payment_details[index].classes_scheduled + enrollment.payment_details[index].classes_late_charged)
            if(!(changes[i].classes >= (-1*classes_left))) new_error[2*i+(1)] = true;
            if(changes[i].price_per_class < 50) new_error[2*i+2] = true;
        }
        if(new_error.includes(true)) return false;
        else return true;
    }



    const saveChanges = async () => {
        if(!validate()) {
            alert("Please make sure all fields are filled correctly");
            return
        }
        try{
            const response = await Swal.fire({
                title: 'Are you sure you want to edit the enrollment',
                text: 'This action cannot be undone.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, edit it!'
            });
            if(response.isConfirmed){
                setLoading(true)
                await axios.put(`/api/admin/enrollment/${enrollmentId}`, {changes, comment, cordinator})
                cogoToast.success("Enrollment successfully edited")
                const newEnrollments = [...enrollments];
                const index = newEnrollments.findIndex(item => item._id === enrollmentId);
                for(let i=0; i<newEnrollments[index].payment_details.length; i++){
                    const payment = newEnrollments[index].payment_details[i]
                    const index2 = changes.findIndex(item => item.receipt === payment.receipt);
                    const delta_classes = parseInt(changes[index2].classes);
                    const new_price = changes[index2].price_per_class
                    const total_price = changes[index2].total_price
                    newEnrollments[index].payment_details[i].price = new_price
                    newEnrollments[index].payment_details[i].total_price = total_price
                    newEnrollments[index].payment_details[i].num_classes = newEnrollments[index].payment_details[i].num_classes + (delta_classes)
                    newEnrollments[index].num_classes = newEnrollments[index].num_classes + (delta_classes)
                    newEnrollments[index].payment_details[i].classes_left = newEnrollments[index].payment_details[i].classes_left + (delta_classes)
                    newEnrollments[index].classes_left = newEnrollments[index].classes_left + (delta_classes)
                    if(cordinator) newEnrollments[index].cordinator = cordinator
                }
                setEnrollments(newEnrollments);
                setLoading(false)
                setShow(false)
            }
        } catch(err){
            console.log(err)
            setLoading(false)
        }
    }


    const editPayment = (value, type, receipt) => {
        const newChanges = [...changes];
        const index = newChanges.findIndex(item => item.receipt === receipt);
        newChanges[index][type] = value;
        setChanges(newChanges)
    }

    return (
        <Modal show = {show} onHide = {() => setShow(false)} centered>
            {(enrollment && enrollment.payment_details) && <div className = 'modal-content' >
                <div className = 'modal-body '>
                <div class = 'close pointer' onClick = {() => setShow(false)}>&times;</div>
                    <div class = 'mb-4 text-center px-lg-5 '>
                        <div className = 'h2 mb-1'>Edit Enrollment</div>
                    </div>

                    <form class = 'px-lg-4'>  
                        {enrollment.payment_details.filter(item => item.status !== 'not-paid').map(item => <div class = 'bg-light rounded p-4 mb-3'>
                            <h4 class = 'mb-4'>Receipt Id:{item.receipt}</h4>
                            <div class= 'mb-3'>Total Classes: <strong>{item.num_classes}</strong></div>
                            <div class= 'mb-3' >Classes left to be requested: <strong>{item.num_classes - (item.classes_completed + item.classes_requested + item.classes_scheduled + item.classes_late_charged)}</strong></div>
                            
                            <div class = 'mt-4 row'>
                                <div class = 'col-6'>
                                    <div class = 'form-group mb-0'>
                                        <label>Classes you want to add?</label>
                                        <input 
                                            className = 'form-control'
                                            type = "number"
                                            value = {changes.filter(item2 => item2.receipt === item.receipt)[0].classes}
                                            onChange = {e => editPayment(e.target.value, 'classes', item.receipt)}
                                        />
                                    </div>
                                </div>
                                <div class = 'col-6'>
                                    <div class = 'form-group mb-0'>
                                        <label>Price per class?</label>
                                        <input 
                                            className = 'form-control'
                                            type = "number"
                                            value = {changes.filter(item2 => item2.receipt === item.receipt)[0].price_per_class}
                                            onChange = {e => editPayment(e.target.value, 'price_per_class', item.receipt)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class = 'mt-4 row'>
                                <div class = 'col-6'>
                                    <div class = 'form-group mb-0'>
                                        <label>Total Price?</label>
                                        <input 
                                            className = 'form-control'
                                            type = "number"
                                            value = {changes.filter(item2 => item2.receipt === item.receipt)[0].total_price}
                                            onChange = {e => editPayment(e.target.value, 'total_price', item.receipt)}
                                        />
                                    </div>
                                </div>
                                </div>
                        </div>)}

                        <div class = 'form-group mt-4'>
                            <label>Comments</label>
                            <input className = 'form-control' value = {comment} onChange = {e => setComment(e.target.value)}/>
                        </div>
                       
                        <div class = 'form-group mt-4'>
                            <label>Current Cordinator</label>
                            <input className = 'form-control' value = {cordinator} onChange = {e => setCordinator(e.target.value)}/>
                        </div>
                        
                        <div class = "btn btn-primary btn-block mb-4" onClick = {() => saveChanges()}>
                            {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                            Edit Enrollment
                        </div>
                    </form>
                </div>
            </div>}
        </Modal>
    )
}

export default EditEnrollment


