import React, {useState, useContext, useEffect} from 'react'
import DashboardLayout from '../index'
import { Store } from '../../../../store'
import axios from '../../../../utilis/axios';
import {getDistinct} from '../../../../utilis/helpers'
import { saveEnrollment } from '../../../../store/actions/student'
import Skeleton from 'react-loading-skeleton'
import student_content from '../../../../content/student_content.json'
import moment from 'moment'

const AllResources = () => {

    const {state, dispatch} = useContext(Store)
    const [tab, setTab] = useState();
    const [instructors, setInstructors] = useState();
    const [resources, setResources] = useState([]);


    useEffect(() => {
        if(state.student.enrollments && state.student.enrollments.length >  0){
            formatEnrollments(state.student.enrollments)
        } else fetchEnrollments(state.student.enrollments)
        // eslint-disable-next-line
    }, [])


    const fetchEnrollments = async () => {
        try{
            const res = await axios.get('/api/student/enrollments');
            formatEnrollments(res.data)
            saveEnrollment(res.data, dispatch)
            
        } catch(err){
            console.log(err)
        }
    }


    const formatEnrollments = (data) => {
        let paidData = data.filter(item => item.type === 'paid');
        const newInstructors = getDistinct(paidData.map(item => ({...item.instructor, course: item.course.title, _id: item.course._id})), '_id')

        const newResources = {};
        for(let i=0; i< paidData.length; i++){
            newResources[paidData[i].course._id] = paidData[i].resources
        }
        setInstructors(newInstructors)
        setResources(newResources)
        setTab(newInstructors.length > 0 ? newInstructors[0]._id : '')
    }



    return (
        <DashboardLayout>
            <div className = "px-lg-6 mx-lg-6 px-3 mx-3 mt-4">
                <ActivityCard />
                <div className = 'row mt-6'>
                    {(instructors && instructors.length>0) && <div className = 'col-12 col-lg-3'>
                        <InstructorNav tab = {tab} setTab = {setTab} data = {instructors}/>
                    </div>}
                    <div className = 'col'>
                        <Resources resources = {instructors ? (resources[tab] ? resources[tab]: []) : null}/>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default AllResources


const ActivityCard = () => {
    return (
        <div className = 'card '>
            <div className="card-body py-5">
                <div className="row align-items-center">
                  <div className="col-12 col-md-4 order-md-2">
                    <div className="text-center">
                      <img src="/assets/study_material.svg" alt="..." class="img-fluid mt-n5 mt-lg-0 mb-4 mr-md-n5"  />
                    </div>
                  </div>
                  <div className="col-12 col-md-8  px-4 py-3 order-md-1">
                    <h1 class = 'display-3'>{student_content.resources.title}</h1>
                    <p class="text-muted lead">{student_content.resources.description}</p>
                  </div>
                </div> 
              </div>
        </div>
    )
}



const InstructorNav = ({data, setTab, tab}) => {
    return (
        <div>
            <ul class="list-group list-group-active shadow-sm bg-white rounded mt-0">
                {data ? (data.length > 0 ? data.map(item => <li class={`list-group-item list-group-item-action pointer ${tab === item._id ? 'active' : ''}`} onClick = {() => setTab(item._id)}>
                    <div>
                        <div class = 'row no-gutters'>
                            <div class = 'col-auto'>
                                <div class = 'avatar avatar-sm'>
                                    <img alt="" class = 'avatar-img rounded-circle' src = {item.avatar}/>
                                </div>
                            </div>
                            <div class = 'col pl-3'>
                                {item.firstName + ' ' + item.lastName}
                                <div class = {`small text-${tab === item._id ? 'white': 'secondary'}`}>{item.course}</div>
                            </div>
                        </div>
                        </div>
                </li>) : '') : <LoadingInstructorNav />}
            </ul>
        </div>

    )
}


const Resources = ({resources}) => {

 
    return (
        <div>
            <div class = 'h3 mt-5 mt-md-0'>{student_content.resources.checkout}</div>
            {resources ? (resources.length > 0 ? (<div className="list-group bg-white">
                {resources.map(item => (<ResourceItem data = {item} />))}
            </div>) :  <NoResources />): <LoadingResources />}
        </div>
    )
}




const ResourceItem = ({data}) => {


    return (
        <div className="list-group-item py-4">
            <div className="row align-items-center">
                <div class = 'col-auto'>
                    <img src = "https://media.kasperskydaily.com/wp-content/uploads/sites/92/2020/01/14073112/36C3-PDF-digital-signature-featured-1.jpg" alt = "" class = 'img-fluid rounded' style = {{height: '80px', width: '60px', objectFit: 'cover'}} />
                </div>
                <div className="col-12 col-md ml-n2 mb-4 mb-md-0 d-flex align-items-center">
                    <div class = 'ml-3'>
                    <h4 class="text-body text-focus mb-1 name lead">{data.title}</h4>
                    <p class=" text-muted mb-0">{data.description}</p>
                    <div class ="small text-dark mt-2">Shared on {moment(data.date).format('Do MMM YY')}</div>
                    
                    </div>
                </div>
                <div className = 'col-4 mx-lg-3 px-lg-5 col-md-auto text-center'>
                    <a href = {data.url} class="mb-0 btn btn-primary">Click here</a>
                </div>
            </div>
        </div>
    )
}


const LoadingInstructorNav = () => {
    return (
        <ul class="list-group list-group-active shadow-sm bg-white rounded mt-0">
            <li class='list-group-item'>
                <Skeleton height = {50} />
            </li>
            <li class='list-group-item'>
                <Skeleton height = {50} />
            </li>
            <li class='list-group-item'>
                <Skeleton height = {50} />
            </li>
        </ul>
    )
}



const NoResources = () => {
    return(
        <div className="card mb-0">
            <div class='card-body d-flex align-items-center justify-content-center text-uppercase text-muted h3 py-6 mb-0'>
                {student_content.resources.no_recording}
            </div>
        </div>
    )
}

const LoadingResources = () => {
    return (
        <div className="">
            <Skeleton height = {70} />
            <div class = 'mb-3'></div>
            <Skeleton height = {70} />
            <div class = 'mb-3'></div>
            <Skeleton height = {70} />
            <div class = 'mb-3'></div>
        </div>
    )
}

