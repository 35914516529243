import React, { useState, useEffect, useRef, useContext } from 'react'
import DashboardLayout from '../index'
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import Scheduled from './Scheduled'
import Requests from './Requests'
import Completed from './Completed'
import Cancelled from './Cancelled'
import LateCancelled from './LateCancelled'
import axios from '../../../../utilis/axios';
import { saveRequests, saveSchedule } from '../../../../store/actions/instructor';
import { Store } from '../../../../store';
import { AiFillFilter } from 'react-icons/ai';
import { getDistinct } from '../../Student/Messages';
import { InputMaskItemSm } from '../../../../components/Forms';
import moment from 'moment'

const Schedule = (props) => {

    const { state, dispatch } = useContext(Store)
    const [tab, setTab] = useState('scheduled');
    const [selectedDay, setSelectedDay] = useState();
    const [scheduled, setScheduled] = useState()
    const [requests, setRequests] = useState()
    const [filteredSchedule, setFilteredSchedule] = useState();
    const [filteredRequests, setFilteredRequests] = useState();
    const [filters, setFilters] = useState({ student: '*', type: '*' })
    const [allStudents, setAllStudents] = useState([])

    useEffect(() => {
        if (state.instructor.requests && state.instructor.requests.length > 0) setRequests(state.instructor.requests)
        if (state.instructor.schedule && state.instructor.schedule.length > 0) setScheduled(state.instructor.schedule)
        fetchRequests()
        fetchSchedule()
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        if (state.instructor.schedule) setScheduled(state.instructor.schedule)
        // eslint-disable-next-line
    }, [state.instructor.schedule])

    useEffect(() => {
        if (state.instructor.requests) setRequests(state.instructor.requests)
        // eslint-disable-next-line
    }, [state.instructor.requests])


    const fetchSchedule = async () => {
        try {
            const res = await axios.get('/api/instructor/schedule');
            saveSchedule(res.data, dispatch)

        } catch (err) {
            console.log(err)
        }
    }

    const fetchRequests = async () => {
        try {

            const res = await axios.get('/api/instructor/schedule/request');
            saveRequests(res.data, dispatch)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (requests && Array.isArray(requests)) {
            let data = [...requests]
            if (filters.student && filters.student.length > 5) {
                data = data.filter(item => item.student._id === filters.student)
            }
            if (filters.type && filters.type.length > 3) {
                data = data.filter(item => item.type === filters.type)
            }
            setFilteredRequests(data)
        }
        // eslint-disable-next-line
    }, [requests, filters])

    useEffect(() => {
        if (scheduled && Array.isArray(scheduled)) {
            let data = [...scheduled]
            if (filters.student && filters.student.length > 5) {
                data = data.filter(item => item.student._id === filters.student)
            }
            if (filters.type && filters.type.length > 3) {
                data = data.filter(item => item.type === filters.type)
            }
            setFilteredSchedule(data)
            if (Array.isArray(requests)) setAllStudents(getDistinct([...scheduled.map(item => item.student), ...requests.map(item => item.student)]))
            else setAllStudents(getDistinct(scheduled.map(item => item.student)))
        }
        // eslint-disable-next-line
    }, [scheduled, filters])



    return (
        <DashboardLayout
            showTitle={false}
            {...props}
        >
            <div className='mt-2'>
                <div className='row justify-content-center'>

                    <div className='col-12 col-xl-11'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 class="header-pretitle">Overview</h6>
                                        <h1 class="header-title"> Manage your sessions</h1>
                                    </div>
                                    <div class="col-auto">
                                        <Filters
                                            filters={filters}
                                            setFilters={setFilters}
                                            allStudents={allStudents}
                                            selectedDay={selectedDay}
                                            setSelectedDay={setSelectedDay}
                                        />
                                    </div>
                                </div>
                                <AccountNav tab={tab} setTab={setTab} />

                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                {tab === 'completed' && <Completed
                                    selectedDay={selectedDay}
                                    scheduled={filteredSchedule}
                                    setScheduled={setFilteredSchedule}
                                />}
                                {tab === 'requests' && <Requests
                                    selectedDay={selectedDay}
                                    requests={filteredRequests}
                                    setRequests={setFilteredRequests}
                                />}
                                {tab === 'scheduled' && <Scheduled
                                    selectedDay={selectedDay}
                                    scheduled={filteredSchedule}
                                    setScheduled={setFilteredSchedule}
                                />}
                                {tab === 'cancelled' && <Cancelled
                                    selectedDay={selectedDay}
                                    scheduled={filteredSchedule}
                                    setScheduled={setFilteredSchedule}
                                />}
                                {tab === 'late' && <LateCancelled
                                    selectedDay={selectedDay}
                                    scheduled={filteredSchedule}
                                    setScheduled={setFilteredSchedule}
                                />}
                            </div>
                            <div class="col-auto d-none d-lg-block">
                                <Calendar
                                    value={convertISOToModern(selectedDay)}
                                    onChange={(e) => setSelectedDay(ModernToIso(e))}
                                    shouldHighlightWeekends
                                />
                                {selectedDay && <div class='btn-link pointer text-center mt-3' onClick={() => setSelectedDay()}>Clear Date</div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </DashboardLayout>
    )
}

export default Schedule



const AccountNav = ({ tab, setTab }) => {
    return (
        <div className="row align-items-center">
            <div className="col">
                <ul class="nav nav-tabs nav-overflow header-tabs">
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'completed' && 'active'}`} onClick={() => setTab('completed')}>Completed Sessions</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'scheduled' && 'active'}`} onClick={() => setTab('scheduled')}>Scheduled Sessions</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'requests' && 'active'}`} onClick={() => setTab('requests')}>Requested Sessions</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'cancelled' && 'active'}`} onClick={() => setTab('cancelled')}>Cancelled Sessions</div>
                    </li>
                    <li class="nav-item">
                        <div class={`nav-link pointer ${tab === 'late' && 'active'}`} onClick={() => setTab('late')}>Late Cancelled Sessions</div>
                    </li>
                </ul>
            </div>
        </div>
    )
}


const Filters = ({ filters, setFilters, allStudents, selectedDay, setSelectedDay }) => {

    const dropdown = useRef(null);
    const [showFilters, setShowFilters] = useState(false)
    const [student, setStudent] = useState('*')
    const [type, setType] = useState('*')
    const [date, setDate] = useState(selectedDay)
    const [numFilters, setNumFilters] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        setDate(selectedDay)
    }, [selectedDay])

    const applyFilters = () => {
        setFilters({ student: student, type: type })
        let i = 0;
        if (date && date !== '') {
            if (date.length !== 10 || !moment(date, 'DD/MM/YYYY').isValid()) {
                setError(true);
                return;
            }
            i++
            setSelectedDay(date)
        }
        if (type !== '*') i++
        if (student !== '*') i++
        setShowFilters(false)
        setNumFilters(i)
    }

    const clearFilters = () => {
        setFilters({ student: '*', type: '*' })
        setSelectedDay(null);
        setDate('')
        setType("*")
        setStudent("*")
        setNumFilters(0)
        setShowFilters(false)
    }


    const handleClickOutside = event => {
        if (dropdown.current && !dropdown.current.contains(event.target)) {
            setShowFilters(false)
        }
    };

    // eslint-disable-next-line
    useEffect(() => document.addEventListener("mousedown", handleClickOutside), [])


    return (
        <div className="dropdown" ref={dropdown}>
            <div class='btn btn-white d-flex border align-items-center' onClick={() => setShowFilters(prev => !prev)}>
                <AiFillFilter class='mr-2' />Filters ({numFilters})
            </div>
            <form className={`dropdown-menu dropdown-menu-right dropdown-menu-card ${showFilters ? 'show' : ''}`} >
                <div className="card-header">
                    <h4 className="card-header-title">
                        Filters
                    </h4>
                    <div className="btn btn-sm btn-link text-reset" onClick={(e) => clearFilters(e)}>
                        <small>Clear filters</small>
                    </div>
                </div>
                <div className="card-body">
                    <div className="list-group list-group-flush mt-n4 mb-4">
                        <div className="list-group-item border-bottom d-block d-lg-none">
                            <div class='row align-items-center'>
                                <div class='col'>
                                    Date
                                </div>
                                <div class='col-auto'>
                                    <InputMaskItemSm
                                        error={error}
                                        setError={(val) => setError(val)}
                                        errorText={'Please enter a valid date'}
                                        placeholder={'DD/MM/YYYY'}
                                        mask={'99/99/9999'}
                                        value={date}
                                        setValue={(e) => setDate(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="list-group-item border-bottom">
                            <div class='row align-items-center'>
                                <div class='col'>
                                    Member
                                </div>
                                <div class='col-auto'>
                                    <select className="custom-select custom-select-sm" value={student} onChange={e => setStudent(e.target.value)}>
                                        <option value="*">Any</option>
                                        {allStudents.map(item => <option value={item._id}>{item.firstName + ' ' + item.lastName}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="list-group-item border-bottom">
                            <div class='row align-items-center'>
                                <div class='col'>
                                    Session Type
                                </div>
                                <div class='col-auto'>
                                    <select className="custom-select custom-select-sm" value={type} onChange={e => setType(e.target.value)}>
                                        <option value="*">Any</option>
                                        <option value="paid">Paid</option>
                                        <option value="demo">Assessment</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn btn-block btn-primary" type="submit" onClick={() => applyFilters()}>
                        Apply
                    </div>
                </div>
            </form>
        </div>

    )
}




const convertISOToModern = (date) => {
    if (date && date.length === 10) {
        return ({
            day: parseInt(moment(date, 'DD/MM/YYYY').get('date')),
            month: parseInt(moment(date, 'DD/MM/YYYY').get('month')) + 1,
            year: parseInt(moment(date, 'DD/MM/YYYY').get('year')),
        })
    } else {
        return ''
    }
}

const ModernToIso = (date) => {
    if (date) {
        return moment({
            day: date.day,
            month: date.month - 1,
            year: date.year
        }).format('DD/MM/YYYY')
    } else {
        return ''
    }
}