import { useState, useEffect } from 'react';
import axios from '../../../../utilis/axios';
import moment from 'moment'
import Table from '../../../../components/Table'

const Earnings = () => {

  const [invoices, setInvoices] = useState([]);
  const [totalEarning, setTotalEarning] = useState()
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    fetchInvoices();
  }, [])

  const fetchInvoices = async () => {
    try {
      const res = await axios.get('/api/instructor/invoices/class_wise');
      if (res.data) {
        console.log(res.data)
        setInvoices(res.data.map(item => ({
          invoiceId: item.receipt_id,
          date: { value: moment(item.date).unix(), label: moment(item.date).format('Do MMM YY') },
          amount: parseFloat(item.amount * 0.8).toFixed(2),
          student: item.schedule.student.firstName + ' ' + item.schedule.student.lastName,
          bookingId: item.schedule.bookingId,
          enrollmentId: item.enrollment?.enrollmentId,
          // commission: item.commission? item.commission: process.env.REACT_APP_DHUNGURU_PERCENT
        })))
        setTotalEarning(parseInt(res.data.reduce((a, b) => a + parseInt(b.amount) * 0.8, 0)))
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="card shadow-sm border">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col">
                  <h6 class="text-uppercase text-muted mb-2">Total</h6>
                  <span class="h2 mb-0">₹ {totalEarning}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Table
        data={invoices}
        isSelectable={false}
        headers={headers}
        isSearchable={true}
        handleRowClick={(id) => { console.log(id) }}
        isHeader={true}
        isFooter={true}
        isSortable={true}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        filters={[]}
        isDownloadable={true}
        dateFilter='date'
      />
    </div>
  )
}

export default Earnings;



const headers = [
  { value: 'invoiceId', active: true, label: 'Invoice ID' },
  { value: 'date', active: true, label: 'Date' },
  { value: 'amount', active: true, label: 'Amount' },
  { value: 'student', active: true, label: 'Members' },
  // {value: 'commission', active: true, label: 'Commission'},
  { value: 'bookingId', active: true, label: 'Booking Id' },
  { value: 'enrollmentId', active: true, label: 'Enrollment Id' },
]