import React, {useState, useEffect} from 'react'
import RightModal from '../../../../components/Modals/RightSide'
import axios from '../../../../utilis/axios'
import MyDropzoneImage from '../../../../components/Dropzone/MyDropzoneImage'



const AddAnnouncement = ({show, setShow, announcements, setAnnouncements}) => {

    const [loading, setLoading] = useState(false)
    const [announcement, setAnnouncement] = useState({title: '', description: '', userType: 'student', cta: '', link: ''});
    const [img, setImg] = useState([]);
    const [errors, setErrors] = useState(Array(4).fill(false));

    
    useEffect(() => {
        setErrors(Array(4).fill(false))
    }, [announcement])

    useEffect(() => {
        setAnnouncement({title: '', description: '', userType: 'student', cta: '', link: ''})
        setImg([])
    }, [show])



    const validate = () => {
        let new_errors = Array(4).fill(false)
        if(announcement.title.length === 0) new_errors[0] = true;
        if(announcement.description.length === 0) new_errors[1] = true;
        // if(announcement.cta.length === 0) new_errors[2] = true;
        // if(announcement.link.length === 0) new_errors[3] = true;
        // if(img.length === 0) new_errors[4] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true;
      }


    const createAnnoucement = async (e) => {
        e.preventDefault()
        if(!validate()) return;
        try{
            setLoading(true);
            const formData = new FormData();
            formData.append('title', announcement.title)
            formData.append('description', announcement.description)
            formData.append('cta', announcement.cta)
            formData.append('userType', announcement.userType)
            formData.append('link', announcement.link)
            if(img && img.length > 0){
                formData.append('img', img[0])
            }
            const res = await axios.post('/api/admin/announcement', formData)
            

            const newAnnouncment = [...announcements];
            newAnnouncment.push(res.data.data);
            setAnnouncements(newAnnouncment)
            
            setLoading(false);
            setShow(false)
            
        } catch(err){
            setLoading(false);
            console.log(err)
        }
    }

 
    return (
        <RightModal show = {show} setShow = {setShow}>
            <div className = 'w-100  mt-1'>
                <div className = '' id = 'details'>
                    <div className = 'h2'>Create a Schedule</div>
                </div>

                <form class = "mt-3 ">

                    <div className="form-group">
                        <label>Title</label>
                        <input
                            className={`form-control ${errors[0] && 'is-invalid'} `}
                            value = {announcement.title}
                            onChange = {(e) => setAnnouncement(prev => ({...prev, title: e.target.value}))}
                        />
                        <div class = 'invalid-feedback'>Please add a title</div>
                    </div>
                    
                    <div className="form-group">
                        <label>Description</label>
                        <textarea
                            className={`form-control ${errors[1] && 'is-invalid'} `}
                            value = {announcement.description}
                            onChange = {(e) => setAnnouncement(prev => ({...prev, description: e.target.value}))}
                        />
                        <div class = 'invalid-feedback'>Please add a description</div>
                    </div>
                    
                    <div className="form-group">
                        <label>User Type</label>
                        <select
                            className={`form-control ${errors[2] && 'is-invalid'} `}
                            value = {announcement.userType}
                            onChange = {(e) => setAnnouncement(prev => ({...prev, userType: e.target.value}))}
                        >
                            <option value = "student">Student</option>
                            <option value = "instructor">Instructor</option>
                        </select>
                        <div class = 'invalid-feedback'>Please add a user type</div>
                    </div>
                  
                    <div className="form-group">
                        <label>CTA text</label>
                        <input
                            className={`form-control ${errors[2] && 'is-invalid'} `}
                            value = {announcement.cta}
                            onChange = {(e) => setAnnouncement(prev => ({...prev, cta: e.target.value}))}
                        />
                        <div class = 'invalid-feedback'>Please add a cta text</div>
                    </div>
                   
                    <div className="form-group">
                        <label>Link</label>
                        <input
                            className={`form-control ${errors[3] && 'is-invalid'} `}
                            value = {announcement.link}
                            onChange = {(e) => setAnnouncement(prev => ({...prev, link: e.target.value}))}
                        />
                        <div class = 'invalid-feedback'>Please add a link</div>
                    </div>

                    <div className="form-group">
                        <label>Image</label>
                        <MyDropzoneImage 
                            onFileChange = {setImg} 
                            height = "10vh"
                            file = {img} 
                            link = ""
                        />
                        {errors[4] && <div class = 'invalid-feedback d-block'>Please add a img</div>}
                    </div>
                                        
                    
                    <button class = "btn btn-primary btn-block mt-6" onClick = {(e) => createAnnoucement(e)} disabled = {loading}>
                        {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                        Create Annoucement
                    </button>
                    
                </form>
                
            </div>
        </RightModal>
    )
}

export default AddAnnouncement;





