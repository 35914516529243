export const SelectStyle = {
        control: (provided, state) => ({
            ...provided,
            borderColor: '#d1dded',
            borderRadius: '.375rem',
            minHeight: '40px',
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 100000,
        }),
    }

export const smallSelectStyle = {
        control: (provided, state) => ({
            ...provided,
            borderColor: '#d1dded',
            borderRadius: '.375rem',
            maxHeight: '20px!important',
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 100000,
        }),
}

export const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: '#ffedec',
      primary: '#ff3f3a',
    },
})




