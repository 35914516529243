const customStyles = {
    Select: {
        control: (provided, state) => ({
            ...provided,
            borderColor: '#d1dded',
            borderRadius: '.375rem',
            minHeight: '40px',
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 100000,
        }),
      },

      smallSelect: {
        control: (provided, state) => ({
            ...provided,
            borderColor: '#d1dded',
            borderRadius: '.375rem',
            height: '20px',
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 100000,
        }),
      },

      bigSelect: {
        control: (provided, state) => ({
            ...provided,
            height: '45px',
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 100000,
        }),
      },

      xxlSelect: {
        control: (provided, state) => ({
            ...provided,
            height: '75px',
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: 100000,
        }),
      }
}




export default customStyles;